<template>
  <div
    class="bounce-top card absolute-center"
    :style="{
      display: 'flex',
      flexDirection: 'column',
      gap: '0.5rem',
      justifyContent: 'center',
      zIndex: '1000',
      backgroundColor: 'rgba(255, 255, 255, 0.9)',
      padding: '1rem',
      width: '18rem',
      '--top-margin': getTopMargin(),
    }"
    :hidden="boardAnimationRunning"
  >
    <IntroGameSelection v-if="challengeState == ChallengeState.StartingIntro" />
    <NewGameSelection
      v-if="
        gameId != null &&
        (challengeState == ChallengeState.Aborted ||
          challengeState == ChallengeState.FinishedCasualWin ||
          challengeState == ChallengeState.FinishedCasualNotWin ||
          challengeState == ChallengeState.FinishedRatedWin ||
          challengeState == ChallengeState.FinishedRatedNotWin ||
          challengeState == ChallengeState.FinishedDailyMatchupWin ||
          challengeState == ChallengeState.FinishedDailyMatchupNotWin)
      "
      :challengeState="challengeState"
      :finishedGameId="gameId!"
      @userInput="userInput"
    />

    <div
      v-if="challengeState == ChallengeState.StartingCasual"
      style="display: flex; flex-direction: column; align-items: center; margin: -3rem 0 2rem 0"
    >
      <div class="bot-and-name">
        <img
          alt="Bot profile image"
          class="profile-image-circle"
          :style="{ backgroundColor: bot!.config.boardbg, border: '2px solid #444' }"
          :src="ps.img(bot!.id, ImageType.BotProfile, '175')"
        />
      </div>
      <a
        type="button"
        class="btn btn-info accept-button-text ph-no-capture"
        style="font-size: 1.3rem"
        @click="
          () => {
            startCasualGame();
            track('start_game_box', 'start_casual', 'click');
          }
        "
      >
        <i class="fa-duotone fa-swords" /> Start game
      </a>
    </div>
    <div
      v-if="
        challengeState == ChallengeState.StartingPractice ||
        challengeState == ChallengeState.StartingCustomChallenge
      "
    >
      <div
        style="
          display: flex;
          align-items: center;
          flex-direction: column;
          margin-bottom: 2rem;
          gap: 1rem;
        "
      >
        <ProfileSelector
          :pre-selected-bot-id="customChallengeSelectedBotId"
          :default-to-first-in-list="true"
          @selected-bot="selectBotFromProfileSelector"
        />
        <button
          v-if="customChallengeSelectedBotId != null && !loadingBotForChallenge"
          class="btn btn-info accept-button-text ph-no-capture"
          @click="
            () => {
              if (!customChallengeSelectedBotId) return;
              loadingBotForChallenge = true;
              const selectedBotId = customChallengeSelectedBotId;
              emit('userInput', {
                type: UserInput.SelectBotForChallenge,
                data: selectedBotId,
              });
              track('game_arena', 'select_bot_for_challenge', 'click', {
                botId: selectedBotId,
              });
            }
          "
        >
          Start challenge
        </button>

        <div v-if="loadingBotForChallenge" class="text-center">
          <LoaderNew size="3rem" />
        </div>
      </div>
    </div>
    <div
      v-if="challengeState == ChallengeState.StartingChallenge"
      style="display: flex; flex-direction: column; align-items: center; margin: -3rem 0 2rem 0"
    >
      <div class="bot-and-name">
        <img
          alt="Bot profile image"
          class="profile-image-circle"
          :style="{ backgroundColor: bot!.config.boardbg, border: '2px solid #444' }"
          :src="ps.img(bot!.id, ImageType.BotProfile, '175')"
        />
      </div>
      <a
        type="button"
        class="btn btn-info accept-button-text ph-no-capture"
        @click="
          () => {
            emit('userInput', { type: UserInput.StartChallenge });
            track('start_game_box', 'start_challenge', 'click');
          }
        "
      >
        <i class="fa-duotone fa-swords" /> Start challenge
      </a>
    </div>
    <div
      v-if="challengeState == ChallengeState.StartingDailyPosition"
      style="display: flex; flex-direction: column; align-items: center; margin: -3rem 0 2rem 0"
    >
      <div class="bot-and-name">
        <img
          alt="Bot profile image"
          class="profile-image-circle"
          :style="{ backgroundColor: bot!.config.boardbg, border: '2px solid #444' }"
          :src="ps.img(bot!.id, ImageType.BotProfile, '175')"
        />
      </div>
      <a
        type="button"
        class="btn btn-info accept-button-text ph-no-capture"
        @click="
          () => {
            emit('userInput', { type: UserInput.StartDailyPosition });
            track('start_game_box', 'start_daily_position', 'click');
          }
        "
      >
        <i class="fa-duotone fa-swords" /> Start challenge
      </a>
    </div>
    <div
      v-if="challengeState == ChallengeState.StartingDailyMatchup"
      style="display: flex; flex-direction: column; align-items: center; margin: -3rem 0 2rem 0"
    >
      <div class="bot-and-name">
        <img
          alt="Bot profile image"
          class="profile-image-circle"
          :style="{ backgroundColor: bot!.config.boardbg, border: '2px solid #444' }"
          :src="ps.img(bot!.id, ImageType.BotProfile, '175')"
        />
      </div>
      <a
        type="button"
        class="btn btn-info accept-button-text ph-no-capture"
        @click="
          () => {
            emit('userInput', { type: UserInput.StartDailyMatchup });
            track('start_game_box', 'start_daily_matchup', 'click');
          }
        "
      >
        <i class="fa-duotone fa-swords" /> Accept the challenge
      </a>
    </div>
    <div
      v-if="
        challengeState == ChallengeState.StartingPuzzleSet ||
        challengeState == ChallengeState.ContinuingPuzzleSet
      "
      style="display: flex; flex-direction: column; align-items: center; margin: -3rem 0 2rem 0"
    >
      <div class="bot-and-name">
        <img
          alt="Bot profile image"
          class="profile-image-circle"
          :style="{ backgroundColor: bot!.config.boardbg, border: '2px solid #444' }"
          :src="ps.img(bot!.id, ImageType.BotProfile, '175')"
        />
      </div>
      <a
        type="button"
        class="btn btn-info bounce-top accept-button-text ph-no-capture"
        @click="
          () => {
            emit('userInput', { type: UserInput.StartPuzzle });
            track('start_game_box', 'start_puzzle', 'click', {
              continuing: challengeState == ChallengeState.ContinuingPuzzleSet,
            });
          }
        "
      >
        <i class="fa-duotone fa-swords" />
        {{ challengeState == ChallengeState.ContinuingPuzzleSet ? 'Continue' : "Let's go!" }}
      </a>
    </div>

    <div
      v-if="
        alwaysShowSettingsInfo &&
        challengeState != ChallengeState.StartingIntro &&
        challengeState != ChallengeState.StartingPuzzleSet &&
        challengeState != ChallengeState.ContinuingPuzzleSet
      "
      style="text-align: center; margin: 1rem 0 -1.5rem 0"
    >
      <div :class="showSettingsInfo ? 'bounce-top' : ''" style="height: 3rem">
        <span> Change game settings here! </span><br />
        <i
          class="fa-solid fa-reply fa-rotate-by"
          style="color: var(--clr-accent); --fa-rotate-angle: -65deg"
        />
      </div>
    </div>
    <div v-else-if="willShowSettingsInfo" style="text-align: center; margin: 1rem 0 -1.5rem 0">
      <div v-if="!showSettingsInfo" style="height: 3rem">
        <span>&nbsp;</span>
      </div>
      <div v-else class="bounce-top" style="height: 3rem">
        <span> Change game settings here! </span><br />
        <i
          class="fa-solid fa-reply fa-rotate-by"
          style="color: var(--clr-accent); --fa-rotate-angle: -65deg"
        />
      </div>
    </div>
    <GameSettingsSection
      v-if="
        challengeState != ChallengeState.StartingPuzzleSet &&
        challengeState != ChallengeState.ContinuingPuzzleSet &&
        challengeState != ChallengeState.StartingIntro
      "
      :challengeState="challengeState"
      style="margin-top: 1rem"
    />
  </div>
</template>
<script setup lang="ts">
  import { type PropType, onMounted, ref, watch } from 'vue';

  import ProfileSelector from '@/components/common/ProfileSelector.vue';
  import GameSettingsSection from '@/components/games/startgame/GameSettingsSection.vue';
  import IntroGameSelection from '@/components/games/startgame/IntroGameSelection.vue';
  import NewGameSelection from '@/components/games/startgame/NewGameSelection.vue';
  import LoaderNew from '@/components/util/LoaderNew.vue';
  import { useBackgroundStore } from '@/stores/backgroundStore';
  import { usePageStore } from '@/stores/pageStore';
  import type { Bot } from '@/types/apitypes';
  import { ChallengeState, ImageType, UserInput } from '@/types/internaltypes';
  import { track } from '@/util/tracking';
  import { isMobileWidth } from '@/util/util';

  const props = defineProps({
    bot: {
      type: Object as PropType<Bot>,
      required: true,
    },
    challengeState: {
      type: Number as PropType<ChallengeState | null>,
      required: true,
    },
    gameId: {
      type: String,
    },
    boardAnimationRunning: {
      type: Boolean,
      required: true,
    },
  });

  // const ms = useModalsStore();
  const ps = usePageStore();
  const bs = useBackgroundStore();
  const emit = defineEmits(['userInput', 'setBoardBg']);

  const alwaysShowSettingsInfo = true; // Turning on always showing the settings info to test
  const willShowSettingsInfo = ref(false);
  const showSettingsInfo = ref(false);
  const loadingBotForChallenge = ref(false);
  const customChallengeSelectedBotId = ref<string | null>(
    localStorage.getItem('customChallengeSelectedBotId')
  );

  onMounted(() => {
    if (!customChallengeSelectedBotId.value && props.bot) {
      customChallengeSelectedBotId.value = props.bot.id;
    }
  });

  watch(
    () => props.boardAnimationRunning,
    () => {
      if (!props.boardAnimationRunning) {
        checkSettingsInfo();
      }
    }
  );

  function getTopMargin() {
    if (!isMobileWidth()) {
      return '5rem';
    } else if (
      props.challengeState == ChallengeState.StartingIntro ||
      props.challengeState == ChallengeState.Aborted ||
      props.challengeState == ChallengeState.FinishedCasualWin ||
      props.challengeState == ChallengeState.FinishedCasualNotWin ||
      props.challengeState == ChallengeState.FinishedRatedWin ||
      props.challengeState == ChallengeState.FinishedRatedNotWin
    ) {
      // We're in a state where the start game box does not have a image above it so no need for margin
      return '0rem';
    } else {
      return '2rem';
    }
  }

  function checkSettingsInfo() {
    // Check if we should show the game settings reminder
    try {
      if (
        localStorage.getItem('gamesettings_flash') == null ||
        props.challengeState == ChallengeState.StartingPuzzleSet ||
        props.challengeState == ChallengeState.ContinuingPuzzleSet ||
        props.challengeState == ChallengeState.StartingIntro
      ) {
        // Either no localstorage item (meaning it was removed so we've probably already shown the reminder) or
        // we're in a state where we're not showing settings
        return;
      }

      const gameSettingsFlashState = parseInt(localStorage.getItem('gamesettings_flash') ?? '');

      if (gameSettingsFlashState == null) {
        return;
      }

      if (gameSettingsFlashState >= 1) {
        willShowSettingsInfo.value = true; // Prepare to show the settings info (so the element don't jump when it shows up)
        // Time to show the settings info
        setTimeout(() => {
          localStorage.removeItem('gamesettings_flash');
          showSettingsInfo.value = true;
        }, 2000);
      } else if (gameSettingsFlashState > 5) {
        // The value somehow got above the last state, so time to remove it
        localStorage.removeItem('gamesettings_flash');
      } else {
        // Not time to show the settings info yet, so increment the state
        localStorage.setItem('gamesettings_flash', (gameSettingsFlashState + 1).toString());
      }
    } catch (e) {
      // Couldn't parse the value, so it's set to something strange, just remove it and ignore it
      localStorage.removeItem('gamesettings_flash');
      return;
    }
  }

  function startCasualGame() {
    emit('userInput', { type: UserInput.StartCasual });
  }

  function userInput(input: any) {
    emit('userInput', input);
  }

  function selectBotFromProfileSelector(bot: Bot | null) {
    if (bot) {
      localStorage.setItem('customChallengeSelectedBotId', bot.id);
      customChallengeSelectedBotId.value = bot.id;
      bs.setBackground(ps.img(bot.id, ImageType.BotBackground, null));
      emit('setBoardBg', bot.config.boardbg);
    } else {
      localStorage.removeItem('customChallengeSelectedBotId');
      customChallengeSelectedBotId.value = null;
    }
  }
</script>

<style scoped>
  .btn {
    --bs-btn-border-color: #333;
  }

  .absolute-center {
    position: absolute;
    margin: auto;
    width: fit-content;
    height: fit-content;
    /*noinspection CssUnresolvedCustomProperty*/
    top: var(--top-margin);
    bottom: 0;
    left: 0;
    right: 0;
    bottom: auto;
  }

  .accept-button-text {
    font-size: 1.3rem;
  }

  h2 {
    text-align: center;
    margin-block: 1rem;
  }

  .profile-image-circle {
    position: absolute;
    width: 4rem;
    height: 4rem;
    z-index: 10;
    border-radius: 50%;
    border: 1.5px solid #444;
  }

  .bot-and-name {
    position: relative;
    width: 4rem;
    height: 5rem;
    margin: auto;
    z-index: 100;
  }

  .card-text p {
    color: green;
  }

  @media (max-width: 1199px) {
    .profile-image-circle {
      width: 3.5rem;
      height: 3.5rem;
    }
    .quote-box {
      min-height: 8rem;
    }
  }
</style>
