<template>
  <div v-if="loading" class="text-center" style="height: 4em">
    <Loader />
  </div>
  <div v-if="!expanded && !loading" class="profile-selector-container">
    <div class="botSelected">
      <button
        v-if="selectedBot"
        @click="
          () => {
            expandBox();
            track('common', 'profile_selector_expand', 'click', {
              botSelected: true,
            });
          }
        "
        class="d-flex btn align-items-center flex-column ph-no-capture"
        role="button"
      >
        <span style="font-size: 0.8rem">Select opponent</span>
        <ProfileBanner :bot="selectedBot!" />
        <div class="profile-selector-container-expanded-after" />
      </button>
      <button
        v-else
        class="btn btn-info ph-no-capture"
        @click="
          () => {
            expandBox();
            track('common', 'profile_selector_expand', 'click', {
              botSelected: false,
            });
          }
        "
      >
        "Select opponent"
      </button>
    </div>
  </div>
  <Transition name="bounce">
    <div v-if="expanded && !loading">
      <div class="profile-selector-container-expanded-before" />
      <div
        ref="listOfBots"
        id="profile-selector-container-expanded"
        class="profile-selector-container-expanded"
      >
        <Loader v-if="loading" />

        <ProfileBanner
          :bot="bot"
          v-else
          @click="
            () => {
              selectBot(bot);
              track('common', 'profile_selector_select_bot', 'click', {
                selectedBotId: bot.id,
              });
            }
          "
          v-for="(bot, index) in bots"
          :key="index"
          :id="'botbanner' + bot.id"
          class="mb-2 ph-no-capture"
          role="button"
        />
        <div
          v-if="!getPremiumState()"
          style="
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 7rem;
            margin: auto;
            color: var(--clr-accent);
            text-align: center;
          "
          class="ph-no-capture"
          role="button"
          @click="
            () => {
              router.push({
                name: 'premium',
              });
              track('profile_selector', 'go_premium', 'click', null);
            }
          "
        >
          <span><i class="fa-solid fa-crown" style="color: var(--clr-premium-yellow)" /></span>
          <span>Get premium to access all 500 bots</span>
        </div>
      </div>
      <div class="profile-selector-container-expanded-after" />
    </div>
  </Transition>
</template>

<script setup lang="ts">
  import type { PropType, Ref } from 'vue';
  import { nextTick, ref } from 'vue';
  import { useRouter } from 'vue-router';

  import ProfileBanner from '@/components/common/ProfileBanner.vue';
  import Loader from '@/components/util/Loader.vue';
  import { useBotsStore } from '@/stores/botStore';
  import { useModalsStore } from '@/stores/modalsStore';
  import { useUserStore } from '@/stores/userStore';
  import type { Bot } from '@/types/apitypes';
  import { getPremiumState } from '@/util/premium';
  import { track } from '@/util/tracking';

  const router = useRouter();
  const bs = useBotsStore();
  const ms = useModalsStore();
  const emit = defineEmits(['selected-bot']);

  const props = defineProps({
    preSelectedBotId: {
      type: String as PropType<string | null>,
      default: null,
    },
    defaultToFirstInList: {
      type: Boolean,
      default: false,
    },
  });

  const listOfBots = ref<HTMLElement | null>(null);
  const loading = ref(true);
  const bots: Ref<Bot[] | null> = ref(null);
  const sortType = ref<'Rating' | 'Name'>('Rating'); // For now no way to change this
  const expanded = ref(false);
  const selectedBot: Ref<Bot | null> = ref(null);

  bs.refresh().then(() => {
    bots.value = bs.list.slice(0).filter((bot) => getPremiumState() || bot.premium == 'open');
    orderBots();
    if (props.preSelectedBotId) {
      selectBot(bots.value.find((b) => b.id == props.preSelectedBotId) ?? null);
    } else if (props.defaultToFirstInList && bots.value.length > 0) {
      selectBot(bots.value[0]);
    }
    loading.value = false;
  });

  function selectBot(bot: Bot | null) {
    selectedBot.value = bot;
    expanded.value = false;

    emit('selected-bot', selectedBot.value);
  }

  function expandBox() {
    expanded.value = true;
    if (selectedBot.value != null) {
      nextTick().then(() => {
        if (selectedBot.value != null) {
          scrollTo(selectedBot.value.id);
        }
      });
      scrollTo(selectedBot.value.id);
    }
  }

  const orderBots = () => {
    if (bots.value == null) {
      return;
    }

    if (sortType.value == 'Rating') {
      bots.value.sort((a, b) => a.strength.estimated_elo - b.strength.estimated_elo);
    } else if (sortType.value == 'Name') {
      bots.value.sort((a, b) => (a.name > b.name ? 1 : -1));
    }
  };

  function scrollTo(botId: string) {
    try {
      const container = document.getElementById('profile-selector-container-expanded');
      const anchor = document.getElementById('botbanner' + botId); // Adjust the selector to target the anchor you want.
      // @ts-ignore
      const anchorPosition = anchor.offsetTop;
      // @ts-ignore
      const containerCenter = container.offsetHeight / 2;
      // @ts-ignore
      const newScrollTop =
        // @ts-ignore
        anchorPosition - containerCenter + anchor.offsetHeight / 2;
      // @ts-ignore
      container.scrollTop = newScrollTop;
    } catch (e) {
      // Ignore, it seems that sometimes there's a race condition here where the anchor element isn't yet rendered or so, just don't scroll then it's not the end of the world
    }
  }
</script>

<style scoped>
  .bounce-enter-active {
    transform-origin: top;
    animation: bounce-in 0.5s;
  }
  @keyframes bounce-in {
    0% {
      transform: scaleY(0);
    }
    50% {
      transform: scaleY(1);
    }
    100% {
      transform: scaleY(1);
    }
  }
  .profile-selector-container {
    cursor: pointer;
  }

  .profile-selector-container-expanded {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    height: 20rem;
    overflow-y: auto;
  }

  .profile-selector-container-expanded::-webkit-scrollbar {
    display: none;
  }

  .botSelected {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }

  .profile-selector-container-expanded-after {
    left: 25%;
    bottom: 0;
    width: 0; /* Set width to 0 */
    height: 0; /* Set height to 0 */
    border-left: 5rem solid transparent; /* Left border, half the width of the triangle */
    border-right: 5rem solid transparent; /* Right border, half the width of the triangle */
    border-top: 1rem solid var(--clr-main-lighter); /* Top border, height and color of the triangle */
    margin: 0.2rem auto;
  }
  .profile-selector-container-expanded-before {
    left: 25%;
    bottom: 0;
    width: 0; /* Set width to 0 */
    height: 0; /* Set height to 0 */
    border-left: 5rem solid transparent; /* Left border, half the width of the triangle */
    border-right: 5rem solid transparent; /* Right border, half the width of the triangle */
    border-top: 1rem solid var(--clr-main-lighter); /* Top border, height and color of the triangle */
    margin: 0.2rem auto;
    transform: rotate(180deg);
  }
</style>
