export function useWinLossStats() {
  /**
   * Calculates the ratio of value1 to the sum of value1 and value2 as a percentage
   * @param value1 First value (typically wins)
   * @param value2 Second value (typically losses)
   * @returns Ratio as a decimal between 0 and 1
   */
  const ratioPercent = (value1: number, value2: number) => {
    let ratio = value1 / (value1 + value2);
    if (ratio > 1) {
      ratio = 1;
    } else if (ratio < 0) {
      ratio = 0;
    }
    return parseFloat(ratio.toFixed(3));
  };

  /**
   * Formats win/loss statistics as a percentage text
   * @param wins Number of wins
   * @param losses Number of losses
   * @returns Formatted win percentage text
   */
  const getWinLossText = (wins: number, losses: number) => {
    if (wins == 0 && losses == 0) {
      return '-';
    } else if (wins == 0) {
      return '0%';
    } else if (losses == 0) {
      return '100%';
    }

    return (ratioPercent(wins, losses) * 100).toFixed(1) + '%';
  };

  /**
   * Formats bot score as text with appropriate sign
   * @param bot Bot object with score property
   * @returns Formatted score text
   */
  const equalBotText = (bot: any) => {
    if (bot.score > 0) {
      return '+' + bot.score + ' score';
    }

    if (bot.score < 0) {
      return bot.score + ' score';
    }

    return '±0 score';
  };

  return {
    ratioPercent,
    getWinLossText,
    equalBotText,
  };
}
