<template>
  <div style="display: flex; flex-direction: column; align-items: center">
    <ConfettiExplosion
      :stageHeight="getViewportSize().height"
      :stageWidth="getViewportSize().width"
    />
    <div
      style="
        margin: 0rem 0 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
      "
    >
      <span style="font-size: 1.5rem">Let's play!</span>
      <span>Choose your first opponent</span>
      <div style="display: flex; flex-direction: column; gap: 2rem; margin: 1rem 0">
        <div
          v-for="(b, i) in introBots"
          :key="'introBot' + i"
          style="display: flex; flex-direction: column; align-items: center; color: #43ccff"
        >
          <span>{{ b.difficulty }}</span>
          <div
            class="bot-and-name ph-no-capture"
            role="button"
            @click="
              () => {
                chooseAndStart(b.id);
                track('start_game_box', 'intro_' + b.difficulty, 'click');
              }
            "
          >
            <div style="position: relative">
              <img
                alt="Bot profile image"
                class="profile-image-circle"
                :style="{
                  backgroundColor: b.bg,
                  border: '2px solid #444',
                }"
                :src="ps.img(b.id, ImageType.BotProfile, '175')"
              />
            </div>
            <div
              class="card profile-name-text"
              :style="{
                backgroundColor: b.bg,
                zIndex: 10,
                whiteSpace: 'nowrap',
                fontSize: '0.9rem',
                textAlign: 'center',
              }"
            >
              {{ b.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
  import ConfettiExplosion from 'vue-confetti-explosion';
  import { useRouter } from 'vue-router';

  import { useBotsStore } from '@/stores/botStore';
  import { usePageStore } from '@/stores/pageStore';
  import { useUserStore } from '@/stores/userStore';
  import { ImageType } from '@/types/internaltypes';
  import { track } from '@/util/tracking';
  import { getViewportSize } from '@/util/util';

  const router = useRouter();
  const us = useUserStore();
  const bs = useBotsStore();
  const ps = usePageStore();

  const introBots = [
    { id: 'UCQPF', name: 'Ethan Snide', bg: '#4F98F6', difficulty: 'Beginner' },

    {
      id: 'cjgqx',
      name: 'Pawnt Perry',
      bg: '#B5FF8D',
      difficulty: 'Intermediate',
    },
    {
      id: 'tvgBZ',
      name: 'Pawla Koenig',
      bg: '#F35F1C',
      difficulty: 'Advanced',
    },
  ];

  function chooseAndStart(botId: string) {
    window.location.href = window.location.origin + '/game/?bid=' + botId + '&type=intro&auto=true';
  }
</script>
<style scoped>
  .profile-image-circle {
    width: 5rem;
    aspect-ratio: 1;
    z-index: 10;
    border-radius: 50%;
    border: 1.5px solid #444;
  }

  .profile-name-text {
    position: absolute;
    bottom: -1rem;
    padding: 0 0.5rem;
  }

  .bot-and-name {
    position: relative;
    display: flex;
    justify-content: center;
    margin: auto;
    z-index: 100;
  }

  .bot-and-name:hover {
    filter: drop-shadow(0.1rem 0.1rem 0.2rem #000);
  }
</style>
