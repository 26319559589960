<template>
  <div v-if="loading || (wins == 0 && losses == 0 && draws == 0)" style="width: 100%">
    <div class="percentage-bar" style="height: 2rem">
      <div
        style="
            display: flex;
            width: 100%;
            justifyContent: center;
            text-align: center;
            alignItems: end;
            fontSize: 1.2rem;
            minWidth: 5rem;
          },
        "
      >
        -
      </div>
    </div>
    <div class="card" style="height: 34px; background-color: #f0f0f0" />
    <div class="percentage-bar" style="height: 2rem">
      <div
        :style="[
          winsStyle,
          {
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'start',
            minWidth: '5rem',
          },
        ]"
      >
        -
      </div>
    </div>
  </div>
  <div v-else style="width: 100%">
    <div class="percentage-bar" style="height: 2rem">
      <div
        :style="[
          winsStyle,
          {
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'end',
            fontSize: '1.2rem',
            minWidth: '5rem',
          },
        ]"
      >
        <span
          ><i class="fa-solid fa-circle-star" style="color: var(--clr-accent)" />
          {{ winsPercent }}%</span
        >
      </div>
      <div
        :style="[
          drawsStyle,
          {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'end',
            fontSize: '1.2rem',
          },
        ]"
      >
        <span
          ><i class="fa-solid fa-circle-half-stroke" style="color: var(--clr-accent)" />
          {{ drawsPercent }}%</span
        >
      </div>
      <div
        :style="[
          lossesStyle,
          {
            display: 'flex',
            justifyContent: 'end',
            alignItems: 'end',
            fontSize: '1.2rem',
            minWidth: '5rem',
          },
        ]"
      >
        <span
          ><i class="fa-solid fa-circle-minus" style="color: var(--clr-accent)" />
          {{ lossesPercent }}%</span
        >
      </div>
    </div>
    <div class="card percentage-bar">
      <div
        :style="[
          winsStyle,
          {
            backgroundColor: 'var(--clr-accent)',
          },
        ]"
      />
      <div
        :style="[
          drawsStyle,
          {
            backgroundColor: 'var(--clr-main-lightest)',
          },
        ]"
      />
      <div
        :style="[
          lossesStyle,
          {
            backgroundColor: 'var(--clr-accent2)',
          },
        ]"
      />
    </div>
    <div class="percentage-bar" style="height: 2rem">
      <div
        :style="[
          winsStyle,
          {
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'start',
            minWidth: '5rem',
          },
        ]"
      >
        {{ props.wins }} Won
      </div>
      <div
        :style="[drawsStyle, { display: 'flex', justifyContent: 'center', alignItems: 'start' }]"
      >
        {{ props.draws }} Drawn
      </div>
      <div
        :style="[
          lossesStyle,
          {
            display: 'flex',
            justifyContent: 'end',
            alignItems: 'start',
            minWidth: '5rem',
          },
        ]"
      >
        {{ props.losses }} Lost
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ref, watch } from 'vue';

  const props = defineProps({
    wins: {
      type: Number,
      required: true,
    },
    draws: {
      type: Number,
      required: true,
    },
    losses: {
      type: Number,
      required: true,
    },
    loading: {
      type: Boolean,
      default: true,
    },
  });

  // Define the percentages for each section
  const winsPercent = ref();
  const drawsPercent = ref();
  const lossesPercent = ref();

  // Define styles for each section
  const winsStyle = ref();

  const drawsStyle = ref();

  const lossesStyle = ref();

  watch(
    () => [props.wins, props.draws, props.losses],
    () => {
      calulatePercentages();
    },
    { immediate: true }
  );

  function calulatePercentages() {
    winsPercent.value = Math.round((props.wins / (props.wins + props.draws + props.losses)) * 100);
    drawsPercent.value = Math.round(
      (props.draws / (props.wins + props.draws + props.losses)) * 100
    );
    lossesPercent.value = Math.round(
      (props.losses / (props.wins + props.draws + props.losses)) * 100
    );

    winsStyle.value = {
      width: winsPercent.value + '%',
      height: '30px',
    };

    drawsStyle.value = {
      width: drawsPercent.value + '%',
      height: '30px',
    };

    lossesStyle.value = {
      width: lossesPercent.value + '%',
      height: '30px',
    };
  }
</script>

<style scoped>
  .percentage-bar {
    width: 100%;
    display: flex;
    flex-direction: row;
    white-space: nowrap;
  }
</style>
