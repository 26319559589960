<template>
  <div id="text-spot" style="overflow-x: hidden; overflow-y: hidden">
    <ConfettiExplosion
      v-if="showConfetti"
      :colors="['#b7ffb7', '#9dff9d', '#41ff41', '#38ff38', '#49ff49']"
      :force="0.7"
      :stageHeight="getViewportSize().height"
      :stageWidth="getViewportSize().width"
      :duration="3500"
      :particleCount="150"
    />
    <div id="inner-text" class="plus-points">+{{ points }}</div>
  </div>
</template>

<script setup lang="ts">
  import { nextTick, onMounted, ref } from 'vue';
  import ConfettiExplosion from 'vue-confetti-explosion';

  import { usePageStore } from '@/stores/pageStore';
  import { getViewportSize } from '@/util/util.js';

  const props = defineProps({
    points: {
      type: Number,
      required: true,
    },
  });

  const showConfetti = ref(false);

  const explode = async () => {
    showConfetti.value = false;
    await nextTick();
    showConfetti.value = true;
  };

  const start = () => {
    usePageStore().setPlaySound('green-star');

    const innerTextElement = document.getElementById('inner-text');

    innerTextElement!.classList.add('tracking-in-contract');
    explode();
    setTimeout(() => {
      showConfetti.value = false;
    }, 2500);

    innerTextElement!.addEventListener('animationend', function (event) {
      innerTextElement!.classList.remove('tracking-in-contract');
      // @ts-ignore
      innerTextElement.classList.add('puff-out-center');
    });
  };

  onMounted(() => {
    start();
  });
</script>

<style scoped>
  .puff-out-center {
    -webkit-animation: puff-out-center 1s cubic-bezier(0.165, 0.84, 0.44, 1) both;
    animation: puff-out-center 1s cubic-bezier(0.165, 0.84, 0.44, 1) both;
  }

  /* ----------------------------------------------
 * Generated by Animista on 2023-10-10 12:48:11
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

  /**
 * ----------------------------------------
 * animation puff-out-center
 * ----------------------------------------
 */
  @-webkit-keyframes puff-out-center {
    0% {
      -webkit-transform: scale(1);
      transform: scale(1);
      -webkit-filter: blur(0px);
      filter: blur(0px);
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(2);
      transform: scale(2);
      -webkit-filter: blur(4px);
      filter: blur(4px);
      opacity: 0;
    }
  }
  @keyframes puff-out-center {
    0% {
      -webkit-transform: scale(1);
      transform: scale(1);
      -webkit-filter: blur(0px);
      filter: blur(0px);
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(2);
      transform: scale(2);
      -webkit-filter: blur(4px);
      filter: blur(4px);
      opacity: 0;
    }
  }
  .tracking-in-contract {
    -webkit-animation: tracking-in-contract 2s cubic-bezier(0.215, 0.61, 0.355, 1) both;
    animation: tracking-in-contract 2s cubic-bezier(0.215, 0.61, 0.355, 1) both;
  }

  /* ----------------------------------------------
 * Generated by Animista on 2023-11-30 22:34:16
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

  /**
 * ----------------------------------------
 * animation tracking-in-contract
 * ----------------------------------------
 */
  @-webkit-keyframes tracking-in-contract {
    0% {
      letter-spacing: 1em;
      opacity: 0;
    }
    40% {
      opacity: 0.6;
    }
    100% {
      letter-spacing: normal;
      opacity: 1;
    }
  }
  @keyframes tracking-in-contract {
    0% {
      letter-spacing: 1em;
      opacity: 0;
    }
    40% {
      opacity: 0.6;
    }
    100% {
      letter-spacing: normal;
      opacity: 1;
    }
  }

  @keyframes dash {
    0% {
      transform: skew(-30deg, 0deg) translateX(300%) scale(0.8);
      opacity: 1;
    }

    40% {
      transform: skew(10deg, 0deg) translateX(100%) scale(0.9);
    }

    60% {
      transform: skew(10deg, 0deg) translateX(-10px) scale(1.2);
    }

    70% {
      transform: skew(0, 0deg) translateX(0) scale(1.3);
    }

    75% {
      transform: skew(0, 0deg) translateX(0) scale(1.3);
    }

    90% {
      transform: skew(0, 0deg) translateX(0) scale(0.85);
    }

    100% {
      transform: translateY(0) scale(1);
      opacity: 1;
    }
  }

  @keyframes done-animating {
    0% {
      transform: scale(1);
    }

    50% {
      transform: scale(2);
    }

    70% {
      transform: scale(2);
    }

    100% {
      transform: scale(0);
    }
  }

  #inner-text {
    position: relative;
    z-index: 1;
  }

  #inner-text.done-animating {
    animation: done-animating 500ms ease-in;
  }

  #text-spot {
    height: 100%;
    width: 100%;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .plus-points {
    display: inline-block;
    font-size: 120px;
    color: #9dff9d;
    letter-spacing: 10px;
    font-family: 'Luckiest Guy';
    font-weight: 100;
    -webkit-text-stroke: 2px black;
  }

  .letter.animate {
    animation: dash 500ms ease-in forwards;
    opacity: 0;
  }
</style>
