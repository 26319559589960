<template>
  <GameArena v-if="type == 'intro'" :type="type" :botId="botId" :autoStart="autoStart == 'true'" />
  <GameArena v-if="type == 'casual'" :type="type" :botId="botId" :autoStart="autoStart == 'true'" />
  <GameArena v-if="type == 'custom'" :type="type" :custom-challenge="customChallenge" />
  <GameArena
    v-if="type == 'challenge'"
    :type="type"
    :challengeId="challengeId"
    :difficulty="difficulty as Difficulty"
  />
  <GameArena v-if="type == 'practice'" :type="type" :practiceId="practiceId" :botId="botId" />
  <GameArena v-if="type == 'puzzle'" :type="type" :puzzleId="puzzleId" />
  <GameArena v-if="type == 'continue'" :type="type" :initialGameId="gameId" />
  <GameArena v-if="type == 'dailymatchup'" :type="type" :challengeId="challengeId" />
  <GameArena
    v-if="type == 'dailyendgame' || type == 'dailymaster'"
    :type="type"
    :challengeId="challengeId"
    :relative-difficulty="difficulty as RelativeDifficulty"
  />
</template>

<script setup lang="ts">
  import { ref } from 'vue';
  import { useRoute } from 'vue-router';

  import GameArena from '@/components/games/GameArena.vue';
  import type { ChallengeFromPosition } from '@/types/apitypes';
  import type { Difficulty, PlayTypes, RelativeDifficulty } from '@/types/internaltypes';

  const route = useRoute();

  const type = ref(route.query.type as PlayTypes);
  const challengeId = ref(route.query.challengeId as string);
  const practiceId = ref(route.query.practiceId as string);
  const puzzleId = ref(route.query.puzzleId as string);
  const gameId = ref(route.query.id as string);
  const botId = ref(route.query.bid as string);
  const side = ref(route.query.side as string);
  const fen = ref(route.query.fen as string);
  const autoStart = ref(route.query.auto as string);

  const difficulty = ref(route.query.difficulty as string);

  const customChallenge = ref<ChallengeFromPosition>({
    id: 'custom',
    type: 'from_position',
    customBot: { botId: botId.value },
    start_position: fen.value,
    user_side: side.value,
  });
</script>
