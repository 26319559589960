import { ref } from 'vue';
import type { Ref } from 'vue';

// Import as a type

export function useStreakInfo(ratingHistory: Ref<any>, timeframe: Ref<string>) {
  const streakInfo = ref({
    consecutiveDaysWithEntries: 0,
    consecutiveMissingDays: 0,
    gamesPerDay: 0,
  });

  const calculateStreakInfo = () => {
    if (ratingHistory.value == null) {
      return {
        consecutiveDaysWithEntries: 0,
        consecutiveMissingDays: 0,
      };
    }

    let filterMillis = 1;

    if (timeframe.value === 'week') {
      filterMillis = new Date().getTime() - 1000 * 60 * 60 * 24 * 7;
    } else if (timeframe.value === 'month') {
      filterMillis = new Date().getTime() - 1000 * 60 * 60 * 24 * 30;
    } else if (timeframe.value === 'year') {
      filterMillis = new Date().getTime() - 1000 * 60 * 60 * 24 * 365;
    }

    const orderedDaysWithRating = JSON.parse(JSON.stringify(ratingHistory.value.daily))
      .filter((r: any) => r.started_at._seconds * 1000 > filterMillis)
      .map((r: any) => {
        return new Date(r.started_at._seconds * 1000);
      })
      .sort((a: any, b: any) => a.getTime() - b.getTime());

    // Calculate the starting date based on daysAgo
    const today = new Date();
    const startDate = new Date();
    startDate.setDate(today.getDate() - 365);

    let currentStreak = 0;
    let currentMissingStreak = 0;

    let maxConsecutiveDaysWithEntries = 0;
    let maxConsecutiveMissingDays = 0;

    // Iterate from startDate to today, checking for gaps and streaks
    let lastEntryDate: Date | null = null;
    let foundFirstEntry = false;

    for (let d = new Date(startDate); d <= today; d.setDate(d.getDate() + 1)) {
      const isEntryFound = orderedDaysWithRating.some(
        (entryDate: Date) => entryDate.toDateString() === d.toDateString()
      );

      if (!foundFirstEntry && !isEntryFound) {
        continue;
      }

      if (isEntryFound) {
        foundFirstEntry = true;
        // Consecutive days with entries found
        currentStreak += 1;
        maxConsecutiveDaysWithEntries = Math.max(maxConsecutiveDaysWithEntries, currentStreak);
        currentMissingStreak = 0; // Reset missing streak
      } else {
        // Missing day found
        currentMissingStreak += 1;
        maxConsecutiveMissingDays = Math.max(maxConsecutiveMissingDays, currentMissingStreak);

        if (lastEntryDate) {
          currentStreak = 0; // Reset the streak when a missing day is found
        }
      }

      lastEntryDate = d; // Update the last checked day
    }

    streakInfo.value = {
      consecutiveDaysWithEntries: maxConsecutiveDaysWithEntries,
      consecutiveMissingDays: maxConsecutiveMissingDays,
      gamesPerDay: calculateGamesPerDay(),
    };

    return streakInfo.value;
  };

  const calculateGamesPerDay = () => {
    if (ratingHistory.value == null) {
      return 0;
    }

    const daysFromNow = (date: Date) => {
      const timeDifference = date.getTime() - new Date().getTime();
      const daysDifference = Math.abs(Math.ceil(timeDifference / (1000 * 3600 * 24)));
      return daysDifference;
    };

    let filterMillis = 1;
    let days = 365;

    if (timeframe.value === 'week') {
      filterMillis = new Date().getTime() - 1000 * 60 * 60 * 24 * 7;
      days = 7;
    } else if (timeframe.value === 'month') {
      filterMillis = new Date().getTime() - 1000 * 60 * 60 * 24 * 30;
      days = 30;
    } else if (timeframe.value === 'year') {
      filterMillis = new Date().getTime() - 1000 * 60 * 60 * 24 * 365;
      days = 365;
    }

    // If we have rating history, we can calculate the days since first game
    if (ratingHistory.value.daily && ratingHistory.value.daily.length > 0) {
      // Sort by date
      const sortedHistory = [...ratingHistory.value.daily].sort(
        (a: any, b: any) => a.started_at._seconds - b.started_at._seconds
      );

      // Filter by timeframe
      const filteredHistory = sortedHistory.filter(
        (r: any) => r.started_at._seconds * 1000 > filterMillis
      );

      if (filteredHistory.length > 0) {
        const firstGame = new Date(filteredHistory[0].started_at._seconds * 1000);
        const daysSinceFirstGame = daysFromNow(firstGame);
        days = Math.min(days, daysSinceFirstGame);
      }
    }

    // Count games in the selected timeframe
    let games = 0;
    if (ratingHistory.value.daily) {
      games = ratingHistory.value.daily.filter(
        (r: any) => r.started_at._seconds * 1000 > filterMillis
      ).length;
    }

    return days > 0 ? parseFloat((games / days).toFixed(2)) : 0;
  };

  return {
    streakInfo,
    calculateStreakInfo,
    calculateGamesPerDay,
  };
}
