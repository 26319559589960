<template>
  <div
    :class="{ card: true, pulsate: lostOnTime }"
    :style="{
      whiteSpace: 'nowrap',
      backgroundColor: props.bg,
      textAlign: 'center',
      minWidth: getMinWidth(),
    }"
  >
    <span class="time" v-if="lostOnTime">
      <span class="doubledigit">00</span>
      <span class="divider">:</span>
      <span class="doubledigit">00</span>
      <span class="divider">.</span><span class="tenths">0</span>
    </span>
    <span class="time" v-else-if="!initialTimeLeft || initialTimeLeft <= 0">
      <span class="doubledigit">00</span>
      <span class="divider" style="">:</span>
      <span class="doubledigit">00</span>
      <span class="divider">.</span>
      <span class="tenths">0</span>
    </span>
    <span class="time" v-else>
      <span class="doubledigit" v-if="initialTimeLeft && initialTimeLeft > 1000 * 60 * 60">{{
        hours
      }}</span>
      <span class="divider" v-if="initialTimeLeft && initialTimeLeft > 1000 * 60 * 60">:</span>
      <span class="doubledigit">{{ minutes }}</span>
      <span class="divider">:</span>
      <span class="doubledigit">{{ seconds }}</span>
      <span class="divider" v-if="initialTimeLeft && initialTimeLeft < 1000 * 20">.</span
      ><span class="tenths" v-if="initialTimeLeft && initialTimeLeft < 1000 * 20">{{
        tenths
      }}</span>
    </span>
  </div>
</template>
<script setup lang="ts">
  import { type PropType, computed, onUnmounted, ref, watch } from 'vue';

  import { useGameStore } from '@/stores/gameStore';
  import { usePageStore } from '@/stores/pageStore';
  import type { TimeControl } from '@/types/apitypes';

  const props = defineProps({
    bg: {
      type: String,
      default: '#FFF',
    },
    timeControl: {
      type: Object as PropType<TimeControl>,
      required: true,
    },
    isUser: {
      type: Boolean,
      required: true,
    },
    pendingMove: {
      type: Boolean,
      default: false,
    },
    lastMoveTimeSpent: {
      type: Number,
    },
    side: {
      type: String,
      required: true,
    },
    gameId: {
      type: String,
      required: true,
    },
    currentTimes: {
      type: Object as PropType<{ white: number; black: number } | null>,
    },
    active: {
      type: Boolean,
      default: false,
    },
    sideLostOnTime: {
      type: String,
    },
  });

  const ps = usePageStore();

  const initialTimeLeftAtStartOfMove = ref<number | null>(null);
  const initialTimeLeft = ref<number | null>(null);
  let timer: number | undefined = undefined;
  const lostOnTime = ref<boolean>(false);

  let ongoingCheckIfGameIsOver = false;
  const checkIfGameIsOverInterval = 1000;
  let lastCheckIfGameIsOver = checkIfGameIsOverInterval; // The first check should trigger immediately

  const playedRunningOutOfTimeSound: Set<string> = new Set();

  const updateTime = () => {
    if (!props.active) {
      return;
    }

    if (
      initialTimeLeftAtStartOfMove.value != null &&
      initialTimeLeft.value !== null &&
      initialTimeLeft.value > 0
    ) {
      initialTimeLeft.value -= 100; // decrement by 100 milliseconds
      if (props.isUser) {
        if (
          initialTimeLeftAtStartOfMove.value >= 20 * 1000 &&
          initialTimeLeft.value < 20 * 1000 &&
          !playedRunningOutOfTimeSound.has(props.gameId)
        ) {
          playedRunningOutOfTimeSound.add(props.gameId);
          usePageStore().setPlaySound('multimedia-192');
        }

        // Update the current move time (only for the user's side
        ps.setCurrentMoveTime(initialTimeLeftAtStartOfMove.value - initialTimeLeft.value);
      }
    } else {
      lastCheckIfGameIsOver += 100;

      if (lastCheckIfGameIsOver >= checkIfGameIsOverInterval) {
        lastCheckIfGameIsOver = 0;

        // This side is out of time, so we should check with the server if the game is over
        if (!ongoingCheckIfGameIsOver) {
          useGameStore()
            .refreshGame(props.gameId)
            .then((game) => {
              ongoingCheckIfGameIsOver = false;
              if (!game.isActive) {
                clearInterval(timer);
                timer = undefined;
              }
            });
        }
      }
    }
  };

  watch(
    () => props.sideLostOnTime,
    () => {
      lostOnTime.value = props.sideLostOnTime === props.side;
    }
  );

  watch(
    () => props.pendingMove,
    () => {
      if (props.pendingMove && initialTimeLeft.value != null) {
        initialTimeLeft.value += props.timeControl.increment;
      }
    }
  );

  watch(
    () => props.currentTimes,
    () => {
      if (props.currentTimes == null) {
        initialTimeLeftAtStartOfMove.value = null;
        initialTimeLeft.value = null;
        return;
      }

      initialTimeLeft.value = initialTimeLeftAtStartOfMove.value =
        props.side === 'white' ? props.currentTimes?.white : props.currentTimes?.black;

      if (props.lastMoveTimeSpent) {
        initialTimeLeft.value -= props.lastMoveTimeSpent;
      }

      clearInterval(timer);
      timer = setInterval(updateTime, 100);
    },
    { immediate: true }
  );

  onUnmounted(() => {
    if (timer) {
      clearInterval(timer);
    }
  });

  function getMinWidth() {
    if (!initialTimeLeft.value) {
      // No idea here so don't set a min width
      return '0rem';
    }
    if (initialTimeLeft.value >= 1000 * 60 * 60) {
      return '7rem';
    }
    if (initialTimeLeft.value < 1000 * 60 * 60 && initialTimeLeft.value >= 1000 * 20) {
      return '5rem';
    }

    // Less than 20 seconds left
    return '6rem';
  }

  function pad(number: number, size: number) {
    let s = String(number);
    while (s.length < size) {
      s = '0' + s;
    }
    return s;
  }

  const totalSeconds = computed(() => Math.floor((initialTimeLeft.value ?? 0) / 1000));

  const hours = computed(() => {
    return pad(Math.floor(totalSeconds.value / 3600), 2);
  });

  const minutes = computed(() => {
    return pad(Math.floor((totalSeconds.value % 3600) / 60), 2);
  });

  const seconds = computed(() => {
    return pad(totalSeconds.value % 60, 2);
  });

  const tenths = computed(() => {
    const tenths = Math.floor(((initialTimeLeft.value ?? 0) % 1000) / 100);
    return tenths.toString();
  });
</script>

<style scoped>
  .time {
    font-size: 1.5rem;
    padding-inline: 0.5rem;
  }

  .doubledigit {
    display: inline-block;
    width: 2rem;
  }

  .tenths {
    font-size: 1.2rem;
    display: inline-block;
    width: 1rem;
  }

  .divider {
    opacity: 0.2;
  }

  .pulsate {
    animation: pulsate 2s infinite ease-in-out;
  }

  @keyframes pulsate {
    0%,
    20% {
      background-color: red;
    }
    50% {
      background-color: white;
    }
    80%,
    100% {
      background-color: red;
    }
  }

  @media (max-width: 768px) {
    .time {
      font-size: 1.2rem;
    }

    .tenths {
      font-size: 1rem;
    }
  }
</style>
