<template>
  <div>
    <div class="card">
      <div class="card-body text-center">
        <div class="profile-container d-flex flex-row gap-5">
          <div
            class="card profile-name-text ph-no-capture"
            :style="{ backgroundColor: opponentColor }"
            role="button"
            @click="
              () => {
                ms.showPuzzleInfo(() => {});
                track('puzzle_box', 'profile_circle', 'click');
              }
            "
          >
            <div class="card-body" style="margin-top: -0.75rem">
              <i
                class="fa-solid fa-info-circle"
                :style="{
                  color: 'white',
                  fontSize: '1rem',
                  marginRight: '0.5rem',
                }"
              />Puzzles
            </div>
          </div>
          <MoveNavigation
            v-if="currentViewingPuzzle != null && isFullWidth()"
            :opponent-color="opponentColor"
            :moves="puzzles[highlightIndex].moves.split(' ').length"
            :viewing-history-ply="viewingHistoryPly"
            @user-input="emitUserInput"
          />
        </div>
        <div class="positions">
          <PuzzleBox
            :puzzles="puzzles"
            size="medium"
            :highlight-index="highlightIndex"
            @user-input="emitUserInput"
          />
        </div>
      </div>
    </div>
    <div class="bottom-buttons" v-if="challengeState == ChallengeState.ViewingPuzzle">
      <a
        :href="'https://lichess.org/training/' + currentViewingPuzzle!.source_id"
        target="_blank"
        style="text-decoration: none"
        class="profile-image-circle ph-no-capture"
        :style="{
          backgroundColor: opponentColor,
        }"
        v-tippy="{
          content: 'Analyze on Lichess',
        }"
        role="button"
        @click="track('puzzle_box', 'analyze_lichess', 'click')"
      >
        <img alt="Lichess logo" src="@/assets/images/lichesslogo.svg" style="height: 1.5rem" />
      </a>
      <button
        style="text-decoration: none"
        class="profile-image-circle ph-no-capture"
        :style="{
          backgroundColor: opponentColor,
        }"
        v-tippy="{
          content: 'Copy PGN to clipboard',
          placement: 'left',
        }"
        @click="
          () => {
            copyPgn();
            track('puzzle_box', 'copy_pgn', 'click');
          }
        "
        role="button"
      >
        <i class="fa-regular fa-copy" style="font-size: 1.5rem" />
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { type PropType, ref, watch } from 'vue';
  import { useToast } from 'vue-toast-notification';

  import MoveNavigation from '@/components/games/MoveNavigation.vue';
  import PuzzleBox from '@/components/puzzles/PuzzleBox.vue';
  import { useModalsStore } from '@/stores/modalsStore';
  import type { Puzzle } from '@/types/apitypes';
  import { ChallengeState, UserInput } from '@/types/internaltypes';
  import { track } from '@/util/tracking';
  import { isFullWidth, puzzleToPgn } from '@/util/util';

  const ms = useModalsStore();

  const props = defineProps({
    puzzles: { type: Array as PropType<Puzzle[]>, required: true },
    opponentColor: { type: String, default: '#fff' },
    hints: { type: Number, default: 0 },
    maxHints: { type: Number, default: 3 },
    currentPuzzleIndex: { type: Number, default: -1 },
    challengeState: {
      type: Number as PropType<ChallengeState | null>,
      default: null,
    },
    currentViewingPuzzle: {
      type: Object as PropType<Puzzle | null>,
      default: null,
    },
    viewingHistoryPly: {
      type: null as unknown as PropType<number | null>,
      default: null,
    },
  });

  const highlightIndex = ref(-1);

  watch(
    props,
    () => {
      updateCurrentHighlightIndex();
    },
    { immediate: true, deep: true }
  );

  const emit = defineEmits(['userInput']);

  function copyPgn() {
    const pgn = puzzleToPgn(props.currentViewingPuzzle);

    if (pgn == '') {
      useToast().error('No moves found');
      return;
    }

    navigator.clipboard
      .writeText(pgn)
      .then(() => {
        useToast().success('Copied PGN to clipboard');
      })
      .catch((e) => {
        useToast().error(
          'Failed copying PGN to clipboard, make sure you allow the browser to copy, the error was: ' +
            e
        );
      });
  }

  function emitUserInput(userInput: any) {
    emit('userInput', userInput);
  }

  function updateCurrentHighlightIndex() {
    if (props.currentViewingPuzzle != null) {
      highlightIndex.value = props.puzzles.findIndex((p) => p.id == props.currentViewingPuzzle!.id);
    } else {
      highlightIndex.value = props.currentPuzzleIndex;
    }
  }
</script>

<style scoped>
  .bottom-buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 1rem;
    margin-top: -1.5rem;
  }

  .profile-image-circle {
    height: 2.5rem;
    width: 2.5rem;
    z-index: 10;
    border-radius: 50%;
    border: 1px solid #444;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .profile-name-text {
    height: 2rem;
    width: 8rem;
    text-align: center;
  }

  .profile-container {
    position: absolute;
    top: -0.75rem;
    left: 1rem;
  }
  .positions {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    flex-wrap: wrap;
    justify-content: center;
    margin: 2rem 0rem 1rem 0rem;
  }
</style>
