import { markRaw, shallowRef } from 'vue';

import axios from 'axios';
import { makeUci } from 'chessops';
import { makeFen } from 'chessops/fen';
import { type Game, type PgnNodeData, parsePgn, startingPosition, transform } from 'chessops/pgn';
import { makeSanAndPlay, parseSan } from 'chessops/san';
import { defineStore } from 'pinia';

import router from '@/router';
import * as api from '@/services/rest';
import { useUserStore } from '@/stores/userStore';
import type { CoursePublicInfo, CourseVideo } from '@/types/apitypes';
import { type MatchGame, UserInput } from '@/types/internaltypes';
import { render } from '@/util/courseParser';
import { STARTING_FEN_SHORT, debounce, shortFen } from '@/util/util';

export const useCourseStore = defineStore('course', {
  state: () => ({
    courseInfo: null as CoursePublicInfo | null,
    courseDataMain: null as {
      headerData: Map<string, string> | null;
      courseTree: any | null;
      courseQueue: any[];
      uidToNode: Record<number, any>;
    } | null,
    courseDataModelGames: null as Record<
      string,
      {
        headerData: Map<string, string> | null;
        courseTree: any | null;
        courseQueue: any[];
        uidToNode: Record<number, any>;
      }
    > | null,
    courseDataVideoGames: null as Record<
      string,
      {
        headerData: Map<string, string> | null;
        courseTree: any | null;
        courseQueue: any[];
        uidToNode: Record<number, any>;
      }
    > | null,
    courseDataReferenceGames: null as
      | {
          headerData: Map<string, string> | null;
          courseTree: any | null;
          courseQueue: any[];
          uidToNode: Record<number, any>;
        }[]
      | null,
    loadedLichessGame: null as {
      headerData: Map<string, string> | null;
      courseTree: any | null;
      courseQueue: any[];
      uidToNode: Record<number, any>;
    } | null,

    loadedVersion: null as string | null,

    activeCourseGame: { id: '0', type: 'main' } as {
      id: string;
      type: 'main' | 'model' | 'reference' | 'lichess' | 'video';
    },

    explorer: {} as Record<
      string,
      {
        id: string;
        type: 'main' | 'model' | 'reference' | 'video';
        uids: number[];
      }[]
    >, // Fen to course identifier (main or model game id)
    // Current state of the loaded course data
    selectedNode: shallowRef<any | null>(null), //null as any | null,
    alternatives: [] as any[],
    selectedAlternative: null as any | null,
    choseAlternative: null as any | null,
    manualViewing: false,
    orientation: 'black' as 'white' | 'black', // Default orientation for the course
    selectedCourseSection: null as
      | 'keypositions'
      | 'board'
      | 'gamesinternal'
      | 'gameslichess'
      | 'settings'
      | 'share'
      | 'videos'
      | null,
    selectedCourseSectionData: null as any | null, // When selecting a section, use this to pass data to the section (we can for example open the Lichess explorer from the TreeComment, which makes props or provide not feasible)
  }),
  getters: {
    activeCourseData(): any {
      if (this.activeCourseGame.type === 'main') {
        return this.courseDataMain;
      } else if (this.activeCourseGame.type === 'model') {
        return this.courseDataModelGames == null
          ? null
          : this.courseDataModelGames[this.activeCourseGame.id];
      } else if (this.activeCourseGame.type === 'reference') {
        return this.courseDataReferenceGames == null
          ? null
          : this.courseDataReferenceGames[parseInt(this.activeCourseGame.id)];
      } else if (this.activeCourseGame.type === 'video') {
        return this.courseDataVideoGames == null
          ? null
          : this.courseDataVideoGames[this.activeCourseGame.id];
      } else if (this.activeCourseGame.type === 'lichess') {
        return this.loadedLichessGame == null ? null : this.loadedLichessGame;
      }

      console.error('Invalid active course game type', this.activeCourseGame.type);
      return null;
    },
    getCourseVideoById:
      (state) =>
      (id: string): CourseVideo | undefined => {
        return state.courseInfo?.videos.find((video) => video.id === id);
      },
    getHeaderData(): any {
      return this.activeCourseData?.headerData || null;
    },
    getCourseTree(): any {
      return this.activeCourseData?.courseTree || null;
    },
    getCourseQueue(): any[] {
      return this.activeCourseData?.courseQueue || [];
    },
    getNodeByUid() {
      return (uid: number) => this.activeCourseData?.uidToNode[uid] || null;
    },
    getAllUids(): string[] {
      return Object.keys(this.activeCourseData?.uidToNode);
    },
    getNextModelGameId(): string | null {
      if (this.activeCourseGame.type !== 'model' || this.courseDataModelGames == null) {
        return null;
      }
      const keys = Object.keys(this.courseDataModelGames).sort((a, b) => {
        const numA = Number(a);
        const numB = Number(b);
        const aIsNum = !isNaN(numA);
        const bIsNum = !isNaN(numB);
        if (aIsNum && bIsNum) {
          return numA - numB;
        } else if (aIsNum && !bIsNum) {
          return -1; // numeric keys come first
        } else if (!aIsNum && bIsNum) {
          return 1;
        } else {
          // Both non-numeric: alphabetical order.
          return a.localeCompare(b);
        }
      });
      const index = keys.indexOf(this.activeCourseGame.id);
      return index >= 0 && index < keys.length - 1 ? keys[index + 1] : null;
    },

    getPreviousModelGameId(): string | null {
      if (this.activeCourseGame.type !== 'model' || this.courseDataModelGames == null) {
        return null;
      }
      const keys = Object.keys(this.courseDataModelGames).sort((a, b) => {
        const numA = Number(a);
        const numB = Number(b);
        const aIsNum = !isNaN(numA);
        const bIsNum = !isNaN(numB);
        if (aIsNum && bIsNum) {
          return numA - numB;
        } else if (aIsNum && !bIsNum) {
          return -1;
        } else if (!aIsNum && bIsNum) {
          return 1;
        } else {
          return a.localeCompare(b);
        }
      });
      const index = keys.indexOf(this.activeCourseGame.id);
      return index > 0 ? keys[index - 1] : null;
    },
  },
  actions: {
    setSelectedCourseSection(
      section:
        | 'keypositions'
        | 'board'
        | 'gamesinternal'
        | 'gameslichess'
        | 'settings'
        | 'share'
        | 'videos'
        | null,
      sectionData: any | null = null
    ) {
      const sameSection = this.selectedCourseSection === section;
      const sameData =
        (sectionData == null && this.selectedCourseSectionData == null) ||
        (sectionData != null && this.selectedCourseSectionData != null);

      if (sameSection && sameData) {
        this.selectedCourseSection = null;
        this.selectedCourseSectionData = null;
      } else {
        this.selectedCourseSection = section;
        this.selectedCourseSectionData = sectionData;
      }
    },
    async setActiveCourseGame(
      type: 'main' | 'model' | 'reference' | 'lichess' | 'video',
      newId: string
    ) {
      if (type === 'lichess') {
        // This is a lichess game, so load it from the lichess API, parse it and set the current lichess game to the result

        const response = await axios.get(
          `https://lichess.org/game/export/${newId}?evals=0&clocks=0&pgnInJson=true`
        );
        const pgnGame = parsePgn(response.data.pgn)[0];
        this.loadedLichessGame = await this.parseCourse(pgnGame, 'black');
      }

      this.selectedNode = null;
      this.selectMove(null);
      this.refreshAlternatives(null);
      this.activeCourseGame.id = newId;
      this.activeCourseGame.type = type;
    },
    async loadCourseInfo(courseId: string): Promise<CoursePublicInfo> {
      this.courseInfo = (await api.getCourseInfo(courseId)).data;

      return this.courseInfo;
    },
    async loadCourseData(courseId: string) {
      if (this.courseInfo == null) {
        await this.loadCourseInfo(courseId);
      }

      if (
        this.loadedVersion == this.courseInfo?.latestVersion &&
        this.courseDataMain != null &&
        this.courseDataModelGames != null
      ) {
        // Already loaded the latest version
        return;
      }

      const rawPgnData = (await this.loadCourseFromServer(courseId, null, null)).split(
        '###COURSE_PGN_DIVIDER###'
      );

      const [mainData, modelData, referenceData, videoData] = await Promise.all([
        Promise.all(parsePgn(rawPgnData[0]).map((game) => this.parseCourse(game, 'black'))),
        Promise.all(parsePgn(rawPgnData[1]).map((game) => this.parseCourse(game, 'black'))),
        Promise.all(parsePgn(rawPgnData[2]).map((game) => this.parseCourse(game, 'black'))),
        Promise.all(parsePgn(rawPgnData[3]).map((game) => this.parseCourse(game, 'black'))),
      ]);

      const { fens: mainFens, ...courseMain } = mainData[0];
      this.courseDataMain = markRaw(courseMain);

      const modelFens = modelData.map((course) => course.fens);
      const modelParsedCourses = modelData.map(({ fens, ...rest }) => markRaw(rest));

      const modelFensMap: Record<string, Map<string, number[]>> = {};
      if (this.courseInfo?.modelGames != null) {
        this.courseDataModelGames = {};
        this.courseInfo.modelGames.forEach((modelGame) => {
          this.courseDataModelGames![modelGame.id] = markRaw(
            modelParsedCourses[modelGame.pgnIndex]
          );
          modelFensMap[modelGame.id] = modelFens[modelGame.pgnIndex];
        });
      }

      const referenceFens = referenceData.map((course) => course.fens);
      this.courseDataReferenceGames = markRaw(
        referenceData.map(({ fens, ...rest }) => markRaw(rest))
      );

      const videoParsedCourses = videoData.map(({ fens, ...rest }) => markRaw(rest));

      if (this.courseInfo?.videos != null) {
        this.courseDataVideoGames = {};
        this.courseInfo.videos.forEach((video) => {
          const matchingVideo = videoParsedCourses.find(
            (course: any) => course.headerData?.get('Videoid') === video.id
          );

          if (matchingVideo != null) {
            this.courseDataVideoGames![video.id] = markRaw(matchingVideo);
          }
        });
      }

      this.explorer = markRaw({});

      // Populate the start positions, since that isn't part of the fen-maps

      this.explorer[STARTING_FEN_SHORT] = [];

      if (this.courseDataModelGames != null) {
        for (const key of Object.keys(this.courseDataModelGames)) {
          this.explorer[STARTING_FEN_SHORT].push({
            id: key,
            type: 'model',
            uids: [],
          });
        }
      }

      if (this.courseDataReferenceGames != null) {
        this.courseDataReferenceGames.forEach((game, index) => {
          this.explorer[STARTING_FEN_SHORT].push({
            id: index + '',
            type: 'reference',
            uids: [],
          });
        });
      }

      if (this.courseInfo?.videos != null) {
        this.courseInfo.videos.forEach((video) => {
          this.explorer[STARTING_FEN_SHORT].push({
            id: video.id,
            type: 'video',
            uids: [],
          });
        });
      }

      // Continue with populating from the fen-maps

      for (const [fen, uids] of mainFens.entries()) {
        if (!this.explorer[fen]) this.explorer[fen] = [];
        this.explorer[fen].push({ id: '0', type: 'main', uids: uids });
      }

      for (const [id, fenToUids] of referenceFens.entries()) {
        for (const [fen, uids] of fenToUids.entries()) {
          if (!this.explorer[fen]) this.explorer[fen] = [];
          this.explorer[fen].push({
            id: id + '',
            type: 'reference',
            uids: uids,
          });
        }
      }

      for (const video of videoData) {
        const fenToUids = video.fens;
        for (const [fen, uids] of fenToUids.entries()) {
          if (!this.explorer[fen]) this.explorer[fen] = [];
          this.explorer[fen].push({
            id: video.headerData?.get('Videoid') ?? 'noid', // This shouldn't happen
            type: 'video',
            uids: uids,
          });
        }
      }

      Object.keys(modelFensMap).forEach((id) => {
        const fensMap = modelFensMap[id];
        for (const [fen, uid] of fensMap.entries()) {
          if (!this.explorer[fen]) this.explorer[fen] = [];
          this.explorer[fen].push({ id, type: 'model', uids: uid });
        }
      });

      this.loadedVersion = this.courseInfo!.latestVersion;
    },
    async loadCourseFromServer(
      courseId: string,
      type: 'main' | 'model' | null,
      gameid: string | null = null
    ): Promise<string> {
      const pgnData = (await api.getCourseData(courseId, type, gameid)).data;
      const keyBuffer = new TextEncoder().encode(useUserStore().user.data?.id + '_' + courseId);
      const contentBuffer = Uint8Array.from(atob(pgnData), (c) => c.charCodeAt(0));

      const decodedBuffer = contentBuffer.map(
        (byte, index) => byte ^ keyBuffer[index % keyBuffer.length]
      );

      return new TextDecoder().decode(decodedBuffer);
    },
    async parseCourse(
      game: Game<PgnNodeData>,
      orientation: 'white' | 'black'
    ): Promise<{
      headerData: Map<string, string> | null;
      fens: Map<string, number[]>;
      courseTree: any | null;
      courseQueue: any[];
      uidToNode: Record<number, any>;
    }> {
      let courseTree = null;
      let courseQueue = null;
      const fens: Map<string, number[]> = new Map(); // All fens in the course, to the uid of the node
      const uidToNode: Record<number, any> = markRaw({});

      const pos = startingPosition(game.headers).unwrap();
      let id = 0;
      courseTree = markRaw(
        transform(game.moves, pos, (pos, node) => {
          const move = parseSan(pos, node.san);
          if (!move) {
            return;
          }

          const san = makeSanAndPlay(pos, move); // Mutating pos!
          const fen = makeFen(pos.toSetup());

          if (!fens.has(shortFen(fen))) {
            fens.set(shortFen(fen), []);
          }
          fens.get(shortFen(fen))!.push(id);

          const uci = makeUci(move);

          // Clean line breaks in startingComments and comments
          const cleanedStartingComments = node.startingComments?.map((comment) =>
            comment.replace(/[\r\n]+/g, ' ')
          );
          const cleanedComments = node.comments?.map((comment) => comment.replace(/[\r\n]+/g, ' '));

          const decoratedNode = {
            ...node, // Keep comments and annotation glyphs
            startingComments: cleanedStartingComments, // Update cleaned startingComments
            comments: cleanedComments, // Update cleaned comments
            san, // Normalized SAN
            uci, // UCI
            fen, // Add arbitrary user data to node
            uid: id++,
            type: [],
          };

          return decoratedNode;
        })
      );

      const flattenToMap = (
        node: any,
        parent: number | null,
        depth: number,
        foldParents: number[]
      ) => {
        let foldable = depth >= 5 && (depth - 5) % 2 === 0 && node.children.length > 0;

        if (foldable && node.data != null && node.data.type.includes('main')) {
          foldable = false;
        }

        if (!foldable && parent != null && uidToNode[parent]?.data?.type.includes('main')) {
          foldable = true;
        }

        if (node.data != null) {
          // Check for nodes that have more than one from from the course orientation perspective (a black opening repertoire having multiple black move options)
          if (node.data.type.some((t: string) => t == 'caution' || t == 'alternative')) {
            node.children.forEach((child: any) => {
              child.data.type = [...node.data.type];
            });
          } else if (depth % 2 == (orientation == 'white' ? 0 : 1) && node.children?.length > 1) {
            for (let i = 1; i < node.children.length; i++) {
              const child = node.children[i];
              if (child.data != null) {
                if (
                  child.data.nags &&
                  child.data.nags.some((nag: number) => [2, 4, 6].includes(nag))
                ) {
                  child.data.type.push('caution');
                } else {
                  child.data.type.push('alternative');
                }
              }
            }
          }
          uidToNode[node.data.uid] = {
            ...node.data,
            children: node.children.map((child: any) => child.data.uid),
            parent: parent,
            foldable: foldable,
            foldParents: foldParents,
          };
        }
        node.children.forEach((child: any) =>
          flattenToMap(child, node.data ? node.data.uid : parent, depth + 1, [
            ...foldParents,
            ...(foldable ? [node.data?.uid] : []),
          ])
        );
      };

      const markMainLine = (node: any) => {
        if (node) {
          if (node.data) {
            // Mark the current node as part of the main line
            node.data.type.push('main');
          }

          // If there's a first child, continue marking the main line
          if (node.children.length > 0) {
            markMainLine(node.children[0]);
          }
        }
      };

      markMainLine(courseTree);
      flattenToMap(courseTree, null, 0, []);
      courseQueue = markRaw(render(courseTree));

      return {
        headerData: game.headers,
        fens,
        courseTree,
        courseQueue,
        uidToNode,
      };
    },
    selectMoveByFen(fen: string) {
      fen = shortFen(fen);
      const courseIdentifier = this.explorer[fen].find(
        (id) => id.id == this.activeCourseGame.id && id.type == this.activeCourseGame.type
      );

      if (courseIdentifier == null) {
        return;
      }

      // TODO Just picking the first positions here if there are many, need the line or similar to distuingish between transpositions
      this.selectMove(courseIdentifier.uids[0]);
    },
    selectMove(uid: number | null) {
      const debouncedNodeChanged = debounce(() => {
        this.manualViewing = false;
        if (uid == null) {
          this.selectedNode = null;
          this.updateAlternatives(null);
        } else {
          this.selectedNode = this.getNodeByUid(uid);
          this.updateAlternatives(this.selectedNode.uid);
        }
      }, 50);

      debouncedNodeChanged();
    },
    refreshAlternatives(preferredAlternativeUid: number | null) {
      this.updateAlternatives(this.selectedNode?.uid, preferredAlternativeUid);
    },
    updateAlternatives(nodeUid: number | null, preferredUid: number | null = null) {
      this.choseAlternative = null;
      if (nodeUid == null) {
        // nodeUid==null means that we're at the root with no move selected already. So we need to return all
        // root nodes as alternatives. It's really unnecessary to do this dynamically, we could do it once in parseCourse
        // but as long as it's not a performance bottleneck, it's fine.

        const rootNodes = Object.keys(this.getAllUids)
          .map((key) => this.getNodeByUid(Number(key))) // Convert key to a number if keys are numbers
          .filter((node) => node.parent === null);

        this.alternatives = rootNodes.map((node) => ({
          ...node,
          selected: false,
        }));
        if (preferredUid == null) {
          this.alternatives[0].selected = true;
        } else {
          const alternative = this.alternatives.find((a) => a.uid == preferredUid);
          if (alternative == undefined) {
            this.alternatives[0].selected = true;
          } else {
            alternative.selected = true;
          }
        }
        return;
      }

      const node = this.getNodeByUid(nodeUid);
      if (node.children.length > 0) {
        this.alternatives = node.children.map((child: number) => {
          return { ...this.getNodeByUid(child), selected: false };
        });

        if (preferredUid == null) {
          this.alternatives[0].selected = true;
        } else {
          const alternative = this.alternatives.find((a) => a.uid == preferredUid);
          if (alternative == undefined) {
            this.alternatives[0].selected = true;
          } else {
            alternative.selected = true;
          }
        }
      } else {
        this.alternatives = [];
      }
    },
    findNodePath(uid: number | null): number[] | null {
      if (uid == null) {
        return null;
      }

      let current = this.getNodeByUid(uid);

      if (current == null) {
        return null;
      }

      const path = [uid];

      while (current.parent != null) {
        current = this.getNodeByUid(current.parent);
        path.unshift(current.uid);
      }

      return path;
    },
    navigate(input: UserInput, moveUid: number | null = null) {
      if (input == UserInput.HistoryNext) {
        if (moveUid != null) {
          this.selectMove(moveUid);
        } else if (this.selectedNode == null) {
          this.selectMove(0);
        } else if (this.alternatives.length > 0) {
          this.selectMove(this.alternatives.find((a) => a.selected)!.uid);
        }
      } else if (input == UserInput.HistoryPrevious) {
        this.selectMove(this.selectedNode.parent == null ? null : this.selectedNode.parent);
      } else if (input == UserInput.AlternativeNext) {
        const currentIndex = this.alternatives.findIndex((item) => item.selected);
        const nextIndex = currentIndex + 1 > this.alternatives.length - 1 ? 0 : currentIndex + 1;
        this.alternatives[currentIndex].selected = false;
        this.alternatives[nextIndex].selected = true;
        this.choseAlternative = this.alternatives[nextIndex];
      } else if (input == UserInput.AlternativePrevious) {
        const currentIndex = this.alternatives.findIndex((item) => item.selected);
        const nextIndex = currentIndex - 1 < 0 ? this.alternatives.length - 1 : currentIndex - 1;
        this.alternatives[currentIndex].selected = false;
        this.alternatives[nextIndex].selected = true;
        this.choseAlternative = this.alternatives[nextIndex];
      }
    },
    navigateToLichessGame(gameId: string) {
      // router.push({
      //   name: "courseview",
      //   params: { courseid: this.courseInfo!.shortName },
      //   query: { gametype: "lichess", gameid: gameId },
      // });
      const url = router.resolve({
        name: 'courseview',
        params: { courseid: this.courseInfo!.shortName },
        query: { gametype: 'lichess', gameid: gameId },
      }).href;

      window.open(url, '_blank');
    },
    navigateToFen(
      fen: string,
      gameType: 'main' | 'model' | 'reference' = 'main',
      gameId: string | number = '0'
    ) {
      const query: {
        fen: string;
        gametype: 'main' | 'model' | 'reference';
        gameid?: string | number;
      } = { fen: fen, gametype: gameType };

      if (gameType === 'model' || gameType === 'reference') {
        query.gameid = gameId;
      }

      router.push({
        name: 'courseview',
        params: { courseid: this.courseInfo!.shortName },
        query: query,
      });
    },
    navigateToUid(
      uid: number | null,
      gameType: 'main' | 'model' | 'reference' | 'video',
      gameId: string | number | null = null
    ) {
      const query: {
        uid?: number;
        gameid?: string | number;
        gametype: string;
      } = {
        gametype: gameType,
      };

      if (uid != null) {
        query.uid = uid;
      }

      if (
        gameId != null &&
        (gameType == 'model' || gameType == 'reference' || gameType == 'video')
      ) {
        query.gameid = gameId;
      }

      router.push({
        name: 'courseview',
        params: { courseid: this.courseInfo!.shortName },
        query,
      });
    },
    findReferenceGame(candidate: string): number | null {
      if (!this.courseDataReferenceGames || this.courseDataReferenceGames.length === 0) {
        console.warn('No reference games available.');
        return null;
      }

      const preparedGames: MatchGame[] = this.courseDataReferenceGames.map((game: any) => ({
        date: game.headerData?.get('Date') || '',
        white: game.headerData?.get('White') || '',
        black: game.headerData?.get('Black') || '',
        whiteElo: game.headerData?.get('WhiteElo') || '',
        blackElo: game.headerData?.get('BlackElo') || '',
      }));

      return this.findMatchingGames(candidate, preparedGames);
    },
    findMatchingGames(candidate: string, games: MatchGame[]): number | null {
      if (!games || games.length === 0) {
        console.warn('No reference games available.');
        return null;
      }

      // Create an array that keeps track of the original index.
      let refGames = games.map((game, index) => ({ game, index }));

      // --- Extract candidate details ---
      const candidateTokens = candidate.trim().split(/\s+/);
      const lastToken = candidateTokens[candidateTokens.length - 1];
      const candidateYear = /^\d{4}$/.test(lastToken) ? lastToken : null;
      const candidateYearNum = candidateYear ? parseInt(candidateYear) : null;

      const dashIndex = candidate.indexOf('-');
      if (dashIndex === -1) {
        console.warn('Candidate string missing dash separator:', candidate);
        return null;
      }

      let leftPart = candidate.substring(0, dashIndex).trim();
      let rightPart = candidate.substring(dashIndex + 1).trim();

      const ratingRegex = /\((\d{4})\)/;

      let candidateWhiteElo = '';
      const leftMatch = leftPart.match(ratingRegex);
      if (leftMatch) {
        candidateWhiteElo = leftMatch[1];
        leftPart = leftPart.replace(leftMatch[0], '').trim();
      }

      let candidateBlackElo = '';
      const rightMatch = rightPart.match(ratingRegex);
      if (rightMatch) {
        candidateBlackElo = rightMatch[1];
        rightPart = rightPart.replace(rightMatch[0], '').trim();
      }

      if (candidateYear) {
        rightPart = rightPart.replace(new RegExp(`\\s*${candidateYear}$`), '').trim();
      }

      const candidateWhite = leftPart;
      const candidateBlack = rightPart;

      // --- Rule 1: Year matching ---
      const yearMatchedGames = refGames.filter(({ game }) => {
        const dateStr = game.date;
        if (!dateStr) return false;
        const gameDate = new Date(dateStr);
        if (isNaN(gameDate.getTime())) return false;
        return candidateYearNum !== null && gameDate.getFullYear() === candidateYearNum;
      });
      if (yearMatchedGames.length > 0) {
        refGames = yearMatchedGames;
      }

      // --- Rule 2: WhiteElo matching ---
      let whiteEloMatched: typeof refGames = [];
      if (candidateWhiteElo) {
        whiteEloMatched = refGames.filter(({ game }) => {
          return game.whiteElo === candidateWhiteElo;
        });
        if (whiteEloMatched.length > 0) {
          refGames = whiteEloMatched;
        }
      }

      // --- Rule 3: BlackElo matching ---
      let blackEloMatched: typeof refGames = [];
      if (candidateBlackElo) {
        blackEloMatched = refGames.filter(({ game }) => {
          return game.blackElo === candidateBlackElo;
        });
        if (blackEloMatched.length > 0) {
          refGames = blackEloMatched;
        }
      }

      // --- Rule 4 & 5: Name matching ---
      function cleanName(name: string): string[] {
        return name
          .replace(/[.,]/g, '')
          .split(/\s+/)
          .filter(Boolean)
          .map((word) => word.toLowerCase());
      }
      const candidateWhiteWords = cleanName(candidateWhite);
      const candidateBlackWords = cleanName(candidateBlack);

      let bestGame: {
        game: MatchGame;
        index: number;
      } | null = null;
      let bestMatchCount = -1;

      refGames.forEach(({ game, index }) => {
        const headerWhite = game.white || '';
        const headerBlack = game.black || '';
        const headerWhiteWords = cleanName(headerWhite);
        const headerBlackWords = cleanName(headerBlack);

        const whiteMatches = candidateWhiteWords.filter((word) =>
          headerWhiteWords.includes(word)
        ).length;
        const blackMatches = candidateBlackWords.filter((word) =>
          headerBlackWords.includes(word)
        ).length;
        const totalMatches = whiteMatches + blackMatches;

        if (totalMatches > bestMatchCount) {
          bestMatchCount = totalMatches;
          bestGame = { game, index };
        }
      });

      const yearMatches = !candidateYear || yearMatchedGames.length > 0;
      const whiteEloMatches = !candidateWhiteElo || whiteEloMatched.length > 0;
      const blackEloMatches = !candidateBlackElo || blackEloMatched.length > 0;
      const nameMatches = bestMatchCount > 0;

      if (bestGame && yearMatches && whiteEloMatches && blackEloMatches && nameMatches) {
        // @ts-ignore
        return bestGame.index; // Return the original index.
      } else {
        return null;
      }
    },
  },
});
