<template>
  <div
    style="
      margin-left: 0rem;
      margin-top: 0rem;
      text-align: center;
      justify-content: space-between;
      display: flex;
      align-items: start;
      width: 100%;
    "
  >
    <div>
      <button
        v-if="cs.manualViewing"
        class="btn btn-info smaller-button ph-no-capture"
        v-tippy="{
          content: isMobileWidth() ? null : 'Back to course',
          delay: [1000, 0],
        }"
        @click="
          () => {
            cs.manualViewing = false;
            track('course_move_navigation', 'back_to_course', 'click');
          }
        "
      >
        <i class="fa-solid navigation-arrow fa-angle-left" />
        <i class="fa-solid navigation-arrow fa-angle-left" />
        <span style="margin-left: 0.25rem">Back to course</span>
      </button>
      <button
        v-if="!cs.manualViewing"
        class="btn btn-info smaller-button ph-no-capture"
        :class="{ 'navigation-disabled': !props.hasPrev }"
        v-tippy="{
          content: isMobileWidth() ? null : 'Previous move (Shortcut: Left arrow)',
          delay: [1000, 0],
        }"
        @click="
          () => {
            navigate(UserInput.HistoryPrevious);
            track('course_move_navigation', 'history_previous', 'click');
          }
        "
      >
        <i class="fa-solid navigation-arrow fa-angle-left" />
      </button>
    </div>
    <div
      v-if="!cs.manualViewing"
      style="display: flex; gap: 0.1rem; max-width: 22rem; flex-wrap: wrap; justify-content: end"
    >
      <button
        v-for="(move, index) in cs.alternatives"
        :key="'alts' + index"
        class="btn smaller-button ph-no-capture"
        :class="{
          'btn-info': move.selected,
          'btn-outline-info': !move.selected,
        }"
        style="white-space: nowrap"
        role="button"
        v-tippy="{
          content: isMobileWidth()
            ? null
            : 'Next move (Shortcuts: Right arrow to select, Up/down arrows to choose alternative)',
          delay: [1000, 0],
        }"
        @click="
          () => {
            navigate(UserInput.HistoryNext, move.uid);
            track('course_move_navigation', 'history_next', 'click');
          }
        "
      >
        <i v-if="move.selected" class="fa-solid navigation-arrow fa-angle-right" />
        {{ getSan(move) }}
      </button>
    </div>
  </div>
</template>
<script setup lang="ts">
  import { type PropType, onMounted } from 'vue';

  import { useCourseStore } from '@/stores/courseStore';
  import { UserInput } from '@/types/internaltypes';
  import { track } from '@/util/tracking';
  import { isMobileWidth } from '@/util/util';

  const props = defineProps({
    hasPrev: { type: Boolean, default: true },
    nextMoves: { type: Array as PropType<string[] | null>, default: null },
    hasAlternatives: { type: Boolean, default: false },
  });

  const cs = useCourseStore();

  onMounted(() => {
    window.addEventListener('keydown', function (e) {
      if (e.key === 'ArrowLeft' && props.hasPrev) {
        e.preventDefault();
        navigate(UserInput.HistoryPrevious);
      } else if (e.key === 'ArrowRight' && props.nextMoves != null) {
        e.preventDefault();
        navigate(UserInput.HistoryNext);
      } else if (e.key === 'ArrowUp' && props.hasAlternatives) {
        e.preventDefault();
        navigate(UserInput.AlternativePrevious);
      } else if (e.key === 'ArrowDown' && props.hasAlternatives) {
        e.preventDefault();
        navigate(UserInput.AlternativeNext);
      }
    });
  });

  function navigate(type: UserInput, uid: number | null = null) {
    useCourseStore().navigate(type, uid);
  }

  // Annoying typescript workaround, using move.san directly will cause a typescript error
  function getSan(move: any) {
    return move.san;
  }
</script>
<style scoped>
  .navigation-arrow {
    font-size: 0.8rem;
  }

  .profile-image-circle {
    height: 2rem;
    width: 2rem;
    z-index: 10;
    border-radius: 50%;
    border: 1px solid #444;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .big-forward {
    height: 3rem;
    width: 3rem;
  }

  .navigation-disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  .alternative-box {
    border-radius: 4px;
    padding: 0.2rem;
    border: 2px solid var(--clr-accent);
  }
</style>
