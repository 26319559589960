<template>
  <div>
    <h2 class="c2 c6" id="h.4c30jori0la4">
      <span class="c22">Privacy Policy</span>
    </h2>
    <h3 class="c21 c6" id="h.345h2k9ay5pz">
      <span class="c5">Personal Data We May Collect About You</span>
    </h3>
    <h4 class="c2 c6" id="h.ihf0z7etzfjv">
      <span class="c19">Information You Give Us Voluntarily</span>
    </h4>
    <p class="c2">
      <span class="c0"
        >We collect certain personal data that you provide to us directly in the following
        ways:</span
      >
    </p>
    <ul class="c13 lst-kix_ok1m75ayyqqz-0 start">
      <li class="c2 c3 li-bullet-0">
        <span class="c16">Creating an account:</span
        ><span class="c0"
          >&nbsp;When you create a Chessiverse account and populate your profile, we collect certain
          information. If you sign up directly, we will ask for your email and password for your
          account.</span
        >
      </li>
      <li class="c2 c3 li-bullet-0">
        <span class="c16">Making purchases:</span
        ><span class="c0"
          >&nbsp;If you choose to purchase a subscription, we require that you provide additional
          information as required by the selected payment method. This information is collected and
          stored by our third-party payment processors.</span
        >
      </li>
      <li class="c2 c3 li-bullet-0">
        <span class="c16">Contacting us:</span
        ><span class="c0"
          >&nbsp;Additionally, we will obtain your personal data when you send us feedback or
          contact us for any reason. The personal data we collect may include your name, username,
          email address, phone number, physical address, and the contents of your communication
          which can include any information you choose to provide. We will only use that information
          for the purpose of providing the service or support requested.</span
        >
      </li>
      <li class="c2 c3 li-bullet-0">
        <span class="c16"
          >Registering for online tournaments, signing up to receive newsletters from us, or
          completing a survey:</span
        ><span class="c0"
          >&nbsp;When you engage in any of these activities, we may collect your name, email
          address, and phone number as well as your past chess experience, rank, and any other
          information you provide to us as part of that request (such as information about your user
          experience in response to a survey).</span
        >
      </li>
      <li class="c2 c3 li-bullet-0">
        <span class="c16">Sharing user-generated content:</span
        ><span class="c0"
          >&nbsp;We collect any information provided to us when you write comments on a course page;
          post to another Chessiverse user&rsquo;s profile; or chat with other users, students, and
          coaches.</span
        >
      </li>
      <li class="c2 c3 li-bullet-0">
        <span class="c16">Inviting others to try Chessiverse:</span
        ><span class="c0"
          >&nbsp;If you invite a friend to use Chessiverse or if you are invited in this way, we
          will collect and store the invitee&rsquo;s email address in order to enable us to send an
          invitation. We will also let the invitee know who invited them to use Chessiverse and let
          them request that their information be deleted from our systems.</span
        >
      </li>
      <li class="c2 c3 li-bullet-0">
        <span class="c16">Connecting with other chess platforms:</span
        ><span
          >&nbsp;If you have an account on another chess platform different from Chessiverse such as
          Lichess, Chess.com, etc., and you choose to connect it with your Chessiverse account, you
          will grant us access to the username you provide to us and all your information publicly
          available on the chess platform so we can process it to customize your experience on
          Chessiverse and provide you with new features.</span
        ><sup><a href="#cmnt2" id="cmnt_ref2">[b]</a></sup>
      </li>
    </ul>
    <h4 class="c2 c6" id="h.3d8ta6hbrl8q">
      <span class="c19">Information We Collect Automatically</span>
    </h4>
    <p class="c2">
      <span class="c0"
        >We may also collect certain information automatically as you navigate our Services through
        the use of cookies and similar technologies. For example, we may monitor how many times you
        visit, which pages you go to, traffic data, and location data. Some of this data will be
        aggregated or statistical, which means that we will not be able to identify you
        individually.</span
      >
    </p>
    <p class="c2">
      <span class="c0"
        >You can disable cookies, limit the types of cookies you allow, or set your browser to alert
        you when cookies are sent. Please refer to the guidance offered by your chosen web browser
        if you&rsquo;d like help managing your cookie preferences.</span
      >
    </p>
    <h4 class="c2 c6" id="h.2wxz1xxjv1jk">
      <span class="c19">Analytics and Advertising</span>
    </h4>
    <p class="c2">
      <span class="c0"
        >We also partner with third parties who provide analytics services and serve advertisements
        on our behalf across the Internet and in mobile applications. These third-party service
        providers may use cookies and similar technologies of their own to track your engagement
        with our Service as well as our online advertising and email marketing campaigns and permit
        us and them to analyze and track data, determine the popularity of content, learn about
        which ads you see and click, and better understand your online activity.</span
      >
    </p>
    <p class="c2">
      <span class="c0"
        >For further information on our use of cookies, please see our Use of Cookies section
        below.</span
      >
    </p>
    <h4 class="c2 c6" id="h.pn93rlpmp09r">
      <span class="c19">Information We Collect from Other Sources</span>
    </h4>
    <ul class="c13 lst-kix_sp5gg61svm48-0 start">
      <li class="c2 c3 li-bullet-0">
        <span class="c16">Payment processors:</span
        ><span class="c0"
          >&nbsp;When you purchase digital content or subscriptions from Chessiverse, your payment
          card information is provided directly to our third-party payment processor (Stripe) which
          encrypts all payment transactions. We do not store your payment card details nor do we
          share financial details with any third parties. However, we may receive limited
          information about you from our payment processors such as your name, address, zip code,
          and the last four digits of your payment card.</span
        >
      </li>
      <li class="c2 c3 li-bullet-0">
        <span class="c16">Another Chessiverse user:</span
        ><span
          >&nbsp;We may collect your personal data from another Chessiverse user if that user
          provides us with your email address in order to invite you to try Chessiverse or provides
          other information about you through our chat or public forum features.</span
        ><sup><a href="#cmnt3" id="cmnt_ref3">[c]</a></sup>
      </li>
      <li class="c2 c3 li-bullet-0">
        <span class="c16">Third-party services:</span
        ><span class="c0"
          >&nbsp;We depend on certain third-party services that are used in connection with our
          Service. Please be aware that these third-party services may make certain information
          available to Chessiverse. This can include information necessary to facilitate the
          integration as well as any additional information the provider chooses to share with
          Chessiverse or which you have permitted the provider to share through your privacy
          settings. You should check the privacy settings and privacy policies of these third-party
          services to understand what information may be shared with Chessiverse. We may combine the
          information we collect about you from these various sources. If a combination of
          information that we collect identifies you as an individual, we will treat the combined
          information as personal data.</span
        >
      </li>
    </ul>
    <p class="c9 c20"><span class="c0" /></p>
    <h3 class="c21 c6" id="h.k70n2qhfuw3j">
      <span class="c5">How We Use Your Personal Data</span>
    </h3>
    <p class="c2">
      <span class="c0"
        >We process your personal data for the purposes of providing the agreed services to you if
        you are or become a Chessiverse user and for related legitimate business activities such
        as:</span
      >
    </p>
    <ul class="c13 lst-kix_4h44wdrr4feu-0 start">
      <li class="c2 c3 li-bullet-0">
        <span class="c0"
          >facilitating subscriptions, and potentially future digital chess content purchases;</span
        >
      </li>
      <li class="c2 c3 li-bullet-0"><span class="c0">billing you;</span></li>
      <li class="c2 c3 li-bullet-0">
        <span class="c0">to help us identify you and any accounts you hold with us;</span>
      </li>
      <li class="c2 c3 li-bullet-0">
        <span class="c0">responding to your requests, inquiries, comments, and suggestions;</span>
      </li>
      <li class="c2 c3 li-bullet-0">
        <span class="c0"
          >communicating with you about the services, and providing news and information we think
          will be of interest to you;</span
        >
      </li>
      <li class="c2 c3 li-bullet-0">
        <span class="c0"
          >providing notifications, including through push notifications (if enabled);</span
        >
      </li>
      <li class="c2 c3 li-bullet-0">
        <span class="c0">posting testimonials;</span>
      </li>
      <li class="c2 c3 li-bullet-0">
        <span class="c0">publishing the names of tournament winners or high performers;</span>
      </li>
      <li class="c2 c3 li-bullet-0">
        <span class="c0">administrative purposes;</span>
      </li>
      <li class="c2 c3 li-bullet-0">
        <span class="c0">fraud prevention and detection;</span>
      </li>
      <li class="c2 c3 li-bullet-0">
        <span class="c0"
          >customizing Chessiverse and its content to your particular preferences;</span
        >
      </li>
      <li class="c2 c3 li-bullet-0">
        <span class="c0"
          >to notify you of any changes to Chessiverse or to our services that may affect you;</span
        >
      </li>
      <li class="c2 c3 li-bullet-0">
        <span class="c0">statistical analysis;</span>
      </li>
      <li class="c2 c3 li-bullet-0">
        <span class="c0">improving our services; and</span>
      </li>
      <li class="c2 c3 li-bullet-0">
        <span class="c0"
          >carrying out any other purpose described to you at the time the information was
          collected.</span
        >
      </li>
    </ul>
    <p class="c2">
      <span class="c0"
        >We will ask for your specific consent if we additionally intend to use your information for
        any of the following purposes:</span
      >
    </p>
    <ul class="c13 lst-kix_p55tflv4jjzo-0 start">
      <li class="c2 c3 li-bullet-0">
        <span class="c0">research, and behavioral analysis;</span>
      </li>
      <li class="c2 c3 li-bullet-0">
        <span class="c0">customer profiling and analyzing your purchasing preferences;</span>
      </li>
      <li class="c2 c3 li-bullet-0">
        <span class="c0">marketing&mdash;see Marketing and opting out, below;</span>
      </li>
      <li class="c2 c3 li-bullet-0">
        <span class="c0"
          >communicating with you about products, services, offers, promotions, and events we offer
          and providing news and information we think will be of interest to you;</span
        >
      </li>
    </ul>
    <h3 class="c14" id="h.hhpv2skfw4nn">
      <span class="c5">Marketing and Opting Out</span>
    </h3>
    <p class="c2">
      <span class="c0"
        >If you have given permission, we may contact you by email about products, services, offers,
        promotions, and events we think may be of interest to you. If you prefer not to receive any
        direct marketing communications from us, you can opt out at any time. For more details see
        Your rights, below.</span
      >
    </p>
    <h3 class="c14" id="h.1e145z5rrtfz">
      <span class="c5">Disclosure of Your Personal Data</span>
    </h3>
    <p class="c2">
      <span class="c0"
        >To achieve the purposes mentioned in this Privacy Policy and Notice and to the extent
        necessary, we may disclose your personal data to Chessiverse&rsquo;s agents, service
        providers, and other affiliated companies, hereunder affiliates. These third parties may
        include, amongst others, financial institutions, technological and analytical service
        providers, advertising and marketing-related partners, and other service providers.</span
      >
    </p>
    <p class="c2">
      <span class="c0"
        >We may also share your personal data with any corporate purchaser or prospect to the extent
        permitted by law as part of any merger, acquisition, sale of assets, or transition of
        service to another provider, as well as in the event of insolvency, bankruptcy, or
        receivership in which personal data would be transferred as Chessiverse&rsquo;s asset.</span
      >
    </p>
    <p class="c2">
      <span class="c0"
        >Lastly, we may share your personal data in order to comply with Chessiverse&rsquo;s legal
        obligations, court orders, or other legal processes, to comply with a request from our
        regulators, governmental requests, or any other legally enforceable demand.</span
      >
    </p>
    <p class="c2">
      <span class="c0"
        >For service efficiency purposes, some of these affiliates and third parties are located in
        territories outside the EU/EEA, and from time to time we may transfer your personal data to
        a third country not being approved by the European Commission as a safe country for such
        transfer (adequacy decision). Whenever applicable Chessiverse will use the Standard
        Contractual Clauses to ensure an equivalent level of protection as granted within the EU/EEA
        or other lawful grounds for carrying out said transfer.</span
      >
    </p>
    <h3 class="c14" id="h.9uleuzh6n9gu">
      <span class="c5">Keeping Your Data Secure</span>
    </h3>
    <p class="c2">
      <span class="c0"
        >We will use appropriate technical and organizational measures to safeguard your personal
        data. For example:</span
      >
    </p>
    <ul class="c13 lst-kix_785555g9zrpn-0 start">
      <li class="c2 c3 li-bullet-0">
        <span class="c0"
          >Access to your account is controlled by a password and username that are unique to you;
          and</span
        >
      </li>
      <li class="c2 c3 li-bullet-0">
        <span class="c0">We store your personal data on secure servers.</span>
      </li>
    </ul>
    <p class="c2">
      <span class="c0"
        >While we will use all reasonable efforts to safeguard your personal data, you acknowledge
        that the use of the internet is not entirely secure and for this reason, we cannot guarantee
        the security or integrity of any personal data that is transferred from you or to you via
        the internet. It remains your responsibility to:</span
      >
    </p>
    <ul class="c13 lst-kix_1b5umsur35s0-0 start">
      <li class="c2 c3 li-bullet-0">
        <span class="c0"
          >Ensure nobody else uses Chessiverse while you are logged into your account (we advise our
          users to take extra care when using public computers/devices and to NOT leave themselves
          logged in; it is your responsibility to ensure this does not happen);</span
        >
      </li>
      <li class="c2 c3 li-bullet-0">
        <span class="c0"
          >Log out and exit your account on Chessiverse when you are not using it, on any private
          and/or public computer or device; and</span
        >
      </li>
      <li class="c2 c3 li-bullet-0">
        <span class="c0"
          >Keep your password and any account details or access details secret and to
          yourself.</span
        >
      </li>
    </ul>
    <p class="c2">
      <span class="c0"
        >Our payment services provider may store your information outside the European Economic area
        but do so with appropriate safeguards.</span
      >
    </p>
    <h3 class="c2 c6" id="h.v52w2vppoviz"><span class="c5">Profiling</span></h3>
    <p class="c2">
      <span class="c0"
        >We may use profiling techniques to propose to you certain content types or titles. We do
        this on the basis of your usage patterns of Chessiverse, including previous purchases.</span
      >
    </p>
    <h3 class="c14" id="h.hqoxyc4b3hjv">
      <span class="c5">Use of Cookies</span>
    </h3>
    <p class="c2">
      <span class="c0"
        >A cookie is a small text file that is placed onto your device when you Chessiverse. We use
        cookies to:</span
      >
    </p>
    <ul class="c13 lst-kix_rnfo27xxv2u-0 start">
      <li class="c2 c3 li-bullet-0">
        <span class="c0"
          >Recognize you whenever you visit Chessiverse (this speeds up your access to the Service
          as you do not have to log on each time);</span
        >
      </li>
      <li class="c2 c3 li-bullet-0">
        <span class="c0">Obtain information about your preferences;</span>
      </li>
      <li class="c2 c3 li-bullet-0">
        <span class="c0"
          >Carry out research and statistical analysis to help improve our content, products, and
          services and to help us better understand our visitor requirements and interests;</span
        >
      </li>
      <li class="c2 c3 li-bullet-0">
        <span class="c0">Make your online experience more efficient and enjoyable.</span>
      </li>
    </ul>
    <p class="c2">
      <span class="c0"
        >The information we obtain from our use of cookies will not usually contain your personal
        data. Although we may obtain information about your computer or other electronic device such
        as your IP address, your browser, and/or other internet log information, this will generally
        not identify you personally.</span
      >
    </p>
    <p class="c2">
      <span class="c0"
        >In most cases, we will need your consent to use cookies on Chessiverse. This is done
        through a cookie banner included on the Chessiverse website landing page, where you will be
        asked to accept or decline cookies that are set for purposes related to marketing and
        personalization. If you change your mind in the future about letting us use cookies, you can
        modify the settings on our webpage (link on the footer via Cookie Preferences) or in your
        browser to reject cookies or disable cookies completely.</span
      >
    </p>
    <p class="c9 c20"><span class="c0" /></p>
    <h4 class="c1" id="h.6n93os6hv5qd">
      <span class="c19">Description of Cookies</span>
    </h4>
    <p class="c2">
      <span class="c0"
        >The table below is designed to provide more information about the cookies we use and
        why:</span
      >
    </p>
    <a id="t.352505505e2d1ef040d1bb05c165704b9ed11a7c" /><a id="t.0" />
    <table class="c28">
      <tbody>
        <tr class="c27">
          <td class="c11" colspan="1" rowspan="1">
            <p class="c26"><span class="c16">Name of Cookie</span></p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c26"><span class="c16">Owner</span></p>
          </td>
          <td class="c25" colspan="1" rowspan="1">
            <p class="c26"><span class="c16">Purpose</span></p>
          </td>
        </tr>
        <tr class="c30">
          <td class="c11" colspan="1" rowspan="1">
            <p class="c9"><span class="c0">Login Cookies</span></p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c9"><span class="c0">Chessiverse</span></p>
          </td>
          <td class="c25" colspan="1" rowspan="1">
            <p class="c9">
              <span class="c0"
                >These cookies allow us to remember that you are logged in to the service. Without
                them, your computer may have trouble remaining logged in. This cookie stores your
                user id in an encrypted manner so we can identify you. This cookie is not shared
                with third parties.</span
              >
            </p>
          </td>
        </tr>
        <tr class="c17">
          <td class="c11" colspan="1" rowspan="1">
            <p class="c9"><span class="c0">Stripe Cookie</span></p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c9"><span class="c0">Stripe</span></p>
          </td>
          <td class="c25" colspan="1" rowspan="1">
            <p class="c9">
              <span class="c0"
                >If you have used Stripe as our payment provider, they may set up cookies to
                facilitate the service. More information is in their own Cookie Policy.</span
              >
            </p>
          </td>
        </tr>
        <tr class="c23">
          <td class="c11" colspan="1" rowspan="1">
            <p class="c9"><span class="c0">Google Analytics</span></p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c9"><span class="c0">Google</span></p>
          </td>
          <td class="c25" colspan="1" rowspan="1">
            <p class="c9">
              <span class="c0"
                >This is a web analytics service provided by Google, Inc which uses cookies to show
                us how visitors found and explored our Service, and how we can enhance their
                experience. It provides us with anonymized information about the behavior of our
                visitors (e.g., how long they stayed on Chessiverse, the average number of pages
                viewed) and also tells us how many visitors we have had.</span
              >
            </p>
          </td>
        </tr>
        <tr class="c23">
          <td class="c11" colspan="1" rowspan="1">
            <p class="c9"><span class="c0">Sentry</span></p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c9 c20"><span class="c0" /></p>
          </td>
          <td class="c25" colspan="1" rowspan="1">
            <p class="c9 c20"><span class="c0" /></p>
          </td>
        </tr>
        <tr class="c12">
          <td class="c11" colspan="1" rowspan="1">
            <p class="c9"><span class="c0">Facebook</span></p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c9"><span class="c0">Meta Platforms</span></p>
          </td>
          <td class="c25" colspan="1" rowspan="1">
            <p class="c9">
              <span class="c0"
                >This is a marketing and login service provided by Meta Platforms, Inc which uses
                cookies to allow us to provide you with the Facebook login service, Facebook like
                and share functionality as well as remarketing services to bring new course launches
                to you. This cookie is disabled for Chessiverse Classroom.</span
              >
            </p>
          </td>
        </tr>
        <tr class="c17">
          <td class="c11" colspan="1" rowspan="1">
            <p class="c9"><span class="c0">Posthog</span></p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c9 c20"><span class="c0" /></p>
          </td>
          <td class="c25" colspan="1" rowspan="1">
            <p class="c9 c20"><span class="c0" /></p>
          </td>
        </tr>
        <tr class="c30">
          <td class="c11" colspan="1" rowspan="1">
            <p class="c9"><span class="c0">Vimeo</span></p>
          </td>
          <td class="c7" colspan="1" rowspan="1">
            <p class="c9 c20"><span class="c0" /></p>
          </td>
          <td class="c25" colspan="1" rowspan="1">
            <p class="c9 c20"><span class="c0" /></p>
          </td>
        </tr>
      </tbody>
    </table>
    <p class="c2">
      <span class="c0"
        >Chessiverse may also use third-party vendor re-marketing, by means of Facebook Ads and
        Google Adwords tracking cookies. The third-party vendors, including Google, whose services
        we use, will place cookies on users&#39; web browsers in order to serve ads to the user
        based on the user&rsquo;s past visit to our website. This allows us to make special offers
        and continue to market our services to those who have shown interest in our Service.</span
      >
    </p>
    <p class="c2">
      <span class="c0"
        >This means we may show ads to you across the internet, specifically on Facebook and the
        Google Content Network (GCN). As always we respect your privacy and are not collecting any
        identifiable information through the use of these re-marketing systems.</span
      >
    </p>
    <h3 class="c14" id="h.1b5tja3azaoq">
      <span class="c5">How to Turn Off Cookies</span>
    </h3>
    <p class="c2">
      <span class="c0"
        >If you do not want to accept cookies, you can change your browser settings so that cookies
        are not accepted. If you do this, please be aware that you may lose some of the
        functionality of Chessiverse. For further information about cookies and how to disable them
        please go to: www.aboutcookies.org or www.allaboutcookies.org.</span
      >
    </p>
    <p class="c2">
      <span>To opt-out of Google Analytics, visit this webpage &ndash;</span
      ><span
        ><a
          class="c10"
          href="https://www.google.com/url?q=https://tools.google.com/dlpage/gaoptout&amp;sa=D&amp;source=editors&amp;ust=1722618800215281&amp;usg=AOvVaw37u2LoQOKcsdBk5kYJk1DB"
          >&nbsp;</a
        ></span
      ><span class="c18"
        ><a
          class="c10"
          href="https://www.google.com/url?q=https://tools.google.com/dlpage/gaoptout&amp;sa=D&amp;source=editors&amp;ust=1722618800215404&amp;usg=AOvVaw2Qt7TWifxP55A0GhYsgs57"
          >https://tools.google.com/dlpage/gaoptout</a
        ></span
      >
    </p>
    <h3 class="c14" id="h.p0beirledk1b">
      <span class="c5">Information About Other Individuals</span>
    </h3>
    <p class="c2">
      <span class="c0"
        >If you give us information on behalf of someone else, you confirm that the other person has
        appointed you to act on his/her behalf and has agreed that you can:</span
      >
    </p>
    <ul class="c13 lst-kix_127j53ggk0e-0 start">
      <li class="c2 c3 li-bullet-0">
        <span class="c0"
          >give consent on his/her behalf to the processing of his/her personal data; and</span
        >
      </li>
      <li class="c2 c3 li-bullet-0">
        <span class="c0">receive on his/her behalf any data protection notices;</span>
      </li>
    </ul>
    <h3 class="c14" id="h.ezdg363ve9k9">
      <span class="c5">Do Not Use Chessiverse for Private and Sensitive Data</span>
    </h3>
    <p class="c2">
      <span class="c0"
        >Chessiverse is a tool to help you improve your chess learning. We do not wish for
        Chessiverse to be used for the processing of any sensitive data. Do not use Chessiverse to
        share with any user any personal contact information like your phone number or street
        address. Do not use Chessiverse to send or store any item revealing racial or ethnic origin,
        political opinions, religious or philosophical beliefs, or trade union membership, and the
        processing of genetic data, biometric data for the purpose of uniquely identifying a natural
        person, data concerning health or data concerning a natural person&rsquo;s sex life or
        sexual orientation.</span
      >
    </p>
    <h3 class="c14" id="h.4erhzuf09c73">
      <span class="c5">Links to Other Sites</span>
    </h3>
    <p class="c2">
      <span class="c0"
        >Chessiverse does and may contain links to other websites. This Policy applies only to our
        Services, so when you visit other websites or applications please read their privacy
        policies, as we cannot accept any responsibility for breaches or issues you may have in
        relation to privacy once you leave our website.</span
      >
    </p>
    <h3 class="c14" id="h.4g3oe07cvm3h">
      <span class="c5">What is the Legal Basis for Our Processing Your Personal Data</span>
    </h3>
    <p class="c2">
      <span class="c0"
        >The laws in some jurisdictions require companies to tell you about the legal grounds they
        rely on to use or disclose your personal data. To the extent those laws apply, our legal
        grounds are as follows:</span
      >
    </p>
    <ul class="c13 lst-kix_73lq7sizydkx-0 start">
      <li class="c2 c3 li-bullet-0">
        <span class="c16">Consent:</span
        ><span class="c0"
          >&nbsp;In some cases, the legal basis for processing your personal data is your consent.
          For example, if you agree to us emailing you with marketing information. Any consent you
          provide may be withdrawn at any time by emailing support@chessiverse.com. If you withdraw
          your consent, and we do not have another legal basis for processing your information, we
          will stop processing your personal data.</span
        >
      </li>
      <li class="c2 c3 li-bullet-0">
        <span class="c16">Contractual Necessity:</span
        ><span class="c0"
          >&nbsp;In other cases, the processing will be necessary for the purposes of honoring our
          contractual commitments to you, such as providing you with the Services referred to in our
          Terms of Use.</span
        >
      </li>
      <li class="c2 c3 li-bullet-0">
        <span class="c16">Legitimate Interests:</span
        ><span class="c0"
          >&nbsp;We may process personal data in accordance with our legitimate interests in
          providing and developing our Services to you and other users&mdash;provided that those
          interests are not outweighed by your rights and interests. If you think that your rights
          and interests outweigh our legitimate interests in providing our Services, please let us
          know by emailing us at support@chessiverse.com.</span
        >
      </li>
      <li class="c2 c3 li-bullet-0">
        <span class="c16">Legal Obligations:</span
        ><span
          >&nbsp;We may also process your data where this is necessary to allow us to comply with
          our legal obligations.</span
        >
      </li>
    </ul>
    <h3 class="c14" id="h.fv2ntfltncea"><span class="c5">Your Rights</span></h3>
    <p class="c2">
      <span class="c0"
        >You may choose not to provide us with personal data and you may turn off cookies in your
        browser by changing its settings. If you make these decisions, you may continue to browse
        the pages of chessiverse.com, but we will not be able to provide you with the Chessiverse
        service.</span
      >
    </p>
    <p class="c2">
      <span class="c0"
        >You have the right to ask us not to process your personal data for marketing
        purposes.</span
      >
    </p>
    <p class="c2">
      <span class="c0"
        >You have the right to access information we hold about you, together with an explanation of
        the categories of data being processed, the purposes of such processing, and the categories
        of third parties to whom the data may be disclosed. You can also require us to provide you
        details of the period for which the data will be stored (or the criteria used to determine
        that period) and information about your other rights regarding our use of your data. We will
        provide you with the information within one month of your request unless doing so would
        adversely affect the rights and freedoms of others. That would include, for example, another
        person&rsquo;s confidentiality or intellectual property rights. We&rsquo;ll tell you if we
        can&rsquo;t meet your request for that reason. If you wish to exercise this right, please
        send a request to dpo@chessiverse.com.</span
      >
    </p>
    <p class="c2">
      <span class="c0">You also have the following rights:</span>
    </p>
    <ul class="c13 lst-kix_gkq3e15l92xy-0 start">
      <li class="c2 c3 li-bullet-0">
        <span class="c0"
          >The right to require us to rectify any inaccurate personal data concerning you.</span
        >
      </li>
      <li class="c2 c3 li-bullet-0">
        <span class="c0"
          >The right to be &lsquo;forgotten&rsquo; by us and have us erase any personal data we hold
          about you, if it is no longer necessary for us to hold the data for purposes of your use
          of the Service.</span
        >
      </li>
      <li class="c2 c3 li-bullet-0">
        <span class="c0"
          >The right to object to us using your data for the purposes of profiling you or making
          automated decisions about you. We may use your data to determine whether we should let you
          know information about the Service which might be relevant to you. Otherwise, the only
          circumstances in which we will do this is to provide you with the Chessiverse
          service.</span
        >
      </li>
      <li class="c2 c3 li-bullet-0">
        <span class="c0"
          >The right to ask us to port your data to another service (if technically feasible) or to
          provide you with a copy of data we hold about you in a structured, commonly used
          machine-readable format so that you can provide it to them. We will do so to the extent
          that we are required to under applicable laws, but not to the extent that this involves
          disclosing data about any other individual.</span
        >
      </li>
    </ul>
    <p class="c2">
      <span>You can also exercise these rights at any time by contacting us at </span
      ><span>dpo@chessiverse.com</span><sup><a href="#cmnt4" id="cmnt_ref4">[d]</a></sup
      ><span class="c0"
        >. To the extent that it does not involve disproportionate effort on our part, if you
        exercise any of the above rights, we will tell any third parties to whom we have passed your
        data.</span
      >
    </p>
    <p class="c2">
      <span class="c0"
        >Additionally, you have the right to lodge a complaint regarding our use of your data with
        the supervising authorities. However, we invite you to contact us with any concerns, as we
        would be happy to try to resolve them directly. EU residents should address any complaint to
        their respective data protection authorities.</span
      >
    </p>
    <h3 class="c14" id="h.z0fmn6c38k6t">
      <span class="c5">How Long We Keep Your Data</span>
    </h3>
    <p class="c2">
      <span class="c0"
        >We will retain personal data for the period necessary to fulfill the purposes outlined in
        this Privacy Policy unless a longer retention period is required or permitted by law.</span
      >
    </p>
    <h3 class="c14" id="h.prfn1fm6jl35">
      <span class="c5">Updates to This Policy</span>
    </h3>
    <p class="c2">
      <span class="c0"
        >If we change our Privacy Policy, we will post any changes on this page. You should check
        this page occasionally to ensure you are happy with any changes to this policy. If you have
        created a Chessiverse account, we will notify you of any material changes to this policy by
        email or by a notification in your Chessiverse account.</span
      >
    </p>
    <p class="c2">
      <span class="c0"
        >Your continued use of the Services after the revised Privacy Policy has become effective
        indicates that you have read, understood, and agreed to the current version of this Privacy
        Policy, to the extent permitted by law.</span
      >
    </p>
    <h3 class="c14" id="h.cipzbgoujnvp">
      <span class="c5">Our Contact Details</span>
    </h3>
    <p class="c2">
      <span
        >We welcome your feedback and questions. If you wish to contact us, please send an email to </span
      ><span class="c18"
        ><a class="c10" href="mailto:support@chessiverse.com">support@chessiverse.com</a></span
      ><span class="c0">.</span>
    </p>
    <p class="c2">
      <span class="c0"
        >If you wish to contact our Data Protection Officer, please send an email to
        dpo@chessiverse.com.</span
      >
    </p>
    <p class="c2">
      <span class="c0"
        >Chessiverse AB is a limited company registered in Sweden, and our registered office is at
        Fleminggatan 21, 112 26, Stockholm, Sweden.</span
      >
    </p>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped></style>
