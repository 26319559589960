import type { PerfectRecordBot } from '@/types/statstypes';

/**
 * Composable for calculating Perfect Record statistics
 * Perfect Record refers to bots against which the player has never lost
 */
export function usePerfectRecordStats() {
  /**
   * Find bots with perfect record (never lost against, minimum games threshold)
   * @param validBots Array of bots with game statistics
   * @param minGamesThreshold Minimum number of games required to qualify (default: 3)
   * @returns Array of perfect record bots
   */
  return (validBots: any[], minGamesThreshold = 3) => {
    // Filter bots with perfect record (no losses) and minimum games threshold
    const perfectRecordBots = validBots.filter((bot) => {
      return bot.games >= minGamesThreshold && bot.losses === 0;
    });

    // Sort by number of wins (most wins first)
    const sortedPerfectRecordBots = perfectRecordBots
      .map(
        (bot: any) =>
          ({
            botId: bot.bot_id,
            games: bot.games,
            wins: bot.wins,
            draws: bot.draws,
          }) as PerfectRecordBot
      )
      .sort((a: PerfectRecordBot, b: PerfectRecordBot) => b.wins - a.wins)
      .slice(0, 5); // Limit to top 5 bots

    return {
      perfectRecordBots: sortedPerfectRecordBots,
      perfectRecordBotsCount: perfectRecordBots.length,
    };
  };
}
