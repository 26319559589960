<template>
  <div
    class="modal fade"
    id="gameFeedbackModal"
    tabindex="-1"
    aria-labelledby="gameFeedbackModalLabel"
    aria-hidden="true"
    ref="modalElement"
  >
    <div class="modal-dialog modal-sm">
      <div class="modal-content">
        <div class="modal-header" style="border-bottom: 0px; justify-content: right; z-index: 10">
          <a type="button" id="gameFeedbackModalClose" data-bs-dismiss="modal" aria-label="Close">
            <img
              alt="Cross icon"
              src="https://storage.googleapis.com/chessiverse1/images/elements/cross.webp"
              style="width: 1rem; height: 1rem"
            />
          </a>
        </div>
        <div class="modal-body">
          <h3 style="margin-top: -3rem; margin-bottom: 2rem">What did you think about the game?</h3>
          <div style="text-align: left; color: #40abc0" class="mt-3">
            <h5 style="margin-bottom: 0">How was the playstyle?</h5>
            <span style="margin-top: 2rem">Was it enojyable? Human-like?</span><br />
            <a
              type="button"
              class="btn btn-primary mt-2"
              style="padding: 0.2rem"
              ref="playstyleHappy"
              @click="select('playstyle', FeedbackType.Happy)"
            >
              <img alt="Happy face icon" style="height: 2rem" src="@/assets/images/happy.svg"
            /></a>
            <a
              type="button"
              class="btn btn-primary mx-1 mt-2"
              style="padding: 0.2rem"
              ref="playstyleNeutral"
              @click="select('playstyle', FeedbackType.Neutral)"
            >
              <img alt="Neutral face icon" style="height: 2rem" src="@/assets/images/neutral.svg"
            /></a>
            <a
              type="button"
              class="btn btn-primary mt-2"
              style="padding: 0.2rem"
              ref="playstyleSad"
              @click="select('playstyle', FeedbackType.Sad)"
            >
              <img alt="Sad face icon" style="height: 2rem" src="@/assets/images/sad.svg"
            /></a>
            <input
              placeholder="(Optional) Tell us more..."
              class="mt-3"
              maxlength="2000"
              style="width: 100%"
              ref="humanLikeCommentInput"
            />
          </div>
          <div style="text-align: left; color: #40abc0" class="mt-3">
            <h5 style="margin-bottom: 0">How was the opening?</h5>
            <span style="margin-top: 2rem">Suitable lines? Matched the strength?</span><br />
            <a
              type="button"
              class="btn btn-primary mt-2"
              style="padding: 0.2rem"
              ref="openingHappy"
              @click="select('opening', FeedbackType.Happy)"
            >
              <img alt="Happy face icon" style="height: 2rem" src="@/assets/images/happy.svg"
            /></a>
            <a
              type="button"
              class="btn btn-primary mx-1 mt-2"
              style="padding: 0.2rem"
              ref="openingNeutral"
              @click="select('opening', FeedbackType.Neutral)"
            >
              <img alt="Neutral face icon" style="height: 2rem" src="@/assets/images/neutral.svg"
            /></a>
            <a
              type="button"
              class="btn btn-primary mt-2"
              style="padding: 0.2rem"
              ref="openingSad"
              @click="select('opening', FeedbackType.Sad)"
            >
              <img alt="Sad face icon" style="height: 2rem" src="@/assets/images/sad.svg"
            /></a>
            <input
              placeholder="(Optional) Tell us more..."
              class="mt-3"
              maxlength="2000"
              style="width: 100%"
              ref="openingCommentInput"
            />
          </div>
          <div style="text-align: left; color: #40abc0" class="mt-3">
            <h5 style="margin-bottom: 0">How was the chat?</h5>
            <span style="margin-top: 2rem">Was it fun? Did it add to the experience?</span><br />
            <a
              type="button"
              class="btn btn-primary mt-2"
              style="padding: 0.2rem"
              ref="chatHappy"
              @click="select('chat', FeedbackType.Happy)"
            >
              <img alt="Happy face icon" style="height: 2rem" src="@/assets/images/happy.svg"
            /></a>
            <a
              type="button"
              class="btn btn-primary mx-1 mt-2"
              style="padding: 0.2rem"
              ref="chatNeutral"
              @click="select('chat', FeedbackType.Neutral)"
            >
              <img alt="Neutral face icon" style="height: 2rem" src="@/assets/images/neutral.svg"
            /></a>
            <a
              type="button"
              class="btn btn-primary mt-2"
              style="padding: 0.2rem"
              ref="chatSad"
              @click="select('chat', FeedbackType.Sad)"
            >
              <img alt="Sad face icon" style="height: 2rem" src="@/assets/images/sad.svg"
            /></a>
            <input
              placeholder="(Optional) Tell us more..."
              class="mt-3"
              maxlength="2000"
              style="width: 100%"
              ref="chatsCommentInput"
            />
          </div>
          <div style="text-align: left; color: #40abc0" class="mt-3">
            <h5 style="margin-bottom: 0">Anything else you'd like to add?</h5>
            <input
              placeholder="(Optional) Tell us..."
              class="mt-3"
              maxlength="2000"
              style="width: 100%"
              ref="generalCommentInput"
            />
          </div>
          <span style="color: red; margin-top: -0.2rem; font-size: 0.8rem">{{ errorText }}</span>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-outline-info"
            style="height: 2.5rem; width: 5rem"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            Cancel
          </button>
          <button
            type="button"
            class="btn btn-info ph-no-capture"
            style="height: 2.5rem; width: 5rem"
            @click="
              () => {
                sendFeedback();
                // Track in function so we can get the feedback, and errors if there are any
              }
            "
          >
            {{ sendingFeedback ? '' : 'Send' }}
            <Loader v-if="sendingFeedback" size="1.5" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { type Ref, ref } from 'vue';
  import { useToast } from 'vue-toast-notification';

  import { storeToRefs } from 'pinia';

  import Loader from '@/components/util/Loader.vue';
  import { useGameStore } from '@/stores/gameStore';
  import { useModalsStore } from '@/stores/modalsStore';
  import { type DetailedFeedback, FeedbackType } from '@/types/apitypes';
  import { track } from '@/util/tracking';

  const gameStore = useGameStore();
  const ms = useModalsStore();

  const { gameFeedbackInitialFeedbackRating, gameFeedbackGameId } = storeToRefs(ms);

  const errorText = ref('');
  const sendingFeedback = ref(false);

  // Buttons
  const playstyleHappy: Ref<HTMLElement | null> = ref(null);
  const playstyleNeutral: Ref<HTMLElement | null> = ref(null);
  const playstyleSad: Ref<HTMLElement | null> = ref(null);
  const openingHappy: Ref<HTMLElement | null> = ref(null);
  const openingNeutral: Ref<HTMLElement | null> = ref(null);
  const openingSad: Ref<HTMLElement | null> = ref(null);
  const chatHappy: Ref<HTMLElement | null> = ref(null);
  const chatNeutral: Ref<HTMLElement | null> = ref(null);
  const chatSad: Ref<HTMLElement | null> = ref(null);

  // Inputs
  const generalCommentInput: Ref<HTMLInputElement | null> = ref(null);
  const humanLikeCommentInput: Ref<HTMLInputElement | null> = ref(null);
  const openingCommentInput: Ref<HTMLInputElement | null> = ref(null);
  const chatsCommentInput: Ref<HTMLInputElement | null> = ref(null);

  let selectedPlaystyle: FeedbackType | null = null;
  let selectedOpening: FeedbackType | null = null;
  let selectedChat: FeedbackType | null = null;

  const select = (group: string, type: FeedbackType) => {
    let happy: HTMLElement | null;
    let neutral: HTMLElement | null;
    let sad: HTMLElement | null;

    if (group == 'playstyle') {
      selectedPlaystyle = type;
      happy = playstyleHappy.value;
      neutral = playstyleNeutral.value;
      sad = playstyleSad.value;
    } else if (group == 'opening') {
      selectedOpening = type;
      happy = openingHappy.value;
      neutral = openingNeutral.value;
      sad = openingSad.value;
    } else if (group == 'chat') {
      selectedChat = type;
      happy = chatHappy.value;
      neutral = chatNeutral.value;
      sad = chatSad.value;
    }

    happy!.classList.remove('btn-primary');
    neutral!.classList.remove('btn-primary');
    sad!.classList.remove('btn-primary');
    happy!.classList.remove('btn-info');
    neutral!.classList.remove('btn-info');
    sad!.classList.remove('btn-info');

    switch (type) {
      case FeedbackType.Happy:
        happy!.classList.add('btn-info');
        neutral!.classList.add('btn-primary');
        sad!.classList.add('btn-primary');
        break;
      case FeedbackType.Neutral:
        happy!.classList.add('btn-primary');
        neutral!.classList.add('btn-info');
        sad!.classList.add('btn-primary');
        break;
      case FeedbackType.Sad:
        happy!.classList.add('btn-primary');
        neutral!.classList.add('btn-primary');
        sad!.classList.add('btn-info');
        break;
    }
  };

  const doneWithModal = (feedback: DetailedFeedback) => {
    ms.closeCurrentModal(feedback);
    sendingFeedback.value = false;
  };

  const sendFeedback = () => {
    if (selectedPlaystyle == null || selectedOpening == null || selectedChat == null) {
      errorText.value = 'Please select a rating for each category';
      track('game_feedback_modal', 'send_feedback', 'click', {
        error: errorText.value,
      });
      return;
    }

    if (gameFeedbackGameId == null) {
      console.error("No game id, can't send feedback");
      track('game_feedback_modal', 'send_feedback', 'click', {
        error: "No game id, can't send feedback",
      });
      return;
    }

    const feedback: DetailedFeedback = {
      initialRating: gameFeedbackInitialFeedbackRating.value,
      generalComment: generalCommentInput.value?.value,
      humanLike: {
        rating: selectedPlaystyle,
        comment: humanLikeCommentInput.value?.value,
      },
      opening: {
        rating: selectedOpening,
        comment: openingCommentInput.value?.value,
      },
      chats: { rating: selectedChat, comment: chatsCommentInput.value?.value },
    };

    sendingFeedback.value = true;
    gameStore
      .sendFeedback(gameFeedbackGameId.value!, feedback)
      .then(() => {
        doneWithModal(feedback);
        useToast().success('Feedback sent. Thanks!');
        track('game_feedback_modal', 'send_feedback', 'click', {
          feedback: feedback,
        });
      })
      .catch((error) => {
        console.error('Failed sending feedback: ' + error);
        sendingFeedback.value = false;
        track('game_feedback_modal', 'send_feedback', 'click', {
          error: 'Failed sending feedback: ' + error,
        });
      });
  };
</script>

<style scoped></style>
