// Shortened days of week
export const DAYS_OF_WEEK = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

// Text formatting constants
export const TEXT_FORMATTING = {
  SINGULAR_GAME: 'game',
  PLURAL_GAMES: 'games',
};

// Full weekday names
export const WEEKDAY_NAMES = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];
