<template>
  <div>
    <div v-if="loadingBots || (loadingGames && games.length == 0)" class="text-center">
      <Loader />
    </div>
    <div v-else>
      <div v-if="fixedViewType == null" style="text-align: right">
        <img
          :src="viewType == 'grid' ? listIcon : gridIcon"
          :alt="viewType == 'grid' ? 'A grid icon' : 'A list icon'"
          class="bounce-top ph-no-capture"
          style="cursor: pointer"
          @click="
            () => {
              toggleViewType();
              track('game_history_table', 'toggle_view_type', 'click');
            }
          "
        />
      </div>
      <div
        v-if="viewType == 'grid'"
        style="display: flex; flex-wrap: wrap; justify-content: center; gap: 1rem"
      >
        <div v-for="(game, index) in games" :key="index">
          <GameHistoryCard
            :bot="bs.getBot(game.bot.id)"
            :game="game"
            @updateLatestGames="updateLatestGames"
          />
        </div>
      </div>

      <div v-if="viewType == 'list'">
        <GameHistoryList :games="games" />
      </div>
      <div v-if="!endOfGames" class="text-center">
        <Loader v-if="loadingGames" />
        <a
          v-else
          @click="
            () => {
              getMore();
              track('game_history_table', 'load_more_games', 'click');
            }
          "
          style="cursor: pointer"
          class="ph-no-capture"
          >Load more...</a
        >
      </div>
      <div v-if="!loadingGames && endOfGames && games.length == 0" class="text-center">
        No games played yet
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { type PropType, type Ref, ref } from 'vue';

  import gridIcon from '@/assets/images/grid.svg';
  import listIcon from '@/assets/images/list.svg';
  import GameHistoryCard from '@/components/history/GameHistoryCard.vue';
  import GameHistoryList from '@/components/history/GameHistoryList.vue';
  import Loader from '@/components/util/Loader.vue';
  import { useBotsStore } from '@/stores/botStore';
  import { useGameStore } from '@/stores/gameStore';
  import type { Game } from '@/types/apitypes';
  import { track } from '@/util/tracking';

  const gs = useGameStore();
  const bs = useBotsStore();
  const props = defineProps({
    fixedViewType: {
      type: String as PropType<'grid' | 'list'>,
      default: null,
    },
    maxGames: {
      type: Number,
      default: null,
    },
  });

  const games: Ref<Game[]> = ref([]);

  const viewType = ref(props.fixedViewType == null ? 'grid' : props.fixedViewType);
  const loadingGames = ref(true);
  const loadingBots = ref(true);
  const endOfGames = ref(false);

  bs.refresh().then(() => {
    loadingBots.value = false;
  });
  getMore();

  function toggleViewType() {
    if (props.fixedViewType != null) {
      viewType.value = props.fixedViewType;
    } else {
      viewType.value = viewType.value == 'grid' ? 'list' : 'grid';
    }
  }

  function getMore() {
    loadingGames.value = true;
    const fromDate =
      games.value.length > 0 ? games.value[games.value.length - 1].startedAt : new Date();

    const getNGames = props.maxGames != null && props.maxGames < 20 ? props.maxGames : 20;

    gs.getGames(fromDate, getNGames).then((g) => {
      g.sort((a, b) => b.startedAt.getTime() - a.startedAt.getTime());
      endOfGames.value = g.length < getNGames;
      games.value.push(...g);

      if (props.maxGames != null && games.value.length >= props.maxGames) {
        games.value = games.value.slice(0, props.maxGames);
        endOfGames.value = true;
      }

      loadingGames.value = false;
    });
  }
  const updateLatestGames = () => {
    games.value = [];
    getMore();
  };
</script>

<style scoped></style>
