<template>
  <div style="margin: 50px auto"><BotProfileComponent :botId="botId" /></div>
</template>

<script setup lang="ts">
  import { ref, watch } from 'vue';
  import { useRoute } from 'vue-router';

  import BotProfileComponent from '@/components/modals/BotProfileComponent.vue';
  import { useBackgroundStore } from '@/stores/backgroundStore';
  import { usePageStore } from '@/stores/pageStore';
  import { ImageType } from '@/types/internaltypes';

  const route = useRoute();
  const backgroundStore = useBackgroundStore();
  const ps = usePageStore();

  const botId = ref(
    typeof route.params.botid === 'string' ? route.params.botid : route.params.botid[0]
  );

  watch(
    () => botId.value,
    () => {
      backgroundStore.setBackground(ps.img(botId.value, ImageType.BotBackground, null));
    },
    { immediate: true }
  );
</script>
