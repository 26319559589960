<template>
  <button
    class="btn btn-warning"
    style="padding: 0.2rem 0.5rem; font-size: 1.2rem"
    @click="onUnlockClick"
  >
    <i class="fa-solid fa-crown" />
    {{ text }}
  </button>
</template>

<script setup lang="ts">
  import router from '@/router';
  import { track } from '@/util/tracking';

  const props = defineProps({
    text: { type: String, default: 'Unlock' },
    trackingCategory: { type: String, required: true },
    trackingObject: { type: String, required: true },
  });

  const onUnlockClick = () => {
    router.push({
      name: 'premium',
    });
    track(props.trackingCategory, props.trackingCategory, 'click');
  };
</script>
