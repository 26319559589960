<template>
  <div id="text-spot" style="overflow-x: hidden; overflow-y: hidden">
    <div
      id="start-game-text"
      class="draw-text"
      :style="{
        color: animationColor,
      }"
    >
      Begin!
    </div>
  </div>
</template>

<script setup lang="ts">
  import { onMounted, ref } from 'vue';

  import { usePageStore } from '@/stores/pageStore';
  import { hexToComplimentary } from '@/util/util';

  const props = defineProps({
    color: {
      type: String,
      default: '#fff',
    },
  });

  const animationColor = ref('#fff');

  const start = () => {
    usePageStore().setPlaySound('start');

    if (props.color != '#fff') {
      animationColor.value = hexToComplimentary(props.color);
    }
    const innerTextElement = document.getElementById('start-game-text');

    innerTextElement!.classList.add('scale-in-center');

    innerTextElement!.addEventListener('animationend', function () {
      innerTextElement!.classList.remove('scale-in-center');
      // @ts-ignore
      innerTextElement.classList.add('puff-out-center');
    });
  };

  onMounted(() => {
    start();
  });
</script>

<style scoped>
  @media (max-width: 359px) {
    .draw-text {
      font-size: 3rem;
    }
  }

  @media (max-width: 410px) {
    .draw-text {
      font-size: 5.5rem;
    }
  }

  .scale-in-center {
    -webkit-animation: scale-in-center 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: scale-in-center 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }
  .puff-out-center {
    -webkit-animation: puff-out-center 1s cubic-bezier(0.165, 0.84, 0.44, 1) both;
    animation: puff-out-center 1s cubic-bezier(0.165, 0.84, 0.44, 1) both;
  }

  /* ----------------------------------------------
 * Generated by Animista on 2023-10-10 12:48:11
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

  /**
 * ----------------------------------------
 * animation puff-out-center
 * ----------------------------------------
 */
  @-webkit-keyframes puff-out-center {
    0% {
      -webkit-transform: scale(1);
      transform: scale(1);
      -webkit-filter: blur(0px);
      filter: blur(0px);
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(2);
      transform: scale(2);
      -webkit-filter: blur(4px);
      filter: blur(4px);
      opacity: 0;
    }
  }
  @keyframes puff-out-center {
    0% {
      -webkit-transform: scale(1);
      transform: scale(1);
      -webkit-filter: blur(0px);
      filter: blur(0px);
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(2);
      transform: scale(2);
      -webkit-filter: blur(4px);
      filter: blur(4px);
      opacity: 0;
    }
  }
  /* ----------------------------------------------
 * Generated by Animista on 2023-10-10 12:50:1
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

  /**
 * ----------------------------------------
 * animation scale-in-center
 * ----------------------------------------
 */
  @-webkit-keyframes scale-in-center {
    0% {
      -webkit-transform: scale(0);
      transform: scale(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
      opacity: 1;
    }
  }
  @keyframes scale-in-center {
    0% {
      -webkit-transform: scale(0);
      transform: scale(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
      opacity: 1;
    }
  }

  .tracking-in-expand {
    -webkit-animation: tracking-in-expand 1.5s cubic-bezier(0.215, 0.61, 0.355, 1) both;
    animation: tracking-in-expand 1.5s cubic-bezier(0.215, 0.61, 0.355, 1) both;
  }

  .tracking-out-contract {
    -webkit-animation: tracking-out-contract 0.7s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
    animation: tracking-out-contract 0.7s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  }
  /* ----------------------------------------------
 * Generated by Animista on 2023-9-23 22:36:51
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

  /**
 * ----------------------------------------
 * animation tracking-out-contract
 * ----------------------------------------
 */
  @-webkit-keyframes tracking-out-contract {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    100% {
      letter-spacing: -0.5em;
      opacity: 0;
    }
  }
  @keyframes tracking-out-contract {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    100% {
      letter-spacing: -0.5em;
      opacity: 0;
    }
  }

  /* ----------------------------------------------
 * Generated by Animista on 2023-9-23 22:37:15
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

  /**
 * ----------------------------------------
 * animation tracking-in-expand
 * ----------------------------------------
 */
  @-webkit-keyframes tracking-in-expand {
    0% {
      letter-spacing: -0.5em;
      opacity: 0;
    }
    40% {
      opacity: 0.6;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes tracking-in-expand {
    0% {
      letter-spacing: -0.5em;
      opacity: 0;
    }
    40% {
      opacity: 0.6;
    }
    100% {
      opacity: 1;
    }
  }

  #text-spot {
    height: 100%;
    width: 100%;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .draw-text {
    display: inline-block;
    font-size: 120px;
    color: #d4d4d4;
    letter-spacing: 10px;
    font-family: 'Luckiest Guy';
    font-weight: 100;
    -webkit-text-stroke: 2px black;
  }

  .draw-animation-sub-text {
    display: inline-block;
    font-size: 2rem;
    color: #d4d4d4;
    font-family: 'Luckiest Guy';
    font-weight: 100;
    -webkit-text-stroke: 1.5px black;
    position: absolute;
    margin-top: 7rem;
  }

  .swing-in-top-fwd {
    -webkit-animation: swing-in-top-fwd 1s ease-in-out both;
    animation: swing-in-top-fwd 1s ease-in-out both;
  }

  /* ----------------------------------------------
 * Generated by Animista on 2024-5-11 15:34:50
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

  /**
 * ----------------------------------------
 * animation swing-in-top-fwd
 * ----------------------------------------
 */
  @-webkit-keyframes swing-in-top-fwd {
    0% {
      -webkit-transform: rotateX(-100deg);
      transform: rotateX(-100deg);
      -webkit-transform-origin: top;
      transform-origin: top;
      opacity: 0;
    }
    100% {
      -webkit-transform: rotateX(0deg);
      transform: rotateX(0deg);
      -webkit-transform-origin: top;
      transform-origin: top;
      opacity: 1;
    }
  }
  @keyframes swing-in-top-fwd {
    0% {
      -webkit-transform: rotateX(-100deg);
      transform: rotateX(-100deg);
      -webkit-transform-origin: top;
      transform-origin: top;
      opacity: 0;
    }
    100% {
      -webkit-transform: rotateX(0deg);
      transform: rotateX(0deg);
      -webkit-transform-origin: top;
      transform-origin: top;
      opacity: 1;
    }
  }
  @keyframes dash {
    0% {
      transform: skew(-30deg, 0deg) translateX(300%) scale(0.8);
      opacity: 1;
    }

    40% {
      transform: skew(10deg, 0deg) translateX(100%) scale(0.9);
    }

    60% {
      transform: skew(10deg, 0deg) translateX(-10px) scale(1.2);
    }

    70% {
      transform: skew(0, 0deg) translateX(0) scale(1.3);
    }

    75% {
      transform: skew(0, 0deg) translateX(0) scale(1.3);
    }

    90% {
      transform: skew(0, 0deg) translateX(0) scale(0.85);
    }

    100% {
      transform: translateY(0) scale(1);
      opacity: 1;
    }
  }

  @keyframes done-animating {
    0% {
      transform: scale(1);
    }

    50% {
      transform: scale(2);
    }

    70% {
      transform: scale(2);
    }

    100% {
      transform: scale(0);
    }
  }

  @keyframes scale-out {
    0% {
      transform: scale(1);
      opacity: 1;
    }

    100% {
      transform: scale(0);
      opacity: 0;
    }
  }

  #main-text {
    position: relative;
    z-index: 1;
  }

  .done-animating {
    animation: done-animating 500ms ease-in;
  }

  .scale-out {
    animation: scale-out 500ms ease-in;
  }

  #text-spot {
    height: 100%;
    width: 100%;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .sub-text {
    font-size: 2rem;
    color: #fff2af;
    font-family: 'Luckiest Guy';
    font-weight: 100;
    -webkit-text-stroke: 1.5px black;
    position: absolute;
    margin-top: 7rem;
  }

  .letter {
    display: inline-block;
    font-size: 120px;
    color: #fff2af;
    letter-spacing: 10px;
    font-family: 'Luckiest Guy';
    font-weight: 100;
    -webkit-text-stroke: 2px black;
  }

  .letter.animate {
    animation: dash 500ms ease-in forwards;
    opacity: 0;
  }

  .swing-in-top-fwd {
    -webkit-animation: swing-in-top-fwd 1s ease-in-out both;
    animation: swing-in-top-fwd 1s ease-in-out both;
  }

  /* ----------------------------------------------
 * Generated by Animista on 2024-5-11 15:34:50
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

  /**
 * ----------------------------------------
 * animation swing-in-top-fwd
 * ----------------------------------------
 */
  @-webkit-keyframes swing-in-top-fwd {
    0% {
      -webkit-transform: rotateX(-100deg);
      transform: rotateX(-100deg);
      -webkit-transform-origin: top;
      transform-origin: top;
      opacity: 0;
    }
    100% {
      -webkit-transform: rotateX(0deg);
      transform: rotateX(0deg);
      -webkit-transform-origin: top;
      transform-origin: top;
      opacity: 1;
    }
  }
  @keyframes swing-in-top-fwd {
    0% {
      -webkit-transform: rotateX(-100deg);
      transform: rotateX(-100deg);
      -webkit-transform-origin: top;
      transform-origin: top;
      opacity: 0;
    }
    100% {
      -webkit-transform: rotateX(0deg);
      transform: rotateX(0deg);
      -webkit-transform-origin: top;
      transform-origin: top;
      opacity: 1;
    }
  }
</style>
