<template>
  <div class="box">
    <h3 class="termination-title">
      <i class="fa-solid fa-flag-checkered" style="color: var(--clr-accent)" />
      {{ heading }}
    </h3>

    <div v-if="loading" class="loading-container">
      <h3 style="color: var(--clr-main-lightest)">-</h3>
    </div>
    <div v-else class="termination-breakdown">
      <!-- Main termination table with Win/Loss columns -->
      <div class="termination-table">
        <!-- Headers -->
        <div class="table-headers">
          <div class="header-spacer" />
          <div class="header">Win</div>
          <div class="header">Loss</div>
        </div>

        <!-- Termination rows -->
        <div
          v-for="termType in ['Resignation', 'Checkmate', 'Timeout']"
          :key="termType"
          class="termination-table-row"
        >
          <div class="termination-label">{{ termType }}</div>
          <div class="termination-count">
            {{ getCountByName(termType, 'Win') }}
          </div>
          <div class="termination-count">
            {{ getCountByName(termType, 'Loss') }}
          </div>
        </div>
      </div>

      <!-- Draw section -->
      <div class="termination-table draw-table">
        <!-- Header -->
        <div class="table-headers">
          <div class="header-spacer" />
          <div class="header draw">Draw</div>
        </div>

        <!-- Draw rows -->
        <div
          v-for="item in getTerminationsByCategory('Draw')"
          :key="item.type"
          class="termination-table-row"
        >
          <div class="termination-label">{{ item.name }}</div>
          <div class="termination-count">
            {{ item.count }}
          </div>
        </div>
      </div>

      <!-- Aborted games (always shown in the last row) -->
      <div class="termination-table aborted-table">
        <div class="termination-table-row">
          <div class="termination-label">Aborted</div>
          <div class="termination-count">
            {{ abortedCount }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { computed } from 'vue';

  import { GameTermination } from '@/types/apitypes';

  const props = defineProps({
    heading: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      default: true,
    },
    gameResults: {
      type: Array,
      required: true,
    },
  });

  // Define categories for termination types
  const terminationCategories = {
    Win: [
      { type: 'resign_won', name: 'Resignation' },
      { type: 'checkmate_won', name: 'Checkmate' },
      { type: 'time_won', name: 'Timeout' },
    ],
    Loss: [
      { type: 'resign_lost', name: 'Resignation' },
      { type: 'checkmate_lost', name: 'Checkmate' },
      { type: 'time_lost', name: 'Timeout' },
    ],
    Draw: [
      { type: GameTermination.Threefold, name: 'Threefold Repetition' },
      {
        type: GameTermination.InsufficientMaterial,
        name: 'Insufficient Material',
      },
      { type: GameTermination.Stalemate, name: 'Stalemate' },
      { type: GameTermination.FiftyMove, name: 'Fifty Move Rule' },
    ],
  };

  // Process game results to count termination methods
  const terminationCounts = computed(() => {
    if (props.loading || !props.gameResults || props.gameResults.length === 0) {
      return {};
    }

    // Initialize counts object with all termination types
    const counts: Record<string | number, number> = {};

    // Initialize all termination types with 0
    Object.values(GameTermination)
      .filter((value) => typeof value === 'number')
      .forEach((value) => {
        counts[value as number] = 0;
      });

    // Add special categories
    counts['checkmate_won'] = 0;
    counts['checkmate_lost'] = 0;
    counts['resign_won'] = 0;
    counts['resign_lost'] = 0;
    counts['time_won'] = 0;
    counts['time_lost'] = 0;
    counts[-1] = 0; // Other category

    props.gameResults.forEach((game: any) => {
      if (game && game.termination !== undefined) {
        // Convert to number if it's a string
        const terminationValue =
          typeof game.termination === 'string' ? parseInt(game.termination, 10) : game.termination;

        // Special handling for checkmate
        if (terminationValue === GameTermination.Checkmate) {
          // Check if user won or lost
          if (game.result_user === 'win') {
            counts['checkmate_won']++;
          } else if (game.result_user === 'loss') {
            counts['checkmate_lost']++;
          } else {
            // Fallback for any unexpected cases
            counts[terminationValue]++;
          }
        }
        // Special handling for resignation
        else if (terminationValue === GameTermination.Resign) {
          // We count this in both Win and Loss categories based on result
          // We need to check the result to know if it was a win or loss for the user
          if (game.result_user === 'win') {
            // User won, opponent resigned
            counts['resign_won'] = (counts['resign_won'] || 0) + 1;
          } else if (game.result_user === 'loss') {
            // User lost, user resigned
            counts['resign_lost'] = (counts['resign_lost'] || 0) + 1;
          } else {
            // Fallback for any unexpected cases
            counts[terminationValue]++;
          }
        }
        // Special handling for timeout
        else if (terminationValue === GameTermination.Time) {
          // We count this in both Win and Loss categories based on result
          if (game.result_user === 'win') {
            // User won, opponent timed out
            counts['time_won'] = (counts['time_won'] || 0) + 1;
          } else if (game.result_user === 'loss') {
            // User lost, user timed out
            counts['time_lost'] = (counts['time_lost'] || 0) + 1;
          } else {
            // Fallback for any unexpected cases
            counts[terminationValue]++;
          }
        }
        // Check if it's a valid termination type
        else if (terminationValue in counts) {
          counts[terminationValue]++;
        } else {
          counts[-1]++; // Other category
        }
      }
    });

    return counts;
  });

  // Get the count of aborted games for separate display
  const abortedCount = computed(() => {
    return terminationCounts.value[GameTermination.Aborted] || 0;
  });

  // Function to get terminations by category with counts
  function getTerminationsByCategory(category: string) {
    const items = terminationCategories[category as keyof typeof terminationCategories] || [];

    return items.map((item) => {
      const count = terminationCounts.value[item.type] || 0;
      return {
        type: item.type,
        name: item.name,
        count: count,
      };
    });
  }

  // Function to get count by termination name and category (Win/Loss)
  function getCountByName(terminationName: string, category: 'Win' | 'Loss') {
    const items = terminationCategories[category];
    if (!items) return 0;

    const item = items.find((item) => item.name === terminationName);
    if (!item) return 0;

    return terminationCounts.value[item.type] || 0;
  }
</script>

<style scoped>
  .box {
    width: 100%;
    padding: 1.25rem;
    text-align: center;
  }

  .termination-title {
    margin-bottom: 1.5rem;
    text-align: center;
    color: var(--clr-main);
  }

  .termination-breakdown {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    margin-top: 0.5rem;
  }

  .termination-table {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .draw-table,
  .aborted-table {
    margin-top: 1.5rem;
  }

  .table-headers {
    display: grid;
    grid-template-columns: 1fr 4rem 4rem;
    margin-bottom: 0.5rem;
  }

  .draw-table .table-headers {
    grid-template-columns: 1fr 4rem;
  }

  .header {
    font-size: 1rem;
    font-weight: bold;
    padding: 0 0.5rem;
    text-align: center;
  }

  .header-spacer {
    text-align: left;
  }

  .termination-table-row {
    display: grid;
    grid-template-columns: 1fr 4rem 4rem;
    align-items: center;
    padding: 0.2rem 0;
    margin-bottom: 0.2rem;
  }

  .draw-table .termination-table-row,
  .aborted-table .termination-table-row {
    grid-template-columns: 1fr 4rem;
  }

  .termination-label {
    font-size: 1rem;
    font-weight: 600;
    color: black;
    text-align: left;
    padding-left: 0.2rem;
  }

  .termination-count {
    font-weight: bold;
    font-size: 1rem;
    text-align: center;
    color: var(--clr-accent);
  }

  .loading-container {
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 600px) {
    .box > span {
      font-size: 0.8rem;
      margin-bottom: 0.5rem;
      color: var(--clr-main-light);
    }

    .termination-label {
      font-size: 0.8rem;
    }

    .termination-count {
      font-size: 0.8rem;
    }
  }
</style>
