<template>
  <div
    v-show="show"
    class="card information-container bounce-top"
    style="--bs-card-border-color: var(--clr-accent); margin-bottom: 1.5rem"
  >
    <div class="main">
      <div class="premium-text-and-button">
        <span>{{ getText() }}</span>
      </div>
      <div>
        <a
          role="button"
          class="ph-no-capture"
          @click="
            () => {
              dismissBanner();
              track('dashboard', 'dismiss_going_premmium_banner', 'click');
            }
          "
          ><i class="fa-regular fa-xmark-circle close-x"
        /></a>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ref } from 'vue';

  import { useUserStore } from '@/stores/userStore';
  import { track } from '@/util/tracking';

  const userStore = useUserStore();

  const show = ref(shouldShow());

  function isActiveFreePremium() {
    return useUserStore().user.data?.trial != null && Date.now() < useUserStore().user.data!.trial!;
  }

  function getText() {
    if (useUserStore().user.data?.trial == null) {
      // Should never happen
      return '';
    }
    const fullDaysLeft = Math.floor(
      (useUserStore().user.data!.trial! - Date.now()) / 1000 / 60 / 60 / 24
    );

    function daysLeft(left: number) {
      if (left <= 1) {
        return 'day';
      } else {
        return left + ' days';
      }
    }

    return `👋 We've enabled free Premium for you for another ${daysLeft(
      fullDaysLeft
    )}, no action required! Make sure you try out all the bots and find your favorite! 🎉🚀`;
  }

  function shouldShow() {
    if (
      useUserStore().user.data?.actions?.includes('dismissedGotFreePremiumBanner') ||
      localStorage.getItem('dismissedGotFreePremiumBanner') == 'true'
    ) {
      return false;
    }

    // This banner should only show for people who got the one time free trial, which ends at this exact time
    if (useUserStore().user.data?.trial != 1730451946000) {
      return false;
    }

    if (
      !isActiveFreePremium() ||
      useUserStore().user.data?.roles?.includes('premium') ||
      useUserStore().user.data?.roles?.includes('lifetime') ||
      useUserStore().user.data?.roles?.includes('yearly') ||
      useUserStore().user.data?.roles?.includes('monthly')
    ) {
      return false;
    }

    return true;
  }

  function dismissBanner() {
    show.value = false;
    localStorage.setItem('dismissedGotFreePremiumBanner', 'true');
    useUserStore().addAction('dismissedGotFreePremiumBanner');
  }
</script>

<style scoped>
  .premium-text-and-button {
    display: flex;
    gap: 1rem;
  }

  .premium-text-and-button strong {
    font-size: 1.1rem !important;
  }

  .premium-text-and-button a {
    height: auto;
    white-space: nowrap;
  }

  @media (max-width: 768px) {
    .premium-text-and-button {
      flex-direction: column;
      gap: 1rem;
      align-items: center;
    }
    .premium-text-and-buttonstrong {
      font-size: 1.5rem;
    }

    .premium-text-and-button {
      text-align: center;
      text-wrap: balance;
    }
  }

  .main {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
  }

  .information-container {
    width: 100%;
    padding: 0.5rem 0.5rem 0.5rem 2rem;
  }

  .close-x {
    color: var(--clr-accent);
  }

  .close-x:hover {
    color: var(--clr-accent2);
  }
</style>
