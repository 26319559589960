<template>
  <div style="line-height: 0; position: absolute; left: 0; top: 3.7rem; opacity: 0.3; z-index: -1">
    <!-- Repeat this div 100 times for 10x10 mosaic -->
    <div class="square" />
    <div class="square" />
    <div class="square" />
    <div class="square" />
    <div class="square" />
    <br />
    <div class="square" />
    <div class="square" />
    <div class="square" />
    <br />
    <div class="square" />
    <div class="square" />
    <br />
    <div class="square" />

    <!-- ... -->
  </div>
</template>

<script setup lang="ts"></script>

<style scoped>
  .mosaic {
    display: grid;
    grid-template-columns: repeat(10, 10rem);
    grid-gap: 0.5rem;
  }

  .square {
    width: 4rem;
    height: 4rem;
    display: inline-block;
    background-color: var(--color);
  }

  /* You can set more mellow colors as needed */
  .square:nth-child(1) {
    --color: #b52c5f;
  } /* PeachPuff */
  .square:nth-child(2) {
    --color: #cc3360;
  } /* Lavender */
  .square:nth-child(3) {
    --color: #cf395e;
  } /* LemonChiffon */
  .square:nth-child(4) {
    --color: #e35047;
  } /* Beige */
  .square:nth-child(5) {
    --color: #ff8147;
  } /* SeaShell */
  .square:nth-child(6) {
    --color: #ff0;
  } /* AntiqueWhite */
  .square:nth-child(7) {
    --color: #e0355c;
  } /* BlanchedAlmond */
  .square:nth-child(8) {
    --color: #fd6655;
  } /* MistyRose */
  .square:nth-child(9) {
    --color: #ffa49f;
  } /* Khaki */
  .square:nth-child(10) {
    --color: #ff0;
  } /* LightCyan */
  .square:nth-child(11) {
    --color: #ffa49f;
  } /* LightCyan */
  .square:nth-child(12) {
    --color: #fcb9b5;
  } /* LightCyan */
  .square:nth-child(13) {
    --color: #ff0;
  } /* LightCyan */
  .square:nth-child(14) {
    --color: #ffdea9;
  } /* LightCyan */
</style>
