<template>
  <div>
    <div class="card">
      <div class="card-body" style="text-align: center">
        <div id="moves" class="moves-container">
          <div
            style="display: flex; flex-direction: column; align-items: center"
            v-tippy="{
              content:
                lichessUrl == null
                  ? 'Games starting from postion can\'t currently be linked directly to Lichess. Copy the PGN instead. Sorry about that!'
                  : 'Open the game on a Lichess analysis board',
            }"
          >
            <a
              :href="lichessUrl ?? ''"
              target="_blank"
              :class="'btn btn-info ph-no-capture' + (lichessUrl == null ? ' disabled' : '')"
              role="button"
              @click="track('moves_box', 'lichess_analysis', 'click')"
            >
              <svg
                viewBox="0 0 50 50"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                stroke="currentColor"
                height="1rem"
              >
                <path
                  stroke-linejoin="round"
                  d="M38.956.5c-3.53.418-6.452.902-9.286 2.984C5.534 1.786-.692 18.533.68 29.364 3.493 50.214 31.918 55.785 41.329 41.7c-7.444 7.696-19.276 8.752-28.323 3.084C3.959 39.116-.506 27.392 4.683 17.567 9.873 7.742 18.996 4.535 29.03 6.405c2.43-1.418 5.225-3.22 7.655-3.187l-1.694 4.86 12.752 21.37c-.439 5.654-5.459 6.112-5.459 6.112-.574-1.47-1.634-2.942-4.842-6.036-3.207-3.094-17.465-10.177-15.788-16.207-2.001 6.967 10.311 14.152 14.04 17.663 3.73 3.51 5.426 6.04 5.795 6.756 0 0 9.392-2.504 7.838-8.927L37.4 7.171z"
                />
              </svg>

              <span style="margin-left: 0.5rem">Analyze on Lichess</span>
            </a>
          </div>
          <textarea class="pgn-input" v-model="pgn" />
        </div>
        <div class="profile-container">
          <div style="width: 1rem" />
          <div class="card profile-name-text" :style="{ backgroundColor: opponent.config.boardbg }">
            <div class="card-body" style="margin-top: -0.75rem">PGN</div>
          </div>
          <div style="flex-grow: 1" />
          <button
            v-tippy="{
              content: 'Copy PGN',
            }"
            class="btn btn-info ph-no-capture"
            style="padding: 0.2rem 0.2rem 0rem 0.2rem"
            @click="copy()"
          >
            <i class="fa-regular fa-copy" />
          </button>
          <div style="width: 0.5rem" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { type PropType, type Ref, ref, watch } from 'vue';
  import { useToast } from 'vue-toast-notification';

  import { useUserStore } from '@/stores/userStore';
  import { type Bot, GameTermination, type TimeControl } from '@/types/apitypes';
  import { track } from '@/util/tracking';

  const props = defineProps({
    opponent: {
      type: Object as PropType<Bot>,
      default: null,
    },
    moves: { type: Array as PropType<string[]> },
    startPosition: {
      type: String as PropType<string | null>,
      default: null,
    },
    movesToStartPosition: {
      type: String as PropType<string | null>,
      default: null,
    },
    result: { type: String as PropType<string | null>, default: null },
    viewingHistoryPly: {
      type: null as unknown as PropType<number | null>,
      default: null,
    },
    termination: {
      type: Number as PropType<GameTermination>,
      default: null,
    },
    side: { type: String as PropType<'white' | 'black'>, default: 'white' },
    startedAt: {
      type: String,
      default: null,
    },
    timeControl: {
      type: Object as PropType<TimeControl>,
      default: null,
    },
    rated: {
      type: Boolean,
      default: false,
    },
    ratingChange: {
      type: Object as PropType<{ old: { rating: number } }>,
      default: null,
    },
  });

  const moveList: Ref<
    {
      moveNumber: number;
      ply: number;
      white: string | null;
      black: string | null;
    }[]
  > = ref(parseMoves(props.moves));
  const pgn = ref(movesToPgn());
  const lichessUrl = ref(getLichessUrl());

  watch(
    () => props.moves,
    (newMoves) => {
      moveList.value = parseMoves(newMoves);
      pgn.value = movesToPgn();
      lichessUrl.value = getLichessUrl();
    }
  );

  function parseMoves(moves: string[] | undefined): {
    moveNumber: number;
    ply: number;
    white: string | null;
    black: string | null;
  }[] {
    if (moves == undefined) {
      return [{ moveNumber: 1, ply: 0, white: null, black: null }];
    }

    const moveList: {
      moveNumber: number;
      ply: number;
      white: string | null;
      black: string | null;
    }[] = [];

    for (let i = 0; i < moves.length; i += 2) {
      const white = moves[i];
      let black = null;
      if (i + 1 < moves.length) {
        black = moves[i + 1];
      }

      moveList.push({
        moveNumber: i / 2 + 1,
        ply: i + 1,
        white: white,
        black: black,
      });
    }
    return moveList;
  }

  function getStartedAtDate() {
    let date = '?';
    try {
      date = new Date(props.startedAt).toISOString().split('T')[0];
    } catch (e) {
      // Not interested in why we can't get the date, it might be missing or malformed, doesn't really matter, it will stay as "?"
    }

    return date;
  }

  function getStartedAtTime() {
    let time = '?';
    try {
      time = new Date(props.startedAt).toISOString().split('T')[1];
    } catch (e) {
      // Not interested in why we can't get the time, it might be missing or malformed, doesn't really matter, it will stay as "?"
    }

    return time;
  }

  function movesToPgn() {
    let pgn = '';

    pgn += `[Event "Chessiverse ${props.rated ? 'Rated' : 'Unrated'} Game"]\n`;
    pgn += `[Site "Chessiverse.com"]\n`;
    pgn += `[Date "${getStartedAtDate()}"]\n`;
    pgn += `[Time "${getStartedAtTime()}"]\n`;
    if (props.startPosition != null) {
      pgn += '[FEN "' + props.startPosition + '"]\n';
    }
    pgn += `[White "${props.side == 'white' ? 'Player' : props.opponent.name}"]\n`;
    pgn += `[Black "${props.side == 'black' ? 'Player' : props.opponent.name}"]\n`;
    pgn += `[Result "${props.result}"]\n`;
    pgn += `[WhiteElo "${
      props.side == 'white'
        ? props.ratingChange?.old?.rating
          ? Math.round(props.ratingChange.old.rating)
          : getCurrentUserRating()
        : props.opponent.strength.estimated_elo
    }"]\n`;
    pgn += `[BlackElo "${
      props.side == 'white'
        ? props.opponent.strength.estimated_elo
        : props.ratingChange?.old?.rating
          ? Math.round(props.ratingChange.old.rating)
          : getCurrentUserRating()
    }"]\n`;

    if (props.timeControl != null) {
      pgn += `[TimeControl "${props.timeControl.initial / 1000}+${
        props.timeControl.increment / 1000
      }"]\n`;
    }
    pgn += `[Termination "${GameTermination[props.termination]}"]\n`;

    pgn += '\n';

    for (let i = 0; i < moveList.value.length; i++) {
      const move = moveList.value[i];
      if (move.black == null) {
        pgn += move.moveNumber + '. ' + move.white + ' ';
      } else {
        pgn += move.moveNumber + '. ' + move.white + ' ' + move.black + ' ';
      }
    }

    return pgn.trim();
  }

  function copy() {
    navigator.clipboard
      .writeText(pgn.value)
      .then(() => {
        useToast().success('Copied PGN to clipboard');
      })
      .catch((e) => {
        useToast().error(
          'Failed copying PGN to clipboard, make sure you allow the browser to copy, the error was: ' +
            e
        );
      });
  }

  function getLichessUrl(): string | null {
    if (props.moves == undefined || props.moves.length == 0) {
      return null;
    }

    let movesString = '';

    if (props.startPosition != null) {
      if (props.movesToStartPosition != null) {
        movesString = props.movesToStartPosition + ' ';
      } else {
        // There was a start position, but no moves to get there, so we can't create a link
        return null;
      }
    }

    movesString += props.moves.join(' ');

    return 'https://lichess.org/analysis/pgn/' + movesString + '?color=' + props.side;
  }

  function getCurrentUserRating() {
    const rating = useUserStore().user.data?.rating?.rating;

    if (rating == undefined) {
      return '?';
    }

    return Math.round(rating);
  }
</script>

<style scoped>
  .moves-container {
    margin-top: 1rem;
    white-space: nowrap;
    display: flex;
    gap: 1rem;
    flex-direction: column;
  }

  .pgn-input {
    width: 100%;
    height: 20rem;
    padding: 1rem;
  }

  .profile-name-text {
    height: 2rem;
    width: 8rem;
    text-align: center;
  }

  .profile-container {
    position: absolute;
    top: -0.75rem;
    left: 0rem;
    display: flex;
    width: 100%;
    gap: 0.5rem;
  }
</style>
