<template>
  <div
    :style="{
      width: '100%',
      height: '100%',
      backgroundColor: !loading && ratingHistory != null && ratingsDaily != null ? '' : '#fcfcfc',
    }"
  >
    <div
      v-if="!loading && ratingHistory != null && ratingsDaily != null"
      style="width: 100%; height: 100%"
    >
      <apexchart
        type="line"
        width="100%"
        height="100%"
        :series="currentSeries"
        :options="currentOptions"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
  import { type PropType, ref, watch } from 'vue';

  import LoaderNew from '@/components/util/LoaderNew.vue';

  const props = defineProps({
    ratingHistory: {
      type: Object,
    },
    timeframe: {
      type: String as PropType<'week' | 'month' | 'year' | 'all'>,
      default: 'all',
    },
    loading: {
      type: Boolean,
      default: true,
    },
  });

  const chosenTimeframe = ref<'week' | 'month' | 'year' | 'all'>(props.timeframe);
  const ratingsDaily = ref();
  const currentSeries = ref();
  const currentOptions = ref();

  watch(
    () => props.ratingHistory,
    (newRatingHistory) => {
      if (!props.loading) {
        parseRatings(newRatingHistory).then(() => {
          currentSeries.value = getCurrentSeries();
          currentOptions.value = getCurrentOptions(chosenTimeframe.value);
        });
      }
    }
  ),
    { deep: true, immediate: true };

  watch(
    () => props.timeframe,
    (newTimeframe) => {
      chosenTimeframe.value = newTimeframe;
    }
  );

  watch(
    () => ({
      ratingsDaily: ratingsDaily.value,
      chosenTimeframe: chosenTimeframe.value,
    }),
    () => {
      currentSeries.value = getCurrentSeries();
      currentOptions.value = getCurrentOptions(chosenTimeframe.value);
    },
    { deep: true }
  );

  function getCurrentSeries() {
    return [
      {
        name: 'Rating',
        data: ratingsDaily.value.map((entry: any) => entry.rating_new),
        color: '#73cdee',
      },
    ];
  }

  function getCurrentOptions(timeframe: 'week' | 'month' | 'year' | 'all') {
    const data = ratingsDaily.value;

    let initialZoom = null;

    switch (timeframe) {
      case 'week':
        initialZoom = 7;
        break;
      case 'month':
        initialZoom = 30;
        break;
      case 'year':
        initialZoom = 365;
        break;
    }

    return {
      chart: {
        toolbar: {
          show: false, // Show the toolbar with zoom options
        },
      },
      stroke: {
        curve: 'smooth', // Optional: Make the line smooth
        width: 3, // Set the width of the line
        colors: ['#3498db'], // Line color (same as defined in series, this works too)
      },
      xaxis: {
        categories: data.map((entry: any) =>
          new Date(entry.started_at).toLocaleString('default', {
            month: 'short',
            day: 'numeric',
          })
        ),
        min: initialZoom == null ? null : data.length - initialZoom,
        max: initialZoom == null ? null : data.length - 1,
        tickAmount: 6,
        labels: {
          style: {
            colors: '#73cdee', // Set the color of the x-axis labels
            fontSize: '1rem', // Optional: Change font size
            fontFamily: 'Neucha, Helvetica, Arial, sans-serif', // Optional: Change font family
          },
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: '#73cdee', // Set the color of the y-axis labels
            fontSize: '1rem', // Optional: Change font size
            fontFamily: 'Neucha, Helvetica, Arial, sans-serif', // Optional: Change font family
          },
        },
        axisTicks: {
          show: false,
        },
      },
      grid: {
        borderColor: '#efefef', // Set the color of the grid lines
      },
    };
  }

  async function parseRatings(entries: any): Promise<void> {
    ratingsDaily.value = fillMissingDays(entries);
  }

  function fillMissingDays(ratings: any): any {
    if (ratings.daily == null || ratings.daily.length == 0) {
      return [];
    }

    const entryMap = new Map<string, number>();
    let earliestEntry: Date | null = null;
    let earliestEntryNumber = -1;

    ratings.daily.forEach((rating: any) => {
      const date = new Date(rating.started_at._seconds * 1000);

      if (earliestEntry == null || rating.started_at._seconds * 1000 < earliestEntry.getTime()) {
        earliestEntry = date;
        earliestEntryNumber = rating.started_at._seconds;
      }

      entryMap.set(date.toISOString().split('T')[0], Math.round(rating.rating_new));
    });

    const today = new Date();
    const currentDate = earliestEntry!;

    let previous = null;
    const result = [];

    // Loop through every day from the earliest entry to today, and fill any missing days with the previous ratings
    while (currentDate <= today) {
      const next: any =
        entryMap.get(currentDate.toISOString().split('T')[0]) == null
          ? previous
          : entryMap.get(currentDate.toISOString().split('T')[0]);

      previous = next;

      // Move to the next day
      currentDate.setDate(currentDate.getDate() + 1);

      result.push({
        started_at: currentDate.toISOString().split('T')[0],
        rating_new: next,
      });
    }

    return result;
  }
</script>

<style scoped></style>
