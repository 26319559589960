<template>
  <div v-if="showFilter" class="mb-2 d-flex opponent-filter-container">
    <div class="card" style="width: 18.85rem">
      <div class="scrollable">
        <div class="mb-2 d-flex">
          <label for="search" style="color: var(--clr-accent)"
            >Search:
            <input
              type="text"
              id="search"
              name="search"
              placeholder="Name, openings, country etc."
              style="width: 15.9rem"
              v-model="filter.search"
          /></label>
        </div>
        <div class="mb-3 d-flex align-items-end">
          <label for="openingsSearch" style="color: var(--clr-accent)"
            >Openings:
            <input
              type="text"
              id="openingsSearch"
              name="openingsSearch"
              placeholder="Opening name"
              style="width: 11rem"
              v-model="filter.openingsSearch"
            />
          </label>
          <button
            :class="{
              'btn openings-color-toggler': true,
              'openings-white': isOpeningsWhite,
              'openings-black': !isOpeningsWhite,
            }"
            @click="toggleOpeningColor"
          >
            <span>
              <i class="fa-solid fa-chess-queen" />
              <span v-if="isOpeningsWhite"> White</span>
              <span v-else> Black</span>
            </span>
          </button>
        </div>
        <CountryPicker
          :selectedCountry="filter.country"
          @updateCountry="(country) => (filter.country = country)"
        />
        <div style="display: flex; gap: 0.5rem; margin-block: 1rem">
          <Toggle v-model="filter.beaten" class="filter-toggle ph-no-capture">
            <template v-slot:label="{ checked, classList }">
              <span v-if="checked" :class="classList.label">Beaten</span>
              <span v-else :class="classList.label">Beaten</span>
            </template>
          </Toggle>
          <Toggle v-model="filter.unbeaten" class="filter-toggle ph-no-capture">
            <template v-slot:label="{ checked, classList }">
              <span v-if="checked" :class="classList.label">Unbeaten</span>
              <span v-else :class="classList.label">Unbeaten</span>
            </template>
          </Toggle>
        </div>

        <div style="display: flex; gap: 0.5rem; margin-block: 1rem">
          <Toggle v-model="filter.others_nonFavorite" class="filter-toggle ph-no-capture">
            <template v-slot:label="{ classList }">
              <span
                style="display: flex; gap: 6px; justify-content: center"
                :class="classList.label"
              >
                <i class="fa-solid fa-heart" />
                Others
              </span>
            </template>
          </Toggle>
          <Toggle v-model="filter.favorite" class="filter-toggle ph-no-capture">
            <template v-slot:label="{ classList }">
              <span
                style="display: flex; gap: 6px; justify-content: center"
                :class="classList.label"
              >
                <i style="color: red" class="fa-solid fa-heart" />
                Favorite
              </span>
            </template>
          </Toggle>
        </div>

        <div class="rating-section">
          <div class="rating-and-slider">
            <span style="color: var(--clr-accent)">Rating:</span>
            <Slider
              :min="0"
              :max="2500"
              :lazy="true"
              :step="10"
              showTooltip="drag"
              class="filer-slider rating-slider"
              v-model="filter.rating"
            />
          </div>
          <div class="preset-rating-btns">
            <button
              v-for="preset in presetRangeOptions"
              :key="preset.label"
              :class="{
                'btn btn-info': true,
                active: filter.presetRatingLabel === preset.label,
              }"
              @click="onPresetRatingClick(preset)"
            >
              {{ preset.label }}
            </button>
          </div>
        </div>

        <div>
          <span style="color: var(--clr-accent)">Playstyle:</span>

          <div style="display: flex; gap: 0.5rem">
            <div
              v-for="(playstyle, i) in playstyles"
              :key="'playstyle' + i"
              v-tippy="{
                content: isMobileWidth() ? '' : playstyle.tooltip,
              }"
            >
              <img
                role="button"
                :src="playstyle.img"
                :style="{
                  height: '2rem',
                  width: '2rem',
                  opacity: filter.excludePlaystyles.includes(i) ? 0.2 : 1,
                }"
                @click="
                  () => {
                    if (filter.excludePlaystyles.includes(i)) {
                      filter.excludePlaystyles = filter.excludePlaystyles.filter(
                        (e: any) => e !== i
                      );
                    } else {
                      filter.excludePlaystyles.push(i);
                    }
                  }
                "
              />
            </div>
          </div>
        </div>
        <div class="bot-features">
          <!--                <span style="color: var(--clr-accent)">Features:</span>-->
          <div class="features-header" @click="showFeatures = !showFeatures">
            <div class="">Features</div>
            <div class="arrow">
              <i class="fa-solid fa-caret-down" />
            </div>
          </div>
          <div class="bot-features-list" v-if="showFeatures">
            <div
              v-for="(range, featureKey) in filter.features"
              :key="featureKey"
              class="bot-feature"
            >
              <span
                v-tippy="{
                  content: featureTip(featureKey),
                }"
                >{{ capitalizedFeatures[featureKey] }}</span
              >
              <Slider
                :min="0"
                :max="10"
                :lazy="true"
                :step="1"
                showTooltip="drag"
                class="filer-slider bot-feature-slider"
                v-model="filter.features[featureKey]"
              />
            </div>
          </div>
        </div>
      </div>
      <div style="display: flex; justify-content: end; margin: 10px 30px 15px 0">
        <a
          type="button"
          class="btn btn-info ph-no-capture"
          style="padding: 0.2rem; height: 2rem"
          @click="
            () => {
              resetFilter();
              track('opponent_list', 'reset_filter', 'click');
            }
          "
          >Clear</a
        >
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { type PropType, computed, onMounted, ref, watch } from 'vue';

  import Slider from '@vueform/slider';
  import Toggle from '@vueform/toggle';

  import CountryPicker from '@/components/common/CountryPicker.vue';
  import { useBotsStore } from '@/stores/botStore';
  import type { Bot } from '@/types/apitypes';
  import { personaCategories, personaValues } from '@/util/personplaycategory';
  import { getPremiumState } from '@/util/premium';
  import { track } from '@/util/tracking';
  import { deepEqual, isMobileWidth } from '@/util/util';

  const props = defineProps({
    bots: {
      type: (Array as PropType<Bot[]>) || null,
      required: true,
    },
    premiumToggle: {
      type: Boolean,
      required: true,
    },
    showFilter: {
      type: Boolean,
      required: true,
    },
  });

  const emit = defineEmits(['botsInitiallyFiltered', 'updateFilteredBots', 'updatePremiumToggle']);

  const bs = useBotsStore();

  const playstyles = personaCategories();

  const filterVersion = '5';

  const defaultFilter = {
    premium: true,
    beaten: true,
    unbeaten: true,
    favorite: true,
    others_nonFavorite: true,
    search: '',
    country: {
      code: '',
      name: '',
      flagSvgLink: '',
    },
    openingsSearch: '',
    openingsColor: 'white' as 'white' | 'black',
    rating: [0, 2500],
    version: filterVersion,
    excludePlaystyles: [] as number[],
    presetRatingLabel: '',
    features: {
      openings: [0, 10],
      repertoire: [0, 10],
      blunders: [0, 10],
      endgames: [0, 10],
      playstyle: [0, 10],
      temperament: [0, 10],
      tenacity: [0, 10],
    },
  };

  const presetRangeOptions = [
    { label: 'Beginner', range: [0, 499] },
    { label: 'Novice', range: [500, 999] },
    { label: 'Intermediate', range: [1000, 1499] },
    { label: 'Skilled', range: [1500, 1999] },
    { label: 'Advanced', range: [2000, 2500] },
  ];

  const filter = ref({ ...JSON.parse(JSON.stringify(defaultFilter)) });

  const showFeatures = ref(false);

  const updateFilteringBots = async () => {
    await bs.refresh();

    const filteredBots = filterBots();

    emit('updateFilteredBots', {
      updatedFilteredBots: filteredBots,
      filterInUse: !deepEqual(filter.value, defaultFilter),
    });
  };

  watch(
    filter,
    () => {
      updateFilteringBots();

      localStorage.setItem('botListFilter', JSON.stringify(filter.value));
    },
    { deep: true, immediate: false }
  );

  watch(
    () => props.bots,
    () => {
      updateFilteringBots();
    },
    { immediate: false }
  );

  watch(
    [() => props.premiumToggle],
    async () => {
      filter.value = {
        ...filter.value,
        premium: props.premiumToggle,
        version: filterVersion,
      };
    },
    { immediate: false }
  );

  function filterBots() {
    const isFeatureOutOfRange = (featureValue: number, range: [number, number]): boolean => {
      return !(featureValue >= range[0] && featureValue <= range[1]);
    };

    const result = bs.list?.filter((b) => {
      if (
        filter.value.search &&
        ![b.openings.white, b.openings.black, b.country.name, b.occupation, b.name]
          .join(' ')
          .toLowerCase()
          .includes(filter.value.search.toLowerCase())
      ) {
        return false;
      }

      if (
        filter.value.openingsSearch &&
        ![b.openings[filter.value.openingsColor as 'white' | 'black']]
          .join(' ')
          .toLowerCase()
          .includes(filter.value.openingsSearch.toLowerCase())
      ) {
        return false;
      }

      if (
        filter.value.country.code &&
        filter.value.country.code.toLowerCase() !== b.country.code.toLowerCase()
      ) {
        return false;
      }

      if (
        b.strength.estimated_elo < filter.value.rating[0] ||
        b.strength.estimated_elo > filter.value.rating[1]
      ) {
        // Doesn't match rating
        return false;
      }

      if (b.user == null || !b.user.hasWon) {
        if (!filter.value.unbeaten) {
          // User has not beaten this bot and we're not showing unbeaten bots
          return false;
        }
      } else {
        if (!filter.value.beaten) {
          // User as beating this bot and we're not showing beaten bots
          return false;
        }
      }

      if (b.user == null || !b.user.isFavorite) {
        if (!filter.value.others_nonFavorite) {
          // This not is not favorite and we're not showing non-favorite bots
          return false;
        }
      } else {
        if (!filter.value.favorite) {
          // This not is favorite and we're not showing favorite bots
          return false;
        }
      }

      if (!filter.value.premium && !getPremiumState() && b.premium == 'closed') {
        return false;
      }

      if (filter.value.excludePlaystyles.includes(b.persona.category - 1)) {
        return false;
      }

      if (
        isFeatureOutOfRange(b.persona.openings, filter.value.features.openings) ||
        isFeatureOutOfRange(b.persona.repertoire, filter.value.features.repertoire) ||
        isFeatureOutOfRange(b.persona.blunders, filter.value.features.blunders) ||
        isFeatureOutOfRange(b.persona.endgames, filter.value.features.endgames) ||
        isFeatureOutOfRange(b.persona.playstyle, filter.value.features.playstyle) ||
        isFeatureOutOfRange(b.persona.temperament, filter.value.features.temperament) ||
        isFeatureOutOfRange(b.persona.tenacity, filter.value.features.tenacity) ||
        isFeatureOutOfRange(b.persona.openings, filter.value.features.openings)
      ) {
        // Doesn't match feature ranges
        return false;
      }

      return true;
    });

    return result;
  }

  function resetFilter() {
    filter.value = { ...JSON.parse(JSON.stringify(defaultFilter)) };
  }

  const toggleOpeningColor = () => {
    filter.value.openingsColor = filter.value.openingsColor === 'white' ? 'black' : 'white';
  };

  const onPresetRatingClick = (preset: { label: string; range: number[] }) => {
    filter.value.rating = preset.range;
    filter.value.presetRatingLabel = preset.label;
  };

  const isOpeningsWhite = computed<boolean>(() => {
    return filter.value.openingsColor === 'white';
  });

  const featureTip = (featureName: keyof typeof capitalizedFeatures.value): string => {
    const feature = capitalizedFeatures.value[featureName];

    return personaValues[feature as keyof typeof personaValues].desc;
  };

  // Computed property for capitalized feature names to avoid update on every rerender
  const capitalizedFeatures = computed<Record<keyof typeof filter.value.features, string>>(() => {
    return Object.keys(filter.value.features).reduce(
      (acc, key) => {
        acc[key] = key.charAt(0).toUpperCase() + key.slice(1);
        return acc;
      },
      {} as Record<string, string>
    );
  });

  onMounted(async () => {
    let initialFilter = filter.value;

    if (localStorage.getItem('botListFilter') != null) {
      const storedFilter = JSON.parse(localStorage.getItem('botListFilter')!);

      // Only load the filter if it matches the current version
      if (storedFilter.version === filterVersion) {
        initialFilter = storedFilter;
        emit('updatePremiumToggle', filter.value.premium);
      }
    }

    localStorage.setItem('botListFilter', JSON.stringify(initialFilter));

    filter.value = initialFilter;
  });
</script>

<style scoped>
  .opponent-filter-container {
    justify-content: right;

    .scrollable {
      /*max-height: 380px;
    overflow-y: auto;
    overflow-x: hidden;*/
      padding: 1rem;
    }

    .filer-slider {
      --slider-bg: var(--clr-main-lightest);
      --slider-connect-bg: var(--clr-accent);
      --slider-tooltip-bg: var(--clr-accent);
      --slider-handle-ring-color: var(--clr-accent);
    }

    .rating-section {
      .rating-and-slider {
        display: flex;
        flex-direction: row;
        gap: 0.5rem;
        margin: 0.5rem 0 0.5rem 0;

        .rating-slider {
          width: 12rem;
          margin: 0.5rem 0 0 0.5rem;
        }
      }

      .preset-rating-btns {
        display: flex;
        justify-content: center;
        gap: 7px;
        margin-bottom: 16px;
        button {
          font-size: 0.7rem;
          padding: 6px 3px;
        }
      }
    }

    .openings-color-toggler {
      display: flex;
      border: 2px solid var(--clr-rect-1);
      cursor: pointer;
      height: 31px;
      font-size: 0.8rem;

      &:hover {
        border: 2px solid var(--clr-rect-1) !important;
      }

      span {
        display: flex;
        width: 45px;
        align-items: center;
        gap: 5px;
      }

      &.openings-white {
        color: black;
        background: white;
      }

      &.openings-black {
        color: white;
        background: black;
      }
    }

    .bot-features {
      margin-top: 16px;

      .features-header {
        display: flex;
        align-items: center;
        gap: 3px;
        color: var(--clr-accent) !important;
        cursor: pointer;
        padding: 5px 0;

        .arrow {
          display: flex;
        }
      }

      .bot-feature {
        margin-bottom: 5px;
        font-size: 0.8rem;
        cursor: pointer;

        .bot-feature-slider {
          max-width: 240px;
          margin: 0.5rem 0 0 0.5rem;
        }
      }
    }

    @media (max-width: 992px) {
      .filter-align {
        justify-content: center;
      }
    }

    .filter-toggle {
      outline: none;
      --toggle-width: 7rem;
      --toggle-height: 1.5rem;
      --toggle-font-size: 1rem;
      --toggle-bg-on: var(--clr-dark-accent);
      --toggle-bg-off: var(--clr-main-lighter);
      --toggle-text-on: white;
      --toggle-text-off: white;
      --toggle-border-on: var(--clr-dark-accent);
      --toggle-border-off: var(--clr-main-lighter);
      --toggle-handle-enabled: var(--clr-rect-2);
    }
  }
</style>
