<template>
  <div class="container">
    <h5 style="align-self: start">What's new</h5>
    <div class="news-items">
      <NewsItem v-for="(item, index) in getNewsItems()" :item="item" :key="index" />
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { NewsItemContent } from '@/types/internaltypes';

  import NewsItem from './NewsItem.vue';

  function getNewsItems(): NewsItemContent[] {
    return newsItems.slice(0, 4);
  }
  const newsItems: NewsItemContent[] = [
    {
      icon: 'fa-duotone fa-solid fa-chart-simple',
      title: 'More stats',
      summary: "We've added more statistics to the statistics page, check them out!",
      date: 'March 19',
    },
    {
      icon: 'fa-solid fa-filter',
      title: 'Better filters',
      summary:
        "It's now easier than ever to find your favorite bot. Check out the new filters on the Explore-page!",
      date: 'March 18',
    },
    {
      icon: 'fa-duotone fa-solid fa-robot',
      title: 'Rating rework',
      summary:
        "All bots have had their ratings reworked. To fill in some of the gaps, we've added over 30 new bots as well. Check them out!",
      date: 'November 28',
    },
    {
      icon: 'fa-duotone fa-solid fa-chart-simple',
      title: 'Statistics',
      summary:
        "We've added a statistics page with rating history and more. Check it out in the account menu!",
      date: 'October 12',
    },
    {
      icon: 'fa-solid fa-icons',
      title: 'Playstyles',
      summary:
        "We've added solidness and aggressiveness to the bots and now group them into playstyle categories. Check it out on the Play-page or in the bot profiles!",
      date: 'Sep 3',
    },
    {
      icon: 'fa-solid fa-arrow-right-arrow-left',
      title: 'Next bot suggestions',
      summary:
        'After games you will now get a suggested next bot, based on rating, previous history and more.',
      date: 'Jul 20',
    },
    {
      icon: 'fa-solid fa-book',
      title: 'Opening changes',
      summary:
        'All bots have gotten a massive workover on their opening choices. They will prefer openings more common to their level and also stick to them much more.',
      date: 'Jun 25',
    },
    {
      icon: 'fa-solid fa-gear',
      title: 'Settings',
      summary:
        "We've added a settings page (check the menu) where you for example can override the board colors. Check it out!",
      date: 'Jun 14',
    },
    {
      icon: 'fa-solid fa-chess-clock-flip',
      title: 'Time controls',
      summary:
        'Play blitz or other controls against the bots, they will use the time realistically! Simply choose a time before the game starts.',
      date: 'May 13',
    },
    {
      icon: 'fa-solid fa-share-all',
      title: 'Premoves',
      summary: 'Premoves have been activated, good for the shorter time controls.',
      date: 'May 13',
    },
    {
      icon: 'fa-solid fa-face-monocle',
      title: 'Daily challenges',
      summary:
        'Win a classic endgame or a position from a recent top game. Check out the daily positions!',
      date: 'Apr 5',
    },
    {
      icon: 'fa-solid fa-swords',
      title: "Today's Matchup",
      summary: "Beat today's bot and see how many days you can keep on winning.",
      date: 'Apr 5',
    },
    {
      icon: 'fa-duotone fa-dumbbell',
      title: 'Practice to the brim',
      summary: 'More than 400 new practice positions were added to the practice section.',
      date: 'Mar 10',
    },
    {
      icon: 'fa-duotone fa-arrow-trend-up',
      title: 'Rated games',
      summary: 'You can now get a rating and play rated games.',
      date: 'Feb 20',
    },
    {
      icon: 'fa-regular fa-comment',
      title: 'Better chats',
      summary: 'The bots should now feel more engaging in the chat. More to come!',
      date: 'Feb 10',
    },
    {
      icon: 'fa-duotone fa-volume',
      title: 'Sounds',
      summary:
        'Move sounds, win sounds, puzzle sounds you name it. Turn them off or on in the Account menu.',
      date: 'Jan 18',
    },
    {
      icon: 'fa-solid fa-puzzle-piece',
      title: 'Puzzles!',
      summary:
        "We've added a new puzzles section presented by the bots. Let us know what you think!",
      date: 'Jan 7',
    },
    {
      icon: 'fa-solid fa-cloud',
      title: 'In the cloud',
      summary:
        "We've improved our backend hosting and the site should hopefully feel a lot quicker now! Let us know what you think!",
      date: 'Dec 12',
    },
    {
      icon: 'fa-duotone fa-dumbbell',
      title: 'Much more practice',
      summary:
        'Our practice section has been filled to the brim with practice positions. Start earning your green stars!',
      date: 'Dec 4',
    },
    {
      icon: 'fa-solid fa-dumbbell',
      title: 'Practice',
      summary:
        'Visit our practice section and setup a custom position to train against a bot. Much more practice things to come!',
      date: 'Nov 20',
    },
    {
      icon: 'fa-solid fa-table-cells-large',
      title: 'New dashboard',
      summary:
        'The dashboard (this page!) got a lot more exciting with a weekly challenge, user stats and more to come!',
      date: 'Nov 5',
    },
    {
      icon: 'fa-solid fa-clock-rotate-left',
      title: 'Game history',
      summary: 'Added a history page to review and revisit old games played',
      date: 'Oct 21',
    },
    {
      icon: 'fa-solid fa-list-ol',
      title: 'Moves notation',
      summary: 'Moves notation box for navigating back and forth through moves',
      date: 'Oct 15',
    },
    {
      icon: 'fa-solid fa-pagelines',
      title: 'Preferred openings',
      summary: 'Bots now have personal preferred openings',
      date: 'Oct 12',
    },
  ];
</script>

<style scoped>
  .container {
    display: flex;
    flex-direction: column;
    padding: 0;
    align-items: center;
  }
  .news-items {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 0.5rem;
  }

  @media (min-width: 768px) {
    .news-items {
      gap: 1rem;
    }
  }

  @media (max-width: 1200px) {
    .news-items {
      max-width: 27rem;
    }
  }
</style>
