import { useUserStore } from '@/stores/userStore';
import type { User } from '@/types/apitypes';

export function getPremiumState() {
  const state = localStorage.getItem('userIsPremium');

  if (state == null) {
    // No state in local storage, so retrieve user. Not awaiting here since we'd have to rework everything, but at least we'll update the user
    // this shouldn't really happen since we should always have the user premium state
    useUserStore().refreshUserProfile();
    return false;
  } else {
    return state == 'true';
  }
}

export function getPremiumType() {
  return localStorage.getItem('userPremiumType') ?? null;
}

export function getPremiumPeriod() {
  return localStorage.getItem('userPremiumPeriod') ?? null;
}

export function updatePremiumState(user: User | null) {
  if (user == null) {
    localStorage.removeItem('userIsPremium');
    localStorage.removeItem('userPremiumType');
    localStorage.removeItem('userPremiumPeriod');
  } else {
    const premiumType = calculatePremiumType(user);

    if (premiumType == null) {
      localStorage.setItem('userIsPremium', 'false');
      localStorage.setItem('userPremiumType', '');
      localStorage.setItem('userPremiumPeriod', '');
    } else {
      localStorage.setItem('userIsPremium', 'true');
      localStorage.setItem('userPremiumType', premiumType.type);
      localStorage.setItem('userPremiumPeriod', premiumType.period ?? '');
    }
  }
}

function calculatePremiumType(user: User): {
  type: 'lifetime' | 'subscription' | 'trial';
  period: 'monthly' | 'yearly' | null;
} | null {
  if (user?.roles?.includes('lifetime')) {
    return { type: 'lifetime', period: null };
  } else if (user?.roles?.includes('premium')) {
    let period: 'monthly' | 'yearly' | null = null;
    if (user?.roles?.includes('monthly')) {
      period = 'monthly';
    } else if (user?.roles?.includes('yearly')) {
      period = 'yearly';
    }
    return { type: 'subscription', period: period };
  } else if (user.trial != null && Date.now() < user.trial) {
    return { type: 'trial', period: null };
  }

  return null;
}
