import { useBotsStore } from '@/stores/botStore';

/**
 * Composable for calculating Quick Victory statistics
 * Quick Victory refers to wins that are completed in a relatively small number of moves
 */
export function useQuickVictoryStats() {
  /**
   * Calculate Quick Victory average moves (average number of moves in winning games)
   * @param validBots Array of bots with game statistics
   * @param userRating The current rating of the user
   * @param totalWins Total number of wins by the user
   * @returns The estimated average number of moves in quick victories
   */
  const calculateQuickVictoryStats = (validBots: any[], userRating: number, totalWins: number) => {
    // Assumption: Wins against lower-rated bots are likely to be quicker victories
    const quickWins = validBots
      .filter((bot: any) => {
        const botRating = useBotsStore().getBot(bot.bot_id)?.strength?.estimated_elo || 0;
        // Consider wins against bots rated significantly lower than the player as potential quick wins
        return botRating < userRating - 100 && bot.wins > 0;
      })
      .reduce((total: number, bot: any) => total + bot.wins, 0);

    let quickVictoryAvgMoves = 0;
    // Estimate average moves based on quick wins vs total wins
    if (quickWins > 0 && totalWins > 0) {
      // Estimate: Quick wins take about 20 moves, other wins take about 35 moves
      const quickWinMoves = quickWins * 20;
      const otherWinMoves = (totalWins - quickWins) * 35;
      quickVictoryAvgMoves = (quickWinMoves + otherWinMoves) / totalWins;
    } else {
      // Fallback to standard estimate
      quickVictoryAvgMoves = totalWins > 0 ? 25 : 0; // Placeholder average of 25 moves per win
    }

    return {
      quickVictoryAvgMoves,
      quickWins,
    };
  };

  return {
    calculateQuickVictoryStats,
  };
}
