<template>
  <div
    class="game-info card my-4 background-card"
    :style="{
      background: 'url(' + ps.img(bot.id, ImageType.BotBackground, '20') + ')',
    }"
  >
    <div class="card-body text-center">
      <div
        class="profile-container ph-no-capture"
        @click="
          () => {
            ms.showBotProfile(bot.id);
            track('game_history_card', 'show_bot_profile', 'click');
          }
        "
        style="cursor: pointer"
      >
        <img
          alt="Bot profile image"
          class="profile-image-circle"
          :style="{
            backgroundColor: bot.config.boardbg,
          }"
          :src="ps.img(bot.id, ImageType.BotProfile, '80')"
        />
        <div
          class="card profile-name-text"
          :style="{
            backgroundColor: bot.config.boardbg,
          }"
        >
          <div class="card-body" style="margin-top: -0.75rem">
            {{ bot.name }}
            <img
              :src="getFlagUrl(bot.country.code)"
              :alt="bot.country.name"
              style="height: 0.7rem; margin: -0.2rem 0.2rem 0 0"
              v-tippy="{
                content: bot.country.name,
              }"
            />
            ({{ bot.strength.estimated_elo }})
          </div>
        </div>
      </div>
      <div class="main-info">
        <div class="info-column">
          <div
            class="card profile-date-text"
            :style="{
              backgroundColor: bot.config.boardbg,
            }"
            v-tippy="{
              content: game.startedAt.toLocaleString(),
            }"
          >
            {{ dateToDateMonthWithOptionalYear(game.startedAt) }}
          </div>
          <div
            class="card profile-type-text text-capitalize"
            :style="{
              backgroundColor: bot.config.boardbg,
            }"
          >
            {{
              game.type?.toLowerCase().includes('dailyendgame')
                ? 'Endgame'
                : game.type?.toLowerCase().includes('dailymaster')
                  ? 'Master'
                  : game.type
            }}
          </div>
        </div>
        <div
          class="board card ph-no-capture"
          @click="
            () => {
              if (game.termination != GameTermination.Aborted) {
                game.result == null ? gotoGame(game.id) : gotoAnalysis(game.id);
                track('game_history_card', 'board_image', 'click', {
                  isActiveGame: game.result == null,
                });
              }
            }
          "
          style="box-sizing: content-box; background-color: grey"
        >
          <TheChessboard
            :board-config="{
              fen: game.positions[game.positions.length - 1],
              blockTouchScroll: false,
              orientation: game.userSide === Side.White ? 'white' : 'black',
              coordinates: false,
              viewOnly: true,
            }"
          />
        </div>
        <div class="info">
          <div class="side" v-if="false">
            <a
              v-if="game.userSide === Side.White"
              class="btn btn-info"
              style="
                cursor: auto;
                background: white;
                color: black;
                height: 1.5rem;
                width: 4rem;
                padding: 0;
              "
              >White
            </a>
            <a
              v-else
              class="btn btn-info"
              style="cursor: auto; background: black; height: 1.5rem; width: 4rem; padding: 0"
              >Black
            </a>
          </div>
          <div class="result">
            <div v-if="game.result != null">
              <a
                class="btn btn-info"
                :style="{
                  cursor: 'auto',
                  background: resultData(game.result, game.userSide).color,
                  color: 'black',
                  height: '1.5rem',
                  width: '4rem',
                  padding: '0',
                }"
                >{{ resultData(game.result, game.userSide).message }}
              </a>
            </div>
            <div v-else-if="game.termination == GameTermination.Aborted">
              <a
                class="btn btn-info"
                :style="{
                  cursor: 'auto',
                  background: 'white',
                  color: 'black',
                  height: '1.5rem',
                  width: '4rem',
                  padding: '0',
                }"
                >Aborted
              </a>
            </div>
          </div>
          <div
            class="rating-change card"
            :style="{
              backgroundColor: resultData(game.result, game.userSide).color,
            }"
            v-if="game.result != null && game.rated && game.ratingChange != null"
          >
            <div>
              <span v-if="getRatingChangeNumber(game.ratingChange!) >= 0" style="color: white">
                +{{ getRatingChangeNumber(game.ratingChange!) }}
              </span>
              <span v-else style="color: white">
                {{ getRatingChangeNumber(game.ratingChange!) }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div v-if="game.termination != GameTermination.Aborted">
        <button
          v-if="game.result == null"
          type="button"
          class="btn btn-outline-info to-game-button to-game-button-ongoing ph-no-capture"
          @click="onOngoingGameClick(game.id)"
        >
          {{ 'Ongoing' }}
        </button>
        <button
          v-else
          type="button"
          class="btn btn-info to-game-button ph-no-capture"
          @click="
            () => {
              gotoAnalysis(game.id);
              track('game_history_card', 'view_button', 'click');
            }
          "
        >
          {{ 'View' }}
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { PropType } from 'vue';
  import { TheChessboard } from 'vue3-chessboard';
  import { useRouter } from 'vue-router';
  import { useToast } from 'vue-toast-notification';

  import { useGameStore } from '@/stores/gameStore';
  import { useModalsStore } from '@/stores/modalsStore';
  import { usePageStore } from '@/stores/pageStore';
  import {
    type Bot,
    type Game,
    GameTermination,
    type Rating,
    Result,
    Side,
  } from '@/types/apitypes';
  import { ImageType } from '@/types/internaltypes';
  import { track } from '@/util/tracking';
  import { dateToDateMonthWithOptionalYear, getFlagUrl } from '@/util/util';

  const gs = useGameStore();
  const ms = useModalsStore();
  const ps = usePageStore();
  const router = useRouter();

  defineProps({
    bot: {
      type: Object as PropType<Bot>,
      required: true,
    },
    game: {
      type: Object as PropType<Game>,
      required: true,
    },
  });

  const emit = defineEmits(['updateLatestGames']);

  function getRatingChangeNumber(ratingChange: {
    potential: {
      win: number;
      draw: number;
      loss: number;
    };
    old?: Rating;
    new?: Rating;
  }) {
    if (ratingChange.new == null || ratingChange.old == null) {
      // This shouldn't happen since we null-check in the template, so just least bit of harm return 0
      return 0;
    }
    return Math.round(ratingChange.new.rating - ratingChange.old.rating);
  }

  function resultData(
    result: Result | null,
    userSide: Side
  ): { message: string; color: string; userWon: boolean } {
    if (result == null) {
      return { message: '', color: '#f00', userWon: false };
    } else if (result === Result.Draw) {
      return { message: 'Draw', color: '#ccc', userWon: false };
    } else if (
      (result === Result.White && userSide === Side.White) ||
      (result === Result.Black && userSide === Side.Black)
    ) {
      return { message: 'Win', color: '#469C14', userWon: true };
    } else {
      return { message: 'Loss', color: '#9C2614', userWon: false };
    }
  }

  const gotoAnalysis = (gameId: string) => {
    router.push({
      name: 'analysis',
      query: { id: gameId },
    });
  };

  const onOngoingGameClick = async (gameId: string) => {
    track('game_history_card', 'ongoing_button', 'click');

    const activeGame = await gs.refreshActiveGame();
    if (!activeGame) {
      emit('updateLatestGames');
      useToast().success('The game was finished already, start a new game instead');

      return;
    }

    router.push({
      name: 'game',
      query: { id: gameId, type: 'continue' },
    });
  };

  const gotoGame = (gameId: string) => {
    router.push({
      name: 'game',
      query: { id: gameId, type: 'continue' },
    });
  };
</script>

<style scoped>
  .profile-container {
    position: absolute;
    top: -2rem;
    left: -3rem;
  }
  .profile-image-circle {
    height: 4rem;
    margin-left: 1rem;
    position: absolute;
    z-index: 10;
    border-radius: 50%;
    border: 1.5px solid #444;
  }

  .profile-name-text {
    height: 2rem;
    position: absolute;
    top: 1rem;
    left: 4rem;
    width: 14rem;
    text-align: center;
  }

  .info-column {
    position: absolute;
    top: 1.5rem;
    left: -2em;
    z-index: 10;
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    width: 4rem;
  }

  .profile-date-text,
  .profile-type-text {
    padding: 0.15rem 0;
    text-align: center;
    height: 1.5rem;
    line-height: 1.2rem;
  }

  .profile-type-text {
    font-size: 0.85rem;
  }
  .result {
    position: absolute;
    top: 5rem;
    left: -2em;
    width: 4rem;
    padding: 0;
    z-index: 10;
    text-align: center;
  }

  .rating-change {
    position: absolute;
    top: 6.8rem;
    left: -2em;
    width: 4rem;
    padding: 0;
    z-index: 10;
    text-align: center;
  }
  .game-info {
    width: 15.5rem;
    min-width: 15.5rem;
    padding-top: 1rem;
    margin-left: 1.5rem;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .main-info {
    display: grid;
    grid-template-areas: 'info board';
  }
  .to-game-button {
    position: absolute;
    width: 4.5rem;
    left: -2em;
    top: 10rem;
    white-space: nowrap;
  }
  .to-game-button-ongoing {
    background-color: white;
  }
  .to-game-button-ongoing:hover {
    color: white;
  }
  .info {
    grid-area: info;
  }
  .board {
    width: 10rem;
    margin-left: 2rem;
    grid-area: board;
  }
  :deep(cg-board) {
    background-color: v-bind('bot.config.boardbg'); /* Override the board background color */
  }
  .background-card {
    background-size: cover !important;
    background-repeat: no-repeat !important;
  }
</style>
