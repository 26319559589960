<template>
  <div
    v-show="show"
    class="card information-container bounce-top"
    style="--bs-card-border-color: var(--clr-accent); margin-bottom: 1.5rem"
  >
    <div class="main">
      <div class="premium-text-and-button">
        <span>{{ getMessage() }}</span>
        <div>
          <a
            class="btn btn-warning"
            href="/premium"
            @click="
              () => {
                track('dashboard_notification', 'get_premium', 'click');
              }
            "
          >
            <i class="fa-solid fa-crown" /> Get premium
          </a>
        </div>
      </div>
      <div>
        <a
          role="button"
          class="ph-no-capture"
          @click="
            () => {
              dismissBanner();
              track('dashboard', 'dismiss_going_premmium_banner', 'click');
            }
          "
          ><i class="fa-regular fa-xmark-circle close-x"
        /></a>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ref } from 'vue';

  import { useUserStore } from '@/stores/userStore';
  import { track } from '@/util/tracking';
  import { parseDate } from '@/util/util';

  const userStore = useUserStore();

  const show = ref(shouldShow());

  function isActiveFreePremium() {
    return useUserStore().user.data?.trial != null && Date.now() < useUserStore().user.data!.trial!;
  }

  function getMessage() {
    if (useUserStore().user.data?.trial != null) {
      // User has had a free premium trial, but it's not active anymore
      return '👋 Your free premium period has ended, to keep using all 500+ bots you can sign up for Premium. It comes with a free 7 day trial if you need more time to decide!';
    }

    return '👋 Enjoying Chessiverse? Try a 7-day free Premium trial and unlock all 500+ bots! Use the code <strong>TRYOUT20</strong>, and if you decide to stay you also get a 20% discount! 🎉';
  }

  function shouldShow() {
    if (
      useUserStore().user.data?.actions?.includes('dismissedGoingPremiumBanner') ||
      localStorage.getItem('dismissedGoingPremiumBanner') == 'true'
    ) {
      return false;
    }

    if (
      isActiveFreePremium() ||
      useUserStore().user.data?.roles?.includes('premium') ||
      useUserStore().user.data?.roles?.includes('lifetime') ||
      useUserStore().user.data?.roles?.includes('yearly') ||
      useUserStore().user.data?.roles?.includes('monthly') ||
      useUserStore().user.data?.roles?.includes('beta') // Beta users get their own banner
    ) {
      return false;
    }

    if (useUserStore().user.data?.trial != null) {
      // The use has had a free premium trial, but it's not active anymore (or the previous if-statement would be true)
      // and since we're here, the user hasn't dismissed the banner so we should show it no matter what
      return true;
    }

    // @ts-ignore createdAt does exist
    const createdAt = parseDate(useUserStore().user.firebaseUser?.createdAt);

    if (createdAt == null) {
      return false;
    }

    const sevenDaysAgo = new Date();
    sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
    const fourteenDaysAgo = new Date();
    fourteenDaysAgo.setDate(fourteenDaysAgo.getDate() - 14);

    if (createdAt > sevenDaysAgo || createdAt < fourteenDaysAgo) {
      return false;
    }

    return true;
  }

  function dismissBanner() {
    show.value = false;
    localStorage.setItem('dismissedGoingPremiumBanner', 'true');
    useUserStore().addAction('dismissedGoingPremiumBanner');
  }
</script>

<style scoped>
  .premium-text-and-button {
    display: flex;
    gap: 1rem;
  }

  .premium-text-and-button strong {
    font-size: 1.1rem !important;
  }

  .premium-text-and-button a {
    height: auto;
    white-space: nowrap;
  }

  @media (max-width: 768px) {
    .premium-text-and-button {
      flex-direction: column;
      gap: 1rem;
      align-items: center;
    }
    .premium-text-and-buttonstrong {
      font-size: 1.5rem;
    }

    .premium-text-and-button {
      text-align: center;
      text-wrap: balance;
    }
  }

  .main {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
  }

  .information-container {
    width: 100%;
    padding: 0.5rem 0.5rem 0.5rem 2rem;
  }

  .close-x {
    color: var(--clr-accent);
  }

  .close-x:hover {
    color: var(--clr-accent2);
  }
</style>
