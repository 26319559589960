<template>
  <div>
    <div class="card">
      <div class="card-body text-center" style="height: 12rem">
        <div id="moves" style="overflow: auto; height: 9rem; margin-top: 1rem">
          <div
            v-for="(move, index) in moveList"
            :key="index"
            :id="'move' + move.moveNumber"
            class="d-flex flex-row gap-2 justify-content-center"
          >
            <span style="flex-grow: 0; flex-shrink: 0; flex-basis: 0.5rem"
              >{{ move.moveNumber }}.</span
            >
            <span
              style="flex-grow: 0; flex-shrink: 0; flex-basis: 2.5rem; cursor: pointer"
              :style="getMoveStyle(move.ply)"
              class="ph-no-capture"
              @click="
                () => {
                  gotoPly(move.ply, move.moveNumber);
                  track('moves_box', 'goto_ply', 'click', {
                    white: true,
                    ply: move.ply,
                    moveNumber: move.moveNumber,
                  });
                }
              "
              >{{ move.white }}</span
            >
            <span
              style="flex-grow: 0; flex-shrink: 0; flex-basis: 2.5rem; cursor: pointer"
              :style="getMoveStyle(move.ply + 1)"
              class="ph-no-capture"
              @click="
                () => {
                  gotoPly(move.ply + 1, move.moveNumber);
                  track('moves_box', 'goto_ply', 'click', {
                    white: false,
                    ply: move.ply,
                    moveNumber: move.moveNumber,
                  });
                }
              "
              >{{ move.black }}</span
            ><br />
          </div>
          <div
            v-if="result != null"
            class="card bounce-top"
            :style="{
              width: '6rem',

              margin: '1rem auto',
              backgroundColor: opponentColor,
            }"
          >
            <div
              class="card-body"
              :style="{
                padding: '0rem',
                textAlign: 'center',
              }"
            >
              Result: {{ result }}<br />
              ({{ terminationText }})
            </div>
          </div>
        </div>
        <div class="profile-container d-flex flex-row gap-5">
          <div class="card profile-name-text" :style="{ backgroundColor: opponentColor }">
            <div class="card-body" style="margin-top: -0.75rem">Moves</div>
          </div>

          <MoveNavigation
            :opponent-color="opponentColor"
            :moves="moves == undefined ? -1 : moves.length"
            :viewing-history-ply="viewingHistoryPly"
            @user-input="moveNavigationUserInput"
          />
        </div>
        <div
          class="bottom-buttons"
          v-if="
            result != null &&
            (challengeState == ChallengeState.FinishedCasualNotWin ||
              challengeState == ChallengeState.FinishedCasualWin ||
              challengeState == ChallengeState.FinishedRatedWin ||
              challengeState == ChallengeState.FinishedRatedNotWin ||
              challengeState == ChallengeState.FinishedChallengeCustomWin ||
              challengeState == ChallengeState.FinishedChallengeCustomNotWin ||
              challengeState == ChallengeState.FinishedChallengeDifficultyWin ||
              challengeState == ChallengeState.FinishedChallengeDifficultyNotWin ||
              challengeState == ChallengeState.FinishedPracticeWin ||
              challengeState == ChallengeState.FinishedPracticeNotWin)
          "
        >
          <button
            style="text-decoration: none"
            class="btn btn-info ph-no-capture"
            @click="
              () => {
                gotoAnalysis();
                track('moves_box', 'analyze_game', 'click');
              }
            "
            v-tippy="{
              content: 'Go to analysis board',
            }"
            role="button"
          >
            <i class="fa-solid fa-magnifying-glass-chart" /> Analyze
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { type PropType, type Ref, nextTick, ref, watch } from 'vue';
  import { useRouter } from 'vue-router';

  import MoveNavigation from '@/components/games/MoveNavigation.vue';
  import { GameTermination } from '@/types/apitypes';
  import { ChallengeState, UserInput } from '@/types/internaltypes';
  import { track } from '@/util/tracking';

  const router = useRouter();

  const props = defineProps({
    moves: { type: Array as PropType<string[]> },
    startPosition: {
      type: String as PropType<string | null>,
      default: null,
    },
    movesToStartPosition: {
      type: String as PropType<string | null>,
      default: null,
    },
    gameId: {
      type: String as PropType<string | null>,
      default: null,
    },
    result: { type: String as PropType<string | null>, default: null },
    opponentColor: { type: String, default: '#fff' },
    viewingHistoryPly: {
      type: null as unknown as PropType<number | null>,
      default: null,
    },
    challengeState: {
      type: Number as PropType<ChallengeState | null>,
      default: null,
    },
    termination: {
      type: Number as PropType<GameTermination>,
    },
  });

  const terminationText = ref();

  const emit = defineEmits(['userInput']);

  function getTerminationText(termination: GameTermination | undefined) {
    switch (termination) {
      case GameTermination.Checkmate:
        return 'Checkmate';
      case GameTermination.Time:
        return 'Time';
      case GameTermination.Resign:
        return 'Resignation';
      case GameTermination.Threefold:
        return 'Repetition';
      case GameTermination.Stalemate:
        return 'Stalemate';
      case GameTermination.InsufficientMaterial:
        return 'Material';
      case GameTermination.FiftyMove:
        return '50 moves';
      case GameTermination.Aborted:
        return 'Aborted';
      default: // Should never happen since the only win conditions are above
        return '';
    }
  }

  function moveNavigationUserInput(userInput: { type: UserInput }) {
    emit('userInput', userInput);
  }

  const moveList: Ref<
    {
      moveNumber: number;
      ply: number;
      white: string | null;
      black: string | null;
    }[]
  > = ref([]);

  watch(
    () => props.termination,
    () => {
      terminationText.value = getTerminationText(props.termination);
    },
    { immediate: true }
  );

  watch(props, () => {
    moveList.value = parseMoves(props.moves);
    if (props.viewingHistoryPly == null) {
      nextTick().then(() => {
        scrollTo(moveList.value.length);
      });
    } else {
      let scroll = Math.floor((props.viewingHistoryPly! + 1) / 2);
      if (scroll == 0) {
        scroll = 1;
      }
      nextTick().then(() => {
        scrollTo(scroll);
      });
    }
  });

  function gotoAnalysis() {
    router.push({
      name: 'analysis',
      query: { id: props.gameId },
    });
  }

  function parseMoves(moves: string[] | undefined): {
    moveNumber: number;
    ply: number;
    white: string | null;
    black: string | null;
  }[] {
    if (moves == undefined) {
      return [{ moveNumber: 1, ply: 0, white: null, black: null }];
    }

    const moveList: {
      moveNumber: number;
      ply: number;
      white: string | null;
      black: string | null;
    }[] = [];

    for (let i = 0; i < moves.length; i += 2) {
      const white = moves[i];
      let black = null;
      if (i + 1 < moves.length) {
        black = moves[i + 1];
      }

      moveList.push({
        moveNumber: i / 2 + 1,
        ply: i + 1,
        white: white,
        black: black,
      });
    }
    return moveList;
  }

  function getMoveStyle(ply: number): string {
    if (props.moves == undefined || props.moves.length == 0) {
      return 'color: #444';
    }

    const isViewingHistory = props.viewingHistoryPly != null;
    let moveColor: string;

    if (isViewingHistory) {
      if (ply < props.viewingHistoryPly) {
        moveColor = '#444';
      } else if (ply > props.viewingHistoryPly) {
        moveColor = '#aaa';
      } else {
        moveColor = props.opponentColor;
      }
    } else {
      moveColor = ply == props.moves.length ? props.opponentColor : '#444';
    }

    return 'color: ' + moveColor;
  }

  function gotoPly(ply: number, moveNumber: number) {
    scrollTo(moveNumber);
    emit('userInput', { type: UserInput.HistoryGoto, ply: ply });
  }

  function scrollTo(moveNumber: number) {
    try {
      const container = document.getElementById('moves');
      const anchor = document.getElementById('move' + moveNumber.toString()); // Adjust the selector to target the anchor you want.
      // @ts-ignore
      const anchorPosition = anchor.offsetTop;
      // @ts-ignore
      const containerCenter = container.offsetHeight / 2;
      // @ts-ignore
      const newScrollTop =
        // @ts-ignore
        anchorPosition - containerCenter + anchor.offsetHeight / 2;
      // @ts-ignore
      container.scrollTop = newScrollTop;
    } catch (e) {
      // Ignore, it seems that sometimes there's a race condition here where the anchor element isn't yet rendered or so, just don't scroll then it's not the end of the world
    }
  }
</script>

<style scoped>
  .bottom-buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 1rem;
  }

  .profile-image-circle {
    height: 2.5rem;
    width: 2.5rem;
    z-index: 10;
    border-radius: 50%;
    border: 1px solid #444;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .profile-name-text {
    height: 2rem;
    width: 8rem;
    text-align: center;
  }

  .profile-container {
    position: absolute;
    top: -0.75rem;
    left: 1rem;
  }
</style>
