<template>
  <div id="text-spot" style="overflow-x: hidden; overflow-y: hidden">
    <ConfettiExplosion
      v-if="showConfetti"
      :colors="['#FFDFB7', '#FFD49D', '#FFC741', '#FFC139', '#FFA849']"
      :force="0.7"
      :stageHeight="getViewportSize().height"
      :stageWidth="getViewportSize().width"
      :duration="3500"
      :particleCount="50"
    />
    <img
      alt="Golden star outline"
      src="https://storage.googleapis.com/chessiverse1/images/elements/star_grey.webp"
      id="starOutline"
      class="scale-in-center"
      style="width: 10rem"
      :class="blue ? 'blue' : ''"
    />
    <img
      alt="Golden star"
      v-show="showStar"
      src="https://storage.googleapis.com/chessiverse1/images/elements/star.webp"
      id="starFull"
      style="width: 10rem; position: absolute"
      :class="blue ? 'blue' : ''"
    />
  </div>
</template>

<script setup lang="ts">
  import { nextTick, onMounted, ref } from 'vue';
  import ConfettiExplosion from 'vue-confetti-explosion';

  import { usePageStore } from '@/stores/pageStore';
  import { getViewportSize } from '@/util/util.js';

  const props = defineProps({
    blue: {
      type: Boolean,
      default: false,
    },
  });

  const emit = defineEmits(['isRunning']);

  const showStar = ref(false);
  const showConfetti = ref(false);

  const explode = async () => {
    showConfetti.value = false;
    await nextTick();
    showConfetti.value = true;
  };

  function start() {
    emit('isRunning', { isRunning: true });
    if (props.blue) {
      usePageStore().setPlaySound('blue-star');
    } else {
      usePageStore().setPlaySound('yellow-star');
    }
    showStar.value = true;
    explode();

    const starOutline = document.getElementById('starOutline');
    const starFullElement = document.getElementById('starFull');
    starFullElement!.classList.add('rotate-scale-up-diag-1');

    starFullElement!.addEventListener('animationend', function () {
      starFullElement!.classList.remove('rotate-scale-up-diag-1');
      starOutline!.classList.remove('scale-in-center');

      setTimeout(() => {
        showConfetti.value = false;
        starFullElement!.classList.add('rotate-out-center');
        starOutline!.classList.add('rotate-out-center');
        emit('isRunning', { isRunning: false });
      }, 2000);
    });
  }

  onMounted(() => {
    setTimeout(start, 1000);
  });
</script>

<style scoped>
  .blue {
    filter: hue-rotate(180deg);
  }

  .rotate-out-center {
    -webkit-animation: rotate-out-center 0.6s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
    animation: rotate-out-center 0.6s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  }
  .rotate-scale-up-diag-1 {
    -webkit-animation: rotate-scale-up-diag-1 0.7s linear both;
    animation: rotate-scale-up-diag-1 0.7s linear both;
  }

  .scale-in-center {
    -webkit-animation: scale-in-center 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: scale-in-center 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }
  /* ----------------------------------------------
 * Generated by Animista on 2023-9-24 21:29:12
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

  /**
 * ----------------------------------------
 * animation rotate-out-center
 * ----------------------------------------
 */
  @-webkit-keyframes rotate-out-center {
    0% {
      -webkit-transform: rotate(0);
      transform: rotate(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: rotate(-360deg);
      transform: rotate(-360deg);
      opacity: 0;
    }
  }
  @keyframes rotate-out-center {
    0% {
      -webkit-transform: rotate(0);
      transform: rotate(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: rotate(-360deg);
      transform: rotate(-360deg);
      opacity: 0;
    }
  }

  /* ----------------------------------------------
 * Generated by Animista on 2023-9-24 21:25:2
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

  /**
 * ----------------------------------------
 * animation scale-in-center
 * ----------------------------------------
 */
  @-webkit-keyframes scale-in-center {
    0% {
      -webkit-transform: scale(0);
      transform: scale(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
      opacity: 1;
    }
  }
  @keyframes scale-in-center {
    0% {
      -webkit-transform: scale(0);
      transform: scale(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
      opacity: 1;
    }
  }

  /* ----------------------------------------------
 * Generated by Animista on 2023-9-24 20:46:50
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

  /**
 * ----------------------------------------
 * animation rotate-scale-up-diag-1
 * ----------------------------------------
 */
  @-webkit-keyframes rotate-scale-up-diag-1 {
    0% {
      -webkit-transform: scale(1) rotate3d(1, 1, 0, 0deg);
      transform: scale(1) rotate3d(1, 1, 0, 0deg);
    }
    50% {
      -webkit-transform: scale(2) rotate3d(1, 1, 0, -180deg);
      transform: scale(2) rotate3d(1, 1, 0, -180deg);
    }
    100% {
      -webkit-transform: scale(1) rotate3d(1, 1, 0, -360deg);
      transform: scale(1) rotate3d(1, 1, 0, -360deg);
    }
  }
  @keyframes rotate-scale-up-diag-1 {
    0% {
      -webkit-transform: scale(1) rotate3d(1, 1, 0, 0deg);
      transform: scale(1) rotate3d(1, 1, 0, 0deg);
    }
    50% {
      -webkit-transform: scale(2) rotate3d(1, 1, 0, -180deg);
      transform: scale(2) rotate3d(1, 1, 0, -180deg);
    }
    100% {
      -webkit-transform: scale(1) rotate3d(1, 1, 0, -360deg);
      transform: scale(1) rotate3d(1, 1, 0, -360deg);
    }
  }
  #inner-text {
    position: relative;
    z-index: 1;
  }

  #inner-text.done-animating {
    animation: done-animating 500ms ease-in;
  }

  #text-spot {
    height: 100%;
    width: 100%;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .letter {
    display: inline-block;
    font-size: 120px;
    color: #fff2af;
    letter-spacing: 10px;
    font-family: 'Luckiest Guy';
    font-weight: 100;
    -webkit-text-stroke: 2px black;
  }

  .letter.animate {
    animation: dash 500ms ease-in forwards;
    opacity: 0;
  }
</style>
