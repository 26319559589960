<template>
  <div style="padding-inline: 0.5rem; font-size: 0.9rem; height: 1.4rem">
    <i
      v-for="(p, i) in side == 'white'
        ? material.black.capturedPieces
        : material.white.capturedPieces"
      :key="'piece' + i"
      :class="getPieceClass(p) + ' ' + (side == 'white' ? 'outline-white' : 'outline-black')"
      :style="{
        marginLeft: getMarginLeft(
          i,
          side == 'white' ? material.black.capturedPieces : material.white.capturedPieces
        ),
        color: side == 'white' ? 'white' : 'black',
      }"
    />
    <span
      v-if="getMaterialString(material, side) != '+0'"
      style="margin-left: 0.3rem"
      :class="side == 'white' ? 'outline-white' : 'outline-black'"
      >{{ getMaterialString(material, side) }}</span
    >
  </div>
</template>
<script setup lang="ts">
  import { type PropType, ref, watch } from 'vue';

  const props = defineProps({
    fen: { type: String, required: true },
    side: { type: String as PropType<'white' | 'black'>, default: 'white' },
  });

  watch(
    () => props.fen,
    () => {
      material.value = getCapturedMaterial(props.fen);
    }
  );

  const material = ref<MaterialInfo>(getCapturedMaterial(props.fen));

  function getMarginLeft(index: string | number, captured: string[]): string {
    index = Number(index);

    if (index == 0) return '0rem';

    if (captured[index - 1] != captured[index]) return '0.2rem';

    return '-0.3rem';
  }

  function getMaterialString(inputMaterial: MaterialInfo, side: string): string {
    let result = '';

    if (side == 'white') {
      result += inputMaterial.black.material;
    } else {
      result += inputMaterial.white.material;
    }

    return result.startsWith('-') ? result : '+' + result;
  }

  function getPieceClass(piece: string): string {
    let result = 'fa fa-solid fa-';

    switch (piece) {
      case 'P':
        result += 'chess-pawn';
        break;
      case 'N':
        result += 'chess-knight';
        break;
      case 'B':
        result += 'chess-bishop';
        break;
      case 'R':
        result += 'chess-rook';
        break;
      case 'Q':
        result += 'chess-queen';
        break;
      case 'K':
        result += 'chess-king';
        break;
    }

    return result + ' outline';
  }

  type MaterialInfo = {
    white: { capturedPieces: string[]; material: number };
    black: { capturedPieces: string[]; material: number };
  };

  function getCapturedMaterial(fen: string): MaterialInfo {
    const pieceValues: { [key: string]: number } = {
      K: 0,
      P: 1,
      N: 3,
      B: 3,
      R: 5,
      Q: 9,
    };
    const capturedWhite: { [key: string]: number } = {
      P: 8,
      N: 2,
      B: 2,
      R: 2,
      Q: 1,
      K: 1,
    };
    const capturedBlack: { [key: string]: number } = {
      P: 8,
      N: 2,
      B: 2,
      R: 2,
      Q: 1,
      K: 1,
    };

    let materialStanding = 0;
    for (const char of fen.split(' ')[0]) {
      if (!/^[A-Za-z]$/.test(char)) continue;

      const pieceValue = pieceValues[char.toUpperCase()];
      const isWhitePiece = char.toLowerCase() !== char;

      materialStanding += isWhitePiece ? pieceValue : -pieceValue;

      if (isWhitePiece) {
        capturedWhite[char]--;
      } else {
        capturedBlack[char.toUpperCase()]--;
      }
    }

    function capturedToString(captured: { [key: string]: number }): string[] {
      const result: string[] = [];
      for (const key of Object.keys(captured)) {
        for (let i = 0; i < captured[key]; i++) {
          result.push(key);
        }
      }
      return result;
    }

    return {
      white: {
        capturedPieces: capturedToString(capturedBlack),
        material: materialStanding,
      },
      black: {
        capturedPieces: capturedToString(capturedWhite),
        material: -materialStanding,
      },
    };
  }
</script>
<style scoped>
  .outline-white {
    color: white;
    text-shadow:
      0.05em 0 black,
      0 0.05em black,
      -0.05em 0 black,
      0 -0.05em black,
      -0.05em -0.05em black,
      -0.05em 0.05em black,
      0.05em -0.05em black,
      0.05em 0.05em black;
  }
  .outline-black {
    color: black;
    text-shadow:
      0.05em 0 #ccc,
      0 0.05em #ccc,
      -0.05em 0 #ccc,
      0 -0.05em #ccc,
      -0.05em -0.05em #ccc,
      -0.05em 0.05em #ccc,
      0.05em -0.05em #ccc,
      0.05em 0.05em #ccc;
  }
</style>
