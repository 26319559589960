<template>
  <div
    class="d-flex flex-row"
    style="margin-left: 0rem; margin-top: 0rem; text-align: center; justify-content: center"
  >
    <button
      class="profile-image-circle ph-no-capture"
      :style="{
        backgroundColor: opponentColor,
        opacity:
          (viewingHistoryPly != null && viewingHistoryPly <= 0) || moves == null || moves == 0
            ? 0.6
            : 1.0,
        cursor:
          (viewingHistoryPly != null && viewingHistoryPly <= 0) || moves == null || moves == 0
            ? 'default'
            : 'pointer',
      }"
      v-tippy="{
        content: isMobileWidth() ? null : 'Go to start of game (Shortcut: Up arrow)',
        delay: [1000, 0],
      }"
      @click="
        () => {
          history(UserInput.HistoryStart);
          track('move_navigation', 'history_start', 'click');
        }
      "
    >
      <i class="fa-solid fa-angles-left" />
    </button>
    <button
      class="profile-image-circle ph-no-capture"
      :style="{
        backgroundColor: opponentColor,
        opacity:
          (viewingHistoryPly != null && viewingHistoryPly <= 0) || moves == null || moves == 0
            ? 0.6
            : 1.0,
        cursor:
          (viewingHistoryPly != null && viewingHistoryPly <= 0) || moves == null || moves == 0
            ? 'default'
            : 'pointer',
      }"
      v-tippy="{
        content: isMobileWidth() ? null : 'Previous move (Shortcut: Left arrow)',
        delay: [1000, 0],
      }"
      @click="
        () => {
          history(UserInput.HistoryPrevious);
          track('move_navigation', 'history_previous', 'click');
        }
      "
    >
      <i class="fa-solid fa-angle-left" />
    </button>
    <button
      class="profile-image-circle ph-no-capture"
      :style="{
        backgroundColor: opponentColor,
        height: '3rem',
        width: '3rem',
        marginTop: '-0.5rem',
        opacity: viewingHistoryPly != null && viewingHistoryPly < moves ? 1.0 : 0.6,
        cursor: viewingHistoryPly != null && viewingHistoryPly < moves ? 'pointer' : 'default',
      }"
      v-tippy="{
        content: isMobileWidth() ? null : 'Next move (Shortcut: Right arrow)',
        delay: [1000, 0],
      }"
      @click="
        () => {
          history(UserInput.HistoryNext);
          track('move_navigation', 'history_next', 'click');
        }
      "
    >
      <i class="fa-solid fa-angle-right" style="font-size: 1.4rem" />
    </button>
    <button
      class="profile-image-circle ph-no-capture"
      :style="{
        backgroundColor: opponentColor,
        opacity: viewingHistoryPly != null && viewingHistoryPly < moves ? 1.0 : 0.6,
        cursor: viewingHistoryPly != null && viewingHistoryPly < moves ? 'pointer' : 'default',
      }"
      v-tippy="{
        content: isMobileWidth() ? null : 'Go to end of game (Shortcut: Down arrow)',
        delay: [1000, 0],
      }"
      @click="
        () => {
          history(UserInput.HistoryEnd);
          track('move_navigation', 'history_end', 'click');
        }
      "
    >
      <i class="fa-solid fa-angles-right" />
    </button>
  </div>
</template>
<script setup lang="ts">
  import { type PropType, onMounted } from 'vue';

  import { UserInput } from '@/types/internaltypes';
  import { track } from '@/util/tracking';
  import { isMobileWidth } from '@/util/util';

  const props = defineProps({
    moves: { type: Number, required: true },
    opponentColor: { type: String, default: '#fff' },
    viewingHistoryPly: {
      type: null as unknown as PropType<number | null>,
      default: null,
    },
  });

  const emit = defineEmits(['userInput']);

  onMounted(() => {
    window.addEventListener('keydown', function (e) {
      if (props.moves == null || props.moves == 0) {
        return;
      }

      if (e.key === 'ArrowLeft') {
        e.preventDefault();
        history(UserInput.HistoryPrevious);
      } else if (e.key === 'ArrowRight') {
        e.preventDefault();
        history(UserInput.HistoryNext);
      } else if (e.key === 'ArrowUp') {
        e.preventDefault();
        history(UserInput.HistoryStart);
      } else if (e.key === 'ArrowDown') {
        e.preventDefault();
        history(UserInput.HistoryEnd);
      }
    });
  });

  const history = (type: UserInput) => {
    emit('userInput', { type: type });
  };
</script>
<style scoped>
  .profile-image-circle {
    height: 2rem;
    width: 2rem;
    z-index: 10;
    border-radius: 50%;
    border: 1px solid #444;
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
