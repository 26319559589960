import { useBotsStore } from '@/stores/botStore';
import type { GiantSlayerBot } from '@/types/statstypes';

/**
 * Composable for calculating Giant Slayer statistics
 * Giant Slayer refers to wins against bots rated 200+ points higher than the player
 */
export function useGiantSlayerStats() {
  /**
   * Calculate Giant Slayer wins and find the best Giant Slayer bot
   * @param validBots Array of bots with game statistics
   * @param currentUserRating The current rating of the user
   * @returns Object containing giantSlayerWins and bestGiantSlayerBot
   */
  const calculateGiantSlayerStats = (validBots: any[], currentUserRating: number) => {
    // Filter bots that are 200+ points above the player's rating and have been beaten
    const giantSlayerBots = validBots.filter((bot: any) => {
      const botRating = useBotsStore().getBot(bot.bot_id)?.strength?.estimated_elo || 0;
      return botRating >= currentUserRating + 200 && bot.wins > 0;
    });

    // Count total wins against these much higher rated bots
    const giantSlayerWins = giantSlayerBots.reduce(
      (total: number, bot: any) => total + bot.wins,
      0
    );

    let bestGiantSlayerBot: GiantSlayerBot | undefined = undefined;

    // Find the best Giant Slayer bot (bot with highest rating that is 200+ points higher)
    if (giantSlayerBots.length > 0) {
      // Get bot ratings and enhance the array with rating information
      const enhancedGiantSlayerBots = giantSlayerBots.map((bot: any) => {
        const botRating = useBotsStore().getBot(bot.bot_id)?.strength?.estimated_elo || 0;
        return {
          ...bot,
          rating: botRating,
          ratingDifference: botRating - currentUserRating,
        };
      });

      // Sort by rating (descending) to find the highest-rated bot you've beaten
      const sortedGiantSlayerBots = enhancedGiantSlayerBots.sort((a: any, b: any) => {
        return b.rating - a.rating;
      });

      // Get the bot with the highest rating
      const bestBot = sortedGiantSlayerBots[0];

      bestGiantSlayerBot = {
        botId: bestBot.bot_id,
        botName: bestBot.bot_name || bestBot.botName || 'Unknown Bot',
        wins: bestBot.wins,
        rating: bestBot.rating,
        ratingDifference: bestBot.ratingDifference,
      } as GiantSlayerBot;
    }

    return {
      giantSlayerWins,
      bestGiantSlayerBot,
    };
  };

  return {
    calculateGiantSlayerStats,
  };
}
