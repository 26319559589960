<template>
  <div v-if="isLoggedInAndPremium()">
    <span>You are already a Premium member!</span>
  </div>
  <div v-else>
    <div v-if="!isLoggedIn()" style="text-align: center; margin-bottom: 2rem">
      <a style="text-decoration: underline" role="button" href="/signin">Sign up to go Premium</a>
    </div>
    <div class="plan-cards">
      <div class="card plan-card">
        <span>Monthly</span>
        <span
          >7-day free trial, then $7<span style="margin-inline: 1px; color: #212529">.</span
          >99/month. Cancel anytime.</span
        >
        <div style="flex: 1" />
        <span class="plan-card-price"
          ><span
            >$7<sup><span style="margin-left: 2px">.</span>99</sup></span
          >/month</span
        >
        <button
          @click="
            () => {
              track('premium_info', 'buy_monthly', 'click');
              gotoStripe('monthly');
            }
          "
          class="btn btn-warning"
        >
          {{ isLoggedIn() ? 'Get Monthly' : 'Sign up' }}
        </button>
      </div>
      <div class="card plan-card">
        <span>Yearly</span>
        <span
          >7-day free trial, then $71<span style="margin-inline: 1px; color: #212529">.</span
          >88/year. Cancel anytime.</span
        >
        <div style="flex: 1" />
        <span class="plan-card-price"
          ><span
            >$5<sup><span style="margin-left: 2px">.</span>99</sup></span
          >/month</span
        >
        <button
          @click="
            () => {
              track('premium_info', 'buy_yearly', 'click');
              gotoStripe('yearly');
            }
          "
          class="btn btn-warning"
        >
          {{ isLoggedIn() ? 'Get Yearly' : 'Sign up' }}
        </button>
        <div class="card most-popular">Most popular</div>
      </div>
      <div class="card plan-card">
        <span>Lifetime</span>
        <span>Lifetime access for $199.</span>
        <div style="flex: 1" />
        <span class="plan-card-price"><span>$199</span> (single payment)</span>
        <button
          @click="
            () => {
              track('premium_info', 'buy_lifetime', 'click');
              gotoStripe('lifetime');
            }
          "
          class="btn btn-warning"
        >
          {{ isLoggedIn() ? 'Get Lifetime' : 'Sign up' }}
        </button>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
  import { useUserStore } from '@/stores/userStore';
  import { getPremiumState, getPremiumType } from '@/util/premium';
  import { track } from '@/util/tracking';

  function gotoStripe(type: string) {
    if (!useUserStore().user.firebaseUser) {
      window.location.href = '/signup';
      return;
    }

    switch (type) {
      case 'yearly':
        window.location.href =
          'https://buy.stripe.com/8wM15z3tu0P90XSdQX?client_reference_id=' +
          useUserStore().user.firebaseUser!.uid;
        break;
      case 'monthly':
        window.location.href =
          'https://buy.stripe.com/6oEg0te88btN4a4dQY?client_reference_id=' +
          useUserStore().user.firebaseUser!.uid;
        break;
      case 'lifetime':
        window.location.href =
          'https://buy.stripe.com/dR6eWp2pq8hB3606ox?client_reference_id=' +
          useUserStore().user.firebaseUser!.uid;
        break;
      default:
        break;
    }
  }

  function isLoggedIn() {
    return useUserStore().user.firebaseUser?.uid;
  }

  function isLoggedInAndPremium() {
    // Check if the user is logged in first, otherwise we get the redirect to login

    return isLoggedIn() && getPremiumState() && getPremiumType() != 'trial';
  }
</script>

<style scoped>
  sup {
    top: -0.25rem;
  }

  .header {
    display: flex;
    max-width: 50rem;
    gap: 2rem;
  }

  .header > img {
    width: 30rem;
    height: 15rem;
  }

  .plan-card {
    width: 16rem;
    height: 16rem;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    position: relative;
  }

  .plan-card button {
    align-self: flex-start;
    font-size: 1.3rem;
  }

  .plan-card span:nth-child(1) {
    color: var(--clr-main-lighter);
  }

  .plan-card span:nth-child(3) {
    color: var(--clr-main-lighter);
    margin-top: 1rem;
  }

  .plan-card span:nth-child(3) > span,
  .plan-card span:nth-child(1) {
    font-size: 1.5rem;
    font-weight: 800;
    color: var(--clr-accent);
  }

  .plan-cards {
    display: flex;
    gap: 1rem;
    justify-content: center;
  }

  .most-popular {
    text-align: center;
    width: 7rem;
    background: var(--clr-accent);
    color: white;
    position: absolute;
    top: -1rem;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }

  .feature-column {
    display: flex;
    gap: 2rem;
    flex-direction: column;
    padding: 1rem;
  }

  .premium-column {
    background-color: var(--clr-accent);
    display: flex;
    gap: 2rem;
    flex-direction: column;
    padding: 1rem;
    font-weight: 800;
  }

  .free-column {
    display: flex;
    gap: 2rem;
    flex-direction: column;
    padding: 1rem;
  }

  .feature-table {
    display: flex;
    gap: 1rem;
  }

  .feature-table .feature-column > div,
  .feature-table .free-column > div,
  .feature-table .premium-column > div {
    height: 2rem;
  }

  @media (max-width: 768px) {
    .plan-cards {
      flex-direction: column;
      align-items: center;
      gap: 2rem;
    }

    .feature-table {
      gap: 0.5rem;
    }

    .header {
      flex-direction: column;
      align-items: center;
      gap: 2rem;
    }

    .header > img {
      width: 100%;
      height: auto;
    }
  }

  @media (max-width: 500px) {
    .feature-table .feature-column > div:nth-child(1),
    .feature-table .free-column > div:nth-child(1),
    .feature-table .premium-column > div:nth-child(1) {
      height: 5rem;
    }
  }
</style>
