<template>
  <div id="text-spot" style="overflow-x: hidden; overflow-y: hidden">
    <ConfettiExplosion
      v-if="showConfetti"
      :stageHeight="getViewportSize().height"
      :stageWidth="getViewportSize().width"
    />
    <div id="main-text" />
    <div id="sub-text" class="sub-text">&nbsp;</div>
  </div>
</template>

<script setup lang="ts">
  import { type PropType, nextTick, onMounted, ref } from 'vue';
  import ConfettiExplosion from 'vue-confetti-explosion';

  import { usePageStore } from '@/stores/pageStore';
  import { GameTermination } from '@/types/apitypes';
  import { getViewportSize } from '@/util/util.js';

  const showConfetti = ref(false);

  const props = defineProps({
    termination: {
      type: Object as PropType<GameTermination>,
    },
  });

  const emit = defineEmits(['isRunning']);

  const explode = async () => {
    showConfetti.value = false;
    await nextTick();
    showConfetti.value = true;
  };

  function getWinText(termination: GameTermination | undefined) {
    switch (termination) {
      case GameTermination.Checkmate:
        return 'by checkmate!';
      case GameTermination.Time:
        return 'by time';
      case GameTermination.Resign:
        return 'by resignation';
      default: // Should never happen since the only win conditions are above
        return '';
    }
  }

  function start() {
    emit('isRunning', { isRunning: true });
    function drawText(word = 'WIN') {
      const wordArray = word.split('');

      const mainTextElement: HTMLElement | null = document.getElementById('main-text');
      const subTextElement: HTMLElement | null = document.getElementById('sub-text');
      if (mainTextElement === null || subTextElement === null) {
        return;
      }

      mainTextElement.innerHTML = '';
      subTextElement.innerHTML = '';
      mainTextElement.classList.remove('done-animating');
      subTextElement.classList.remove('done-animating');

      wordArray.forEach(function (letter: any, index: any) {
        const span = document.createElement('span');
        span.textContent = letter;
        span.className = 'letter animate';

        span.style['animation-delay' as any] = 50 * index + 'ms';

        mainTextElement!.appendChild(span);

        if (index === wordArray.length - 1) {
          span.addEventListener('animationend', function () {
            subTextElement!.innerHTML = getWinText(props.termination);
            subTextElement!.classList.add('swing-in-top-fwd');
            subTextElement!.addEventListener('animationend', function (event) {
              if (event.animationName === 'scale-out') {
                explode();
                setTimeout(() => {
                  showConfetti.value = false;
                }, 2500);
                mainTextElement!.innerHTML = '';
                subTextElement!.innerHTML = '';
                emit('isRunning', { isRunning: false });
              } else if (event.animationName === 'swing-in-top-fwd') {
                mainTextElement!.classList.add('done-animating');
                subTextElement!.classList.remove('swing-in-top-fwd');
                subTextElement!.classList.add('scale-out');
              }
            });
          });
        }
      });
    }

    drawText();

    usePageStore().setPlaySound('win');
  }

  onMounted(() => {
    start();
  });
</script>

<style>
  .tracking-in-expand {
    -webkit-animation: tracking-in-expand 1.5s cubic-bezier(0.215, 0.61, 0.355, 1) both;
    animation: tracking-in-expand 1.5s cubic-bezier(0.215, 0.61, 0.355, 1) both;
  }

  .tracking-out-contract {
    -webkit-animation: tracking-out-contract 0.7s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
    animation: tracking-out-contract 0.7s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  }
  /* ----------------------------------------------
 * Generated by Animista on 2023-9-23 22:36:51
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

  /**
 * ----------------------------------------
 * animation tracking-out-contract
 * ----------------------------------------
 */
  @-webkit-keyframes tracking-out-contract {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    100% {
      letter-spacing: -0.5em;
      opacity: 0;
    }
  }
  @keyframes tracking-out-contract {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    100% {
      letter-spacing: -0.5em;
      opacity: 0;
    }
  }

  /* ----------------------------------------------
 * Generated by Animista on 2023-9-23 22:37:15
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

  /**
 * ----------------------------------------
 * animation tracking-in-expand
 * ----------------------------------------
 */
  @-webkit-keyframes tracking-in-expand {
    0% {
      letter-spacing: -0.5em;
      opacity: 0;
    }
    40% {
      opacity: 0.6;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes tracking-in-expand {
    0% {
      letter-spacing: -0.5em;
      opacity: 0;
    }
    40% {
      opacity: 0.6;
    }
    100% {
      opacity: 1;
    }
  }

  #text-spot {
    height: 100%;
    width: 100%;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .draw-text {
    display: inline-block;
    font-size: 120px;
    color: #d4d4d4;
    letter-spacing: 10px;
    font-family: 'Luckiest Guy';
    font-weight: 100;
    -webkit-text-stroke: 2px black;
  }

  .draw-animation-sub-text {
    display: inline-block;
    font-size: 2rem;
    color: #d4d4d4;
    font-family: 'Luckiest Guy';
    font-weight: 100;
    -webkit-text-stroke: 1.5px black;
    position: absolute;
    margin-top: 7rem;
  }

  .swing-in-top-fwd {
    -webkit-animation: swing-in-top-fwd 1s ease-in-out both;
    animation: swing-in-top-fwd 1s ease-in-out both;
  }

  /* ----------------------------------------------
 * Generated by Animista on 2024-5-11 15:34:50
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

  /**
 * ----------------------------------------
 * animation swing-in-top-fwd
 * ----------------------------------------
 */
  @-webkit-keyframes swing-in-top-fwd {
    0% {
      -webkit-transform: rotateX(-100deg);
      transform: rotateX(-100deg);
      -webkit-transform-origin: top;
      transform-origin: top;
      opacity: 0;
    }
    100% {
      -webkit-transform: rotateX(0deg);
      transform: rotateX(0deg);
      -webkit-transform-origin: top;
      transform-origin: top;
      opacity: 1;
    }
  }
  @keyframes swing-in-top-fwd {
    0% {
      -webkit-transform: rotateX(-100deg);
      transform: rotateX(-100deg);
      -webkit-transform-origin: top;
      transform-origin: top;
      opacity: 0;
    }
    100% {
      -webkit-transform: rotateX(0deg);
      transform: rotateX(0deg);
      -webkit-transform-origin: top;
      transform-origin: top;
      opacity: 1;
    }
  }
  @keyframes dash {
    0% {
      transform: skew(-30deg, 0deg) translateX(300%) scale(0.8);
      opacity: 1;
    }

    40% {
      transform: skew(10deg, 0deg) translateX(100%) scale(0.9);
    }

    60% {
      transform: skew(10deg, 0deg) translateX(-10px) scale(1.2);
    }

    70% {
      transform: skew(0, 0deg) translateX(0) scale(1.3);
    }

    75% {
      transform: skew(0, 0deg) translateX(0) scale(1.3);
    }

    90% {
      transform: skew(0, 0deg) translateX(0) scale(0.85);
    }

    100% {
      transform: translateY(0) scale(1);
      opacity: 1;
    }
  }

  @keyframes done-animating {
    0% {
      transform: scale(1);
    }

    50% {
      transform: scale(2);
    }

    70% {
      transform: scale(2);
    }

    100% {
      transform: scale(0);
    }
  }

  @keyframes scale-out {
    0% {
      transform: scale(1);
      opacity: 1;
    }

    100% {
      transform: scale(0);
      opacity: 0;
    }
  }

  #main-text {
    position: relative;
    z-index: 1;
  }

  .done-animating {
    animation: done-animating 500ms ease-in;
  }

  .scale-out {
    animation: scale-out 500ms ease-in;
  }

  #text-spot {
    height: 100%;
    width: 100%;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .sub-text {
    font-size: 2rem;
    color: #fff2af;
    font-family: 'Luckiest Guy';
    font-weight: 100;
    -webkit-text-stroke: 1.5px black;
    position: absolute;
    margin-top: 7rem;
  }

  .swing-in-top-fwd {
    -webkit-animation: swing-in-top-fwd 1s ease-in-out both;
    animation: swing-in-top-fwd 1s ease-in-out both;
  }

  /* ----------------------------------------------
 * Generated by Animista on 2024-5-11 15:34:50
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

  /**
 * ----------------------------------------
 * animation swing-in-top-fwd
 * ----------------------------------------
 */
  @-webkit-keyframes swing-in-top-fwd {
    0% {
      -webkit-transform: rotateX(-100deg);
      transform: rotateX(-100deg);
      -webkit-transform-origin: top;
      transform-origin: top;
      opacity: 0;
    }
    100% {
      -webkit-transform: rotateX(0deg);
      transform: rotateX(0deg);
      -webkit-transform-origin: top;
      transform-origin: top;
      opacity: 1;
    }
  }
  @keyframes swing-in-top-fwd {
    0% {
      -webkit-transform: rotateX(-100deg);
      transform: rotateX(-100deg);
      -webkit-transform-origin: top;
      transform-origin: top;
      opacity: 0;
    }
    100% {
      -webkit-transform: rotateX(0deg);
      transform: rotateX(0deg);
      -webkit-transform-origin: top;
      transform-origin: top;
      opacity: 1;
    }
  }

  .tracking-in-expand {
    -webkit-animation: tracking-in-expand 1.5s cubic-bezier(0.215, 0.61, 0.355, 1) both;
    animation: tracking-in-expand 1.5s cubic-bezier(0.215, 0.61, 0.355, 1) both;
  }

  .tracking-out-contract {
    -webkit-animation: tracking-out-contract 0.7s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
    animation: tracking-out-contract 0.7s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  }
  /* ----------------------------------------------
 * Generated by Animista on 2023-9-23 22:36:51
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

  /**
 * ----------------------------------------
 * animation tracking-out-contract
 * ----------------------------------------
 */
  @-webkit-keyframes tracking-out-contract {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    100% {
      letter-spacing: -0.5em;
      opacity: 0;
    }
  }
  @keyframes tracking-out-contract {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    100% {
      letter-spacing: -0.5em;
      opacity: 0;
    }
  }

  /* ----------------------------------------------
 * Generated by Animista on 2023-9-23 22:37:15
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

  /**
 * ----------------------------------------
 * animation tracking-in-expand
 * ----------------------------------------
 */
  @-webkit-keyframes tracking-in-expand {
    0% {
      letter-spacing: -0.5em;
      opacity: 0;
    }
    40% {
      opacity: 0.6;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes tracking-in-expand {
    0% {
      letter-spacing: -0.5em;
      opacity: 0;
    }
    40% {
      opacity: 0.6;
    }
    100% {
      opacity: 1;
    }
  }

  #text-spot {
    height: 100%;
    width: 100%;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .draw-text {
    display: inline-block;
    font-size: 120px;
    color: #d4d4d4;
    letter-spacing: 10px;
    font-family: 'Luckiest Guy';
    font-weight: 100;
    -webkit-text-stroke: 2px black;
  }

  .draw-animation-sub-text {
    display: inline-block;
    font-size: 2rem;
    color: #d4d4d4;
    font-family: 'Luckiest Guy';
    font-weight: 100;
    -webkit-text-stroke: 1.5px black;
    position: absolute;
    margin-top: 7rem;
  }

  .swing-in-top-fwd {
    -webkit-animation: swing-in-top-fwd 1s ease-in-out both;
    animation: swing-in-top-fwd 1s ease-in-out both;
  }

  /* ----------------------------------------------
 * Generated by Animista on 2024-5-11 15:34:50
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

  /**
 * ----------------------------------------
 * animation swing-in-top-fwd
 * ----------------------------------------
 */
  @-webkit-keyframes swing-in-top-fwd {
    0% {
      -webkit-transform: rotateX(-100deg);
      transform: rotateX(-100deg);
      -webkit-transform-origin: top;
      transform-origin: top;
      opacity: 0;
    }
    100% {
      -webkit-transform: rotateX(0deg);
      transform: rotateX(0deg);
      -webkit-transform-origin: top;
      transform-origin: top;
      opacity: 1;
    }
  }
  @keyframes swing-in-top-fwd {
    0% {
      -webkit-transform: rotateX(-100deg);
      transform: rotateX(-100deg);
      -webkit-transform-origin: top;
      transform-origin: top;
      opacity: 0;
    }
    100% {
      -webkit-transform: rotateX(0deg);
      transform: rotateX(0deg);
      -webkit-transform-origin: top;
      transform-origin: top;
      opacity: 1;
    }
  }

  .flip-out-hor-top {
    -webkit-animation: flip-out-hor-top 0.45s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
    animation: flip-out-hor-top 0.45s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  }

  .flip-in-hor-bottom {
    -webkit-animation: flip-in-hor-bottom 1.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: flip-in-hor-bottom 1.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }

  /* ----------------------------------------------
 * Generated by Animista on 2023-9-23 22:18:7
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

  /**
 * ----------------------------------------
 * animation flip-in-hor-bottom
 * ----------------------------------------
 */
  @-webkit-keyframes flip-in-hor-bottom {
    0% {
      -webkit-transform: rotateX(80deg);
      transform: rotateX(80deg);
      opacity: 0;
    }
    100% {
      -webkit-transform: rotateX(0);
      transform: rotateX(0);
      opacity: 1;
    }
  }
  @keyframes flip-in-hor-bottom {
    0% {
      -webkit-transform: rotateX(80deg);
      transform: rotateX(80deg);
      opacity: 0;
    }
    100% {
      -webkit-transform: rotateX(0);
      transform: rotateX(0);
      opacity: 1;
    }
  }

  /* ----------------------------------------------
 * Generated by Animista on 2023-9-23 22:26:41
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

  /**
 * ----------------------------------------
 * animation flip-out-hor-top
 * ----------------------------------------
 */
  @-webkit-keyframes flip-out-hor-top {
    0% {
      -webkit-transform: rotateX(0);
      transform: rotateX(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: rotateX(70deg);
      transform: rotateX(70deg);
      opacity: 0;
    }
  }
  @keyframes flip-out-hor-top {
    0% {
      -webkit-transform: rotateX(0);
      transform: rotateX(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: rotateX(70deg);
      transform: rotateX(70deg);
      opacity: 0;
    }
  }

  #text-spot {
    height: 100%;
    width: 100%;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .lose-text {
    display: inline-block;
    font-size: 120px;
    color: #ff7171;
    letter-spacing: 10px;
    font-family: 'Luckiest Guy';
    font-weight: 100;
    -webkit-text-stroke: 2px black;
  }

  .letter {
    display: inline-block;
    font-size: 120px;
    color: #fff2af;
    letter-spacing: 10px;
    font-family: 'Luckiest Guy';
    font-weight: 100;
    -webkit-text-stroke: 2px black;
  }

  .letter.animate {
    animation: dash 500ms ease-in forwards;
    opacity: 0;
  }
</style>
