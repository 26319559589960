<template>
  <div class="account-container">
    <ul class="nav nav-tabs justify-content-center" role="tablist">
      <li class="nav-item" role="presentation">
        <a
          class="nav-link ph-no-capture"
          :class="{ active: selectedSection === 'settings' }"
          data-bs-toggle="tab"
          href="#"
          aria-selected="true"
          tabindex="-1"
          role="tab"
          @click="
            () => {
              setSelectedSection('settings');
              track('account_view', 'set_selected_section', 'click', {
                section: 'settings',
              });
            }
          "
          ><i class="fa-solid fa-gear" style="color: var(--clr-accent)" /> Settings</a
        >
      </li>
      <li class="nav-item" role="presentation">
        <a
          class="nav-link ph-no-capture"
          :class="{ active: selectedSection === 'account_info' }"
          data-bs-toggle="tab"
          href="#"
          aria-selected="false"
          tabindex="-1"
          role="tab"
          @click="
            () => {
              setSelectedSection('account_info');
              track('account_view', 'set_selected_section', 'click', {
                section: 'account_info',
              });
            }
          "
          ><i class="fa-duotone fa-solid fa-list-check" style="color: var(--clr-accent)" /> Account
          info</a
        >
      </li>
    </ul>
    <div v-if="selectedSection == 'settings'" style="display: flex; justify-content: center">
      <div
        style="display: flex; justify-content: center; flex-wrap: wrap; margin-top: 2rem; gap: 1rem"
      >
        <div class="card" style="min-width: 18rem">
          <div class="card-header">
            <i class="fa-solid fa-volume" style="color: var(--clr-accent)" />
            Sound
          </div>

          <div style="display: flex; flex-direction: column; padding: 1rem; gap: 0.5rem">
            <div style="display: flex; flex-direction: column">
              <span>All sounds: </span>
              <div style="display: flex; gap: 0.5rem">
                <SoundsToggle class="dropdown-item" soundType="master" />
                <Slider
                  :min="0"
                  :max="100"
                  :lazy="true"
                  :step="1"
                  showTooltip="drag"
                  class="volume-slider"
                  style="flex-shrink: 0"
                  @change="usePageStore().setPlayMoveSound('move')"
                  v-model="usePageStore().sounds.master.volume"
                />
              </div>
            </div>
            <hr class="hr" style="margin: 0.1rem 0" />
            <div style="display: flex; flex-direction: column">
              <span>Move sounds: </span>
              <div style="display: flex; gap: 0.5rem">
                <SoundsToggle class="dropdown-item" soundType="moves" />
                <Slider
                  :min="0"
                  :max="100"
                  :lazy="true"
                  :step="1"
                  showTooltip="drag"
                  class="volume-slider"
                  style="flex-shrink: 0"
                  @change="usePageStore().setPlayMoveSound('move')"
                  v-model="usePageStore().sounds.moves.volume"
                />
              </div>
            </div>
            <div style="display: flex; flex-direction: column">
              Other sounds:
              <div style="display: flex; gap: 0.5rem">
                <SoundsToggle class="dropdown-item" soundType="other" />
                <Slider
                  :min="0"
                  :max="100"
                  :lazy="true"
                  :step="1"
                  showTooltip="drag"
                  class="volume-slider"
                  style="flex-shrink: 0"
                  @change="usePageStore().setPlaySound('succeed-puzzle-sound-1')"
                  v-model="usePageStore().sounds.other.volume"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="card" style="min-width: 18rem">
          <div class="card-header">
            <i class="fa-regular fa-chess-board" style="color: var(--clr-accent)" />
            Board
          </div>
          <div style="padding: 1rem; display: flex; gap: 0.5rem; flex-direction: column">
            <TheChessboard
              :board-config="boardConfig"
              @board-created="boardCreated"
              reactive-config
              style="font-size: 2rem"
            />
            <div style="display: flex; flex-direction: column; gap: 0.5rem">
              <div class="toggle-with-label">
                <Toggle
                  v-model="usePageStore().boardColorOverride.active"
                  class="toggle-board ph-no-capture"
                />
                <span
                  v-tippy="{
                    content: 'Use a fixed color for all chess boards',
                  }"
                  >Override color
                  <i
                    class="fa-solid fa-comment-exclamation"
                    style="vertical-align: super; font-size: 0.8rem; color: var(--clr-accent)"
                /></span>
              </div>
              <div v-if="usePageStore().boardColorOverride.active">
                Color:
                <pick-colors
                  role="button"
                  class="bounce-top"
                  v-model:value="boardBg"
                  width="18"
                  height="18"
                />
              </div>
              <div class="toggle-with-label">
                <Toggle
                  v-model="usePageStore().showBoardCoordinates"
                  class="toggle-board ph-no-capture"
                />
                <span>Show coordinates</span>
              </div>
              <div class="toggle-with-label">
                <Toggle
                  v-model="usePageStore().showPossibleMoves"
                  class="toggle-board ph-no-capture"
                />
                <span>Show possible moves</span>
              </div>
              <div class="toggle-with-label">
                <Toggle v-model="usePageStore().showLastMove" class="toggle-board ph-no-capture" />
                <span>Highlight last move</span>
              </div>
            </div>
          </div>
        </div>
        <div class="card" style="min-width: 18rem">
          <div class="card-header">
            <i class="fa-solid fa-robot" style="color: var(--clr-accent)" />
            Bots
          </div>
          <div style="padding: 1rem; display: flex; gap: 0.5rem; flex-direction: column">
            <div style="display: flex; flex-direction: column; gap: 0.5rem">
              <div class="toggle-with-label">
                <Toggle
                  v-model="usePageStore().showBotThinkingBubble"
                  class="toggle-board ph-no-capture"
                />
                <span
                  v-tippy="{
                    content: 'Display the thinking bubble animation when the bot is thinking',
                  }"
                  >Thinking animation
                  <i
                    class="fa-solid fa-comment-exclamation"
                    style="vertical-align: super; font-size: 0.8rem; color: var(--clr-accent)"
                /></span>
              </div>
              <div class="toggle-with-label">
                <Toggle
                  v-model="usePageStore().allowResignation"
                  class="toggle-board ph-no-capture"
                />
                <span
                  v-tippy="{
                    content: 'Bots will resign when they feel the game is lost',
                  }"
                  >Allow resignations
                  <i
                    class="fa-solid fa-comment-exclamation"
                    style="vertical-align: super; font-size: 0.8rem; color: var(--clr-accent)"
                /></span>
              </div>
              <div class="toggle-with-label">
                <Toggle
                  v-model="usePageStore().botPlaysInstantInInfinite"
                  class="toggle-board ph-no-capture"
                />
                <span
                  v-tippy="{
                    content:
                      'In games with no time control, the bots will play as quick as possible, not using any extra time',
                  }"
                  >Quick play
                  <i
                    class="fa-solid fa-comment-exclamation"
                    style="vertical-align: super; font-size: 0.8rem; color: var(--clr-accent)"
                /></span>
              </div>
              <div class="toggle-with-label">
                <Toggle
                  v-model="usePageStore().showBotBackgrounds"
                  class="toggle-board ph-no-capture"
                />
                <span
                  v-tippy="{
                    content: 'Use unique bot backgrounds in the playing area',
                  }"
                  >Bot backgrounds
                  <i
                    class="fa-solid fa-comment-exclamation"
                    style="vertical-align: super; font-size: 0.8rem; color: var(--clr-accent)"
                /></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="selectedSection == 'account_info'" style="display: flex; justify-content: center">
      <div
        class="card"
        style="display: flex; justify-content: center; max-width: 30rem; margin-top: 2rem"
      >
        <table class="table table-striped" style="margin-bottom: 0">
          <tbody>
            <tr>
              <td>
                <i class="fa-solid fa-envelope" style="color: var(--clr-accent)" />
                Email
              </td>
              <td v-if="userEmail && userEmailVerified == true" style="text-align: right">
                {{ userEmail }}
                <i
                  class="fa-solid fa-circle-check"
                  style="color: var(--clr-accent4)"
                  v-tippy="{
                    content: 'Email verified!',
                  }"
                />
              </td>
              <td v-else-if="userEmail && userEmailVerified == false" style="text-align: right">
                {{ userEmail }}
                <button
                  v-if="verifyEmailState == 'ready'"
                  class="btn btn-info"
                  style="width: 4rem"
                  @click="
                    () => {
                      verifyEmail();
                      track('account_view', 'verify_email', 'click');
                    }
                  "
                >
                  Verify
                </button>
                <button
                  v-else-if="verifyEmailState == 'sending'"
                  class="btn btn-info"
                  style="width: 4rem"
                  disabled
                >
                  <LoaderNew size="1rem" />
                </button>
              </td>
              <td v-else style="text-align: right">?</td>
            </tr>
            <tr>
              <td>
                <i class="fa-solid fa-calendar-days" style="color: var(--clr-accent)" />
                Member since
              </td>
              <td style="text-align: right">
                {{ memberSince }}
              </td>
            </tr>
            <tr v-if="useUserStore().user.data?.roles?.includes('beta')">
              <td>
                <i class="fa-solid fa-star" style="color: var(--clr-accent)" />
                Early adopter
              </td>
              <td style="text-align: right">You were part of the beta, thank you!</td>
            </tr>
            <tr>
              <td>
                <i class="fa-solid fa-lock" style="color: var(--clr-accent)" />
                Password
              </td>
              <td v-if="userEmail" style="text-align: right">
                <button
                  v-if="resetPasswordState == 'ready' || resetPasswordState == 'sent'"
                  class="btn btn-info"
                  style="width: 4rem"
                  @click="
                    () => {
                      resetPassword();
                      track('account_view', 'reset_password', 'click');
                    }
                  "
                >
                  Reset
                </button>
                <button
                  v-else-if="resetPasswordState == 'sending'"
                  class="btn btn-info"
                  style="width: 4rem"
                  disabled
                >
                  <LoaderNew size="1rem" />
                </button>
              </td>
            </tr>
            <tr
              :style="{
                borderBottom: getPremiumType() == 'subscription' ? 'white' : '',
              }"
            >
              <td>
                <i class="fa-solid fa-crown" style="color: var(--clr-premium-yellow)" />
                Premium
              </td>
              <td style="text-align: right">
                <div v-if="getPremiumType() == 'subscription'">
                  <a
                    href="https://billing.stripe.com/p/login/fZe6pV79E9qu1qw288"
                    @click="
                      () => {
                        track('account_view', 'manage_premium', 'click');
                      }
                    "
                    class="btn btn-info"
                    >Manage subscription</a
                  >
                </div>
                <div v-else-if="getPremiumType() == 'lifetime'">Lifetime premium</div>
                <div
                  v-else-if="getPremiumType() == 'trial' && useUserStore().user.data?.trial != null"
                >
                  {{ getTrialDaysLeftString(useUserStore().user.data?.trial as number) }}
                </div>
                <div v-else>
                  <a
                    class="btn btn-warning"
                    href="/premium"
                    @click="
                      () => {
                        track('account_view', 'get_premium', 'click');
                      }
                    "
                  >
                    <i class="fa-solid fa-crown" /> Get premium
                  </a>
                </div>
              </td>
            </tr>
            <tr v-if="getPremiumType() == 'subscription'">
              <td colspan="2">
                <p style="font-size: 0.8rem; color: var(--clr-main-light)">
                  To manage your subscription you will be redirected to our payment partner Stripe.
                  To identify with Stripe, you will be asked to enter the email you used when
                  setting up your payment.
                </p>
                <p style="font-size: 0.8rem; color: var(--clr-main-light)">
                  <strong><i> Note, this can be different from your Chessiverse email</i></strong>
                </p>
                <p style="font-size: 0.8rem; color: var(--clr-main-light)">
                  If you have have any trouble just contact us at
                  <a href="mailto:support@chessiverse.com">support@chessiverse.com</a>
                  or ask in our
                  <a href="https://discord.gg/Js5uPNxEHj">Discord channel</a>
                  and we'll help you out.
                </p>
              </td>
            </tr>
            <tr style="border-color: white">
              <td colspan="2" style="text-align: center">
                <button
                  class="btn btn-info"
                  @click="
                    () => {
                      useUserStore().logout();
                      track('account_view', 'logout_button', 'click');
                    }
                  "
                >
                  Logout
                  <i class="fa-solid fa-right-from-bracket" />
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <div style="display: flex; justify-content: center" />
      </div>
    </div>
    <SoundsStorage />
  </div>
</template>

<script setup lang="ts">
  import { reactive, ref, watch } from 'vue';
  import { type BoardApi, type BoardConfig, TheChessboard } from 'vue3-chessboard';
  import PickColors from 'vue-pick-colors';
  import { useRoute } from 'vue-router';
  import { useToast } from 'vue-toast-notification';

  import Slider from '@vueform/slider';
  import Toggle from '@vueform/toggle';

  import SoundsStorage from '@/components/sounds/SoundsStorage.vue';
  import SoundsToggle from '@/components/sounds/SoundsToggle.vue';
  import LoaderNew from '@/components/util/LoaderNew.vue';
  import { usePageStore } from '@/stores/pageStore';
  import { useUserStore } from '@/stores/userStore';
  import { getPremiumType } from '@/util/premium';
  import { track } from '@/util/tracking';
  import { STARTING_FEN } from '@/util/util';

  const selectedSection = ref(useRoute().meta.type);
  const memberSince = ref(updateMemberSince());
  const userEmail = ref<string | null>(useUserStore().user.firebaseUser?.email ?? null);
  const userEmailVerified = ref<boolean | null>(
    useUserStore().user.firebaseUser?.emailVerified ?? null
  );
  const verifyEmailState = ref<'ready' | 'sending' | 'sent' | 'error'>('ready');
  const resetPasswordState = ref<'ready' | 'sending' | 'sent' | 'error'>('ready');

  const boardBg = ref<string>(usePageStore().boardColorOverride.color);
  const currentBoardBg = ref<string>(
    usePageStore().boardColorOverride.color ? usePageStore().boardColorOverride.color : '#73cdee'
  );

  watch(
    () => boardBg.value,
    () => {
      usePageStore().boardColorOverride.color = boardBg.value;

      if (usePageStore().boardColorOverride.active) {
        currentBoardBg.value = boardBg.value;
      }
    }
  );

  watch(
    () => usePageStore().boardColorOverride.active,
    (newValue) => {
      if (newValue) {
        currentBoardBg.value = boardBg.value;
      } else {
        currentBoardBg.value = '#73cdee';
      }
    }
  );

  watch(
    () => usePageStore().showBoardCoordinates,
    (newValue) => {
      boardConfig.coordinates = newValue;
    }
  );

  watch(
    () => usePageStore().showPossibleMoves,
    (newValue) => {
      boardConfig.movable = { showDests: usePageStore().showPossibleMoves };
    }
  );

  watch(
    () => usePageStore().showLastMove,
    (newValue) => {
      boardConfig.highlight = {
        lastMove: usePageStore().showLastMove,
      };
    }
  );

  const boardConfig = reactive({
    fen: STARTING_FEN,
    coordinates: usePageStore().showBoardCoordinates,
    movable: { showDests: usePageStore().showPossibleMoves },
    highlight: {
      lastMove: usePageStore().showLastMove,
    },
    viewOnly: true,
    predroppable: {
      enabled: true,
    },
  } as BoardConfig);

  function setSelectedSection(sectionType: 'settings' | 'account_info') {
    selectedSection.value = sectionType;

    if (sectionType == 'account_info') {
      userEmail.value = useUserStore().user.firebaseUser?.email ?? null;
      userEmailVerified.value = useUserStore().user.firebaseUser?.emailVerified ?? null;
    }
  }

  function boardCreated(api: BoardApi) {
    api.move('e4');

    // @ts-ignore going directly to the board since selected square is not exposed in vue3-chessboard
    api.board.selectSquare('d7');
  }

  function getTrialDaysLeftString(trialEndMillis: number): string {
    const fullDaysLeft = Math.floor((trialEndMillis - Date.now()) / 1000 / 60 / 60 / 24);

    if (fullDaysLeft <= 0) {
      return 'Ends today';
    } else if (fullDaysLeft == 1) {
      return 'Ends tomorrow';
    } else {
      return `Ends in ${fullDaysLeft} days`;
    }
  }

  function updateMemberSince(): string {
    const options: Intl.DateTimeFormatOptions = {
      month: 'long',
      year: 'numeric',
    };

    return useUserStore().getUserCreationTime.toLocaleDateString('en-US', options);
  }

  function verifyEmail() {
    if (userEmail.value == null) {
      // There should be no button to push if no email, so just have this here just in case
      return;
    }

    verifyEmailState.value = 'sending';

    useUserStore()
      .triggerVerificationEmail()
      .then(() => {
        verifyEmailState.value = 'sent';
        useToast().success('Verification email sent, check you inbox!');
      })
      .catch((error) => {
        console.error('Error sending password reset email', error);
        verifyEmailState.value = 'error';
        useToast().error("Couldn't send verification email, try again later.");
      });
  }

  function resetPassword() {
    if (userEmail.value == null) {
      // There should be no button to push if no email, so just have this here just in case
      return;
    }

    resetPasswordState.value = 'sending';

    useUserStore()
      .resetPassword(userEmail.value)
      .then(() => {
        resetPasswordState.value = 'sent';
        useToast().success('An reset password email was sent, check you inbox!');
      })
      .catch((error) => {
        console.error('Error sending password reset email', error);
        resetPasswordState.value = 'error';
        useToast().error("Couldn't send reset password email, try again later.");
      });
  }
</script>

<style scoped>
  .toggle-with-label {
    display: flex;
    gap: 0.5rem;
  }
  .active {
    background: linear-gradient(315deg, var(--clr-background-detail) 0%, var(--clr-rect-2) 100%);
  }

  .toggle-board {
    outline: none;
    --toggle-width: 3rem;
    --toggle-font-size: 1rem;
    --toggle-bg-on: var(--clr-accent);
    --toggle-bg-off: var(--clr-main-lighter);
    --toggle-text-on: white;
    --toggle-text-off: white;
    --toggle-border-on: var(--clr-accent);
    --toggle-border-off: var(--clr-main-lighter);
    --toggle-handle-enabled: var(--clr-rect-2);
  }

  .volume-slider {
    --slider-bg: var(--clr-main-lightest);
    --slider-connect-bg: var(--clr-accent);
    --slider-tooltip-bg: var(--clr-accent);
    --slider-handle-ring-color: var(--clr-accent);
    --slider-handle-bg: var(--clr-rect-2);
    width: 12rem;
    margin: 0.5rem 0 0 0.5rem;
  }

  :deep(cg-board) {
    background-color: v-bind('currentBoardBg'); /* Override the board background color */
  }
</style>
