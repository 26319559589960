<template>
  <div class="practicegrid">
    <div class="left">
      <MainPuzzleBox style="width: 100%" :puzzles="allPuzzleData" />
    </div>
    <div class="right">
      <ul class="nav nav-tabs justify-content-center" role="tablist">
        <li class="nav-item" role="presentation">
          <a
            class="nav-link active ph-no-capture"
            data-bs-toggle="tab"
            href="#"
            aria-selected="false"
            role="tab"
            tabindex="-1"
            @click="
              () => {
                setSelectedSection('beginner');
                track('puzzle_view', 'set_selected_section', 'click', {
                  section: 'beginner',
                });
              }
            "
            >Beginner</a
          >
        </li>
        <li class="nav-item" role="presentation">
          <a
            class="nav-link ph-no-capture"
            data-bs-toggle="tab"
            href="#"
            aria-selected="true"
            role="tab"
            @click="
              () => {
                setSelectedSection('novice');
                track('puzzle_view', 'set_selected_section', 'click', {
                  section: 'novice',
                });
              }
            "
            >Novice</a
          >
        </li>
        <li class="nav-item" role="presentation">
          <a
            class="nav-link ph-no-capture"
            data-bs-toggle="tab"
            href="#"
            aria-selected="false"
            tabindex="-1"
            role="tab"
            @click="
              () => {
                setSelectedSection('intermediate');
                track('puzzle_view', 'set_selected_section', 'click', {
                  section: 'intermediate',
                });
              }
            "
            >Intermediate</a
          >
        </li>
        <li class="nav-item" role="presentation">
          <a
            class="nav-link ph-no-capture"
            data-bs-toggle="tab"
            href="#"
            aria-selected="false"
            tabindex="-1"
            role="tab"
            @click="
              () => {
                setSelectedSection('skilled');
                track('puzzle_view', 'set_selected_section', 'click', {
                  section: 'skilled',
                });
              }
            "
            >Skilled</a
          >
        </li>
        <li class="nav-item" role="presentation">
          <a
            class="nav-link ph-no-capture"
            data-bs-toggle="tab"
            href="#"
            aria-selected="false"
            tabindex="-1"
            role="tab"
            @click="
              () => {
                setSelectedSection('advanced');
                track('puzzle_view', 'set_selected_section', 'click', {
                  section: 'advanced',
                });
              }
            "
            >Advanced</a
          >
        </li>
      </ul>
      <div v-if="loading" class="text-center">
        <Loader />
      </div>
      <PuzzleList v-else :puzzles="puzzleData" />
    </div>
  </div>
</template>

<script setup lang="ts">
  import { nextTick, onMounted, ref } from 'vue';

  import MainPuzzleBox from '@/components/puzzles/MainPuzzleBox.vue';
  import PuzzleList from '@/components/puzzles/PuzzleList.vue';
  import Loader from '@/components/util/Loader.vue';
  import { useBotsStore } from '@/stores/botStore';
  import { useGeneralStore } from '@/stores/generalStore';
  import { useModalsStore } from '@/stores/modalsStore';
  import type { Puzzle } from '@/types/apitypes';
  import { track } from '@/util/tracking';

  const bs = useBotsStore();
  const gs = useGeneralStore();
  const ms = useModalsStore();

  const loading = ref(true);
  const allPuzzleData = ref<Puzzle[]>([]);
  const puzzleData = ref<Puzzle[]>([]);
  const selectedSection = ref<'beginner' | 'novice' | 'intermediate' | 'skilled' | 'advanced'>(
    'beginner'
  );

  // @ts-ignore - For some very strange reason, the bots are not loaded here on iOS. So have to make sure they're loaded.
  if (Object.values(bs.bots).length === 0) {
    bs.refresh();
  }

  gs.getPuzzles().then((p) => {
    allPuzzleData.value = p;
    loading.value = false;
    setSelectedSection('beginner');
  });

  const sections = {
    beginner: { from: 0, to: 1100 },
    novice: { from: 1100, to: 1300 },
    intermediate: { from: 1300, to: 1600 },
    skilled: { from: 1600, to: 1800 },
    advanced: { from: 1800, to: 3000 },
  };

  onMounted(() => {
    if (localStorage.getItem('puzzleInfoShown') != 'true') {
      ms.showPuzzleInfo(() => {
        localStorage.setItem('puzzleInfoShown', 'true');
      });
    }
  });

  function setSelectedSection(
    section: 'beginner' | 'novice' | 'intermediate' | 'skilled' | 'advanced'
  ) {
    selectedSection.value = section;
    const range = sections[section];

    const tempData = allPuzzleData.value.filter(
      (p) =>
        bs.getBot(p.bot_id).strength.estimated_elo >= range.from &&
        bs.getBot(p.bot_id).strength.estimated_elo < range.to
    );

    nextTick().then(() => {
      puzzleData.value = tempData;
      document.querySelectorAll('.practicecategory').forEach((el) => {
        el.classList.remove('cardEntrance');
        el.classList.add('cardEntrance');
      });

      document.querySelectorAll('.practicesection > h2').forEach((el) => {
        el.classList.remove('cardEntrance');
        el.classList.add('cardEntrance');
      });
    });
  }
</script>

<style scoped>
  .active {
    background: linear-gradient(315deg, var(--clr-background-detail) 0%, var(--clr-rect-2) 100%);
  }

  .practicegrid {
    display: grid;
    grid-template-columns: 1fr 4fr;
    gap: 5rem;
  }

  .positionright img {
    width: 1.5rem;
    aspect-ratio: 1;
    display: inline-block;
  }

  @media (max-width: 992px) {
    .practicegrid {
      grid-template-columns: 1fr;
      gap: 1rem;
    }
  }

  :deep(cg-board) {
    background-color: var(--clr-accent); /* Override the board background color */
  }

  @keyframes cardEntrance {
    from {
      opacity: 0;
      transform: scale(0.3);
      filter: hue-rotate(180deg);
    }

    to {
      opacity: 1;
      transform: scale(1);
      filter: hue-rotate(0deg);
    }
  }
  .points > * {
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
    align-items: center;
  }

  .points img {
    width: 2rem;
    aspect-ratio: 1;
  }

  .best-win img {
    width: 1.5rem;
    aspect-ratio: 1;
    margin: -0.3rem 0 0 0.2rem;
  }

  .categoryheader-points img {
    width: 2.3rem;
    aspect-ratio: 1;
  }

  .categoryheader-points div:first-child {
    font-size: 1.8rem;
  }
  .right {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .right a {
    font-family: 'Cabin Sketch', 'Neucha', sans-serif;
  }

  .left {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    min-width: 300px;
  }
</style>
