<template>
  <div class="win-rate-time-container">
    <h3 class="time-title">
      <i class="fa-solid fa-clock" style="color: var(--clr-accent)" />
      Win Rate by Time of Day
    </h3>
    <div v-if="!loading" class="time-chart">
      <div class="time-bars">
        <div
          v-for="(hour, index) in hourlyWinRates"
          :key="index"
          class="time-bar-container"
          v-tippy="{
            content: `${formatHour(index)}: ${hour.winRate.toFixed(
              1
            )}% win rate (${hour.wins}/${hour.total} games)`,
          }"
        >
          <div class="time-bar-wrapper">
            <div
              class="time-bar"
              :style="{
                height: `${getBarHeight(hour.winRate)}%`,
                backgroundColor: getBarColor(hour.winRate),
              }"
            />
          </div>
          <div class="time-label" v-if="index % 3 === 0">
            {{ formatHour(index) }}
          </div>
          <div class="time-label" v-else>&nbsp;</div>
        </div>
      </div>
    </div>
    <div v-else class="time-placeholder pulsate-load" />
  </div>
</template>

<script setup lang="ts">
  import { computed } from 'vue';

  const props = defineProps({
    loading: {
      type: Boolean,
      default: true,
    },
    userStatsResponse: {
      type: Object,
      default: () => ({}),
    },
  });

  // Process the game data to get win rates by hour of day
  const hourlyWinRates = computed(() => {
    // Initialize counts for each hour of the day (0-23)
    const hourlyStats = Array(24)
      .fill(null)
      .map(() => ({
        total: 0,
        wins: 0,
        winRate: 0,
        ratingChange: 0,
        averageRatingChange: 0,
      }));

    if (!props.userStatsResponse || !props.userStatsResponse.gameResults) {
      return hourlyStats;
    }

    try {
      // Parse the gameResults JSON string
      const games = JSON.parse(props.userStatsResponse.gameResults);
      if (!Array.isArray(games) || games.length === 0) {
        return hourlyStats;
      }

      // Process each game
      games.forEach((game) => {
        try {
          // Process game timestamp (ended_at)
          if (game && game.ended_at) {
            let gameDate;

            // Handle Firestore timestamp format
            if (typeof game.ended_at === 'object' && 'value' in game.ended_at) {
              gameDate = new Date(game.ended_at.value);
            }
            if (gameDate && gameDate instanceof Date && !isNaN(gameDate.getTime())) {
              const hour = gameDate.getHours();
              hourlyStats[hour].total += 1;

              // Check if the game was a win
              if (game.result_user === 'win') {
                hourlyStats[hour].wins += 1;
              }
            }
          }
        } catch (error) {
          console.error('Error processing game:', error);
        }
      });

      // Calculate win rates
      hourlyStats.forEach((hourStat) => {
        if (hourStat.total > 0) {
          hourStat.winRate = (hourStat.wins / hourStat.total) * 100;
        }
      });
    } catch (error) {
      console.error('Error parsing gameResults:', error);
    }

    return hourlyStats;
  });

  // Format the hour for display (e.g., "3 AM", "2 PM")
  function formatHour(hour: number): string {
    if (hour === 0) return '12 AM';
    if (hour === 12) return '12 PM';

    return hour < 12 ? `${hour} AM` : `${hour - 12} PM`;
  }

  // Calculate the height percentage for each bar
  function getBarHeight(winRate: number): number {
    // Scale from 0-100% to 5-100% (so even 0% win rate shows a tiny bar)
    return Math.max(5, winRate);
  }

  // Get a color for the bar based on the win rate
  function getBarColor(winRate: number): string {
    if (winRate >= 60) return '#76b7b2'; // Green for high win rate (using accent4 which is #71d34d)
    if (winRate >= 40) return '#f28e2c'; // Yellow for average win rate (using premium-yellow which is #ffc107)
    return '#4e79a7'; // Red for low win rate (using rect-4 which is #c20b0b)
  }
</script>

<style scoped>
  .win-rate-time-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 1rem;
  }

  .time-title {
    margin-bottom: 1.5rem;
    text-align: center;
    color: var(--clr-main);
  }

  .time-chart {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .time-bars {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    height: 250px;
    margin-top: 1rem;
  }

  .time-bar-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 4%;
  }

  .time-bar-wrapper {
    width: 100%;
    height: 200px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }

  .time-bar {
    width: 100%;
    border-radius: 4px 4px 0 0;
    transition: height 0.5s ease;
  }

  .time-label {
    margin-top: 0.5rem;
    font-size: 0.7rem;
    color: var(--clr-main);
    transform: rotate(-45deg);
    white-space: nowrap;
  }

  .time-placeholder {
    width: 100%;
    height: 250px;
    background-color: var(--clr-main-lightest);
    border-radius: 8px;
  }

  @media (max-width: 768px) {
    .time-bars {
      height: 200px;
    }

    .time-bar-wrapper {
      height: 150px;
    }

    .time-label {
      font-size: 0.6rem;
    }
  }
</style>
