<template>
  <div class="card container" :style="{ minHeight: showMore ? '37rem' : '21rem' }">
    <h2 class="title">Quick play</h2>

    <div class="text-center" v-if="loading">
      <LoaderNew size="3rem" />
    </div>
    <div v-else class="main fade-in">
      <div v-if="active != null">
        <div class="text-center">
          <span v-if="active.challenge == null"><strong>Ongoing game with:</strong></span>
          <span v-else><strong>Continue challenge</strong></span>
        </div>
        <div>
          <ProfileCard :bot="activeBot!" profileScale="small" class="max-auto" />
        </div>

        <div class="mx-auto mt-3" style="text-align: center">
          <a
            class="btn btn-info ph-no-capture"
            @click="
              () => {
                continueGame();
                track('quick_play', 'continue', 'click');
              }
            "
            >Continue</a
          >
        </div>
      </div>
      <div v-else>
        <div class="quick-play-type-wrapper">
          <span
            v-tippy="{
              content: 'Play a rated against a suitable opponent around your rating',
            }"
            ><strong>{{
              us.user.data?.rating == null
                ? 'Just give me a bot to play'
                : 'Play a bot around your rating'
            }}</strong></span
          >
          <img
            style="width: 8rem; height: 8rem; margin-bottom: 0.5rem"
            class="card"
            src="https://storage.googleapis.com/chessiverse1/images/elements/rated_box_124.webp"
          />

          <div style="display: flex; flex-direction: column; align-items: start">
            <div style="display: flex; gap: 0.2rem">
              <button
                class="btn btn-info ph-no-capture"
                @click="
                  () => {
                    startRatedGame();
                    track('quick_play', 'start_rated_game', 'click');
                  }
                "
                v-tippy="{
                  content: 'Play a rated against a suitable opponent around your rating',
                }"
              >
                <i class="fa-duotone fa-swords" />
                {{ us.user.data?.rating == null ? 'Play now' : 'Play' }}
              </button>
            </div>
          </div>
          <a
            v-if="!showMore"
            href="/explore"
            style="margin-top: 2rem"
            @click="track('quick_play', 'explore_more_opponents', 'click')"
          >
            Explore more opponents <i class="fa-duotone fa-arrow-right"
          /></a>
        </div>
        <div
          style="
            display: flex;
            flex-direction: row;
            justify-content: center;
            gap: 0.5rem;
            flex-wrap: wrap;
            margin-top: 1rem;
          "
          v-if="showMore"
        >
          <div class="quick-play-type-wrapper" v-if="firstUnbeatenBot != null">
            <span
              v-tippy="{
                content:
                  'Play a against your lowest rated unbeaten bot: ' +
                  firstUnbeatenBot.name +
                  ' (' +
                  firstUnbeatenBot.strength.estimated_elo +
                  ')',
              }"
              ><strong>Lowest unbeaten</strong></span
            >
            <ProfileCard :showName="false" :bot="firstUnbeatenBot" profileScale="card" />

            <div style="display: flex; flex-direction: column; align-items: start">
              <div style="display: flex; gap: 0.2rem">
                <button
                  v-if="useBotsStore().isLocked(firstUnbeatenBot)"
                  class="btn btn-warning btn-sm ph-no-capture"
                  @click="
                    () => {
                      router.push({
                        name: 'premium',
                      });
                      track('quick_play', 'play_first_unbeaten_locked', 'click', {
                        botId: firstUnbeatenBot?.id,
                      });
                    }
                  "
                  v-tippy="{
                    content:
                      'Play against your lowest rated unbeaten bot: ' +
                      firstUnbeatenBot.name +
                      ' (' +
                      firstUnbeatenBot.strength.estimated_elo +
                      ')',
                  }"
                >
                  <i class="fa-solid fa-crown" /> Unlock
                </button>
                <button
                  v-else
                  class="btn btn-info btn-sm ph-no-capture"
                  @click="
                    () => {
                      playAgainst(firstUnbeatenBot!.id);
                      track('quick_play', 'play_first_unbeaten', 'click', {
                        botId: firstUnbeatenBot?.id,
                      });
                    }
                  "
                  v-tippy="{
                    content:
                      'Play against your lowest rated unbeaten bot: ' +
                      firstUnbeatenBot.name +
                      ' (' +
                      firstUnbeatenBot.strength.estimated_elo +
                      ')',
                  }"
                >
                  <i class="fa-duotone fa-swords" /> Play
                </button>
              </div>
            </div>
          </div>
          <div class="quick-play-type-wrapper" v-if="nextUnbeatenBot != null">
            <span
              v-tippy="{
                content:
                  'Play against the next highest rated of your beaten bots: ' +
                  nextUnbeatenBot.name +
                  ' (' +
                  nextUnbeatenBot.strength.estimated_elo +
                  ')',
              }"
              ><strong>Next unbeaten</strong></span
            >
            <ProfileCard :showName="false" :bot="nextUnbeatenBot" profileScale="card" />

            <div style="display: flex; flex-direction: column; align-items: start">
              <div style="display: flex; gap: 0.2rem">
                <button
                  v-if="useBotsStore().isLocked(nextUnbeatenBot)"
                  class="btn btn-warning btn-sm ph-no-capture"
                  @click="
                    () => {
                      router.push({
                        name: 'premium',
                      });
                      track('quick_play', 'play_next_unbeaten_locked', 'click', {
                        botId: nextUnbeatenBot!.id,
                      });
                    }
                  "
                  v-tippy="{
                    content:
                      'Play against the next highest rated of your beaten bots: ' +
                      nextUnbeatenBot.name +
                      ' (' +
                      nextUnbeatenBot.strength.estimated_elo +
                      ')',
                  }"
                >
                  <i class="fa-solid fa-crown" /> Unlock
                </button>
                <button
                  v-else
                  class="btn btn-info btn-sm ph-no-capture"
                  @click="
                    () => {
                      playAgainst(nextUnbeatenBot!.id);
                      track('quick_play', 'play_next_unbeaten', 'click', {
                        botId: nextUnbeatenBot!.id,
                      });
                    }
                  "
                  v-tippy="{
                    content:
                      'Play against the next highest rated of your beaten bots: ' +
                      nextUnbeatenBot.name +
                      ' (' +
                      nextUnbeatenBot.strength.estimated_elo +
                      ')',
                  }"
                >
                  <i class="fa-duotone fa-swords" /> Play
                </button>
              </div>
            </div>
          </div>
          <div
            class="quick-play-type-wrapper"
            v-if="nextUnbeatenChallenge != null && nextUnbeatenChallengeBot != null"
          >
            <span
              v-tippy="{
                content:
                  'Play the first unbeaten challenge of this week: ' +
                  nextUnbeatenChallengeBot.name +
                  ' (' +
                  nextUnbeatenChallengeBot.strength.estimated_elo +
                  ')',
              }"
              ><strong>Next challenge</strong></span
            >
            <ProfileCard
              :showName="false"
              :showStar="false"
              :bot="nextUnbeatenChallengeBot"
              :alwaysUnlocked="true"
              profileScale="card"
            />

            <div style="display: flex; flex-direction: column; align-items: start">
              <div style="display: flex; gap: 0.2rem">
                <button
                  class="btn btn-info btn-sm ph-no-capture"
                  @click="
                    () => {
                      // @ts-ignore
                      playChallenge(nextUnbeatenChallenge);
                      track('quick_play', 'play_next_challenge', 'click', {
                        challenge: nextUnbeatenChallenge,
                      });
                    }
                  "
                  v-tippy="{
                    content: 'Play the first unbeaten challenge of this week',
                  }"
                >
                  <i class="fa-duotone fa-flask-round-potion" /> Play
                </button>
              </div>
            </div>
          </div>
          <div class="quick-play-type-wrapper">
            <div
              style="text-align: center"
              v-tippy="{
                content: 'Play against a random bot',
              }"
            >
              <span><strong>Random</strong></span>
              <ProfileCard :showName="false" :unknown="true" profileScale="card" />
            </div>

            <div style="display: flex; flex-direction: column; align-items: start">
              <div style="display: flex; gap: 0.2rem">
                <button
                  class="btn btn-info btn-sm ph-no-capture"
                  v-tippy="{
                    content: 'Play against a random bot',
                  }"
                  @click="
                    () => {
                      let randomBotId = getRandomBotId(null);
                      if (randomBotId != null) {
                        playAgainst(randomBotId);
                        track('quick_play', 'play_random', 'click', {
                          botId: randomBotId,
                        });
                      }
                    }
                  "
                >
                  <i class="fa-duotone fa-swords" /> Play
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { type Ref, onMounted, ref } from 'vue';
  import { useRouter } from 'vue-router';
  import { useToast } from 'vue-toast-notification';

  import ProfileCard from '@/components/common/ProfileCard.vue';
  import LoaderNew from '@/components/util/LoaderNew.vue';
  import { useBotsStore } from '@/stores/botStore';
  import { useGameStore } from '@/stores/gameStore';
  import { useModalsStore } from '@/stores/modalsStore';
  import { useUserStore } from '@/stores/userStore';
  import type { Bot, ChallengeFromPosition } from '@/types/apitypes';
  import { track } from '@/util/tracking';

  defineProps({
    showMore: {
      type: Boolean,
      default: false,
    },
  });

  const router = useRouter();
  const gs = useGameStore();
  const us = useUserStore();
  const bs = useBotsStore();
  const ms = useModalsStore();

  const loading = ref(true);
  const active = ref();
  const activeBot: Ref<Bot | null> = ref(null);
  const firstUnbeatenBot: Ref<Bot | null> = ref(null);
  const nextUnbeatenBot: Ref<Bot | null> = ref(null);
  const nextUnbeatenChallenge: Ref<{
    challenge: ChallengeFromPosition;
    unbeatenDifficulty: string;
  } | null> = ref(null);
  const nextUnbeatenChallengeBot: Ref<Bot | null> = ref(null);

  onMounted(() => {
    // First check if there is an active game, then if there is set activeBot otherwise get next unbeaten bot.
    gs.refreshActiveGame().then((g) => {
      if (g == null) {
        Promise.all([us.getUnbeateBots(), us.getNextUnbeatenChallenge()]).then((responses) => {
          firstUnbeatenBot.value = responses[0].first;
          nextUnbeatenBot.value = responses[0].next;
          nextUnbeatenChallenge.value = responses[1];

          if (nextUnbeatenChallenge.value != null) {
            const unbeatenBotId =
              nextUnbeatenChallenge.value.challenge.difficulties![
                nextUnbeatenChallenge.value.unbeatenDifficulty as
                  | 'beginner'
                  | 'novice'
                  | 'intermediate'
                  | 'skilled'
                  | 'advanced'
              ].botId;
            nextUnbeatenChallengeBot.value = bs.getBot(unbeatenBotId);
          }
          loading.value = false;
        });
      } else {
        bs.getUserBotProfile(g.bot.id).then((response) => {
          active.value = g;
          activeBot.value = response.data.bot;
          loading.value = false;
        });
      }
    });
  });

  function startRatedGame() {
    // Use the users rating, or just pick a bot around beginner level (the user's rating will be set when the game starts)
    const rating = us.user.data?.rating == null ? 800 : us.user.data.rating.rating;

    const bot = bs.getRandomBot(rating);

    if (bot == null) {
      // Couldn't find a bot, shouldn't happen, but handling it just in case
      useToast().error('Failed to find a bot, please try again later.');
      return;
    }

    // If successfullyChoseRating is not true, the user probably closed the modal and we shouldn't continue
    router.push({
      name: 'game',
      query: {
        bid: bot.id,
        type: 'casual',
      },
    });
  }

  function playChallenge(challenge: {
    challenge: ChallengeFromPosition;
    unbeatenDifficulty: string;
  }) {
    router.push({
      name: 'game',
      query: {
        challengeId: challenge.challenge.id,
        difficulty: challenge.unbeatenDifficulty,
        type: 'challenge',
      },
    });
  }

  function continueGame() {
    if (active.value != null) {
      router.push({
        name: 'game',
        query: { id: active.value.id, type: 'continue' },
      });
    }
  }

  function playAgainst(botId: string | null) {
    const query = { bid: botId, type: 'casual' };

    router.push({
      name: 'game',
      query: query,
    });
  }

  function getRandomBotId(rating: number | null): string | null {
    const randomBot = bs.getRandomBot(rating);

    if (randomBot == null) {
      return null;
    }

    return randomBot.id;
  }
</script>

<style scoped>
  .container {
    padding-bottom: 1rem;
  }

  .quick-play-type-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .main {
    display: flex;
    justify-content: center;
    gap: 1rem;
  }
</style>
