<template>
  <div style="padding: 0.5rem; display: flex; justify-content: start; flex-direction: column">
    <div class="title">
      <div class="btn-group" role="group">
        <input
          type="radio"
          class="btn-check inactive"
          name="time-settings-timed"
          id="time-settings-timed"
          autocomplete="off"
          value="timed"
          v-model="selectedType"
        />
        <label class="btn inactive btn-outline-info" for="time-settings-timed"
          ><i class="fa-solid fa-chess-clock-flip" /> Timed</label
        >
        <input
          type="radio"
          class="btn-check inactive"
          name="time-settings-untimed"
          id="time-settings-untimed"
          autocomplete="off"
          value="untimed"
          v-model="selectedType"
        />
        <label class="btn inactive btn-outline-info" for="time-settings-untimed"
          ><i class="fa-solid fa-infinity" /> Untimed</label
        >
      </div>
    </div>
    <div
      v-if="selectedType == 'timed' && !choosing"
      style="z-index: 1000; margin-top: 1rem; display: flex; justify-content: center"
    >
      <button
        class="btn btn-info time-button ph-no-capture bounce-top"
        @click="
          () => {
            minutes = '';
            increment = '';
            choosing = true;
          }
        "
      >
        <span class="time-button-text">{{ minutes }} + {{ increment }}</span
        ><br /><span style="font-size: 0.8rem; opacity: 0.7"
          ><i class="fa-solid fa-caret-down"
        /></span>
      </button>
    </div>
    <div
      class="time-container card bounce-top"
      style="z-index: 1000; margin-top: 1rem"
      v-if="selectedType == 'timed' && choosing"
    >
      <div class="time-buttons-container">
        <button
          class="btn btn-info time-button ph-no-capture"
          @click="
            () => {
              setAndSend('1', '0');
            }
          "
        >
          <span class="time-button-text">1 + 0</span><br /><span
            style="font-size: 0.8rem; opacity: 0.7"
            >Bullet</span
          >
        </button>
        <button
          class="btn btn-info time-button ph-no-capture"
          @click="
            () => {
              setAndSend('1', '1');
            }
          "
        >
          <span class="time-button-text">1 + 1</span><br /><span
            style="font-size: 0.8rem; opacity: 0.7"
            >Bullet</span
          >
        </button>
        <button
          class="btn btn-info time-button ph-no-capture"
          @click="
            () => {
              setAndSend('2', '1');
            }
          "
        >
          <span class="time-button-text">2 + 1</span><br /><span
            style="font-size: 0.8rem; opacity: 0.7"
            >Bullet</span
          >
        </button>
        <button
          class="btn btn-info time-button ph-no-capture"
          @click="
            () => {
              setAndSend('3', '0');
            }
          "
        >
          <span class="time-button-text">3 + 0</span><br /><span
            style="font-size: 0.8rem; opacity: 0.7"
            >Blitz</span
          >
        </button>
        <button
          class="btn btn-info time-button ph-no-capture"
          @click="
            () => {
              setAndSend('3', '2');
            }
          "
        >
          <span class="time-button-text">3 + 2</span><br /><span
            style="font-size: 0.8rem; opacity: 0.7"
            >Blitz</span
          >
        </button>
        <button
          class="btn btn-info time-button ph-no-capture"
          @click="
            () => {
              setAndSend('5', '0');
            }
          "
        >
          <span class="time-button-text">5 + 0</span><br /><span
            style="font-size: 0.8rem; opacity: 0.7"
            >Blitz</span
          >
        </button>
        <button
          class="btn btn-info time-button ph-no-capture"
          @click="
            () => {
              setAndSend('10', '0');
            }
          "
        >
          <span class="time-button-text">10 + 0</span><br /><span
            style="font-size: 0.8rem; opacity: 0.7"
            >Rapid</span
          >
        </button>
        <button
          class="btn btn-info time-button ph-no-capture"
          @click="
            () => {
              setAndSend('10', '5');
            }
          "
        >
          <span class="time-button-text">10 + 5</span><br /><span
            style="font-size: 0.8rem; opacity: 0.7"
            >Rapid</span
          >
        </button>
        <button
          class="btn btn-info time-button ph-no-capture"
          @click="
            () => {
              setAndSend('15', '10');
            }
          "
        >
          <span class="time-button-text">15 + 10</span><br /><span
            style="font-size: 0.8rem; opacity: 0.7"
            >Rapid</span
          >
        </button>
      </div>
      <div style="display: flex; flex-direction: column; align-items: center">
        <div>
          <span style="opacity: 0.7">Custom</span>
        </div>
        <div style="display: flex; justify-content: center; align-items: center; gap: 0.2rem">
          <div>
            <input type="text" id="minutes" placeholder="Min" v-model="customMinutes" />
          </div>
          <div>
            <input type="text" id="increment" placeholder="Inc" v-model="customIncrement" />
          </div>
          <button
            class="btn btn-info btn-sm ph-no-capture"
            @click="
              () => {
                setAndSend(customMinutes, customIncrement);
                choosing = false;
              }
            "
            :disabled="!customOk"
          >
            <i class="fa-solid fa-arrow-right" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
  import { type PropType, nextTick, onUnmounted, ref, watch } from 'vue';

  import type { TimeControl } from '@/types/apitypes';

  const props = defineProps({
    initial: {
      type: Object as PropType<TimeControl | null>,
      default: null,
    },
  });

  const emit = defineEmits(['update']);

  const selectedType = ref(props.initial ? 'timed' : 'untimed');

  const minutes = ref<string>(props.initial ? props.initial.initial / 60 / 1000 + '' : '');
  const increment = ref<string>(props.initial ? props.initial.increment / 1000 + '' : '');

  // Init values if current minutes and increment evaluates to Custom (they do that if initial is null too, but then it gets set to empty string which is what we want)
  const customMinutes = ref(getCategory() === 'Custom' ? minutes.value : '');
  const customIncrement = ref(getCategory() === 'Custom' ? increment.value : '');

  const choosing = ref(minutes.value === '' || increment.value === '');
  const customOk = ref(isCustomOk());

  watch(
    () => selectedType.value,
    () => {
      if (selectedType.value == 'untimed') {
        emit('update', null);
      }
    },
    { immediate: true }
  );

  function setAndSend(newMinutes: string, newIncrement: string) {
    minutes.value = newMinutes;
    increment.value = newIncrement;

    choosing.value = false;

    if (getCategory() !== 'Custom') {
      customIncrement.value = '';
      customMinutes.value = '';
    }

    emit('update', {
      initial: parseInt(minutes.value) * 60 * 1000,
      increment: parseInt(increment.value) * 1000,
    });
  }

  watch(customMinutes, (newVal) => {
    newVal = newVal.replace(/\D/g, ''); // Remove non-numeric characters
    customMinutes.value = newVal;
    if (parseInt(newVal) > 60 * 4) {
      customMinutes.value = '240';
    } else if (parseInt(newVal) < 1) {
      customMinutes.value = '1';
    }

    customOk.value = isCustomOk();
  });

  watch(customIncrement, (newVal) => {
    newVal = newVal.replace(/\D/g, ''); // Remove non-numeric characters
    customIncrement.value = newVal;
    if (parseInt(newVal) > 60 * 10) {
      customIncrement.value = '600';
    } else if (parseInt(newVal) < 0) {
      customIncrement.value = '0';
    }

    customOk.value = isCustomOk();
  });

  function isCustomOk() {
    return customIncrement.value !== '' && customMinutes.value !== '';
  }

  function getCategory() {
    if (minutes.value === '1' && increment.value === '0') {
      return 'Bullet';
    } else if (minutes.value === '1' && increment.value === '1') {
      return 'Bullet';
    } else if (minutes.value === '2' && increment.value === '1') {
      return 'Bullet';
    } else if (minutes.value === '3' && increment.value === '0') {
      return 'Blitz';
    } else if (minutes.value === '3' && increment.value === '2') {
      return 'Blitz';
    } else if (minutes.value === '5' && increment.value === '0') {
      return 'Blitz';
    } else if (minutes.value === '10' && increment.value === '0') {
      return 'Rapid';
    } else if (minutes.value === '10' && increment.value === '5') {
      return 'Rapid';
    } else if (minutes.value === '15' && increment.value === '10') {
      return 'Rapid';
    } else {
      return 'Custom';
    }
  }
</script>

<style scoped>
  .inactive:not(:checked) + .inactive {
    background-color: var(--clr-main-lightest);
  }

  .time-buttons-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    gap: 0.5rem;
  }

  .title {
    display: flex;
    gap: 0.2rem;
    justify-content: center;
  }

  .time-container {
    display: flex;
    gap: 1rem;
    justify-content: start;
    padding: 1rem;
  }

  .time-container input {
    width: 4ch;
  }

  .time-button {
    width: 4rem;
    height: 4rem;
  }

  .time-button-text {
    font-size: 1rem;
    white-space: nowrap;
  }
</style>
