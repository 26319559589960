import type { ChildNode, Node, PgnNodeData } from 'chessops/pgn';

export function render(root: Node<any>): any[] {
  const enum MakePgnState {
    Pre = 0,
    Sidelines = 1,
    End = 2,
  }

  interface MakePgnFrame {
    state: MakePgnState;
    ply: number;
    node: ChildNode<PgnNodeData>;
    sidelines: Iterator<ChildNode<PgnNodeData>>;
    startsVariation: boolean;
    inVariation: boolean;
  }

  const nodes: any[] = [];
  const stack: MakePgnFrame[] = [];
  const parentStack: number[] = []; // <-- Track parent UIDs

  const variations = root.children[Symbol.iterator]();
  const firstVariation = variations.next();
  if (!firstVariation.done) {
    stack.push({
      state: MakePgnState.Pre,
      ply: 1,
      node: firstVariation.value,
      sidelines: variations,
      startsVariation: false, // Root line is not a variation
      inVariation: false,
    });
  }

  let currentDepth = 0;

  while (stack.length) {
    const frame = stack[stack.length - 1];

    // If we were in a variation before, decrement the depth
    if (frame.inVariation) {
      currentDepth--;
      parentStack.pop(); // <-- Remove the last tracked parent
      frame.inVariation = false;
    }

    switch (frame.state) {
      case MakePgnState.Pre:
        // "Pre" means we're about to process this node
        nodes.push({
          ...frame.node.data,
          ply: frame.ply,
          depth: currentDepth,
          folded: false,
          variationStart: frame.startsVariation,
          parents: [...parentStack], // <-- Store current lineage
        });
        frame.state = MakePgnState.Sidelines;
      // Fall through to Sidelines

      case MakePgnState.Sidelines: {
        // Try to get the next sibling (side variation)
        const child = frame.sidelines.next();
        if (child.done) {
          // No more siblings: move on to this node's children (main line)
          const subVariations = frame.node.children[Symbol.iterator]();
          const firstSub = subVariations.next();
          if (!firstSub.done) {
            // The first child is mainline, so not a sideline
            stack.push({
              state: MakePgnState.Pre,
              ply: frame.ply + 1,
              node: firstSub.value,
              sidelines: subVariations,
              startsVariation: false, // continuing the main line
              inVariation: false,
            });
          }
          frame.state = MakePgnState.End;
        } else {
          // Found a sibling => new variation
          currentDepth++;

          // @ts-ignore Push the current node UID to parentStack before adding sideline node
          parentStack.push(frame.node.data.uid);

          stack.push({
            state: MakePgnState.Pre,
            ply: frame.ply,
            node: child.value,
            sidelines: [][Symbol.iterator](),
            startsVariation: true, // This node starts a sideline
            inVariation: false,
          });

          frame.inVariation = true;
        }
        break;
      }

      case MakePgnState.End:
        // Done with this frame; pop it and go back up
        stack.pop();
        break;
    }
  }

  return nodes;
}
