<template>
  <div :class="'card container' + (ratingInfo == null ? ' pulsate-load' : '')">
    <div
      v-if="ratingInfo == null"
      style="display: flex; flex-direction: column; align-items: center; gap: 0.5rem"
    >
      <div style="display: flex; flex-direction: column; align-items: center">
        <span><strong>Loading rating...</strong></span>
        <span style="font-size: 2rem; color: var(--clr-main-light)">-</span>
      </div>
      <div
        style="
          display: grid;
          justify-content: center;
          grid-template-columns: repeat(2, 1fr);
          gap: 1rem;
        "
      >
        <div style="display: flex; flex-direction: column; align-items: center">
          <span><strong>&nbsp;</strong></span>
          <div v-html="'&nbsp;'" />
        </div>
        <div style="display: flex; flex-direction: column; align-items: center">
          <span><strong>&nbsp;</strong></span>
          <div v-html="'&nbsp;'" />
        </div>
        <div style="display: flex; flex-direction: column; align-items: center">
          <span><strong>&nbsp;</strong></span>
          <div v-html="'&nbsp;'" />
        </div>
        <div style="display: flex; flex-direction: column; align-items: center">
          <span><strong>&nbsp;</strong></span>
          <div v-html="'&nbsp;'" />
        </div>
      </div>
    </div>
    <div v-else style="display: flex; flex-direction: column; align-items: center; gap: 0.5rem">
      <div
        v-if="!isProvisionalRating(currentRating!)"
        style="display: flex; flex-direction: column; align-items: center"
      >
        <span><strong>Rating</strong></span>
        <span style="font-size: 2rem; color: var(--clr-main-light)">{{
          Math.round(currentRating!.rating)
        }}</span>
      </div>
      <div
        v-else
        style="display: flex; flex-direction: column; align-items: center"
        v-tippy="{
          content:
            'Your rating is provisional, which means it hasn\'t settled quite yet. Your rating will be provisional until your deviation is below 110, it\s currently ' +
            Math.round(currentRating!.ratingDeviation) +
            '. The deviation gets lower the more you play.',
        }"
      >
        <span
          ><strong>Provisional rating </strong>
          <i
            class="fa-solid fa-comment-exclamation"
            style="vertical-align: super; font-size: smaller"
        /></span>
        <span style="font-size: 2rem; color: var(--clr-main-light)"
          >{{ Math.round(currentRating!.rating) }}?</span
        >
      </div>
      <div
        v-if="!isProvisionalRating(currentRating!)"
        style="
          display: grid;
          justify-content: center;
          grid-template-columns: repeat(2, 1fr);
          gap: 1rem;
        "
      >
        <div
          v-if="ratingInfo!.historyChange.lastGame != null"
          style="display: flex; flex-direction: column; align-items: center"
        >
          <span><strong>Last game</strong></span>
          <div
            v-html="
              getRatingChange(
                Math.round(currentRating!.rating - ratingInfo!.historyChange.lastGame)
              )
            "
          />
        </div>
        <div
          v-if="ratingInfo!.historyChange.lastDay != null"
          style="display: flex; flex-direction: column; align-items: center"
        >
          <span><strong>24 hours</strong></span>
          <div
            v-html="
              getRatingChange(Math.round(currentRating!.rating - ratingInfo!.historyChange.lastDay))
            "
          />
        </div>
        <div
          v-if="ratingInfo!.historyChange.lastWeek != null"
          style="display: flex; flex-direction: column; align-items: center"
        >
          <span><strong>7 days</strong></span>
          <div
            v-html="
              getRatingChange(
                Math.round(currentRating!.rating - ratingInfo!.historyChange.lastWeek)
              )
            "
          />
        </div>
        <div
          v-if="ratingInfo!.historyChange.lastMonth != null"
          style="display: flex; flex-direction: column; align-items: center"
        >
          <span><strong>1 month</strong></span>
          <div
            v-html="
              getRatingChange(
                Math.round(currentRating!.rating - ratingInfo!.historyChange.lastMonth)
              )
            "
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
  import { type PropType, ref, watch } from 'vue';

  import { useModalsStore } from '@/stores/modalsStore';
  import { useUserStore } from '@/stores/userStore';
  import type { Rating } from '@/types/apitypes.js';
  import { isProvisionalRating } from '@/util/ratings.js';

  const ms = useModalsStore();
  const us = useUserStore();

  const props = defineProps({
    ratingInfo: {
      type: Object as PropType<{
        currentRating: Rating | null;
        historyChange: {
          lastGame: number | null;
          lastDay: number | null;
          lastWeek: number | null;
          lastMonth: number | null;
        };
      }>,
      default: null,
    },
  });

  const currentRating = ref<Rating | null>(props.ratingInfo?.currentRating ?? null);

  watch(
    () => props.ratingInfo,
    (newRatingInfo) => {
      currentRating.value = newRatingInfo.currentRating;
    }
  );

  function refreshRating() {
    useUserStore()
      .refreshUserProfile()
      .then((user) => {
        if (user.rating != null) {
          currentRating.value = user.rating;
        }
      });
  }

  // Not sure if this is a good way to generate elements, but it works. Maybe JSX would be better?
  function getRatingChange(ratingChange: number) {
    const color = ratingChange >= 0 ? '--clr-accent4' : '--clr-rect-3';
    const plusSign = ratingChange >= 0 ? '+' : '';
    const icon = ratingChange >= 0 ? 'fa-arrow-trend-up' : 'fa-arrow-trend-down';

    return `<span style="color: var(${color})"><i class="fa-duotone ${icon}"></i> ${plusSign}${ratingChange}</span>`;
  }
</script>
<style scoped>
  .container {
    display: flex;
    justify-content: center;
    padding-block: 1rem;
  }
</style>
