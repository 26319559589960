<template>
  <div class="card move-tree" id="move-tree" :style="styles" v-if="cs.getCourseQueue != null">
    <div
      v-for="group in groupedNodes"
      :key="group.map((n) => n.uid).join('-')"
      :style="{
        ...getVariationDecoration(group[0]),
        display: 'flex',
        padding: '0 0.5rem',
      }"
    >
      <div
        v-for="(n, index) in Array.from({ length: group[0].depth })"
        :key="'spanner' + group[0].uid + '-' + n + '-' + index"
        :class="{
          'hover-highlight': hoveredParent === group[0].parents[index],
        }"
        style="
          border-left: 1px solid var(--clr-main-lightest);
          min-width: 1rem;
          color: var(--clr-main-lightest);
        "
        role="button"
        @click="cs.selectMove(group[0].parents[index])"
        @mouseenter="hoveredParent = group[0].parents[index]"
        @mouseleave="hoveredParent = null"
      >
        {{ index == group[0].depth - 1 && group[0].variationStart ? '-' : '' }}
      </div>
      <div v-if="group.length > 1">
        <TreeComment
          v-if="group[0].cleanStartingComments != ''"
          :fen="group[0].fen"
          :comment="group[0].cleanStartingComments"
          :variationType="group[0].type"
        />
        <div class="chain-group">
          <TreeMove
            v-for="(moveNode, index) in group"
            :uid="moveNode.uid"
            :fen="moveNode.fen"
            :ply="moveNode.ply"
            :san="moveNode.san"
            :nags="moveNode.nags"
            :comment="moveNode.comments"
            :startingComment="moveNode.startingComments"
            :has-arrow="hasTag(moveNode.comments)"
            :key="`move-${moveNode.uid}`"
            :variationType="moveNode.type"
            :partOfChain="index === 0 ? 'start' : index === group.length - 1 ? 'end' : 'middle'"
          />
        </div>
        <TreeComment
          v-if="group[group.length - 1].cleanComments != ''"
          :comment="group[group.length - 1].cleanComments"
          :fen="group[group.length - 1].fen"
          :variationType="group[group.length - 1].type"
        />
      </div>
      <div v-else>
        <TreeComment
          v-if="group[0].cleanStartingComments != ''"
          :comment="group[0].cleanStartingComments"
          :fen="group[0].fen"
          :variationType="group[0].type"
        />
        <TreeMove
          :uid="group[0].uid"
          :ply="group[0].ply"
          :fen="group[0].fen"
          :san="group[0].san"
          :nags="group[0].nags"
          :comment="group[0].comments"
          :startingComment="group[0].startingComments"
          :has-arrow="hasTag(group[0].comments)"
          :key="`move-${group[0].uid}`"
          :variationType="group[0].type"
          :partOfChain="null"
        />
        <TreeComment
          v-if="group[0].cleanComments != ''"
          :comment="group[0].cleanComments"
          :fen="group[0].fen"
          :variationType="group[0].type"
        />

        <span
          v-if="group[0].foldable && !unfoldedNodes.includes(group[0].uid)"
          role="button"
          @click="addToUnfoldStates(group[0].uid)"
        >
          <i style="color: var(--clr-main-lighter)" class="fa-solid fa-square-plus"
        /></span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { computed, onMounted, onUpdated, ref, watch } from 'vue';

  import TreeComment from '@/components/common/tree/TreeComment.vue';
  import TreeMove from '@/components/common/tree/TreeMove.vue';
  import { useCourseStore } from '@/stores/courseStore';
  import { usePageStore } from '@/stores/pageStore';
  import { cleanComments, hasTag } from '@/util/course';

  const cs = useCourseStore();
  const ps = usePageStore();

  const unfoldedNodes = ref<number[]>([]);
  let cachesPrecomputedNodes = computeNodes();
  const groupedNodes = ref(computeGroupedNodes());
  const hoveredParent = ref<number | null>(null); // Track hovered parent UID

  const styles = computed(() => ({
    fontSize: (ps.courseSettings.fontSize ?? 1) * 100 + '%',
    fontFamily: ps.courseSettings.fontFamily ?? 'inherit',
  }));

  watch(
    () => cs.selectedNode,
    () => {
      if (cs.selectedNode != null) {
        addToUnfoldStates(cs.selectedNode.uid);
      }
    },
    { immediate: true }
  );

  watch(
    () => cs.getCourseQueue,
    () => {
      cachesPrecomputedNodes = computeNodes();
    },
    { immediate: true }
  );

  function addToUnfoldStates(uid: number): void {
    let node = cs.getNodeByUid(uid);
    if (!node || node.parent == null) return;

    const oldArray = [...unfoldedNodes.value];

    do {
      if (node.foldable && !unfoldedNodes.value.includes(node.uid)) {
        unfoldedNodes.value.push(node.uid);
      }
      node = cs.getNodeByUid(node.parent);
    } while (node && node.parent != null);

    if (!unfoldedNodes.value.includes(uid)) {
      unfoldedNodes.value.push(uid);
    }

    if (oldArray.length !== unfoldedNodes.value.length) {
      autoUnfoldChain(uid);
      cachesPrecomputedNodes.forEach((node) => {
        if (node.uid == uid) {
          node.folded = false;
        } else if (node.foldParents.includes(uid)) {
          node.folded = !node.foldParents.every((parent: number) =>
            unfoldedNodes.value.includes(parent)
          );
        }
      });

      groupedNodes.value = computeGroupedNodes();
    }
  }

  function autoUnfoldChain(startUid: number) {
    let current = cs.getNodeByUid(startUid);
    // Continue down the chain as long as there's exactly one child.
    while (current && current.children && current.children.length === 1) {
      current = cs.getNodeByUid(current.children[0]);
      // Only add the child if it isn't already unfolded.
      if (current.foldable && !unfoldedNodes.value.includes(current.uid)) {
        unfoldedNodes.value.push(current.uid);
      }
    }
  }

  function computeGroupedNodes() {
    const precomputedNodes = cachesPrecomputedNodes.filter((node) => node.folded === false);

    if (precomputedNodes == null || precomputedNodes.length === 0) {
      return [];
    }

    const groups = [];
    let currentGroup = [precomputedNodes[0]];
    for (let i = 1; i < precomputedNodes.length; i++) {
      const node = precomputedNodes[i];
      const previousNode = precomputedNodes[i - 1];

      const continueChain = shouldContinueChain(node.uid, previousNode?.uid);

      if (continueChain) {
        // The current node is part of the current group, so just add to the group and continnue
        currentGroup.push(node);
      } else {
        // The current node is NOT part of the current group, so we need to start a new group
        groups.push(currentGroup);
        currentGroup = [node];
      }
    }

    // Done with all nodes, so push the last group and return
    groups.push(currentGroup);
    return groups;
  }

  function computeNodes() {
    return cs.getCourseQueue.map((node: any) => {
      const storeNode = cs.getNodeByUid(node.uid);
      return {
        ...node,
        cleanComments: cleanComments(node.comments),
        cleanStartingComments: cleanComments(node.startingComments),
        foldable: storeNode?.foldable,
        // Copy the foldParents so you don't have to query the store later:
        foldParents: storeNode?.foldParents || [],
        folded: !(storeNode?.foldParents || []).every((parent: number) =>
          unfoldedNodes.value.includes(parent)
        ),
      };
    });
  }

  function shouldContinueChain(uid: number, previousUid: number | undefined): boolean {
    const previous = previousUid == null ? null : cs.getNodeByUid(previousUid);
    const node = cs.getNodeByUid(uid);
    const parent = cs.getNodeByUid(node.parent);

    if (node == null || previous == null || parent == null) {
      // Either something is wrong and we can't find the current node, or we're on the very first node in the list
      // in both cases, "continuing the chain" is not relevant since there is no chain to continue
      // This conveniently sorts out null checks below too
      return false;
    }

    if (cleanComments(parent.comments) != '') {
      // Comments after the parent breaks a chain
      return false;
    }

    if (parent.children[0] !== uid || parent.uid != previous.uid) {
      // This is not the main line from the parent, or the parent of this node os not the previous node in the view, in both cases we're not continuing a chain
      return false;
    }

    if (cleanComments(node.startingComments) != '') {
      // Starting comments of the current node breaks the chain
      return false;
    }

    return true;
  }

  function getVariationDecoration(node: any) {
    if (ps.courseSettings.colors.mode == 'bg') {
      if (node.type.includes('main')) {
        return {
          backgroundColor: ps.courseSettings.colors.main.bg.override,
        };
      } else if (node.type.includes('caution')) {
        return { backgroundColor: ps.courseSettings.colors.caution.bg.override };
      } else if (node.type.includes('alternative')) {
        return {
          backgroundColor: ps.courseSettings.colors.alternative.bg.override,
        };
      }
    }
    return {};
  }
</script>

<style scoped>
  /* Default state */
  div[role='button'] {
    transition: border-color 0.2s ease-in-out;
  }

  /* Apply blue highlight if the element shares the hovered parent */
  .hover-highlight {
    border-left-color: #8bcddc !important;
  }

  .chain-group {
    display: flex;
    flex-wrap: wrap;
    gap: 0.2rem;
  }
  .move-tree {
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-y: auto;
  }
</style>
