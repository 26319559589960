<template>
  <div class="overflow-auto">
    <table
      class="table table-striped table-responsive caption-top"
      style="width: 0%; margin: 0 auto 2rem auto"
    >
      <caption>
        Games
      </caption>
      <thead class="table-light">
        <tr>
          <th scope="col" class="col-auto">Played</th>
          <th scope="col" class="col-auto">Opponent</th>
          <th scope="col" class="col-auto">Country</th>
          <th scope="col" class="col-auto">Elo</th>
          <th scope="col" class="col-auto">Result</th>
          <th scope="col" class="col-auto">Rating change</th>
          <th scope="col" class="col-auto">Type</th>
          <th scope="col" class="col">Link</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(game, index) in games" :key="index">
          <td style="white-space: nowrap" v-tippy="{ content: game.startedAt.toLocaleString() }">
            {{ dateToDateMonthWithOptionalYear(game.startedAt) }}
          </td>
          <td
            :style="{
              background: bs.getBot(game.bot.id).config.boardbg,
              whiteSpace: 'nowrap',
              cursor: 'pointer',
            }"
            class="ph-no-capture"
            @click="
              () => {
                ms.showBotProfile(game.bot.id);
                track('game_history_table', 'show_bot_profile', 'click');
              }
            "
          >
            <img
              style="height: 2rem; margin-right: 1rem; background: white; border-radius: 50%"
              :src="ps.img(game.bot.id, ImageType.BotProfile, '80')"
            />{{ bs.getBot(game.bot.id).name }}
          </td>
          <td
            style="white-space: nowrap"
            v-tippy="{ content: bs.getBot(game.bot.id).country.name }"
          >
            <img
              :src="getFlagUrl(bs.getBot(game.bot.id).country.code)"
              :alt="bs.getBot(game.bot.id).country.name"
              style="height: 0.7rem; margin: -0.2rem 0.2rem 0 0"
            />
          </td>
          <td style="white-space: nowrap">
            {{ bs.getBot(game.bot.id).strength.estimated_elo }}
          </td>
          <td style="white-space: nowrap">
            <div v-if="game.result != null">
              <a
                class="btn btn-info"
                :style="{
                  cursor: 'auto',
                  background: resultData(game.result, game.userSide).color,
                  color: 'black',
                  height: '1.5rem',
                  width: '4rem',
                  padding: '0',
                }"
                >{{ resultData(game.result, game.userSide).message }}
              </a>
            </div>
            <div class="text-center" v-else>-</div>
          </td>
          <td style="white-space: nowrap; text-align: center">
            <span
              v-if="
                game.result == null ||
                !game.rated ||
                game.ratingChange == null ||
                game.ratingChange.new == null ||
                game.ratingChange.old == null
              "
              >-</span
            >
            <span
              v-else-if="getRatingChangeNumber(game.ratingChange) >= 0"
              style="color: var(--clr-accent4)"
            >
              +{{ getRatingChangeNumber(game.ratingChange!) }}
            </span>
            <span v-else style="color: var(--clr-rect-4)">
              {{ getRatingChangeNumber(game.ratingChange!) }}
            </span>
          </td>
          <td style="white-space: nowrap; text-align: center" class="text-capitalize">
            {{
              game.type?.toLowerCase().includes('dailyendgame')
                ? 'Endgame'
                : game.type?.toLowerCase().includes('dailymaster')
                  ? 'Master'
                  : game.type
            }}
          </td>
          <td style="white-space: nowrap; text-align: center">
            <span v-if="game.termination == GameTermination.Aborted">Aborted</span>
            <button
              v-else-if="game.result == null"
              type="button"
              class="btn btn-outline-info to-game-button to-game-button-ongoing ph-no-capture"
              @click="
                () => {
                  gotoGame(game.id);
                  track('game_history_table', 'ongoing_button', 'click');
                }
              "
            >
              {{ 'Ongoing' }}
            </button>
            <button
              v-else
              type="button"
              class="btn btn-info to-game-button ph-no-capture"
              @click="
                () => {
                  gotoAnalysis(game.id);
                  track('game_history_table', 'view_button', 'click');
                }
              "
            >
              {{ 'View' }}
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup lang="ts">
  // import { useGameStore } from "@/stores/gameStore";
  import { useRouter } from 'vue-router';

  import { useBotsStore } from '@/stores/botStore';
  import { useModalsStore } from '@/stores/modalsStore';
  import { usePageStore } from '@/stores/pageStore';
  import { type Game, GameTermination, type Rating, Result, Side } from '@/types/apitypes';
  import { ImageType } from '@/types/internaltypes';
  import { track } from '@/util/tracking';
  import { dateToDateMonthWithOptionalYear, getFlagUrl } from '@/util/util';

  const router = useRouter();
  // const gs = useGameStore();
  const bs = useBotsStore();
  const ps = usePageStore();
  const ms = useModalsStore();

  defineProps<{
    games: Game[];
  }>();

  function getRatingChangeNumber(ratingChange: {
    potential: {
      win: number;
      draw: number;
      loss: number;
    };
    old?: Rating;
    new?: Rating;
  }) {
    if (ratingChange.old == null || ratingChange.new == null) {
      return 0;
    }
    return Math.round(ratingChange.new.rating - ratingChange.old.rating);
  }

  function resultData(
    result: Result | null,
    userSide: Side
  ): { message: string; color: string; userWon: boolean } {
    if (result == null) {
      return { message: '', color: '#f00', userWon: false };
    } else if (result === Result.Draw) {
      return { message: 'Draw', color: '#ccc', userWon: false };
    } else if (
      (result === Result.White && userSide === Side.White) ||
      (result === Result.Black && userSide === Side.Black)
    ) {
      return { message: 'Win', color: '#469C14', userWon: true };
    } else {
      return { message: 'Loss', color: '#9C2614', userWon: false };
    }
  }

  function gotoAnalysis(gameId: string) {
    router.push({
      name: 'analysis',
      query: { id: gameId },
    });
  }

  function gotoGame(gameId: string) {
    router.push({
      name: 'game',
      query: { id: gameId, type: 'continue' },
    });
  }
</script>

<style scoped>
  .table th,
  .table td {
    vertical-align: middle;
  }

  .to-game-button {
    width: 4.5rem;
  }

  .to-game-button-ongoing {
    background-color: white;
  }

  .to-game-button-ongoing:hover {
    color: white;
  }
</style>
