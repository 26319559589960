import type { Bot, Rating } from '@/types/apitypes.js';

export function isProvisionalRating(rating: Rating): boolean {
  return rating.ratingDeviation > 110;
}

export function getClosestBot(
  rating: number,
  bots: { [key: string]: Bot },
  blacklistBotIds: string[] = []
) {
  // Perhaps not the quickest way to do this, but quick enough
  let closestBot: Bot | undefined;
  let closestDifference = Infinity;

  for (const id in bots) {
    const bot = bots[id];
    if (blacklistBotIds.includes(bot.id)) {
      continue;
    }
    const difference = Math.abs(bot.strength.estimated_elo - rating);
    if (difference < closestDifference) {
      closestBot = bot;
      closestDifference = difference;
    }
  }

  return closestBot;
}

export function normalDistribution(mean: number, stdDev = 50): number {
  // This will return a random number near the given mean
  // Normal distribution works like this:
  // +- 1 stdDev: 68.27%
  // +- 2 stdDev: 95.45%
  // +- 3 stdDev: 99.73%
  // So given 1500 mean and stDev 50, it will return a number between 1450 and 1550 68.27% of the time. Between 1400-1450 and 1550-1600 27.18% of the time and so on.

  // We could cap it so never get a result outside of say 200 from the mean, but on the other hand I think it can be fun, and it should be super rare.

  let u = 0,
    v = 0;
  while (u === 0) u = Math.random(); // Converting [0,1) to (0,1)
  while (v === 0) v = Math.random();
  const z = Math.sqrt(-2.0 * Math.log(u)) * Math.cos(2.0 * Math.PI * v);

  return mean + stdDev * z;
}
