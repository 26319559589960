<template>
  <div class="activity-calendar-container">
    <h3 class="calendar-title">
      <i class="fa-solid fa-calendar-check" style="color: var(--clr-accent)" />
      Activity Calendar
    </h3>
    <div v-if="!loading" class="calendar-wrapper">
      <div class="month-navigation">
        <button @click="previousMonth" class="nav-button">
          <i class="fa-solid fa-chevron-left" />
        </button>
        <div class="month-label">{{ currentMonthLabel }}</div>
        <button @click="nextMonth" class="nav-button" :disabled="isCurrentMonth">
          <i class="fa-solid fa-chevron-right" />
        </button>
      </div>
      <div class="calendar-grid">
        <div v-for="day in DAYS_OF_WEEK" :key="day" class="day-label">
          {{ day }}
        </div>
        <div
          v-for="(cell, index) in calendarCells"
          :key="index"
          class="calendar-cell"
          :class="[
            activityLevelClass(cell.activity),
            { 'has-activity': cell.activity > 0 },
            { today: cell.isToday },
            { 'empty-cell': !cell.date },
          ]"
          v-tippy="
            cell.date
              ? {
                  content:
                    formatDate(cell.date || '') +
                    ': ' +
                    cell.activity +
                    (cell.activity === 1
                      ? ' ' + TEXT_FORMATTING.SINGULAR_GAME
                      : ' ' + TEXT_FORMATTING.PLURAL_GAMES),
                }
              : {}
          "
        >
          <span v-if="cell.date" class="cell-date">{{ new Date(cell.date || '').getDate() }}</span>
        </div>
      </div>
    </div>
    <div v-else class="calendar-placeholder pulsate-load" />
    <div class="activity-legend">
      <div class="legend-item" v-tippy="{ content: 'Days with no chess games' }">
        <div class="legend-color activity-none" />
        <span>0 {{ TEXT_FORMATTING.PLURAL_GAMES }}</span>
      </div>
      <div class="legend-item" v-tippy="{ content: 'Days with a few games' }">
        <div class="legend-color activity-low" />
        <span>{{ legendLabels.low }}</span>
      </div>
      <div class="legend-item" v-tippy="{ content: 'Days with moderate activity' }">
        <div class="legend-color activity-medium" />
        <span>{{ legendLabels.medium }}</span>
      </div>
      <div class="legend-item" v-tippy="{ content: 'Days with high activity' }">
        <div class="legend-color activity-high" />
        <span>{{ legendLabels.high }}</span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { computed, ref } from 'vue';

  import { TEXT_FORMATTING } from '@/constants';
  import type { FirestoreTimestamp } from '@/types/internaltypes';

  // Define days of week starting with Monday
  const DAYS_OF_WEEK = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

  interface GameDayData {
    game_date: FirestoreTimestamp;
    games_played: number;
  }

  const props = defineProps({
    loading: {
      type: Boolean,
      default: true,
    },
    gamesPerDay: {
      type: Array as () => Array<GameDayData>,
      default: () => [],
    },
  });

  // Dynamic legend labels based on activity normalization
  const legendLabels = computed(() => {
    const max = maxMonthlyActivity.value;

    if (max <= 6) {
      // Fixed thresholds for users with few games
      return {
        low: '1-2 ' + TEXT_FORMATTING.PLURAL_GAMES,
        medium: '3-5 ' + TEXT_FORMATTING.PLURAL_GAMES,
        high: '6+ ' + TEXT_FORMATTING.PLURAL_GAMES,
      };
    } else {
      // Normalized thresholds for users with many games
      const lowThreshold = Math.ceil(max * 0.25);
      const mediumThreshold = Math.ceil(max * 0.75);

      return {
        low:
          lowThreshold === 1
            ? '1 ' + TEXT_FORMATTING.SINGULAR_GAME
            : `1-${lowThreshold} ${TEXT_FORMATTING.PLURAL_GAMES}`,
        medium: `${lowThreshold + 1}-${mediumThreshold} ${TEXT_FORMATTING.PLURAL_GAMES}`,
        high: `${mediumThreshold + 1}+ ${TEXT_FORMATTING.PLURAL_GAMES}`,
      };
    }
  });

  // Track the selected month offset (0 = current month, -1 = previous month, etc.)
  const monthOffset = ref(0);

  // Get the date for the currently selected month
  const selectedDate = computed(() => {
    const now = new Date();
    return new Date(now.getFullYear(), now.getMonth() + monthOffset.value, 1);
  });

  // Check if we're viewing the current month
  const isCurrentMonth = computed(() => monthOffset.value === 0);

  // Format the month and year for display
  const currentMonthLabel = computed(() => {
    return selectedDate.value.toLocaleString('default', {
      month: 'long',
      year: 'numeric',
    });
  });

  // Navigation functions
  function previousMonth() {
    monthOffset.value--;
    // Navigated to previous month
    return getActivityDataForCurrentMonth();
  }

  function nextMonth() {
    if (monthOffset.value < 0) {
      monthOffset.value++;
      return getActivityDataForCurrentMonth();
    }
    return null;
  }

  // Helper function to get activity data for the current month
  // This function is used when navigating between months
  function getActivityDataForCurrentMonth() {
    const selectedYear = selectedDate.value.getFullYear();
    const selectedMonth = selectedDate.value.getMonth();
    const monthData: Record<string, number> = {};

    // First check if we have gamesPerDay data from the API
    if (props.gamesPerDay && props.gamesPerDay.length > 0) {
      props.gamesPerDay.forEach((dayData) => {
        if (dayData.game_date && typeof dayData.game_date === 'object') {
          // Handle Firestore timestamp format with _seconds and _nanoseconds
          if ('_seconds' in dayData.game_date) {
            const date = new Date((dayData.game_date as FirestoreTimestamp)._seconds * 1000);
            const year = date.getFullYear();
            const month = date.getMonth();
            if (year === selectedYear && month === selectedMonth) {
              const dateKey = formatDateKey(date);
              monthData[dateKey] = dayData.games_played;
            }
          }
        }
      });
    }
    return monthData;
  }

  // Computed ref to hold processed activity data from gamesPerDay
  const processedActivityData = computed(() => {
    const result: Record<string, number> = {};

    // Process gamesPerDay data if available
    if (props.gamesPerDay && props.gamesPerDay.length > 0) {
      // Use the gamesPerDay array directly since it's already defined as an array in props
      props.gamesPerDay.forEach((dayData: GameDayData) => {
        if (dayData.game_date && typeof dayData.game_date === 'object') {
          // Handle Firestore timestamp format with _seconds and _nanoseconds
          if ('_seconds' in dayData.game_date) {
            const date = new Date((dayData.game_date as FirestoreTimestamp)._seconds * 1000);
            const dateKey = formatDateKey(date);
            result[dateKey] = dayData.games_played;
          }
        }
      });
      return result;
    }

    // Return empty object if no gamesPerDay data is available
    return result;
  });

  const calendarCells = computed(() => {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());

    // Process activity data for calendar display
    // Use the selected month date
    const selectedYear = selectedDate.value.getFullYear();
    const selectedMonth = selectedDate.value.getMonth();
    const firstDayOfMonth = new Date(selectedYear, selectedMonth, 1);
    const lastDayOfMonth = new Date(selectedYear, selectedMonth + 1, 0);

    // Adjust first day to start from Monday (1) instead of Sunday (0)
    let firstDayOfWeek = firstDayOfMonth.getDay();
    // If it's Sunday (0), set it to 6 (last day of the week when Monday is first)
    // Otherwise subtract 1 to make Monday (1) become 0, Tuesday (2) become 1, etc.
    firstDayOfWeek = firstDayOfWeek === 0 ? 6 : firstDayOfWeek - 1;

    const totalDays = lastDayOfMonth.getDate();
    const cells = [];
    // Add empty cells for days before the first day of month
    for (let i = 0; i < firstDayOfWeek; i++) {
      cells.push({ date: null, activity: 0, isToday: false });
    }

    // Add cells for each day of the month
    for (let day = 1; day <= totalDays; day++) {
      const date = new Date(selectedYear, selectedMonth, day);
      const dateString = formatDateKey(date);
      const isToday = date.getTime() === today.getTime();

      // Get activity for this day from the processed activity data
      const activity = processedActivityData.value[dateString] || 0;

      // Force activity to be a number (in case it's coming as a string)
      // Using Number() is more reliable than parseInt as it handles both strings and numbers
      const activityCount =
        typeof activity === 'string' ? parseInt(activity, 10) : Number(activity);

      // Skip invalid activity counts
      if (isNaN(activityCount)) return;

      // Activity tracking for days with games
      cells.push({
        date: dateString,
        activity: activityCount, // Use the converted activity count to ensure it's a number
        isToday,
      });
    }

    return cells;
  });

  function formatDateKey(date: Date): string {
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const formattedDate = `${date.getFullYear()}-${
      month < 10 ? '0' + month : month
    }-${day < 10 ? '0' + day : day}`;
    return formattedDate;
  }

  function formatDate(dateString: string): string {
    const date = new Date(dateString);
    return date.toLocaleDateString('default', {
      weekday: 'short',
      month: 'short',
      day: 'numeric',
    });
  }

  // Find the maximum activity in the current month for normalization
  const maxMonthlyActivity = computed(() => {
    const monthData = getActivityDataForCurrentMonth();
    // Check if monthData exists and has keys
    if (!monthData || Object.keys(monthData).length === 0) {
      return 0;
    }

    // Find the maximum number of games played in a day this month
    const values = Object.values(monthData).map((val) => Number(val));
    return Math.max(...values);
  });

  function activityLevelClass(activity: number): string {
    if (activity === 0) return 'activity-none';

    const max = maxMonthlyActivity.value;
    if (max <= 6) {
      // Use fixed thresholds for users with few games
      if (activity <= 2) return 'activity-low';
      if (activity <= 5) return 'activity-medium';
      return 'activity-high';
    } else {
      // Normalize based on maximum activity
      const ratio = activity / max;
      if (ratio <= 0.25) return 'activity-low';
      if (ratio <= 0.75) return 'activity-medium';
      return 'activity-high';
    }
  }
</script>

<style scoped>
  .activity-calendar-container {
    width: 100%;
    padding: 0.5rem;
    border-radius: 8px;
    transition: all 0.3s ease;
    margin: 0 auto;
  }

  .calendar-title {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    margin-bottom: 1rem;
    color: var(--clr-text-primary);
    text-align: center;
  }

  .calendar-wrapper {
    margin-top: 0.75rem;
    background-color: var(--clr-bg-primary, #ffffff);
    border-radius: 12px;
    padding: 1rem;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
    transition: all 0.3s ease;
    max-width: 550px;
    margin-left: auto;
    margin-right: auto;
  }

  .month-navigation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
    padding: 0 0.5rem;
  }

  .month-label {
    text-align: center;
    font-weight: bold;
    flex-grow: 1;
    font-size: 1.1rem;
    color: var(--clr-text-primary);
    letter-spacing: 0.5px;
    animation: slideIn 0.3s ease-out;
  }

  .nav-button {
    background: none;
    border: 1px solid var(--clr-bg-secondary, #f0f0f0);
    color: var(--clr-text-primary);
    cursor: pointer;
    font-size: 0.9rem;
    padding: 0.4rem 0.7rem;
    border-radius: 6px;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .nav-button:hover:not(:disabled) {
    background-color: var(--clr-bg-secondary, #f0f0f0);
    transform: translateY(-1px);
  }

  .nav-button:active:not(:disabled) {
    transform: translateY(0);
  }

  .nav-button:disabled {
    color: var(--clr-text-disabled, #888);
    cursor: not-allowed;
    opacity: 0.6;
  }

  .calendar-grid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 0.35rem;
    padding: 0.75rem;
    background-color: var(--clr-bg-secondary, #f9f9f9);
    border-radius: 10px;
    box-shadow: inset 0 1px 4px rgba(0, 0, 0, 0.05);
  }

  .day-label {
    text-align: center;
    font-size: 0.85rem;
    font-weight: 600;
    color: var(--clr-text-secondary, #666);
    padding: 0.35rem 0;
    margin-bottom: 0.25rem;
  }

  .calendar-cell {
    aspect-ratio: 1;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--clr-bg-tertiary, #f5f5f5);
    position: relative;
    transition: all 0.2s ease;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
    animation: fadeIn 0.5s ease-out;
  }

  .calendar-cell:hover:not(.empty-cell) {
    transform: translateY(-2px);
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  }

  .cell-date {
    font-size: 0.8rem;
    position: absolute;
    top: 3px;
    left: 5px;
    color: var(--clr-text-secondary, #666);
    font-weight: 500;
  }

  .empty-cell {
    background-color: transparent;
    box-shadow: none;
  }

  .has-activity {
    cursor: pointer;
  }

  .activity-none {
    background-color: var(--clr-bg-tertiary, #f5f5f5);
  }

  .activity-low {
    background-color: var(--clr-activity-low, #d6eaff);
  }

  .activity-medium {
    background-color: var(--clr-activity-medium, #99c9ff);
  }

  .activity-high {
    background-color: var(--clr-activity-high, #4a9eff);
    color: white;
  }

  .activity-high .cell-date {
    color: rgba(255, 255, 255, 0.9);
  }

  .today {
    border: 2px solid var(--clr-accent);
    box-shadow: 0 0 0 1px rgba(var(--clr-accent-rgb, 74, 158, 255), 0.3);
  }

  .calendar-placeholder {
    height: 200px;
    background-color: var(--clr-bg-secondary, #f0f0f0);
    border-radius: 8px;
    margin-top: 1rem;
  }

  .activity-legend {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1rem;
    margin-top: 1.25rem;
    padding: 0.75rem;
    background-color: var(--clr-bg-secondary, #f9f9f9);
    border-radius: 10px;
    max-width: 550px;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
  }

  .legend-item {
    display: flex;
    align-items: center;
    gap: 0.35rem;
    font-size: 0.85rem;
    color: var(--clr-text-secondary, #666);
    padding: 0.25rem 0.5rem;
    border-radius: 4px;
    transition: background-color 0.2s ease;
  }

  .legend-item:hover {
    background-color: var(--clr-bg-tertiary, #f0f0f0);
  }

  .legend-color {
    width: 14px;
    height: 14px;
    border-radius: 3px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  }

  .pulsate-load {
    animation: pulsate 1.5s ease-in-out infinite;
  }

  @keyframes pulsate {
    0% {
      opacity: 0.6;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0.6;
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(4px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes slideIn {
    from {
      opacity: 0;
      transform: translateX(-10px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @media (max-width: 768px) {
    .calendar-grid {
      gap: 0.25rem;
      padding: 0.35rem;
    }
    .day-label {
      font-size: 0.75rem;
      padding: 0.25rem 0;
    }
    .cell-date {
      font-size: 0.7rem;
      top: 2px;
      left: 3px;
    }
    .activity-legend {
      gap: 0.5rem;
      padding: 0.35rem;
    }
    .legend-item {
      font-size: 0.75rem;
      padding: 0.15rem 0.35rem;
    }
    .legend-color {
      width: 12px;
      height: 12px;
    }
  }
</style>
