<template>
  <div style="padding: 0.5rem; display: flex; justify-content: start; flex-direction: column">
    <div class="title">
      <div class="btn-group" role="group">
        <!-- Timed Button -->
        <input
          type="radio"
          class="btn-check inactive"
          name="rated-settings"
          id="rated-settings-rated"
          autocomplete="off"
          value="rated"
          v-model="selected"
        />
        <label class="btn inactive btn-outline-info" for="rated-settings-rated"
          ><i class="fa-duotone fa-arrow-trend-up" /> Rated</label
        >

        <!-- Untimed Button -->
        <input
          type="radio"
          class="btn-check inactive"
          name="rated-settings"
          id="rated-settings-unrated"
          autocomplete="off"
          value="unrated"
          v-model="selected"
        />
        <label class="btn inactive btn-outline-info" for="rated-settings-unrated"
          ><i class="fa-duotone fa-flask-round-potion" /> Unrated</label
        >
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
  import { type PropType, nextTick, onUnmounted, ref, watch } from 'vue';

  import PostHog from 'posthog-js';

  const props = defineProps({
    initial: {
      type: String as PropType<'rated' | 'unrated' | null>,
      required: true,
    },
  });

  const selected = ref(getInitialRating());

  const emit = defineEmits(['update']);

  watch(
    () => selected.value,
    () => {
      emit('update', selected.value);
    }
  );

  function getInitialRating() {
    // If we have a saved value in props.initial, use that
    if (props.initial == 'rated' || props.initial == 'unrated') {
      // Ensure we emit the saved value to persist it
      nextTick(() => {
        emit('update', props.initial);
      });
      return props.initial;
    }

    // Otherwise, default to rated
    const initial = 'rated';

    // Emit the update to save to localStorage
    nextTick(() => {
      emit('update', initial);
    });

    return initial;
  }
</script>

<style scoped>
  .inactive:not(:checked) + .inactive {
    background-color: var(--clr-main-lightest);
  }

  .title {
    display: flex;
    gap: 0.2rem;
    justify-content: start;
  }

  .time-container {
    display: flex;
    gap: 0.2rem;
    justify-content: start;
  }

  .time-container input {
    width: 4ch;
  }
</style>
