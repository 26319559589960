import { useBotsStore } from '@/stores/botStore';

/**
 * Composable for calculating Slip-Up statistics
 * Slip-Up refers to losses against bots rated 200+ points lower than the player
 */
export function useSlipUpStats() {
  /**
   * Calculate Slip-Up loss rate (losses against bots -200 below player's rating)
   * @param validBots Array of bots with game statistics
   * @param userRating The current rating of the user
   * @returns The slip-up loss rate as a decimal
   */
  const calculateSlipUpStats = (validBots: any[], userRating: number) => {
    // Filter bots that are 200+ points below the player's rating
    const slipUpBots = validBots.filter((bot: any) => {
      const botRating = useBotsStore().getBot(bot.bot_id)?.strength?.estimated_elo || 0;
      return botRating <= userRating - 200 && bot.games > 0;
    });

    // Calculate total games and losses against much lower rated bots
    const slipUpLosses = slipUpBots.reduce((total: number, bot: any) => total + bot.losses, 0);
    const slipUpGames = slipUpBots.reduce((total: number, bot: any) => total + bot.games, 0);

    // Calculate loss rate against much lower rated bots
    const slipUpLossRate = slipUpGames > 0 ? slipUpLosses / slipUpGames : 0;

    return {
      slipUpLossRate,
      slipUpLosses,
      slipUpGames,
    };
  };

  return {
    calculateSlipUpStats,
  };
}
