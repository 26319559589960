import type { Ref } from 'vue';

import type { UserStatsResponseData } from '@/types/apitypes';

/**
 * Composable for game statistics calculations
 * Provides functions to calculate win rates for different game scenarios
 */
export function useGameStats(
  userStatsResponse: Ref<UserStatsResponseData | undefined>,
  ratingHistory: Ref<any | undefined>
) {
  /**
   * Calculate win rate for short games (under 20 moves)
   * @returns Formatted win rate percentage or 'N/A' if no data
   */
  const getShortGamesWinRate = () => {
    let total = 0;
    let wins = 0;

    // Process games from userStatsResponse and ratingHistory
    const processGames = (games: any[]) => {
      if (!games || !Array.isArray(games)) return;

      games.forEach((game) => {
        let moveCount = 0;

        // Assuming an average of 1 move per 30 seconds (adjust as needed)
        if (game.started_at && game.ended_at) {
          try {
            let startTime, endTime;

            // Parse started_at
            if (typeof game.started_at === 'string') {
              startTime = new Date(game.started_at).getTime();
            } else if (typeof game.started_at === 'object' && game.started_at.value) {
              startTime = new Date(game.started_at.value).getTime();
            }

            // Parse ended_at
            if (typeof game.ended_at === 'string') {
              endTime = new Date(game.ended_at).getTime();
            } else if (typeof game.ended_at === 'object' && game.ended_at.value) {
              endTime = new Date(game.ended_at.value).getTime();
            }

            if (startTime && endTime) {
              // Calculate duration in seconds
              const durationSeconds = (endTime - startTime) / 1000;
              // Estimate move count (1 move per 30 seconds on average)
              moveCount = Math.round(durationSeconds / 30);

              // Ensure we have at least 1 move if the game was played
              if (moveCount < 1 && durationSeconds > 0) {
                moveCount = 1;
              }
            }
          } catch (error) {
            console.error('Error processing game:', error);
          }
        }
        // Check if it's a short game (under 20 moves)
        if (moveCount > 0 && moveCount < 20) {
          total++;

          if (game.result_user === 'win') {
            wins++;
          }
        }
      });
    };

    // Process games from different sources
    if (userStatsResponse.value && userStatsResponse.value.gameResults) {
      try {
        const results = JSON.parse(userStatsResponse.value.gameResults);
        processGames(results);
      } catch (error) {
        console.error('Error parsing gameResults for short games:', error);
      }
    }

    // Process rating history if available
    if (ratingHistory.value) {
      if (ratingHistory.value.daily) processGames(ratingHistory.value.daily);
      if (ratingHistory.value.monthly) processGames(ratingHistory.value.monthly);
      if (ratingHistory.value.all) processGames(ratingHistory.value.all);
      if (ratingHistory.value.rawStats) {
        processGames(ratingHistory.value.rawStats);
      }
    }

    // Calculate and return win rate
    return total > 0 ? `${((wins / total) * 100).toFixed(1)}%` : 'N/A';
  };

  /**
   * Calculate win rate for long games (over 50 moves)
   * @returns Formatted win rate percentage or 'N/A' if no data
   */
  const getLongGamesWinRate = () => {
    let total = 0;
    let wins = 0;

    // Process games from userStatsResponse and ratingHistory
    const processGames = (games: any[]) => {
      if (!games || !Array.isArray(games)) return;

      games.forEach((game) => {
        let moveCount = 0;

        // Assuming an average of 1 move per 30 seconds (adjust as needed)
        if (game.started_at && game.ended_at) {
          try {
            let startTime, endTime;

            // Parse started_at
            if (typeof game.started_at === 'string') {
              startTime = new Date(game.started_at).getTime();
            } else if (typeof game.started_at === 'object' && game.started_at.value) {
              startTime = new Date(game.started_at.value).getTime();
            }

            // Parse ended_at
            if (typeof game.ended_at === 'string') {
              endTime = new Date(game.ended_at).getTime();
            } else if (typeof game.ended_at === 'object' && game.ended_at.value) {
              endTime = new Date(game.ended_at.value).getTime();
            }

            if (startTime && endTime) {
              const durationSeconds = (endTime - startTime) / 1000;
              // Estimate move count (1 move per 30 seconds on average)
              moveCount = Math.round(durationSeconds / 30);

              // Ensure we have at least 1 move if the game was played
              if (moveCount < 1 && durationSeconds > 0) {
                moveCount = 1;
              }
            }
          } catch (error) {
            console.error('Error processing game:', error);
          }
        }
        // Check if it's a long game (over 50 moves)
        if (moveCount > 50) {
          total++;

          // Check game result
          if (game.result_user === 'win') {
            wins++;
          }
        }
      });
    };

    // Process games from different sources
    if (userStatsResponse.value && userStatsResponse.value.gameResults) {
      try {
        const results = JSON.parse(userStatsResponse.value.gameResults);
        processGames(results);
      } catch (error) {
        console.error('Error parsing gameResults for long games:', error);
      }
    }

    // Process rating history if available
    if (ratingHistory.value) {
      if (ratingHistory.value.daily) processGames(ratingHistory.value.daily);
      if (ratingHistory.value.monthly) processGames(ratingHistory.value.monthly);
      if (ratingHistory.value.all) processGames(ratingHistory.value.all);
      if (ratingHistory.value.rawStats) {
        processGames(ratingHistory.value.rawStats);
      }
    }

    // Calculate and return win rate
    return total > 0 ? `${((wins / total) * 100).toFixed(1)}%` : 'N/A';
  };

  /**
   * Calculate win rates for games played as white and black
   * @returns Formatted string with white and black win rates or error message
   */
  const getWhiteBlackWinRate = () => {
    let whiteTotal = 0;
    let whiteWins = 0;
    let blackTotal = 0;
    let blackWins = 0;

    // Process games from userStatsResponse
    if (userStatsResponse.value && userStatsResponse.value.gameResults) {
      try {
        const results = JSON.parse(userStatsResponse.value.gameResults);
        if (results && Array.isArray(results)) {
          results.forEach((game) => {
            // Check if the game has user_side information (0 = white, 1 = black)
            if (game.user_side !== undefined) {
              if (game.user_side === '0') {
                whiteTotal++;
                if (game.result_user === 'win') {
                  whiteWins++;
                }
              } else if (game.user_side === '1') {
                blackTotal++;
                if (game.result_user === 'win') {
                  blackWins++;
                }
              }
            }
          });
        }
      } catch (error) {
        console.error('Error parsing gameResults for white/black win rate:', error);
        return 'Error parsing data';
      }
    }

    // If no games were found with color information
    if (whiteTotal === 0 && blackTotal === 0) {
      return 'No color data';
    }

    // Calculate win rates
    const whiteWinRate = whiteTotal > 0 ? (whiteWins / whiteTotal) * 100 : 0;
    const blackWinRate = blackTotal > 0 ? (blackWins / blackTotal) * 100 : 0;

    // Return formatted string with both win rates
    return `${whiteWinRate.toFixed(1)}% ${blackWinRate.toFixed(1)}%`;
  };

  return {
    getShortGamesWinRate,
    getLongGamesWinRate,
    getWhiteBlackWinRate,
  };
}
