<template>
  <div
    style="
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      width: 100%;
      max-height: 40vh;
      overflow-y: auto;
    "
  >
    <h2 style="font-size: 1.3rem">Key Positions</h2>
    <div
      v-for="keyPosition in cs.courseInfo?.keyPositions"
      :key="keyPosition.fen"
      style="
        display: flex;
        gap: 1rem;
        justify-content: start;
        border-bottom: 1px solid var(--clr-main-lightest);
        padding: 0.5rem 0 1rem 0;
      "
    >
      <TheChessboard
        :board-config="{
          fen: keyPosition.fen,
          blockTouchScroll: false,
          orientation: 'black',
          coordinates: false,
          viewOnly: true,
        }"
        @click="useCourseStore().navigateToFen(keyPosition.fen, 'main')"
        style="width: 7rem; height: 7rem"
      />
      <div
        style="
          display: flex;
          flex-direction: column;
          gap: 0.2rem;
          justify-content: space-between;
          flex-grow: 1;
        "
      >
        <span>{{
          (keyPosition as CourseKeyPosition).comment == ''
            ? placeholderComment
            : (keyPosition as CourseKeyPosition).comment
        }}</span>
        <div>
          <button
            class="btn btn-info small-button"
            @click="useCourseStore().navigateToFen(keyPosition.fen, 'main')"
          >
            <i class="fa-solid fa-circle-up" /> Set Position
          </button>
        </div>
      </div>
      <div
        style="
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
          justify-content: space-between;
          padding-right: 1rem;
        "
      >
        <div>
          <div>Played: 0 times</div>
          <div>Best win: -</div>
        </div>
        <button
          class="btn btn-info small-button"
          @click="useCourseStore().navigateToFen(keyPosition.fen, 'main')"
        >
          <i class="fa-solid fa-swords" /> Play
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { TheChessboard } from 'vue3-chessboard';

  import router from '@/router';
  import { useCourseStore } from '@/stores/courseStore';
  import type { CourseKeyPosition } from '@/types/apitypes';

  const cs = useCourseStore();

  const placeholderComment = 'Position description coming soon...';
</script>

<style scoped></style>
