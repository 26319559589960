<template>
  <div :class="'bot-and-name' + (bot == null ? ' pulsate-load-strong' : '')">
    <div style="position: relative">
      <span
        v-if="locked"
        :style="{
          fontSize: imageSize / 3 + 'rem',
          textAlign: 'center',
          color: 'white',
          top: imageSize / 3 + 'rem',
          left: '0',
          right: '0',
          position: 'absolute',
          zIndex: 1,
          opacity: 0.8,
        }"
      >
        <i class="fa-solid fa-lock" />
      </span>

      <img
        alt="Bot profile image"
        class="profile-image-circle"
        :style="{
          background: bot ? 'url(' + ps.img(bot.id, ImageType.BotBackground, '20') + ')' : '',
          backgroundColor: borderColor,
          backgroundSize: 'cover',
          border: '2px solid ' + borderColor,
          '--size': imageSize + 'rem',
          filter: locked ? 'saturate(30%) opacity(50%)' : '',
        }"
        :src="
          bot
            ? ps.img(bot.id, ImageType.BotProfile, '175')
            : 'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs='
        "
      />
      <div style="z-index: 100" v-if="bot != null && bot.user">
        <img
          alt="Golden star"
          v-if="!locked && bot.user?.hasWon"
          :style="{
            height: imageSize * 0.3 + 'rem',
            top: '0rem',
            right: '0rem',
            position: 'absolute',
            opacity: 1,
          }"
          src="https://storage.googleapis.com/chessiverse1/images/elements/star.webp
              "
        />
        <img
          alt="Golden star outline"
          v-else-if="!locked"
          :style="{
            height: imageSize * 0.3 + 'rem',
            top: '0rem',
            right: '0rem',
            position: 'absolute',
            opacity: 0.9,
          }"
          src="https://storage.googleapis.com/chessiverse1/images/elements/star_grey.webp
              "
        />
      </div>
    </div>
    <div
      v-if="showPlaque"
      class="card profile-name-text"
      :style="{
        backgroundColor: bot == null ? '#bbb' : bot.config.boardbg,
        zIndex: 10,
        whiteSpace: 'nowrap',
        fontSize: fontSize,
      }"
    >
      {{ getPlaqueText() }}
    </div>
  </div>
</template>
<script setup lang="ts">
  import type { PropType } from 'vue';

  import { usePageStore } from '@/stores/pageStore';
  import type { Bot } from '@/types/apitypes';
  import { ImageType } from '@/types/internaltypes';

  const props = defineProps({
    bot: { type: Object as PropType<Bot | null | undefined>, required: true },
    imageSize: { type: Number, default: 6 },
    fontSize: { type: String, default: '1rem' },
    showPlaque: { type: Boolean, default: true },
    showRating: { type: Boolean, default: true },
    borderColor: { type: String, default: '#ddd' },
    locked: { type: Boolean, default: false },
  });

  const ps = usePageStore();

  function getPlaqueText() {
    // We know bot exists at this point since the card is hidden when bot is null
    let text = props.bot?.short_name || '';

    if (props.showRating && props.bot?.strength?.estimated_elo) {
      text += ' (' + props.bot.strength.estimated_elo + ')';
    }

    return text;
  }
</script>
<style scoped>
  .profile-image-circle {
    width: var(--size);
    height: var(--size);
    z-index: 10;
    border-radius: 50%;
    border: 1.5px solid #444;
  }

  .profile-name-text {
    position: absolute;
    bottom: -1rem;
    padding: 0 0.5rem;
  }

  .bot-and-name {
    position: relative;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    z-index: 100;
  }
</style>
