import type { TimeControl } from '@/types/apitypes';

export function getTimeCategory(timeControl: TimeControl): string {
  const totalTimeMillis = timeControl.initial + 40 * timeControl.increment; // Standard is to count a games as 40 moves to get the total time

  if (totalTimeMillis < 1000 * 60 * 3) {
    // Less than 3 minutes
    return 'Bullet';
  } else if (totalTimeMillis <= 1000 * 60 * 10) {
    // Less than or equal to 10 minutes
    return 'Blitz';
  } else if (totalTimeMillis < 1000 * 60 * 60) {
    // Less than or equal to 1 hour
    return 'Rapid';
  } else {
    return 'Classical';
  }
}
