<template>
  <div style="display: flex; flex-direction: column; gap: 0.5rem">
    <!-- Video player -->
    <div
      v-if="usePageStore().courseSettings.tabs.videos.selectedVideo != null"
      style="display: flex; gap: 1rem"
    >
      <div style="width: 100%; max-height: 20rem; aspect-ratio: 16/9; position: relative">
        <iframe
          ref="vimeoPlayer"
          id="vimeo-player"
          :src="`https://player.vimeo.com/video/${usePageStore().courseSettings.tabs.videos.selectedVideo!.video.externalId}?autoplay=1&badge=0&player_id=0&app_id=58479`"
          frameborder="0"
          allow="fullscreen; picture-in-picture; clipboard-write; encrypted-media"
          style="width: 100%; height: 100%"
        />
      </div>
      <div
        style="
          display: flex;
          flex-direction: column;
          white-space: nowrap;
          justify-content: space-between;
        "
      >
        <div>
          <button
            v-if="
              usePageStore().courseSettings.tabs.videos.selectedVideo?.video.type == 'gameanalysis'
            "
            class="btn btn-info small-button"
            @click="
              useCourseStore().navigateToUid(
                null,
                'video',
                usePageStore().courseSettings.tabs.videos.selectedVideo?.video.id
              )
            "
          >
            <i class="fa-solid fa-circle-up" /> Load Game
          </button>
        </div>
        <div>
          <button
            class="btn btn-info small-button"
            @click="usePageStore().courseSettings.tabs.videos.selectedVideo = null"
          >
            <i class="fa-solid fa-xmark-circle" /> Close Video
          </button>
        </div>
      </div>
    </div>

    <!-- Videos list -->
    <div v-else>
      <h2 style="font-size: 1.3rem">
        {{
          usePageStore().courseSettings.tabs.videos.filterByPosition
            ? 'Matching videos'
            : 'All videos'
        }}
      </h2>
      <span v-if="groupedVideos.length === 0" style="color: var(--clr-main-lighter)"
        >No matching games</span
      >
      <div
        v-else
        style="
          width: 100%;
          max-height: 18rem;
          overflow: auto;
          display: flex;
          flex-wrap: wrap;
          gap: 1rem;
          align-items: flex-start;
        "
      >
        <template v-for="(item, index) in groupedVideos" :key="index">
          <!-- Category header -->
          <span
            v-if="item.header"
            style="width: 100%; font-weight: bold; font-size: 1rem; color: var(--clr-main)"
          >
            {{ item.header }}
          </span>
          <!-- Video item -->
          <div
            v-else-if="item.video"
            style="display: flex; flex-direction: column; gap: 0.5rem; width: 18rem"
          >
            <img
              class="video-thumbnail"
              style="width: 100%; aspect-ratio: 16/9"
              :src="`https://storage.googleapis.com/chessiverse1/courses/${cs.courseInfo!.id}/videos/${item.video.id}.jpg`"
              @click="
                usePageStore().courseSettings.tabs.videos.selectedVideo = {
                  video: item.video,
                  playedSeconds: 0,
                }
              "
            />
            <div style="display: flex; flex-direction: column; gap: 0.2rem">
              <span style="color: var(--clr-main)">{{ item.video.title }}</span>
              <span style="color: var(--clr-main-lighter)">{{ item.video.description }}</span>
            </div>
          </div>
        </template>
      </div>
      <div
        style="
          font-size: 0.8rem;
          display: flex;
          align-items: center;
          gap: 0.5rem;
          justify-content: end;
        "
        v-tippy="{
          content: `Only show games that contains FEN: ${selectedFen}`,
        }"
      >
        <span>Only Current Position</span>
        <Toggle
          v-model="usePageStore().courseSettings.tabs.videos.filterByPosition"
          onLabel=""
          offLabel=""
          class="ph-no-capture"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { computed, onMounted, ref, watch } from 'vue';

  import Toggle from '@vueform/toggle';

  import { useCourseStore } from '@/stores/courseStore';
  import { usePageStore } from '@/stores/pageStore';
  import type { CourseVideo } from '@/types/apitypes';
  import { STARTING_FEN_SHORT, shortFen } from '@/util/util';

  // Load Vimeo Player API on mount
  const vimeoPlayer = ref(null);

  onMounted(() => {
    // Create and load the Vimeo Player API script
    const script = document.createElement('script');
    script.src = 'https://player.vimeo.com/api/player.js';
    script.async = true;
    script.onload = () => {
      // Now that the script is loaded, ensure our iframe exists
      if (vimeoPlayer.value) {
        // @ts-ignore
        const player = new Vimeo.Player(vimeoPlayer.value);

        // Retrieve stored time from localStorage
        const storedTime = usePageStore().courseSettings.tabs.videos.selectedVideo!.playedSeconds;
        if (storedTime) {
          player.setCurrentTime(storedTime).catch((error: any) => {
            console.error('Error setting the video time:', error);
          });
        }

        // Listen for timeupdate events to update localStorage
        player.on('timeupdate', (data: any) => {
          if (usePageStore().courseSettings.tabs.videos.selectedVideo) {
            usePageStore().courseSettings.tabs.videos.selectedVideo!.playedSeconds = data.seconds;
          }
        });
      } else {
        console.error('Vimeo player element not found.');
      }
    };
    document.body.appendChild(script);
  });

  const cs = useCourseStore();

  // Compute the current FEN or fallback to starting position
  const selectedFen = computed(() =>
    cs.selectedNode?.fen == null ? STARTING_FEN_SHORT : shortFen(cs.selectedNode.fen)
  );

  const matchingVideos = ref<{ uids: number[]; video: CourseVideo }[]>([]);

  // Update matching games whenever selectedFen or filterByPosition changes
  watch(
    () => [selectedFen.value, usePageStore().courseSettings.tabs.videos.filterByPosition],
    () => {
      updateMatchingGames();
    },
    { immediate: true }
  );

  function updateMatchingGames() {
    matchingVideos.value = [];

    const key = usePageStore().courseSettings.tabs.videos.filterByPosition
      ? shortFen(selectedFen.value)
      : STARTING_FEN_SHORT;
    const matches = cs.explorer[key];

    if (matches != null && cs.courseInfo != null) {
      matchingVideos.value = matches
        .filter((video) => video.type === 'video')
        .map((video) => ({
          uids: video.uids,
          video: cs.getCourseVideoById(video.id),
        }))
        .filter((v) => v.video != null) as {
        uids: number[];
        video: CourseVideo;
      }[];
    }
  }

  // Group videos: first without category, then by category (sorted)
  const groupedVideos = computed(() => {
    const group: Array<{ header?: string; video?: CourseVideo }> = [];

    // Add videos without a category
    const noCategory = matchingVideos.value.filter((item) => !item.video.category);
    if (noCategory.length > 0) {
      group.push(...noCategory.map((item) => ({ video: item.video })));
    }

    // Group videos that have a category
    const categorized: Record<string, CourseVideo[]> = {};
    matchingVideos.value
      .filter((item) => item.video.category)
      .forEach((item) => {
        const cat = item.video.category as string;
        if (!categorized[cat]) categorized[cat] = [];
        categorized[cat].push(item.video);
      });

    // Sort category names alphabetically and add a header before each group
    Object.keys(categorized)
      .sort((a, b) => a.localeCompare(b))
      .forEach((cat) => {
        group.push({ header: cat });
        categorized[cat].forEach((video) => {
          group.push({ video });
        });
      });

    return group;
  });
</script>

<style scoped>
  /* Table styles (if needed elsewhere) */
  .table td:hover {
    background-color: var(--clr-main-lightest);
  }

  .table th,
  .table td {
    text-align: left;
    border: 1px solid #dee2e6;
    padding: 0;
  }

  .table th.col-auto,
  .table td.col-auto {
    width: 1%;
    white-space: nowrap;
  }

  .table th.col,
  .table td.col {
    width: 100%;
  }

  /* Video thumbnail styles */
  .video-thumbnail {
    cursor: pointer;
    border-radius: 8px;
  }

  .video-thumbnail:hover {
    box-shadow: 0 0 2px 2px var(--clr-main);
    border-radius: 0;
  }
</style>
