<template>
  <div
    v-show="text"
    class="card information-container bounce-top"
    style="--bs-card-border-color: var(--clr-accent); margin-bottom: 1.5rem"
  >
    <div class="main">
      <div>
        <span v-html="text" />
      </div>
      <div>
        <a
          role="button"
          class="ph-no-capture"
          @click="
            () => {
              dismissBanner();
              track('dashboard', 'dismiss_banner', 'click');
            }
          "
          ><i class="fa-regular fa-xmark-circle close-x"
        /></a>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ref } from 'vue';

  import { useGeneralStore } from '@/stores/generalStore';
  import { useUserStore } from '@/stores/userStore';
  import { track } from '@/util/tracking';

  const generalStore = useGeneralStore();
  const userStore = useUserStore();

  const text = ref('');
  const bannerId = ref('');

  generalStore.getDashboardBanner().then(async (b) => {
    // Check if there's text in the banner
    if (b == null || b.banner == null || !b.banner!.text) {
      return;
    }

    // Check if the user already dismissed this banner
    if (localStorage.getItem('dismissedBanner') == b.banner!.id) {
      return;
    }

    bannerId.value = b.banner!.id;

    text.value = b.banner!.text;
  });

  function dismissBanner() {
    localStorage.setItem('dismissedBanner', bannerId.value);
    text.value = '';
  }
</script>

<style scoped>
  .main {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
  }

  .information-container {
    width: 100%;
    padding: 0.5rem 0.5rem 0.5rem 2rem;
  }

  .close-x {
    color: var(--clr-accent);
  }

  .close-x:hover {
    color: var(--clr-accent2);
  }
</style>
