<template>
  <div class="countries-dropdown">
    <button
      class="btn btn-outline-secondary dropdown-toggle"
      type="button"
      id="countriesDropdownMenuButton"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    >
      <div class="selected-value">
        <img
          v-if="selectedCountry.flagSvgUrl"
          :src="selectedCountry.flagSvgUrl"
          width="20"
          class="me-2"
          alt="countryFlag"
        />
        {{ selectedCountry.name || '🌍 Select a country' }}
      </div>
    </button>
    <ul class="dropdown-menu" aria-labelledby="countriesDropdownMenuButton">
      <li v-for="country in countries" :key="country.code">
        <a class="dropdown-item" @click="selectCountry(country)">
          <img :src="country.flagSvgUrl" width="20" class="me-2" alt="countryFlag" />
          {{ country.name }}
        </a>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
  import { type PropType, ref } from 'vue';

  interface Country {
    code: string;
    name: string;
    flagSvgUrl: string;
  }

  const props = defineProps({
    selectedCountry: {
      type: Object as PropType<Country>,
      required: true,
    },
  });

  const emit = defineEmits<{ updateCountry: [newCountry: Country] }>();

  const countries = ref<Country[]>([]);

  const fetchCountries = async () => {
    try {
      const res = await fetch('https://restcountries.com/v3.1/all');
      const data = await res.json();

      countries.value = data
        .map((country: any) => ({
          code: country.cca2.toLowerCase(),
          name: country.name.common,
          flagSvgUrl: country.flags.svg,
        }))
        // Sorts alphabetically
        .sort((prevCountry: Country, nextCountry: Country) =>
          prevCountry.name.localeCompare(nextCountry.name)
        );
    } catch (error) {
      console.error('Error fetching countries:', error);
    }
  };

  fetchCountries();

  const selectCountry = (country: Country) => {
    emit('updateCountry', country);
  };
</script>

<style scoped>
  .countries-dropdown {
    button {
      width: 100%;
      height: 34px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    ul {
      width: 252px;
      cursor: pointer;
      max-height: 300px;
      overflow-y: auto;
    }
  }
</style>
