// Doing this client side, yes it's possible to cheat, but anyone wanting to cheat can just boot up an engine so
// this is good enough.
import type { Puzzle, PuzzleSolveHistory } from '@/types/apitypes';

// Takes an array of puzzles and returns a text like
// Puzzle 1 failed, Puzzle 2 correct, Puzzle 3 correct with hints, ...
// Use for chat prompts
export function getResultText(puzzles: Puzzle[]) {
  let puzzleRecord = '';

  for (let i = 0; i < puzzles.length; i++) {
    if (puzzles[i].user_result == null) {
      break;
    }

    puzzleRecord += 'Puzzle ' + (i + 1) + ' ';
    if (puzzles[i].user_result!.some((subArray) => subArray.includes('failed'))) {
      puzzleRecord += 'failed';
    } else if (
      puzzles[i].user_result!.every((subArray) =>
        subArray.every((element) => element === 'correct_move')
      )
    ) {
      puzzleRecord += 'correct';
    } else {
      puzzleRecord += 'correct with hints';
    }

    puzzleRecord += ', ';
  }

  return puzzleRecord;
}

export function calculateRemainingHintsRaw(
  solveHistories: PuzzleSolveHistory[],
  initialHints: number,
  maxHints: number
): number {
  let hints = initialHints;
  for (let i = 0; i < solveHistories.length; i++) {
    const solveHistory = solveHistories[i];
    if (solveHistory == null) {
      // If user_result is null or undefined, the calculation is done we reached the last puzzle and can return
      return hints;
    }

    let usedHintThisPuzzle = false; // Track if any hint was used in this puzzle

    for (const move of solveHistory) {
      if (move.length === 0) {
        // Reached a move that didn't have an attempt, so done with this move
        break;
      }
      for (const attempt of move) {
        if (
          attempt === 'hint1' ||
          attempt === 'hint2' ||
          attempt === 'wrong_move' ||
          attempt === 'solution'
        ) {
          // Failed so use a hint, and also remove any half hints if there were any
          hints = Math.floor(hints - 1);
          usedHintThisPuzzle = true;
        } else if (attempt === 'failed') {
          // A failed attempt doesn't cost a hint (since you can only fail if you don't have any hints)
          // but if there was a half hint available, it should be removed  since now the streak is broken
          hints = Math.floor(hints);
          usedHintThisPuzzle = true;
        }
      }
    }

    // Check if the puzzle was solved correctly without using any hints, don't include the last puzzle since that's the
    // current puzzle and won't be finished, it's only used to calculate hints used (which the current puzzle does too)
    if (!usedHintThisPuzzle && i < solveHistories.length - 1) {
      hints = hints + 0.5;
    }

    if (hints > maxHints) {
      hints = maxHints;
    }
  }

  return hints;
}
