<template>
  <div class="opponentgrid">
    <div class="left">
      <MainPlayBox style="width: 100%" :bots="allBots" />
      <QuickPlay :showMore="true" class="main-gradient" />
    </div>
    <div v-if="isMobileWidth()" style="text-align: center">
      <hr />
      <h2>All bots</h2>
    </div>
    <div class="right">
      <div>
        <div class="pick-opponent-header">
          <span style="color: var(--clr-accent); font-size: 2rem" />
          <div
            style="display: flex; justify-content: space-between; gap: 0.5rem; align-items: center"
          >
            <div class="filter-section">
              <Toggle
                v-if="!getPremiumState()"
                v-model="premiumToggle"
                class="filter-toggle ph-no-capture"
              >
                <template v-slot:label="{ checked, classList }">
                  <span v-if="checked" :class="classList.label"
                    ><i class="fa-solid fa-crown" /> Premium</span
                  >
                  <span v-else :class="classList.label"
                    ><i class="fa-solid fa-crown" /> Premium</span
                  >
                </template>
              </Toggle>
            </div>
            <div class="filter-section">
              <div style="position: relative">
                <button
                  class="btn btn-info dropdown-toggle ph-no-capture"
                  type="button"
                  @click="
                    () => {
                      toggleFilter();
                      track('opponent_list', 'toggle_filter', 'click', {
                        show_filter: showFilter,
                      });
                    }
                  "
                >
                  More filters
                </button>

                <div v-if="!showFilter && isFilterInUse" class="filterflag">
                  <i class="fa-solid fa-flag" />
                </div>
              </div>

              <div class="dropdown">
                <button
                  class="btn btn-info dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Sort by: {{ sortType }}
                </button>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                  <li>
                    <a
                      class="dropdown-item ph-no-capture"
                      @click="
                        () => {
                          sortType = 'Rating';
                          track('opponent_list', 'sort_type_rating', 'click');
                        }
                      "
                      >Rating</a
                    >
                  </li>
                  <li>
                    <a
                      class="dropdown-item ph-no-capture"
                      @click="
                        () => {
                          sortType = 'Name';
                          track('opponent_list', 'sort_type_name', 'click');
                        }
                      "
                      >Name</a
                    >
                  </li>

                  <li>
                    <a
                      class="dropdown-item ph-no-capture"
                      @click="
                        () => {
                          sortType = 'Playstyle';
                          track('opponent_list', 'sort_type_playstyle', 'click');
                        }
                      "
                      >Playstyle</a
                    >
                  </li>
                  <li v-if="!getPremiumState()">
                    <a
                      class="dropdown-item ph-no-capture"
                      @click="
                        () => {
                          sortType = 'Premium';
                          track('opponent_list', 'sort_type_premium', 'click');
                        }
                      "
                      >Premium</a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <OpponentFilterDialog
          :bots="allBots"
          :showFilter="showFilter"
          :premiumToggle="premiumToggle"
          @updateFilteredBots="(updatedFilteredBots) => updateFilteredBots(updatedFilteredBots)"
          @updatePremiumToggle="(updatedPremiumToggle) => (premiumToggle = updatedPremiumToggle)"
        />
        <div :style="{ textAlign: isMobileWidth() ? 'center' : 'right' }">
          Showing: {{ getShowingText() }}
        </div>
        <div
          v-if="!bs.fetched || filteredBots == null"
          class="bot-card-container pulsate-load-strong"
        >
          <div v-for="i in 40" :key="'filler' + i" class="bot-card">
            <div>
              <div
                class="card main-gradient"
                :style="{
                  width: getProfileCardScale().width + 'rem',
                  height: getProfileCardScale().width + 'rem',
                }"
              />
              <div
                class="card"
                :style="{
                  marginTop: '0.2rem',
                  width: getProfileCardScale().width + 'rem',
                  height: getProfileCardScale().fontSize * 1.5 + 'rem',
                }"
              />
            </div>
          </div>
        </div>
        <div v-else class="bot-card-container">
          <div v-for="(bot, index) in visibleFilteredBots" :key="index" class="bot-card">
            <ProfileCard
              :profileScaleSet="getProfileCardScale()"
              :bot="bot"
              :fadeIntoViewport="true"
              :showPlaystyle="true"
              :showFavorite="true"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { type Ref, computed, nextTick, onMounted, onUnmounted, ref, watch } from 'vue';

  import Toggle from '@vueform/toggle';

  import ProfileCard from '@/components/common/ProfileCard.vue';
  import QuickPlay from '@/components/dashboard/QuickPlay.vue';
  import OpponentFilterDialog from '@/components/opponents/OpponentFilterDialog.vue';
  import MainPlayBox from '@/components/play/MainPlayBox.vue';
  import emitter from '@/eventBus';
  import { useBotsStore } from '@/stores/botStore';
  import { usePageStore } from '@/stores/pageStore';
  import type { Bot } from '@/types/apitypes';
  import { getPremiumState } from '@/util/premium';
  import { track } from '@/util/tracking';
  import { isMobileWidth } from '@/util/util';

  const bs = useBotsStore();
  const ps = usePageStore();

  const batchBotSize = 40;

  // Using intermediate toggle booleans instead of the filter object directly since the toggle element animation bugged out (too many change on the page I guess)
  const premiumToggle = ref(true);

  const filteredBots: Ref<Bot[] | null> = ref(null);
  const visibleFilteredBots: Ref<Bot[]> = ref([]);
  const allBotsLoaded = ref(false);
  const isFilterInUse = ref(false);
  const allBots = ref();
  const sortType = ref('Rating');
  const showFilter = ref(false);

  watch(sortType, async () => {
    visibleFilteredBots.value = [];
    allBotsLoaded.value = false;

    filteredBots.value = bs.list.slice(0);

    await nextTick();
    loadMoreBots();
    orderBots();
  });

  const loadMoreBots = () => {
    if (allBotsLoaded.value || !filteredBots.value) return;

    const nextBatch =
      filteredBots.value?.slice(
        visibleFilteredBots.value.length,
        visibleFilteredBots.value.length + batchBotSize
      ) ?? [];

    if (nextBatch.length === 0) {
      allBotsLoaded.value = true;
    } else {
      visibleFilteredBots.value.push(...nextBatch);
    }
  };
  const onScroll = () => {
    const scrollPosition = window.scrollY + window.innerHeight;
    const pageHeight = document.documentElement.scrollHeight;

    if (scrollPosition >= pageHeight - 100) {
      loadMoreBots();
    }
  };

  onMounted(() => {
    window.addEventListener('scroll', onScroll);

    emitter.on('update-favorite-bots', (botId) => {
      visibleFilteredBots.value.forEach((bot: Bot) => {
        if (bot.user && bot.id === botId) {
          bot.user.isFavorite = !bot.user?.isFavorite;
        }

        return bot;
      });
    });

    bs.refresh().then(async () => {
      allBots.value = bs.list.slice(0);
    });
  });

  onUnmounted(() => {
    window.removeEventListener('scroll', () => {});
    emitter.off('update-favorite-bots', () => {});
  });

  function getProfileCardScale() {
    if (window.innerWidth < 576) {
      // return { width: 8, height: 9.6, fontSize: 0.8 };
      return { width: 6.5, height: 7.8, fontSize: 0.8 };
    } else {
      return { width: 10, height: 12, fontSize: 1 };
    }
  }
  function toggleFilter() {
    showFilter.value = !showFilter.value;
  }

  function getShowingText() {
    if (allBots.value == null) {
      return '';
    }
    const filteredCount =
      filteredBots.value == null ? allBots.value.length : filteredBots.value.length;
    const allCount = allBots.value.length;

    return `${filteredCount} bots ${
      filteredBeaten.value > 0 ? `(${filteredBeaten.value} beaten)` : ''
    } / ${allCount}`;
  }

  const orderBots = () => {
    if (filteredBots.value == null || filteredBots.value?.length) {
      return;
    }

    if (sortType.value == 'Rating') {
      filteredBots.value.sort((a, b) => a.strength.estimated_elo - b.strength.estimated_elo);
    } else if (sortType.value == 'Name') {
      filteredBots.value.sort((a, b) => (a.name > b.name ? 1 : -1));
    } else if (sortType.value == 'Premium') {
      filteredBots.value.sort((a, b) => (a.premium < b.premium ? 1 : -1));
    } else if (sortType.value == 'Playstyle') {
      filteredBots.value.sort((a, b) => (a.persona.category < b.persona.category ? 1 : -1));
    }
  };

  const filteredBeaten = computed<number>(() => {
    return filteredBots.value?.filter((b) => b.user?.hasWon).length ?? 0;
  });
  const updateFilteredBots = async (opts: { updatedFilteredBots: Bot[]; filterInUse: boolean }) => {
    const { updatedFilteredBots, filterInUse } = opts;
    visibleFilteredBots.value = [];
    allBotsLoaded.value = false;

    filteredBots.value = updatedFilteredBots;
    isFilterInUse.value = filterInUse;

    await nextTick();

    loadMoreBots();
    orderBots();
  };
</script>

<style src="@vueform/slider/themes/default.css"></style>

<style scoped>
  .filterflag {
    display: flex;

    position: absolute;
    top: -0.5rem;
    left: -0.5rem;
    color: white;
    font-size: 0.7rem;
    background: var(--clr-rect-4);
    border-radius: 50%;
    width: 1.2rem;
    height: 1.2rem;
    justify-content: center;
    align-items: center;
  }
  .opponentgrid {
    display: grid;
    grid-template-columns: 1fr 4fr;
    gap: 5rem;
  }
  .left {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    min-width: 300px;
  }

  .pick-opponent-header {
    display: flex;
    justify-content: space-between;
    height: 4rem;
  }

  :deep(div.card .title) {
    color: var(--clr-background-detail);
    font-size: 1.5rem;
    text-align: center;
    margin-top: 1rem;
    text-decoration: none;
  }

  .bot-card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 0.5rem;
  }

  @media (max-width: 992px) {
    .opponentgrid {
      grid-template-columns: 1fr;
      gap: 1rem;
    }

    .pick-opponent-header {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 1rem;
    }
  }
  .pulsatefwd {
    -webkit-animation: pulsate-fwd 2s ease-in-out infinite both;
    animation: pulsate-fwd 2s ease-in-out infinite both;
  }

  /* ----------------------------------------------
 * Generated by Animista on 2023-9-25 23:8:31
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

  /**
 * ----------------------------------------
 * animation pulsate-fwd
 * ----------------------------------------
 */
  @-webkit-keyframes pulsate-fwd {
    0% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
    20% {
      -webkit-transform: scale(1.5);
      transform: scale(1.5);
    }
    40% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
  @keyframes pulsate-fwd {
    0% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
    30% {
      -webkit-transform: scale(1.5);
      transform: scale(1.5);
    }
    60% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }

  .filter-toggle {
    outline: none;
    --toggle-width: 7rem;
    --toggle-height: 1.5rem;
    --toggle-font-size: 1rem;
    --toggle-bg-on: var(--clr-dark-accent);
    --toggle-bg-off: var(--clr-main-lighter);
    --toggle-text-on: white;
    --toggle-text-off: white;
    --toggle-border-on: var(--clr-dark-accent);
    --toggle-border-off: var(--clr-main-lighter);
    --toggle-handle-enabled: var(--clr-rect-2);
  }

  .filter-section {
    display: flex;
    justify-content: space-between;
    gap: 0.5rem;
    align-items: center;
  }

  @media (max-width: 576px) {
    .filter-section {
      flex-direction: column;
    }
    .pick-opponent-header {
      height: 6rem;
    }

    .bot-card-container {
      row-gap: 0.75rem;
      column-gap: 0.5rem;
    }
  }
</style>
