<template>
  <div class="position-relative opponent-wrapper">
    <div class="card" style="min-height: 8rem">
      <div class="card-body text-center text-box-padding" :style="getExtraPadding()">
        <div
          class="profile-container ph-no-capture"
          @click="
            () => {
              ms.showBotProfile(opponentId);
              track('opponent_box', 'bot_profile_circle', 'click', {
                botId: opponentId,
              });
            }
          "
          style="cursor: pointer"
        >
          <transition @leave="leaveThinking">
            <img
              src="https://storage.googleapis.com/chessiverse1/images/elements/thought-bubble.gif"
              style="width: 3rem; position: absolute; z-index: 100; left: 4rem; top: -1.5rem"
              class="thought-animation"
              v-if="usePageStore().showBotThinkingBubble && thinking"
            />
          </transition>
          <img
            alt="Bot profile image"
            class="profile-image-circle"
            :style="{
              '--opponent-color': opponentColor,
            }"
            :src="opponentImage"
          />
          <div class="card profile-name-text" :style="{ backgroundColor: opponentColor }">
            <div class="card-body" style="margin-top: -0.75rem">
              {{ opponentName }}
              <img
                :src="getFlagUrl(opponentCountryCode)"
                :alt="opponentCountryName"
                style="height: 0.7rem; margin: -0.2rem 0.2rem 0 0"
              />
              ({{ opponentRating }})
            </div>
          </div>
        </div>
        <div v-if="chatHistory.length > 0 || loadingChat">
          <BlinkingCursor v-if="loadingChat" />
          <div v-else>
            <TypeWriter
              v-if="currentChatIndex == chatHistory.length - 1"
              :targetText="chatHistory[currentChatIndex]"
              :style="'margin: auto; cursor: pointer'"
              :pClasses="['card-text', 'text-center']"
              :newTextDelay="10"
            />
            <p
              v-else
              :style="'margin: auto; cursor: pointer'"
              @click="currentChatIndex = chatHistory.length - 1"
            >
              "{{ chatHistory[currentChatIndex] }}"
            </p>
          </div>
        </div>
      </div>
      <div
        v-if="chatHistory.length > 0 && currentChatIndex > 0"
        style="position: absolute; right: 4rem; top: 0"
      >
        <span style="cursor: pointer" @click="currentChatIndex--">&lt;</span>
      </div>
      <div
        v-if="currentChatIndex < chatHistory.length - 1"
        style="position: absolute; right: 1rem; top: 0"
      >
        <span style="cursor: pointer" @click="currentChatIndex++">&gt;</span>
      </div>
    </div>
    <QuestionBox
      class="question-box"
      :challengeState="challengeState"
      :gameId="gameId"
      :puzzleHintAvailable="puzzleHintAvailable"
      :opponentColor="opponentColor"
      @user-input="userInput"
    />
  </div>
</template>

<script setup lang="ts">
  import { type PropType, ref, watch } from 'vue';
  import 'vue3-chessboard/style.css';

  import QuestionBox from '@/components/games/QuestionBox.vue';
  import BlinkingCursor from '@/components/util/BlinkingCursor.vue';
  import TypeWriter from '@/components/util/TypeWriter.vue';
  import { useModalsStore } from '@/stores/modalsStore';
  import { usePageStore } from '@/stores/pageStore';
  import { ChallengeState } from '@/types/internaltypes';
  import { track } from '@/util/tracking';
  import { getFlagUrl, isFullWidth } from '@/util/util';

  const props = defineProps({
    thinking: { type: Boolean, default: false },
    opponentId: { type: String, required: true },
    opponentRating: { type: Number, required: true },
    opponentName: { type: String, required: true },
    opponentImage: { type: String, required: true },
    opponentCountryCode: { type: String, required: true },
    opponentCountryName: { type: String, required: true },
    opponentColor: { type: String, default: '#fff' },
    loadingChat: { type: Boolean, default: false },
    chatHistory: { type: Array as PropType<string[]>, required: true },
    challengeState: {
      type: Number as PropType<ChallengeState | null>,
      default: null,
    },
    gameId: { type: String, default: null },
    puzzleHintAvailable: { type: Boolean, default: false },
  });

  const ms = useModalsStore();
  const currentChatIndex = ref(props.chatHistory.length - 1);

  watch(
    () => props.chatHistory,
    () => {
      // Something new in history so move the chat box to the latest entry
      currentChatIndex.value = props.chatHistory.length - 1;
    },
    { deep: true }
  );

  function getExtraPadding() {
    const hasBlueText =
      props.challengeState == ChallengeState.FinishedCasualWin ||
      props.challengeState == ChallengeState.FinishedCasualNotWin ||
      props.challengeState == ChallengeState.FinishedRatedWin ||
      props.challengeState == ChallengeState.FinishedRatedNotWin;
    if (!hasBlueText) {
      // No blue text in the question box so no need for extra padding
      return {};
    }

    if (isFullWidth()) {
      // We're in desktop mode and there's blue text so need extra padding at the bottom
      return { paddingBottom: '3rem' };
    } else {
      // We're in mobile mode so the extra padding goes on top
      return { paddingTop: '4rem' };
    }
  }

  const emit = defineEmits(['userInput']);

  const userInput = (input: any) => {
    emit('userInput', input);
  };

  function leaveThinking(el: Element, done: () => void) {
    el.classList.remove('thought-animation');
    el.classList.add('thought-animation-out');
    setTimeout(() => {
      done();
    }, 900); // 900ms is slightly less than the duration of the thought-animation-out, if it's the same it will flicker in the end for some reason
  }
</script>

<style scoped>
  .opponent-wrapper {
    padding-top: 2rem;
    padding-bottom: 0rem;
  }
  .text-box-padding {
    padding: 2rem 2rem 2rem 2rem;
  }
  .question-box {
    margin-top: -1.25rem;
    position: relative;
    z-index: 20;
  }

  .profile-image-circle {
    height: 4rem;
    margin-left: 1rem;
    position: absolute;
    z-index: 10;
    border-radius: 50%;
    border: 1.5px solid #444;
    /*noinspection CssUnresolvedCustomProperty*/
    background-color: var(--opponent-color);
  }

  .thinking {
    display: grid;
    place-content: center;
    color: white;
    text-shadow: 0 1px 0 #000;
    --border-angle: 0turn;
    /*noinspection CssUnresolvedCustomProperty*/
    --main-bg: conic-gradient(
      from var(--border-angle),
      var(--opponent-color) 0%,
      var(--opponent-color) 5%,
      var(--opponent-color) 60%,
      var(--opponent-color) 95%
    );
    border: solid 1.5px transparent;
    border-radius: 50%;
    --gradient-border: conic-gradient(
      from var(--border-angle),
      transparent 25%,
      #fff,
      #fff 99%,
      transparent
    );
    background:
      var(--main-bg) padding-box,
      var(--gradient-border) border-box,
      var(--main-bg) border-box;
    background-position: center center;
    transition:
      opacity 3s ease-in-out,
      background 3s ease-in-out;
    opacity: 0;
  }

  .profile-image-circle.thinking {
    opacity: 1;
    animation: bg-spin 3s linear infinite;
  }

  @keyframes bg-spin {
    to {
      --border-angle: 1turn;
    }
  }

  @property --border-angle {
    syntax: '<angle>';
    inherits: true;
    initial-value: 0turn;
  }

  .profile-name-text {
    height: 2rem;
    position: absolute;
    top: 1rem;
    left: 4rem;
    width: 14rem;
    text-align: center;
  }

  .profile-container {
    position: absolute;
    top: -2rem;
    left: -2rem;
  }

  .flex-container {
    display: flex;
  }

  .flex-info {
    flex: 1;
  }

  @media (max-width: 1200px) {
    .opponent-wrapper {
      padding-top: 0rem;
      padding-bottom: 2rem;
    }

    .profile-container {
      top: auto;
      bottom: 2rem;
    }
    .question-box {
      width: 100%;
      top: 0.5rem;
      position: absolute;
      z-index: 20;
    }
  }

  @media (max-width: 800px) {
    .profile-image {
      height: 5rem;
    }
    .box-flex-direction {
      flex-direction: row;
    }

    .box-flex-padding {
      padding-right: 2rem;
    }
  }
  @media (min-width: 800px) {
    .profile-image {
      height: 10rem;
    }
    .box-flex-direction {
      flex-direction: column;
    }
    .box-flex-padding {
      padding-bottom: 1rem;
    }
  }

  .thought-animation {
    transform-origin: bottom left;
    animation:
      scale-in-animation 1s ease-in,
      wobble-animation 5s linear 1s infinite;
  }
  @keyframes scale-in-animation {
    0% {
      transform: scale(0);
      opacity: 0;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
  @keyframes wobble-animation {
    50% {
      transform: scale(0.9);
    }
    100%,
    0% {
      transform: scale(1);
    }
  }

  .thought-animation-out {
    transform-origin: bottom left;
    animation: scale-out-animation 1s;
  }
  @keyframes scale-out-animation {
    100% {
      transform: scale(0);
      opacity: 0;
    }
    0% {
      transform: scale(1);
      opacity: 1;
    }
  }
</style>
