export function useRatingChangeFormatting() {
  //Calculates the difference between two ratings
  const getRatingChangeDiff = (rating1: number | undefined, rating2: number | undefined) => {
    if (rating1 == null || rating2 == null) {
      return 0;
    }
    return Math.round(rating1 - rating2);
  };

  const getRatingChangeElement = (ratingChange: '-' | number) => {
    if (ratingChange == '-') {
      return '-';
    }
    const color = ratingChange >= 0 ? '--clr-accent4' : '--clr-rect-3';
    const plusSign = ratingChange >= 0 ? '+' : '';
    const icon = ratingChange >= 0 ? 'fa-arrow-trend-up' : 'fa-arrow-trend-down';

    return `<span style="color: var(${color})"><i class="fa-duotone ${icon}"></i> ${plusSign}${ratingChange}</span>`;
  };

  // Calculates rating change based on timeframe
  const getRatingChange = (
    currentRating: number,
    ratingHistory: any,
    timeframe: string,
    change:
      | {
          lastGame: number | null;
          lastDay: number | null;
          lastWeek: number | null;
          lastMonth: number | null;
        }
      | undefined
  ) => {
    if (ratingHistory == null || ratingHistory.length == 0 || change == null) {
      return 0;
    }
    if (timeframe == 'week') {
      if (change.lastWeek == null) return '-';
      return Math.round(currentRating - change.lastWeek);
    } else if (timeframe == 'month') {
      if (change.lastMonth == null) return '-';
      return Math.round(currentRating - change.lastMonth);
    }

    const sortedRatings = ratingHistory.daily.sort((a: any, b: any) => {
      return a.started_at._seconds - b.started_at._seconds;
    });

    const firstDay = new Date(sortedRatings[0].started_at._seconds * 1000);
    const aYearAgoMillis = new Date().getTime() - 1000 * 60 * 60 * 24 * 365;

    if (timeframe == 'all' || firstDay.getTime() > aYearAgoMillis) {
      return Math.round(currentRating - ratingHistory.daily[0].rating_new);
    }

    // Find the first rating within the last year
    for (let i = 0; i < sortedRatings.length; i++) {
      const date = new Date(sortedRatings[i].started_at._seconds * 1000);
      if (date.getTime() > aYearAgoMillis) {
        return Math.round(currentRating - sortedRatings[i].rating_new);
      }
    }

    // Should really not get here
    return 0;
  };

  return {
    getRatingChangeDiff,
    getRatingChangeElement,
    getRatingChange,
  };
}
