<template>
  <div style="display: none" />
</template>

<script setup lang="ts">
  import { ref, watch } from 'vue';

  import { useSound } from '@vueuse/sound';

  import { usePageStore } from '@/stores/pageStore';

  import bluestarsound1 from '/src/assets/sounds/blue_star-chime_musical_15.mp3';
  import capturesound1 from '/src/assets/sounds/capture-game_piece_drop_3.mp3';
  import castlesound1 from '/src/assets/sounds/castle-game_piece_drop_1.mp3';
  import checksound1 from '/src/assets/sounds/check-game_piece_slide_4.mp3';
  import drawsound1 from '/src/assets/sounds/draw-vision_transition_3.mp3';
  import timebellsound1 from '/src/assets/sounds/egg_timer_bell_ring_1.mp3';
  import endoftime from '/src/assets/sounds/end_of_time.mp3';
  import failedpuzzlesound1 from '/src/assets/sounds/failed_puzzle-bee_buzz_1.mp3';
  import gethintsound1 from '/src/assets/sounds/get_hint-multimedia_chime_34.mp3';
  import greenstarsound1 from '/src/assets/sounds/green_star-chime_musical_28.mp3';
  import losesound1 from '/src/assets/sounds/lose-firework_boom.mp3';
  import losesound2 from '/src/assets/sounds/lose_bell.mp3';
  import losehintsound1 from '/src/assets/sounds/lose_hint-crash_glass_2.mp3';
  import movesound1 from '/src/assets/sounds/move-game_piece_move_11.mp3';
  import multimedia192 from '/src/assets/sounds/multimedia_192.mp3';
  import promotionsound1 from '/src/assets/sounds/promote-game_piece_drop_2.mp3';
  import startsound1 from '/src/assets/sounds/start-bell_ring_nautical_short.mp3';
  import succeedpuzzlesound1 from '/src/assets/sounds/succeed_puzzle-multimedia_chime_10.mp3';
  import succeedpuzzlewithhintssound1 from '/src/assets/sounds/succeed_puzzle_with_hints-multimedia_chime_31.mp3';
  import usehintsound1 from '/src/assets/sounds/use_hint-multimedia_chime_35.mp3';
  import winsound1 from '/src/assets/sounds/win-crowd_applause_20_edited.mp3';
  import yellowstarsound1 from '/src/assets/sounds/yellow_star-chime_musical_21.mp3';

  const props = defineProps({
    playSound: { type: String, default: '' },
    playMoveSound: { type: String, default: '' },
  });

  type soundType = {
    id: string;
    url: string;
    sound: any;
  };

  const moveVolume = ref(usePageStore().sounds.moves.volume);
  const otherVolume = ref(usePageStore().sounds.other.volume);

  // To adjust volume of the sound, you can add it to the useSound like this:
  // useSound(movesound1, { volume: 0.5 }),
  const sounds: soundType[] = [
    // Move sounds (using move volume)
    {
      id: 'move-sound-1',
      url: 'https://storage.googleapis.com/chessiverse1/sounds/move-game_piece_move_11.mp3',
      sound: useSound(movesound1, { volume: moveVolume }),
    },
    {
      id: 'capture-sound-1',
      url: 'https://storage.googleapis.com/chessiverse1/sounds/capture-game_piece_drop_3.mp3',
      sound: useSound(capturesound1, { volume: moveVolume }),
    },
    {
      id: 'castle-sound-1',
      url: 'https://storage.googleapis.com/chessiverse1/sounds/castle-game_piece_drop_1.mp3',
      sound: useSound(castlesound1, { volume: moveVolume }),
    },
    {
      id: 'promotion-sound-1',
      url: 'https://storage.googleapis.com/chessiverse1/sounds/promote-game_piece_drop_2.mp3',
      sound: useSound(promotionsound1, { volume: moveVolume }),
    },
    {
      id: 'check-sound-1',
      url: 'https://storage.googleapis.com/chessiverse1/sounds/check-game_piece_slide_4.mp3',
      sound: useSound(checksound1, { volume: moveVolume }),
    },

    // Other sounds (using other volume)
    {
      id: 'draw-sound-1',
      url: 'https://storage.googleapis.com/chessiverse1/sounds/draw-vision_transition_3.mp3',
      sound: useSound(drawsound1, { volume: otherVolume }),
    },
    {
      id: 'lose-sound-1',
      url: 'https://storage.googleapis.com/chessiverse1/sounds/lose-firework_boom.mp3',
      sound: useSound(losesound1, { volume: otherVolume }),
    },
    {
      id: 'lose-sound-2',
      url: 'https://storage.googleapis.com/chessiverse1/sounds/lose-bell.mp3',
      sound: useSound(losesound2, { volume: otherVolume }),
    },
    {
      id: 'win-sound-1',
      url: 'https://storage.googleapis.com/chessiverse1/sounds/win-crowd_applause_20_edited.mp3',
      sound: useSound(winsound1, { volume: otherVolume }),
    },
    {
      id: 'start-sound-1',
      url: 'https://storage.googleapis.com/chessiverse1/sounds/start-bell_ring_nautical_short.mp3',
      sound: useSound(startsound1, { volume: otherVolume }),
    },
    {
      id: 'blue-star-sound-1',
      url: 'https://storage.googleapis.com/chessiverse1/sounds/blue_star-chime_musical_15.mp3',
      sound: useSound(bluestarsound1, { volume: otherVolume }),
    },
    {
      id: 'failed-puzzle-sound-1',
      url: 'https://storage.googleapis.com/chessiverse1/sounds/failed_puzzle-bee_buzz_1.mp3',
      sound: useSound(failedpuzzlesound1, { volume: otherVolume }),
    },
    {
      id: 'get-hint-sound-1',
      url: 'https://storage.googleapis.com/chessiverse1/sounds/get_hint-multimedia_chime_34.mp3',
      sound: useSound(gethintsound1, { volume: otherVolume }),
    },
    {
      id: 'green-star-sound-1',
      url: 'https://storage.googleapis.com/chessiverse1/sounds/green_star-chime_musical_28.mp3',
      sound: useSound(greenstarsound1, { volume: otherVolume }),
    },
    {
      id: 'lose-hint-sound-1',
      url: 'https://storage.googleapis.com/chessiverse1/sounds/lose_hint-crash_glass_2.mp3',
      sound: useSound(losehintsound1, { volume: otherVolume }),
    },
    {
      id: 'succeed-puzzle-sound-1',
      url: 'https://storage.googleapis.com/chessiverse1/sounds/succeed_puzzle-multimedia_chime_10.mp3',
      sound: useSound(succeedpuzzlesound1, { volume: otherVolume }),
    },
    {
      id: 'succeed-puzzle-with-hints-sound-1',
      url: 'https://storage.googleapis.com/chessiverse1/sounds/succeed_puzzle_with_hints-multimedia_chime_31.mp3',
      sound: useSound(succeedpuzzlewithhintssound1, {
        volume: otherVolume,
      }),
    },
    {
      id: 'use-hint-sound-1',
      url: 'https://storage.googleapis.com/chessiverse1/sounds/use_hint-multimedia_chime_35.mp3',
      sound: useSound(usehintsound1, { volume: otherVolume }),
    },
    {
      id: 'yellow-star-sound-1',
      url: 'https://storage.googleapis.com/chessiverse1/sounds/yellow_star-chime_musical_21.mp3',
      sound: useSound(yellowstarsound1, { volume: otherVolume }),
    },
    {
      id: 'time-bell-sound-1',
      url: 'https://storage.googleapis.com/chessiverse1/sounds/egg_timer_bell_ring_1.mp3',
      sound: useSound(timebellsound1, { volume: otherVolume }),
    },
    {
      id: 'multimedia-192',
      url: 'https://storage.googleapis.com/chessiverse1/sounds/multimedia_192.mp3',
      sound: useSound(multimedia192, { volume: otherVolume }),
    },
    {
      id: 'end-of-time',
      url: 'https://storage.googleapis.com/chessiverse1/sounds/end_of_time.mp3',
      sound: useSound(endoftime, { volume: otherVolume }),
    },
  ];

  watch(
    () => usePageStore().playSound,
    (newVal) => {
      if (newVal && !usePageStore().sounds.master.muted && !usePageStore().sounds.other.muted) {
        otherVolume.value = usePageStore().getMasterAdjustedVolumes.other * 0.01;
        getElementByIdPrefix(newVal)?.sound.play();
      }
    }
  );

  watch(
    () => usePageStore().playMoveSounds,
    async (newVal) => {
      if (newVal && newVal.length > 0) {
        if (!usePageStore().sounds.master.muted && !usePageStore().sounds.moves.muted) {
          for (const sound of newVal) {
            getSoundPrefixesByMove(sound).forEach((prefix) => {
              const soundElement = getElementByIdPrefix(prefix)?.sound;
              if (soundElement) {
                moveVolume.value = usePageStore().getMasterAdjustedVolumes.moves * 0.01;
                soundElement.play();
              }
            });

            // Introduce a 100ms delay between each sound
            await new Promise((resolve) => setTimeout(resolve, 100));
          }
        }

        usePageStore().playMoveSounds = [];
      }
    },
    { deep: true }
  );

  function getSoundPrefixesByMove(sanMove: string): string[] {
    const prefixes = [];

    // Base sounds (can be combination of sounds)
    if (sanMove == null) {
      return [];
    } else if (sanMove.includes('O')) {
      // Castling
      prefixes.push('castle');
    } else if (
      sanMove.toLowerCase().endsWith('q') ||
      sanMove.toLowerCase().endsWith('r') ||
      sanMove.toLowerCase().endsWith('b') ||
      sanMove.toLowerCase().endsWith('n')
    ) {
      // Promotion
      prefixes.push('promotion');
    } else {
      // Normal move
      prefixes.push('move');
    }

    // Extra sounds, played on top of the base sound
    if (sanMove.includes('x')) {
      prefixes.push('capture');
    }

    if (sanMove.includes('+') || sanMove.includes('#')) {
      // Check or checkmate (no special sound for checkmate since that will be handled by end of game anyway)
      prefixes.push('check');
    }

    return prefixes;
  }

  function getElementByIdPrefix(prefix: string): (typeof sounds)[number] | undefined {
    // Filter the array to find elements that start with the given prefix
    const filteredSounds = sounds.filter((sound) => sound.id.startsWith(prefix));

    if (filteredSounds.length === 0) {
      // Return undefined if no matching elements are found
      return undefined;
    } else {
      // Randomly select an element from the filtered array
      const randomIndex = Math.floor(Math.random() * filteredSounds.length);
      return filteredSounds[randomIndex];
    }
  }
</script>
