<template>
  <div style="padding: 2rem; text-align: center">
    <div style="text-align: center; margin-bottom: 2rem">
      <h2 style="font-size: 2.2rem">
        <span style="color: white; font-weight: 800">Thousands</span>
        of chess vs computer games played every day!
      </h2>
    </div>
    <Carousel :autoplay="3000" :wrap-around="true" :transition="1000" style="margin: auto">
      <Slide v-for="(quote, i) in quotes" :key="'quote' + i">
        <div class="carousel__item">
          <div
            style="
              display: flex;
              flex-direction: column;
              align-items: end;
              gap: 1rem;
              padding-inline: 2rem;
            "
          >
            <div style="display: flex; align-items: center; gap: 1rem">
              <i class="fa-solid fa-quote-left quote-mark" />
              <span>
                {{ quote.quote }}
              </span>
              <i class="fa-solid fa-quote-right quote-mark" />
            </div>
            <span class="quote-text">- {{ quote.source }}</span>
          </div>
        </div>
      </Slide>

      <template #addons>
        <Pagination />
      </template>
    </Carousel>
  </div>
</template>

<script setup lang="ts">
  import { Carousel, Pagination, Slide } from 'vue3-carousel';
  import 'vue3-carousel/dist/carousel.css';

  const quotes = [
    {
      quote: 'I love that the bots have distinct playing styles, which leads to unexpected games',
      source: 'geoff_batko',
    },
    {
      quote: 'I can tell this is a work of love. Good job!',
      source: 'thenakesingularity10',
    },
    {
      quote: 'Wow! What an amazing website :)',
      source: 'lehrerb42',
    },
    {
      quote:
        'The functionality is great and the bots are much more interesting than what we get on Chess.com.',
      source: 'Material_Front_8819',
    },
    {
      quote: 'The features, user interface, and overall experience were top-notch.',
      source: 'Armaan Alam',
    },
    {
      quote: 'Thank you so much for this site, i adore it!',
      source: 'Ivan',
    },
  ];
</script>

<style scoped>
  .carousel__pagination-button {
    background-color: white;
    border-radius: 50%;
    margin-right: 0.2rem;
  }

  .carousel__pagination-button:hover {
    background-color: #ddd;
  }

  .carousel__pagination-button::after {
    width: 0rem;
    height: 0rem;
    background-color: initial;
  }

  .carousel__pagination-button--active {
    background-color: #ddd;
  }

  .quote-mark {
    opacity: 0.8;
    color: white;
  }

  .usages-list {
    display: grid;
    gap: 0.5rem;
    padding-block: 0.5rem;
    justify-content: center;
    grid-template-columns: 1fr 1fr;
    width: min(80%, 50rem);
    margin: 0 auto;
  }

  .usages-list > div {
    padding: 1rem;
    text-align: center;
  }

  .usages-list span {
    font-size: 1rem;
  }

  .quote-text {
    opacity: 0.5;
    font-size: 1rem !important;
  }

  @media (max-width: 768px) {
    .usages-list {
      grid-template-columns: 1fr;
    }
  }
</style>
