<template>
  <div
    class="modal fade"
    id="puzzleInfoModal"
    tabindex="-1"
    aria-labelledby="puzzleInfoModalLabel"
    aria-hidden="true"
    ref="modalElement"
  >
    <div class="modal-dialog modal-md">
      <div class="modal-content">
        <div class="modal-header" style="border-bottom: 0px; justify-content: right; z-index: 10">
          <a type="button" id="puzzleInfoModalClose" data-bs-dismiss="modal" aria-label="Close">
            <img
              alt="Cross icon"
              src="https://storage.googleapis.com/chessiverse1/images/elements/cross.webp"
              style="width: 1rem; height: 1rem"
            />
          </a>
        </div>
        <div class="modal-body">
          <div class="modal-body-wrapper">
            <div class="header-icon-container">
              <i class="header-icon fa-solid fa-puzzle-piece" />
            </div>
            <div>
              <h1>Puzzles</h1>
              <span style="text-align: center"> Solve the 10 puzzles presented by each bot! </span>
              <ul style="text-align: left; margin-top: 1rem">
                <li>
                  Need help? You begin with
                  <span style="color: var(--clr-rect-2)">2 hints</span>
                </li>
                <li>
                  Hints are consumed if you play the
                  <span style="color: var(--clr-rect-2)">wrong move</span> or
                  <span style="color: var(--clr-rect-2)">ask from a hint</span>
                </li>
                <li>
                  Every 2 correctly solved puzzles you earn
                  <span style="color: var(--clr-rect-2)">1 hint</span>, up to a
                  <span style="color: var(--clr-rect-2)">maximum of 3</span>
                </li>
                <li>If you make a mistake with no hints left, you fail the puzzle</li>
              </ul>
              <i class="fa-regular fa-lightbulb" /> - Solve one more puzzle to get a hint<br /><i
                class="fa-solid fa-lightbulb lighted"
              />
              - Use this hint on any puzzle where you need a little help
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-info ph-no-capture"
            style="height: 2.5rem; width: 5rem"
            @click="
              () => {
                doneWithModal();
                track('puzzle_info_modal', 'dismiss', 'click');
              }
            "
          >
            Got it!
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { useModalsStore } from '@/stores/modalsStore';
  import { track } from '@/util/tracking';

  const ms = useModalsStore();

  const doneWithModal = () => {
    ms.closeCurrentModal();
  };
</script>

<style scoped>
  .header-icon {
    color: white;
    font-size: 2rem;
  }
  .header-icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: var(--clr-rect-2);
    width: 5rem;
    height: 5rem;
    margin-top: -2.5rem;
  }

  .modal-body-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    text-align: center;
  }

  .modal-footer {
    border-top: 0px;
  }

  .lighted {
    color: yellow;
    filter: drop-shadow(0 0 1px black);
  }
</style>
