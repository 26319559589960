<template>
  <div class="card box">
    <div
      v-tippy="{
        content: 'Your current rating',
      }"
    >
      <span style="display: flex; justify-content: center; align-items: center">Current</span>
      <h3 style="color: var(--clr-accent); font-size: 2.5rem">
        {{ loading ? '-' : currentRating }}
      </h3>
    </div>
    <div class="records">
      <div
        v-tippy="{
          content: 'Highest achieved rating',
        }"
        style="display: flex; justify-content: center; flex-direction: column; align-items: center"
      >
        <span>Highest </span>
        <span style="color: var(--clr-accent); font-size: 1.5rem; font-family: 'Cabin Sketch'">
          {{ loading ? '-' : highestRating != '-' ? Math.round(Number(highestRating)) : '-' }}
        </span>
        <span style="color: var(--clr-accent); font-size: 0.9rem; white-space: nowrap">
          {{ loading ? '-' : formatDate(highestRatingDate) }}</span
        >
      </div>
      <div
        v-tippy="{
          content: 'Lowest rating',
        }"
        style="display: flex; justify-content: center; flex-direction: column; align-items: center"
      >
        <span>Lowest </span>
        <span style="color: var(--clr-accent); font-size: 1.5rem; font-family: 'Cabin Sketch'">
          {{ loading ? '-' : lowestRating != '-' ? Math.round(Number(lowestRating)) : '-' }}
        </span>
        <span style="color: var(--clr-accent); font-size: 0.9rem; white-space: nowrap">
          {{ loading ? '-' : formatDate(lowestRatingDate) }}</span
        >
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
  defineProps({
    currentRating: {
      type: [String, Number],
      required: true,
    },
    highestRating: {
      type: [String, Number],
      required: true,
    },
    highestRatingDate: {
      type: String,
      required: true,
    },
    lowestRating: {
      type: [String, Number],
      required: true,
    },
    lowestRatingDate: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      default: true,
    },
  });

  function formatDate(dateString: string): string {
    if (dateString == null || dateString == '-' || dateString == '') return '\u00A0';

    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });
  }
</script>
<style scoped>
  .box {
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    filter: drop-shadow(2px 2px 2px var(--clr-main-lighter));
  }

  .records {
    display: flex;
    gap: 1rem;
  }

  @media (max-width: 1200px) {
    .box {
      padding: 1rem 2rem;
    }
  }
</style>
