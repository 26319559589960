<template>
  <div>
    <div v-if="loadingGame" class="text-center">
      <Loader />
    </div>
    <div v-else class="analysisContainer">
      <div class="left">
        <div class="boardcontainer expose-item-left">
          <div
            style="height: 100%; width: 100%; display: flex; gap: 0.2rem; flex-direction: column"
          >
            <div style="display: flex">
              <MaterialView
                :fen="currentPosition"
                :side="boardConfig.orientation == 'white' ? 'white' : 'black'"
              />
              <div />
            </div>
            <TheChessboard :board-config="boardConfig" @board-created="boardCreated" />

            <div style="display: flex">
              <MaterialView
                :fen="currentPosition"
                :side="boardConfig.orientation == 'white' ? 'black' : 'white'"
              />
              <div />
            </div>
          </div>
        </div>
      </div>
      <div class="right expose-item-right">
        <MovesBox
          :moves="moves"
          :startPosition="startPosition"
          :movesToStartPosition="movesToStartPosition"
          :viewingHistoryPly="viewingHistoryPly"
          :opponentColor="bot.config.boardbg"
          :result="gameResultString"
          :style="{ marginTop: isMobileWidth() ? '1.5rem' : '0' }"
          :challengeState="ChallengeState.AnalyzingGame"
          :termination="gameTermination ?? undefined"
          @user-input="userInput"
        />
        <FenBox :fen="currentPosition" :opponentColor="bot.config.boardbg" />
        <PgnBox
          :opponent="bot"
          :moves="moves"
          :startPosition="startPosition"
          :movesToStartPosition="movesToStartPosition"
          :viewingHistoryPly="viewingHistoryPly"
          :result="gameResultString"
          :side="boardConfig.orientation"
          :startedAt="getStartedAtString()"
          :timeControl="currentGame?.timeControl"
          :termination="gameTermination ?? undefined"
          :rated="currentGame?.rated"
          :ratingChange="currentGame?.ratingChange"
        />
      </div>
    </div>
    <SoundsStorage />
  </div>
</template>

<script setup lang="ts">
  import { type Ref, onMounted, onUnmounted, reactive, ref, watch } from 'vue';
  import { type BoardApi, TheChessboard } from 'vue3-chessboard';
  import 'vue3-chessboard/style.css';
  import { useRouter } from 'vue-router';

  import anime from 'animejs/lib/anime.es.js';

  import FenBox from '@/components/games/FenBox.vue';
  import MaterialView from '@/components/games/MaterialView.vue';
  import MovesBox from '@/components/games/MovesBox.vue';
  import PgnBox from '@/components/games/PgnBox.vue';
  import SoundsStorage from '@/components/sounds/SoundsStorage.vue';
  import Loader from '@/components/util/Loader.vue';
  import { useBackgroundStore } from '@/stores/backgroundStore.js';
  import { useBotsStore } from '@/stores/botStore';
  import { useGameStore } from '@/stores/gameStore';
  import { useGeneralStore } from '@/stores/generalStore';
  import { usePageStore } from '@/stores/pageStore';
  import {
    type Bot,
    type Game,
    GameTermination,
    type GetBotProfileResponse,
    Result,
    Side,
  } from '@/types/apitypes';
  import { ChallengeState, ImageType, UserInput } from '@/types/internaltypes';
  import { track } from '@/util/tracking';
  import { STARTING_FEN, isFullWidth, isMobileWidth } from '@/util/util';

  const loadingGame = ref(true);

  const props = defineProps({
    initialGameId: { type: String, required: true },
  });

  const router = useRouter();
  const gameStore = useGameStore();
  const generalStore = useGeneralStore();
  const bs = useBotsStore();
  const ps = usePageStore();
  const backgroundStore = useBackgroundStore();

  // Something strange with the params, so just redirect out of here
  if (props.initialGameId == null) {
    router.push({
      name: 'home',
    });
  }

  const currentPosition = ref<string>(STARTING_FEN);
  const viewingHistoryPly: Ref<number | null> = ref(null);
  const moves: Ref<string[]> = ref([]);
  const startPosition = ref<string | null>(null);
  const movesToStartPosition = ref<string | null>(null);
  const gameId = ref(props.initialGameId);
  const currentGame = ref<Game>();
  const boardBaseColor = ref('#ff4500');
  const boardAPI: Ref<BoardApi | null> = ref(null);
  let bot: Ref<Bot>;
  const gameResultString: Ref<string | null> = ref(null);
  const gameTermination = ref<GameTermination | null>(null);
  const gameResult: Ref<Result | null> = ref(null);
  const playerWon: Ref<boolean | null> = ref(null);
  const firstWin: Ref<boolean | null> = ref(null);
  const botBg = ref('#fff');
  const chessBoardBg = ref('#fff');
  let reverseExpose = false; // Keeps track of if the expose button should animate in or out

  const boardConfig = reactive({
    blockTouchScroll: true,
    coordinates: usePageStore().showBoardCoordinates,
    movable: { showDests: usePageStore().showPossibleMoves },
    highlight: {
      lastMove: usePageStore().showLastMove,
    },
    orientation: 'white' as 'white' | 'black' | undefined,
    viewOnly: true,
    premovable: { enabled: false },
  });

  gameStore.refreshGame(gameId.value).then((game) => {
    currentGame.value = game;
    boardConfig.orientation =
      gameId.value == undefined
        ? 'white'
        : gameStore.side(gameId.value) == Side.White
          ? 'white'
          : ('black' as 'white' | 'black' | undefined); // Specify the type here
    bs.getUserBotProfile(game.bot.id).then((response) => {
      bot = ref(response.data.bot);
      botBg.value = bot.value.config.boardbg;
      if (usePageStore().boardColorOverride.active) {
        chessBoardBg.value = usePageStore().boardColorOverride.color;
      } else {
        chessBoardBg.value = bot.value.config.boardbg;
      }

      backgroundStore.setBackground(ps.img(bot.value.id, ImageType.BotBackground, null));
      loadingGame.value = false;
    });
  });

  onMounted(() => {
    updateChessboardClasses();
  });

  const boardCreated = (api: BoardApi) => {
    boardAPI.value = api;
    updateBoard();
  };

  watch(boardBaseColor, (color) => {
    const boardElement: Element = document.getElementsByTagName('cg-board')[0];
    if (boardElement instanceof HTMLElement) {
      boardElement.style.backgroundColor = color;
    }
  });

  watch(
    () => {
      if (gameId.value == null) {
        return null;
      } else {
        return gameStore.games[gameId.value];
      }
    },
    (game: Game | null) => {
      if (game == null || game.result == null) {
        return;
      }
      boardConfig.orientation = gameStore.side(gameId.value!) == Side.White ? 'white' : 'black';

      const result = game.result;

      bs.getUserBotProfile(game.bot.id).then((response: GetBotProfileResponse) => {
        gameResultString.value = resultString(result);
        gameTermination.value = game.termination;
        gameResult.value = result;

        gameStore.games[gameId.value!].userSide == Side.White
          ? (playerWon.value = result == Result.White)
          : (playerWon.value = result == Result.Black);

        // We're checking the number of wins after the current game is over, so if the number is 1, it means this is the first win
        // should not be possible to be 0 here
        if (playerWon.value && response.data.gameStats.wins <= 1) {
          firstWin.value = true;
          ps.wonAgainstBotIdForTheFirstTime = game.bot.id;
        } else {
          firstWin.value = false;
        }
      });
    }
  );

  function getStartedAtString() {
    if (currentGame.value == null) {
      return undefined;
    }

    const startedAt = new Date(currentGame.value.startedAt);
    return startedAt.toISOString();
  }

  function exposeEffect() {
    // Assuming the divs are positioned centrally to begin with
    // This will push them outwards in all directions
    const left = anime({
      targets: '.expose-item-right',
      keyframes: [{ translateX: anime.stagger('20vw', { from: 'center', grid: [3, 3] }) }],
      duration: 2000,
      loop: false,
      autoplay: false,
      direction: 'alternate',
    });
    const right = anime({
      targets: '.expose-item-left',
      keyframes: [{ translateX: anime.stagger('-40vw', { from: 'center', grid: [3, 3] }) }],
      duration: 2000,
      loop: false,
      autoplay: false,
      direction: 'alternate',
    });
    const revert = anime({
      targets: ['.expose-item-left', '.expose-item-right'],
      keyframes: [{ translateX: 0 }, { translateY: 0 }],
      duration: 2000,
      autoplay: false,
    });

    if (reverseExpose) {
      revert.play();
    } else {
      left.play();
      right.play();
    }
    reverseExpose = !reverseExpose;
  }

  const updateBoard = () => {
    if (gameId.value == undefined) {
      return;
    }

    gameStore.refreshGame(gameId.value).then((game) => {
      currentGame.value = game;
      let pgn = '';

      if (game.startPosition != null) {
        pgn += '[SetUp "1"]\n[FEN "' + game.startPosition + '"]\n\n';
        startPosition.value = game.startPosition;
      }

      if (game.challenge?.id != null) {
        generalStore
          .getChallenge(game.challenge.id)
          .then((challenge) => {
            movesToStartPosition.value = challenge.initial_moves ?? null;
          })
          .catch((e) => {
            // This was probably a practice, so just ignore, we're not setting movesToStartPosition so the lichess button won't show (which isn't the end of the world if this error was a network glitch or similar)
          });
      }

      pgn += gameStore.moveString(gameId.value!) as string;
      boardAPI.value?.loadPgn(pgn);
      moves.value = boardAPI.value?.getHistory() ?? [];
      currentPosition.value = boardAPI.value?.getFen() ?? '';
      boardConfig.viewOnly = false;
    });
  };

  const resultString = (result: Result | null): string | null => {
    if (gameId.value == null) {
      return null;
    }

    switch (result) {
      case Result.White:
        return '1-0';
      case Result.Black:
        return '0-1';
      case Result.Draw:
        return '1/2-1/2';
      default:
        return null;
    }
  };

  const userInput = (input: any) => {
    if (currentGame.value == null) {
      return;
    }

    if (input.type == UserInput.HistoryGoto) {
      viewingHistoryPly.value = input.ply;
      boardAPI.value?.viewHistory(input.ply);
      currentPosition.value = currentGame.value?.positions[viewingHistoryPly.value! - 1];
    } else if (input.type == UserInput.HistoryStart) {
      viewingHistoryPly.value = 0;
      boardAPI.value?.viewStart();

      if (currentGame.value?.startPosition != null) {
        currentPosition.value = currentGame.value?.startPosition;
      } else {
        currentPosition.value = STARTING_FEN;
      }
    } else if (input.type == UserInput.HistoryPrevious) {
      if (viewingHistoryPly.value == null) {
        viewingHistoryPly.value = boardAPI.value!.getHistory().length - 1;
      } else {
        viewingHistoryPly.value--;
      }

      if (viewingHistoryPly.value < 0) {
        viewingHistoryPly.value = 0;
      }
      boardAPI.value?.viewHistory(viewingHistoryPly.value);

      if (viewingHistoryPly.value == 0) {
        if (currentGame.value?.startPosition != null) {
          currentPosition.value = currentGame.value?.startPosition;
        } else {
          currentPosition.value = STARTING_FEN;
        }
      } else {
        currentPosition.value = currentGame.value?.positions[viewingHistoryPly.value - 1];
      }
    } else if (input.type == UserInput.HistoryNext) {
      if (viewingHistoryPly.value == null) {
        return;
      }

      viewingHistoryPly.value++;

      if (viewingHistoryPly.value >= boardAPI.value!.getHistory().length) {
        viewingHistoryPly.value = null;
      }

      boardAPI.value?.viewHistory(
        viewingHistoryPly.value == null
          ? boardAPI.value!.getHistory().length
          : viewingHistoryPly.value
      );

      if (viewingHistoryPly.value == null) {
        currentPosition.value =
          currentGame.value?.positions[currentGame.value?.positions.length - 1];
      } else {
        currentPosition.value = currentGame.value?.positions[viewingHistoryPly.value! - 1];
      }
    } else if (input.type == UserInput.HistoryEnd) {
      viewingHistoryPly.value = null;
      boardAPI.value?.stopViewingHistory();
      boardAPI.value?.viewHistory(boardAPI.value!.getHistory().length);
      currentPosition.value = currentGame.value?.positions[currentGame.value?.positions.length - 1];
    } else {
      console.error('Unknown user input: ' + input.type);
    }
  };

  function updateChessboardClasses() {
    // Bit of a convoluted way to replace the vue3-chessboard class "main-wrap" with our own "chessboard-wrap" so we can
    // control it better
    const observer = new MutationObserver((mutations, obs) => {
      const mainWrap = document.getElementsByClassName('main-wrap');
      if (mainWrap.length > 0) {
        mainWrap[0].classList.add('chessboard-wrap');
        mainWrap[0].classList.remove('main-wrap');
      }

      const cgBoard = document.getElementsByTagName('cg-board');
      for (const el of cgBoard) {
        el.classList.add('ph-no-capture');
      }
    });

    // Start observing
    observer.observe(document.body, {
      childList: true, // observe direct children
      subtree: true, // and lower descendants too
    });

    // Optional: Disconnect observer when component unmounts to clean up
    onUnmounted(() => observer.disconnect());
  }
</script>

<style scoped>
  .analysisContainer {
    display: grid;
    grid-template-columns: 1fr auto 26rem 1fr;
    gap: 1rem;
  }

  .left {
    grid-column: 2;
    flex: 1 0;
    display: flex;
    justify-content: end;
  }

  .right {
    grid-column: 3;
    display: flex;
    flex-direction: column;
    margin-top: 1.5rem; /* Accounts for the material box so it's aligned with the top of the board */
    gap: 2rem;
    flex: 0 0 26rem;
  }

  .boardcontainer {
    width: min(800px, 80vh);
    height: min(800px, 80vh);
    position: relative;
    display: flex;
    margin-right: 1rem;
  }

  .chessboard-wrap {
    width: 100%;
    height: 100%;
  }

  @media (max-width: 1200px) {
    .analysisContainer {
      grid-template-columns: 1fr; /* Single column layout */
      grid-template-rows: auto auto; /* Each item gets its own row */
      gap: 1rem; /* Add spacing between rows */
      justify-items: center; /* Center-align each item horizontally */
      align-items: start; /* Optional: Align items to the top */
    }

    .left,
    .right {
      grid-column: 1; /* Ensure both items are in the single column */
      width: 100%; /* Make them take full width of the container */
      max-width: none; /* Remove width constraints */
    }

    .left {
      justify-content: center; /* Center-align the content */
    }

    .right {
      flex: 0 0 auto; /* Allow it to shrink dynamically */
      max-width: 26rem; /* Limit the width to 26rem */
      margin-top: 0rem; /* Remove the margin since we don't need to align with the board now */
    }

    .boardcontainer {
      width: min(100%, 78vh);
      height: min(100%, 78vh);
      position: relative;
      display: flex;
      margin: 0;
      padding: 0;
    }
  }

  :deep(cg-board) {
    background-color: v-bind('chessBoardBg'); /* Override the board background color */
  }
</style>
