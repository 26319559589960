import PostHog from 'posthog-js';

// Using Posthog naming convetions from: https://posthog.com/product-engineers/5-ways-to-improve-analytics-data#1-implement-a-naming-convention
export function track(
  category: string, // describes the context the event took place in – e.g., account_settings or signup_flow
  object: string, // a noun that refers to the component or location the event took place in, like forget_password_button or pricing_page
  action: string,
  data: any | null = null
) {
  const eventName = `${category}:${object}_${action}`;
  if (data) {
    PostHog.capture(eventName, data);
  } else {
    PostHog.capture(eventName);
  }
}
