<template>
  <div style="display: flex; flex-direction: column; gap: 1rem; position: relative">
    <div style="position: absolute; right: -0.5rem; top: -0.5rem">
      <LoaderNew v-if="refreshingLichessData" />
    </div>
    <div
      v-if="cs.selectedCourseSectionData != null"
      style="width: 100%; max-height: 10rem; overflow: auto; display: flex; flex-direction: column"
    >
      <span role="button" @click="cs.selectedCourseSectionData = null" style="font-size: 1.1rem"
        >Matching:
        <span style="color: var(--clr-main-lighter)">{{ cs.selectedCourseSectionData }}</span>
        <i
          style="color: var(--clr-externalgame-transparent); margin-left: 0.2rem"
          class="fa-solid fa-circle-xmark"
      /></span>
      <span v-if="matchingGame == null" style="color: var(--clr-main-lighter)"
        >No matching game found...</span
      >
      <LichessExplorerTable
        v-else
        style="--td-bg-color: var(--clr-externalgame-transparent)"
        :lichess-games="[matchingGame]"
      />
    </div>

    <div style="width: 100%; display: flex; flex-direction: column; max-height: 10rem">
      <span style="font-size: 1.1rem">Lichess Games</span>
      <LichessExplorerTable
        v-if="lichessGames != null && lichessGames.length > 0"
        :lichess-games="lichessGames"
      />
      <span v-else style="color: var(--clr-main-lighter)">Not more games in database...</span>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { computed, ref, watch } from 'vue';

  import LichessExplorerTable from '@/components/common/tree/LichessExplorerTable.vue';
  import LoaderNew from '@/components/util/LoaderNew.vue';
  import { useCourseStore } from '@/stores/courseStore';
  import { useGameStore } from '@/stores/gameStore';
  import type { LichessGame, MatchGame } from '@/types/internaltypes';
  import { getCurrentSanLineFromUidPath } from '@/util/course';
  import { STARTING_FEN_SHORT, shortFen } from '@/util/util';

  const cs = useCourseStore();
  const gs = useGameStore();

  defineProps({
    matchCandidate: { type: String, required: false },
  });

  const selectedFen = computed(() => {
    return cs.selectedNode?.fen == null ? STARTING_FEN_SHORT : shortFen(cs.selectedNode.fen);
  });

  const currentUciLine = ref<string>();
  const refreshingLichessData = ref(true);
  const lichessGames = ref<LichessGame[]>();
  const matchingGame = ref<LichessGame | null>();

  watch(
    () => selectedFen.value,
    () => {
      refreshData();
    },
    { immediate: true }
  );

  async function refreshData() {
    currentUciLine.value = getCurrentUciLine();
    refreshingLichessData.value = true;

    // Fetch data from both sources concurrently
    const [lichessResp, masterResp] = await Promise.all([
      gs.getLichessExplorer('lichess', null, currentUciLine.value),
      gs.getLichessExplorer('master', null, currentUciLine.value),
    ]);

    const games: LichessGame[] = [];

    // Helper to convert an individual game into our LichessGame format.
    function convertGame(game: any, db: 'lichess' | 'master', type: 'recent' | 'top'): LichessGame {
      const result = game.winner === 'white' ? '1-0' : game.winner === 'black' ? '0-1' : '1/2-1/2';
      // For the date we use the month string from the response.
      return {
        id: game.id,
        db,
        type,
        speed: game.speed,
        white: game.white,
        black: game.black,
        result,
        date: formatDate(game.month),
      };
    }

    if (masterResp.topGames) {
      games.push(...masterResp.topGames.map((game: any) => convertGame(game, 'master', 'top')));
    }
    if (masterResp.recentGames) {
      games.push(
        ...masterResp.recentGames.map((game: any) => convertGame(game, 'master', 'recent'))
      );
    }
    if (lichessResp.topGames) {
      games.push(...lichessResp.topGames.map((game: any) => convertGame(game, 'lichess', 'top')));
    }
    if (lichessResp.recentGames) {
      games.push(
        ...lichessResp.recentGames.map((game: any) => convertGame(game, 'lichess', 'recent'))
      );
    }

    lichessGames.value = games;
    matchingGame.value = null;

    if (cs.selectedCourseSectionData != null) {
      const matchingGameIndex = matchGame(
        cs.selectedCourseSectionData,
        games.map((game) => ({
          date: game.date,
          white: game.white.name,
          black: game.black.name,
          whiteElo: game.white.rating.toString(),
          blackElo: game.black.rating.toString(),
        }))
      );

      if (matchingGameIndex != null) {
        matchingGame.value = games[matchingGameIndex];
        lichessGames.value.splice(matchingGameIndex, 1);
      }
    }

    refreshingLichessData.value = false;
  }

  function matchGame(candidate: any, games: MatchGame[]): number | null {
    return cs.findMatchingGames(candidate, games);
  }

  function getCurrentUciLine(): string {
    return getCurrentSanLineFromUidPath(cs.findNodePath(cs.selectedNode?.uid))
      .map((move) => move.uci)
      .join(',');
  }

  function formatDate(monthString: string): string {
    const formattedDate = new Intl.DateTimeFormat('se', {
      year: 'numeric',
      month: 'short',
    }).format(new Date(monthString));

    return formattedDate;
  }
</script>

<style scoped></style>
