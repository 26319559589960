<template>
  <div style="display: flex; flex-direction: column; gap: 1rem">
    <div v-if="currentView == 'lichess'">
      <LichessExplorer :fen="selectedFen" />
    </div>
    <div v-if="currentView == 'course'">
      <div v-if="matchingCurrentGame != null && matchingCurrentGame.uids.length > 1">
        <h2 style="font-size: 1.3rem">Transpositions in current game</h2>
        <div style="width: 100%; max-height: 10rem; overflow: auto">
          <table class="table table-striped table-responsive course-game-explorer">
            <tbody>
              <tr>
                <td style="white-space: nowrap; overflow: hidden">
                  <div style="display: flex; flex-direction: column; gap: 0.5rem">
                    <div>
                      <component :is="getGameElements(matchingCurrentGame)" />
                    </div>
                    <div
                      style="display: flex; gap: 0.5rem"
                      v-if="matchingCurrentGame.uids.length > 1"
                    >
                      <span
                        role="button"
                        style="color: var(--clr-accent2)"
                        v-for="(uid, index) in matchingCurrentGame.uids"
                        :key="'pos' + index"
                        @click="
                          useCourseStore().navigateToUid(
                            uid,
                            matchingCurrentGame.type,
                            matchingCurrentGame.id
                          )
                        "
                        ><i class="fa-solid fa-arrows-cross" /> Position {{ index + 1 }}</span
                      >
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div style="display: flex; flex-direction: column; gap: 0.5rem">
        <h2 style="font-size: 1.3rem">Matching Games</h2>
        <span v-if="matchingOtherGames.length <= 0" style="color: var(--clr-main-lighter)"
          >No matching games</span
        >
        <div v-else style="width: 100%; max-height: 10rem; overflow: auto">
          <table class="table table-striped table-responsive course-game-explorer">
            <tbody>
              <tr
                v-for="(otherGame, index) of matchingOtherGames as any[]"
                :key="'model' + index"
                role="button"
              >
                <td style="white-space: nowrap; overflow: hidden">
                  <div style="display: flex; flex-direction: column; gap: 0.5rem">
                    <div
                      @click="
                        useCourseStore().navigateToUid(
                          otherGame.uids[0],
                          otherGame.type,
                          otherGame.id
                        )
                      "
                      role="button"
                    >
                      <component :is="getGameElements(otherGame)" />
                    </div>
                    <div style="display: flex; gap: 0.5rem" v-if="otherGame.uids.length > 1">
                      <span
                        role="button"
                        style="color: var(--clr-accent2)"
                        v-for="(uid, index) in otherGame.uids"
                        :key="'pos' + index"
                        @click="useCourseStore().navigateToUid(uid, otherGame.type, otherGame.id)"
                        ><i class="fa-solid fa-arrows-cross" /> Position {{ index + 1 }}</span
                      >
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div style="display: flex; justify-content: space-between; align-items: center">
      <div style="display: flex; gap: 0.5rem">
        <div>
          <button
            class="btn btn-info ph-no-capture"
            :class="{ active: currentView == 'course' }"
            @click="currentView = 'course'"
          >
            <i class="fa-regular fa-chess-board" style="margin-right: 0.2rem" />
            Course Games
          </button>
        </div>
        <div>
          <button
            class="btn btn-info ph-no-capture"
            :class="{ active: currentView == 'lichess' }"
            @click="currentView = 'lichess'"
          >
            <div style="display: flex; gap: 0.3rem; align-items: center">
              <svg
                viewBox="0 0 50 50"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                stroke="currentColor"
                height="1rem"
                style="margin-top: -0.2rem"
              >
                <path
                  stroke-linejoin="round"
                  d="M38.956.5c-3.53.418-6.452.902-9.286 2.984C5.534 1.786-.692 18.533.68 29.364 3.493 50.214 31.918 55.785 41.329 41.7c-7.444 7.696-19.276 8.752-28.323 3.084C3.959 39.116-.506 27.392 4.683 17.567 9.873 7.742 18.996 4.535 29.03 6.405c2.43-1.418 5.225-3.22 7.655-3.187l-1.694 4.86 12.752 21.37c-.439 5.654-5.459 6.112-5.459 6.112-.574-1.47-1.634-2.942-4.842-6.036-3.207-3.094-17.465-10.177-15.788-16.207-2.001 6.967 10.311 14.152 14.04 17.663 3.73 3.51 5.426 6.04 5.795 6.756 0 0 9.392-2.504 7.838-8.927L37.4 7.171z"
                />
              </svg>
              Lichess
            </div>
          </button>
        </div>
      </div>
      <div
        v-if="currentView == 'course'"
        style="
          font-size: 0.8rem;
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 0.5rem;
        "
        v-tippy="{
          content: `Only show games that contains FEN: ${selectedFen}`,
        }"
      >
        <span>Only Current Position</span>
        <Toggle
          v-model="usePageStore().courseSettings.tabs.games.filterByPosition"
          onLabel=""
          offLabel=""
          class="ph-no-capture"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { Fragment, computed, h, ref, watch } from 'vue';

  import Toggle from '@vueform/toggle';
  import { storeToRefs } from 'pinia';

  import LichessExplorer from '@/components/common/tree/LichessExplorer.vue';
  import { useCourseStore } from '@/stores/courseStore';
  import { usePageStore } from '@/stores/pageStore';
  import { STARTING_FEN_SHORT, shortFen } from '@/util/util';

  const cs = useCourseStore();

  const { activeCourseGame } = storeToRefs(cs);

  const selectedFen = computed(() => {
    return cs.selectedNode?.fen == null ? STARTING_FEN_SHORT : shortFen(cs.selectedNode.fen);
  });

  const currentView = ref<'course' | 'lichess'>(
    cs.selectedCourseSection == 'gamesinternal' ? 'course' : 'lichess'
  );
  const matchingCurrentGame = ref<MatchingGame>();
  const matchingOtherGames = ref<MatchingGame[]>([]);

  type MatchingGame = {
    id: string;
    fen: string;
    uids: number[];
    type: 'model' | 'reference' | 'main' | 'video';
    data: Map<string, string> | null;
  };

  watch(
    () => cs.selectedCourseSection,
    () => {
      if (cs.selectedCourseSection == 'gamesinternal') {
        currentView.value = 'course';
      } else {
        currentView.value = 'lichess';
      }
    }
  );

  watch(
    () => [selectedFen.value, usePageStore().courseSettings.tabs.games.filterByPosition],
    () => {
      updateMatchingGames();
    },
    { immediate: true }
  );

  function updateMatchingGames() {
    matchingCurrentGame.value = undefined;
    matchingOtherGames.value = [];

    const matches =
      cs.explorer[
        usePageStore().courseSettings.tabs.games.filterByPosition
          ? shortFen(selectedFen.value)
          : STARTING_FEN_SHORT
      ];

    if (matches != null) {
      matches.forEach((game) => {
        if (game.type == 'video') {
          // No videos in this component
          return;
        }

        let headers = null;

        if (game.type == 'model') {
          headers = cs.courseDataModelGames![game.id].headerData;
        } else if (game.type == 'reference') {
          headers = cs.courseDataReferenceGames![parseInt(game.id)].headerData;
        }

        const entry = {
          id: game.id,
          fen: selectedFen.value,
          uids: game.uids,
          type: game.type,
          data: headers,
        };

        if (game.id == activeCourseGame.value.id && game.type == activeCourseGame.value.type) {
          matchingCurrentGame.value = entry;
        } else {
          matchingOtherGames.value.push(entry);
        }
      });
    }
    // Define the ordering for types.
    const typeOrder: Record<string, number> = {
      main: 0,
      model: 1,
      reference: 2,
    };

    // Helper to check if a string is numeric (only digits).
    function isNumeric(str: string): boolean {
      return /^\d+$/.test(str);
    }

    // Sort matchingOtherGames based on type and then on id.
    matchingOtherGames.value.sort((a, b) => {
      // Primary sort by type order.
      const orderDiff = typeOrder[a.type] - typeOrder[b.type];
      if (orderDiff !== 0) return orderDiff;

      // Secondary sort on id:
      const aIsNum = isNumeric(a.id);
      const bIsNum = isNumeric(b.id);

      if (aIsNum && bIsNum) {
        // If both are numeric, sort by their numeric value.
        return Number(a.id) - Number(b.id);
      } else if (aIsNum && !bIsNum) {
        // Numeric ids come first.
        return -1;
      } else if (!aIsNum && bIsNum) {
        return 1;
      } else {
        // If neither is numeric, sort alphabetically.
        return a.id.localeCompare(b.id);
      }
    });
  }

  function getGameElements(matchingGame: MatchingGame) {
    if (matchingGame.type == 'video') {
      // This list should be filtered, so shouldn't have video games here
      console.error('Video match in games list');
    }

    let text = 'Main Course';
    let icon = 'fa-solid fa-chess-king';
    let color = 'var(--clr-maingame)';
    if (matchingGame.type == 'model') {
      text = 'Model Game ' + matchingGame.id;
      icon = 'fa-solid fa-chess-queen';
      color = 'var(--clr-modelgame)';
    } else if (matchingGame.type == 'reference') {
      text = 'Reference Game ' + (parseInt(matchingGame.id) + 1);
      icon = 'fa-solid fa-chess-rook';
      color = 'var(--clr-referencegame)';
    }

    return h(Fragment, [
      // First span with chess board icon and title text
      h('span', { style: `color: ${color}; margin-right: 0.5rem` }, [
        h('i', {
          class: `${icon}`,
          style: { marginRight: '0.2rem' },
        }),
        text,
      ]),
      // Conditionally add the details span if data exists
      matchingGame.data !== null
        ? h(
            'span',
            { style: 'color: var(--clr-main-lighter)' },
            `(${matchingGame.data.get('White')} vs ${matchingGame.data.get('Black')})`
          )
        : null,
    ]);
  }
</script>

<style scoped>
  /* Targeting td here since there's only one column, for some reason tr doesn't work */
  .table td:hover {
    background-color: var(--clr-main-lightest);
  }

  .table th,
  .table td {
    text-align: left;
    border: 1px solid #dee2e6;
  }

  .table th.col-auto,
  .table td.col-auto {
    width: 1%;
    white-space: nowrap;
  }

  .table th.col,
  .table td.col {
    width: 100%;
  }
</style>
