<template>
  <div id="text-spot" style="overflow-x: hidden; overflow-y: hidden">
    <WinAnimation v-if="showWin" :termination="termination" />
    <GainedPointsAnimation :points="points" v-if="showPoints" />
  </div>
</template>

<script setup lang="ts">
  import { type PropType, onMounted, ref } from 'vue';

  import type { GameTermination } from '@/types/apitypes';

  import GainedPointsAnimation from './GainedPointsAnimation.vue';
  import WinAnimation from './WinAnimation.vue';

  defineProps({
    points: {
      type: Number,
      required: true,
    },
    termination: {
      type: Object as PropType<GameTermination>,
    },
  });

  const showPoints = ref(false);
  const showWin = ref(false);
  onMounted(() => {
    showWin.value = true;
    setTimeout(() => {
      showWin.value = false;
      showPoints.value = true;
    }, 4000);
  });
</script>

<style scoped>
  #text-spot {
    height: 100%;
    width: 100%;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
</style>
