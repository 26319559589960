<template>
  <div
    class="card"
    style="
      display: flex;
      flex-direction: row;
      align-items: start;
      padding: 1rem;
      justify-content: space-between;
    "
  >
    <div style="display: flex; align-items: start; flex-direction: column">
      <div style="margin-bottom: 0.5rem">
        <component :is="getGameHeaderElements(modelGame)" />
      </div>

      <div style="display: flex; flex-direction: column; align-items: start; gap: 0rem">
        <span class="nowrap-name" style="font-size: 1.5em; color: var(--clr-main)">
          <div
            style="
              width: 1rem;
              height: 1rem;
              background-color: white;
              display: inline-block;
              border: 2px solid black;
              border-radius: 50%;
            "
          />
          {{ modelGame.player1.name }}
          <span style="color: var(--clr-main-lighter)">{{
            modelGame.player1.elo != '' ? '(' + modelGame.player1.elo + ')' : ''
          }}</span>
        </span>
        <span class="nowrap-name" style="font-size: 1.5em; color: var(--clr-main)">
          <div
            style="
              width: 1rem;
              height: 1rem;
              background-color: #5d5d5d;
              display: inline-block;
              border: 2px solid black;
              border-radius: 50%;
            "
          />
          {{ modelGame.player2.name }}
          <span style="color: var(--clr-main-lighter)">{{
            modelGame.player2.elo != '' ? '(' + modelGame.player2.elo + ')' : ''
          }}</span>
        </span>
        <span style="font-size: 0.9em; color: var(--clr-main-lighter)">
          ({{ modelGame.eventText }} {{ modelGame.dateText }})</span
        >
      </div>
    </div>
    <div
      style="
        display: flex;
        align-items: start;
        gap: 0.5rem;
        display: flex;
        flex-direction: column;
        justify-content: start;
      "
    >
      <div class="nav-button" v-if="modelGame.type == 'model'">
        <button
          class="btn btn-info smaller-button"
          @click="useCourseStore().navigateToUid(null, 'model', cs.getPreviousModelGameId)"
          :disabled="cs.getPreviousModelGameId == null"
        >
          <i class="fa-solid fa-caret-left" />
        </button>
        <span>Previous</span>
      </div>
      <div class="nav-button" v-if="modelGame.type == 'model'">
        <button
          class="btn btn-info smaller-button"
          @click="useCourseStore().navigateToUid(null, 'model', cs.getNextModelGameId)"
          :disabled="cs.getNextModelGameId == null"
        >
          <i class="fa-solid fa-caret-right" />
        </button>
        <span>Next</span>
      </div>
      <div class="nav-button">
        <button
          class="btn btn-info smaller-button"
          @click="useCourseStore().navigateToUid(null, 'main', null)"
        >
          <i class="fa-solid fa-share fa-flip-horizontal" />
        </button>
        <span>To course</span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { Fragment, computed, h, onMounted, ref } from 'vue';
  import { useRoute } from 'vue-router';

  import router from '@/router';
  import { useCourseStore } from '@/stores/courseStore';

  const cs = useCourseStore();
  const route = useRoute();

  const currentGameId = computed(() => {
    return route.query.gameid != null ? (route.query.gameid as string) : null;
  });

  const modelGame = ref({
    player1: { name: '', elo: '' },
    player2: { name: '', elo: '' },
    eventText: '',
    dateText: '',
    type: '',
  });

  onMounted(() => {
    if (cs.getHeaderData != null) {
      modelGame.value.player1.name = cs.getHeaderData.get('White') ?? '';
      modelGame.value.player1.elo = cs.getHeaderData.get('WhiteElo') ?? '';
      modelGame.value.player2.name = cs.getHeaderData.get('Black') ?? '';
      modelGame.value.player2.elo = cs.getHeaderData.get('BlackElo') ?? '';
      modelGame.value.eventText = cs.getHeaderData.get('Event') ?? '';
      modelGame.value.dateText = formatPgnDate(cs.getHeaderData.get('Date'));
      modelGame.value.type = cs.activeCourseGame.type;
    }
  });

  function formatPgnDate(pgnDate: string | undefined): string {
    if (pgnDate === '' || pgnDate == null) {
      return '';
    }

    // PGN dates are typically in "YYYY.MM.DD" format.
    // If any component is missing or unknown (e.g. "??"), we return the original string.
    const parts = pgnDate.split('.');
    if (parts.length !== 3 || parts.includes('??')) {
      return pgnDate;
    }
    const [yearStr, monthStr, dayStr] = parts;
    const year = Number(yearStr);
    const month = Number(monthStr) - 1; // JavaScript Date months are zero-based.
    const day = Number(dayStr);

    if (isNaN(year) || isNaN(month) || isNaN(day)) {
      return pgnDate;
    }

    const dateObj = new Date(year, month, day);
    // Use toLocaleDateString with options for short month, numeric day and year.
    return dateObj.toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    });
  }

  function getGameHeaderElements(modelGame: any) {
    let text = 'Main Course';
    let icon = 'fa-solid fa-chess-king';
    let color = 'var(--clr-maingame)';
    if (modelGame.type == 'model') {
      text = 'Model Game ' + currentGameId.value;
      icon = 'fa-solid fa-chess-queen';
      color = 'var(--clr-modelgame)';
    } else if (modelGame.type == 'reference') {
      text = 'Reference Game ' + (parseInt(currentGameId.value!) + 1);
      icon = 'fa-solid fa-chess-rook';
      color = 'var(--clr-referencegame)';
    } else if (modelGame.type == 'lichess') {
      text = 'External Game';
      icon = 'fa-solid fa-arrow-up-right-from-square';
      color = 'var(--clr-externalgame)';
    } else if (modelGame.type == 'video') {
      text = 'Video Game';
      icon = 'fa-solid fa-video';
      color = 'var(--clr-videogame)';
    }

    return h(Fragment, [
      h('i', {
        class: icon,
        style: { marginRight: '0.5rem', color: color },
      }),
      h('span', { style: 'display: inline-block; transform: translateY(2px)' }, text),
    ]);
  }
</script>

<style scoped>
  .nowrap-name {
    max-width: 20rem;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .nav-button {
    display: flex;
    gap: 0.5rem;
    justify-content: start;
    align-items: center;
    width: 6rem;
    white-space: nowrap;
    color: var(--clr-main-lighter);
  }

  .nav-button > button {
    min-width: 2rem;
  }
</style>
