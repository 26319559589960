<template>
  <div class="move-comment" :style="getVariationDecoration()">
    <component :is="decoratedComment" />
  </div>
</template>

<script setup lang="ts">
  import { computed, h, ref } from 'vue';
  import type { PropType } from 'vue';

  import { useCourseStore } from '@/stores/courseStore';
  import { usePageStore } from '@/stores/pageStore';
  import { isAlphanumeric } from '@/util/util';

  const props = defineProps({
    comment: {
      type: String,
      required: true,
    },
    fen: {
      type: String,
      required: true,
    },
    variationType: {
      type: Array as PropType<string[]>,
      required: false,
    },
  });

  const ps = usePageStore();

  function getVariationDecoration() {
    let style = {};
    if (props.variationType?.includes('main')) {
      style = {
        color: 'var(--clr-main)',
      };
    } else {
      style = {
        fontStyle: 'italic',
        color: 'var(--clr-main-light)',
      };
    }

    if (ps.courseSettings.colors.mode == 'fg') {
      if (props.variationType?.includes('main')) {
        style = {
          color: ps.courseSettings.colors.main.fg.override,
        };
      } else if (props.variationType?.includes('caution')) {
        style = { color: ps.courseSettings.colors.caution.fg.override };
      } else if (props.variationType?.includes('alternative')) {
        style = {
          color: ps.courseSettings.colors.alternative.fg.override,
        };
      }
    }

    return style;
  }

  function isChessMove(token: string): boolean {
    // For testing purposes only, remove trailing commas, periods, or right parentheses.
    const testToken = token.replace(/[,.)]+$/, '');
    const moveRegex = new RegExp(
      '^(?:' +
        // Optional prefix: either a move number with 1–3 dots (e.g. "3." or "3...")
        // or an ellipsis ("...") on its own, possibly followed by spaces.
        '(?:(?:\\d+\\.{1,3}|\\.{3})\\s*)?' +
        '(?:' +
        // Castling moves: O-O/O-O-O or 0-0/0-0-0.
        '(?:O-O(?:-O)?|0-0(?:-0)?)' +
        '|' +
        // Standard move:
        // Optional piece letter (K, Q, R, B, N)
        '[KQRBN]?' +
        // Optional disambiguation: file and/or rank.
        '[a-h]?[1-8]?' +
        // Optional separator: hyphen or "x"
        '(?:[-x])?' +
        // Required destination square (file and rank)
        '[a-h][1-8]' +
        // Optional promotion (e.g., "=Q")
        '(?:=[QRBN])?' +
        ')' +
        // Optional check or mate symbols (+ or #)
        '(?:[+#])?' +
        // Optional annotation symbols (e.g., !, ? or their combinations)
        '(?:[!?]+)?' +
        ')$'
    );
    return moveRegex.test(testToken);
  }

  function wrapReferenceGames(text: string): Array<string | ReturnType<typeof h>> {
    const parts: Array<string | ReturnType<typeof h>> = [];
    let currentIndex = 0;

    while (currentIndex < text.length) {
      // Find the next dash from currentIndex.
      const dashIndex = text.indexOf('-', currentIndex);
      if (dashIndex === -1 || dashIndex >= text.length - 1) {
        parts.push(text.substring(currentIndex));
        break;
      }
      // The dash must be immediately followed by a non-space character.
      if (/\s/.test(text.charAt(dashIndex + 1))) {
        parts.push(text.substring(currentIndex, dashIndex + 1));
        currentIndex = dashIndex + 1;
        continue;
      }
      // Look ahead from dashIndex to find a termination: a whitespace followed by exactly four digits.
      const afterDash = text.substring(dashIndex);
      const termMatch = afterDash.match(/\s+\d{4}\b/);
      if (!termMatch || termMatch.index === undefined) {
        parts.push(text.substring(currentIndex, dashIndex + 1));
        currentIndex = dashIndex + 1;
        continue;
      }
      // candidateEnd: absolute index where the candidate ends.
      const candidateEnd = dashIndex + termMatch.index + termMatch[0].length;

      // Check the number of words between the dash and the year.
      const betweenText = text.substring(dashIndex, candidateEnd);
      const wordsAfterDash = betweenText.split(/\s+/);
      if (
        wordsAfterDash.length > 7 ||
        !isAlphanumeric(wordsAfterDash[0][1]) ||
        !isAlphanumeric(wordsAfterDash[0][wordsAfterDash.length - 1])
      ) {
        parts.push(text.substring(currentIndex, dashIndex + 1));
        currentIndex = dashIndex + 1;
        continue;
      }

      // Determine candidateStart.
      const preSegment = text.substring(currentIndex, dashIndex);
      const tokenRegex = /\S+/g;
      const tokens: { token: string; index: number }[] = [];
      let m: RegExpExecArray | null;
      while ((m = tokenRegex.exec(preSegment)) !== null) {
        tokens.push({ token: m[0], index: m.index });
      }
      let candidateStart: number;
      if (tokens.length === 0) {
        candidateStart = dashIndex;
      } else {
        // The candidate core is the last token, unless it's a parenthesized number.
        let coreIndex = tokens.length - 1;
        if (/^\(\d+\)$/.test(tokens[coreIndex].token) && tokens.length > 1) {
          coreIndex--;
        }
        candidateStart = tokens[coreIndex].index;
        // Extend backward: include any immediately preceding tokens that start with an uppercase letter.
        for (let j = coreIndex - 1; j >= 0; j--) {
          if (/^[A-Z]/.test(tokens[j].token)) {
            candidateStart = tokens[j].index;
          } else {
            break;
          }
        }
        candidateStart = currentIndex + candidateStart;
      }

      const candidate = text.substring(candidateStart, candidateEnd);

      if (candidateStart > currentIndex) {
        parts.push(text.substring(currentIndex, candidateStart));
      }

      const referenceGameId: number | null = useCourseStore().findReferenceGame(candidate);

      if (referenceGameId == null) {
        parts.push(
          h(
            'externalgame',
            {
              role: 'button',
              onClick: () => useCourseStore().setSelectedCourseSection('gameslichess', candidate),
            },
            [
              h('i', {
                class: 'fa-solid fa-chess-board',
                style: { marginRight: '0.2rem' },
              }),
              candidate,
            ]
          )
        );
      } else {
        parts.push(
          h(
            'referencegame',
            {
              role: 'button',
              onClick: () => useCourseStore().navigateToUid(null, 'reference', referenceGameId),
            },
            [
              h('i', {
                class: 'fa-solid fa-chess-board',
                style: { marginRight: '0.2rem' },
              }),
              candidate,
            ]
          )
        );
      }

      currentIndex = candidateEnd;
    }
    return parts;
  }

  /**
   * Splits a plain text string into tokens (including whitespace) and wraps any token
   * that is a chess move in a <move> element.
   */
  function wrapChessMoves(text: string): Array<string | ReturnType<typeof h>> {
    // Split the text so that whitespace is preserved.
    const tokens = text.split(/(\s+)/);
    return tokens.map((token) => {
      if (/^\s+$/.test(token)) {
        return token;
      }
      return isChessMove(token) ? h('move', token) : token;
    });
  }

  /**
   * For any part that is a plain text string, further process it so that
   * any chess moves get wrapped in a <move> element.
   */
  function processTextParts(
    parts: Array<string | ReturnType<typeof h>>
  ): Array<string | ReturnType<typeof h>> {
    const result: Array<string | ReturnType<typeof h>> = [];
    parts.forEach((part) => {
      if (typeof part === 'string') {
        result.push(...wrapChessMoves(part));
      } else {
        result.push(part);
      }
    });
    return result;
  }

  const decoratedComment = computed(() => {
    const parts: Array<string | ReturnType<typeof h>> = [];
    const commentText = props.comment;

    // Regex to find ***MODEL GAME X*** pattern.
    const modelGameRegex = /\*\*\*MODEL GAME (\d+)\*\*\*/g;

    let lastIndex = 0;
    let match: RegExpExecArray | null;
    while ((match = modelGameRegex.exec(commentText)) !== null) {
      const index = match.index;
      // Process any text before this model game marker,
      // wrapping reference games and then chess moves.
      if (index > lastIndex) {
        const preText = commentText.slice(lastIndex, index);
        const refParts = wrapReferenceGames(preText);
        parts.push(...processTextParts(refParts));
      }
      const modelGameNumber = match[1];
      // Build the base children for the modelgame element.
      let children: Array<string | ReturnType<typeof h>> = [
        h('i', {
          class: 'fa-solid fa-chess-board',
          style: { marginRight: '0.2rem' },
        }),
        `Model game ${modelGameNumber}: `,
      ];

      // Now check whether immediately following the model game marker there is a reference game.
      const remaining = commentText.slice(modelGameRegex.lastIndex);
      const anchoredRefRegex = new RegExp(
        '^\\s*' +
          '([A-Z][\\p{L}\\p{M}0-9_\\- ,]+?)' +
          '(?:\\s*\\((\\d+)\\))?' +
          '(?:\\s*-\\s*([A-Z][\\p{L}\\p{M}0-9_\\- ,]+?)' +
          '(?:\\s*\\((\\d+)\\))?)?' +
          '\\s+([\\p{L}\\p{M}\\p{N}\\.\\s]+?)' +
          '\\s+(\\d{4})',
        'u'
      );
      const refMatch = anchoredRefRegex.exec(remaining);
      if (refMatch) {
        // Append the reference game text (with any leading whitespace trimmed)
        children.push(refMatch[0].trim());
        // Advance the modelGameRegex.lastIndex by the length of the consumed reference game text.
        modelGameRegex.lastIndex += refMatch[0].length;
      }

      // Process the children so that any plain text gets checked for chess moves.
      children = processTextParts(children);
      parts.push(
        h(
          'modelgame',
          {
            role: 'button',
            onClick: () => useCourseStore().navigateToFen(props.fen, 'model', modelGameNumber),
          },
          children
        )
      );
      lastIndex = modelGameRegex.lastIndex;
    }
    if (lastIndex < commentText.length) {
      const remainingText = commentText.slice(lastIndex);
      const refParts = wrapReferenceGames(remainingText);
      parts.push(...processTextParts(refParts));
    }
    return h('span', parts);
  });
</script>

<style scoped>
  .move-comment {
    padding: 0.2rem 0.5rem;
  }

  move {
    font-weight: bold;
    color: var(--clr-accent2);
  }

  modelgame {
    font-weight: 800;
    color: var(--clr-modelgame);
  }

  referencegame {
    color: var(--clr-referencegame);
  }

  externalgame {
    color: var(--clr-externalgame);
  }
</style>
