<template>
  <div
    :class="
      'card container' + (loadingBots || activeChallenge === undefined ? ' pulsate-load' : '')
    "
  >
    <div v-if="loadingBots || activeChallenge === undefined">
      <div class="top-area">
        <div class="top-left">
          <div class="main-gradient weekly-box">
            <h3 class="beat-them-text" />
            <span class="choose-difficulty-text">Loading challenge...</span>
          </div>
        </div>
        <div class="top-right">
          <h2 class="beat-them-text">&nbsp;</h2>
          <h2 class="opening-text">&nbsp;</h2>
        </div>
      </div>
      <div class="bottom-area">
        <div class="choose-difficulty">
          <h2 class="choose-difficulty-text">&nbsp;</h2>
          <div class="difficulties">
            <div class="difficulty zoom-hover" v-for="n in 5" :key="n">
              <img class="bot-profile-image" />
              <h5>&nbsp;</h5>
              <h6 style="margin-top: -0.5rem; color: var(--clr-main-lighter)">&nbsp;</h6>
              <img
                alt="Golden star outline"
                style="filter: hue-rotate(180deg)"
                src="https://storage.googleapis.com/chessiverse1/images/elements/star_grey.webp"
              />
            </div>
          </div>
        </div>
        <div class="chessboard" v-if="isDesktop()">
          <TheChessboard
            :board-config="{
              orientation: activeChallenge?.user_side as 'white' | 'black',
              blockTouchScroll: false,
              coordinates: false,
              viewOnly: true,
            }"
          />
        </div>
      </div>
    </div>
    <div v-else>
      <div class="top-area">
        <div class="top-left">
          <div class="main-gradient weekly-box">
            <h3 class="beat-them-text" />
            <span class="choose-difficulty-text"
              >Weekly challenge (Ends in:
              <span style="color: var(--clr-accent)">{{ timeLeft }}</span
              >)</span
            >
          </div>
        </div>
        <div class="top-right">
          <h2 class="beat-them-text">
            {{ activeChallenge?.presentation!.title }}
          </h2>
          <h2
            class="opening-text"
            v-tippy="{
              content: activeChallenge?.presentation!.description,
              placement: 'top',
            }"
          >
            {{ activeChallenge?.presentation!.subtitle
            }}<i
              class="fa-solid fa-comment-exclamation"
              style="vertical-align: super; font-size: 0.8rem; color: white"
            />
          </h2>
        </div>
      </div>
      <div class="bottom-area">
        <div class="choose-difficulty">
          <h2 class="choose-difficulty-text">Choose opponent:</h2>
          <div class="difficulties">
            <div
              v-for="(difficulty, index) in difficulties"
              :key="'difficulty' + index"
              class="difficulty zoom-hover ph-no-capture"
              @click="
                () => {
                  gotoGame(difficulty.name.toLocaleLowerCase());
                  track('weekly_challenge_box', 'select_difficulty', 'click', {
                    challengeId: activeChallenge?.id,
                    selectedDifficulty: difficulty,
                  });
                }
              "
            >
              <img
                :src="ps.img(difficulty.botId, ImageType.BotProfile, '175')"
                :style="{
                  backgroundColor: difficulty.color,
                  animationDelay: index * 0.1 + 's',
                }"
                class="bot-profile-image bounce-top"
                alt="Bot profile image"
              />
              <h5>
                {{ getDifficultyDisplayName(difficulty.name) }}
              </h5>
              <h6 style="margin-top: -0.5rem; color: var(--clr-main-lighter)">
                ({{ bs.getBot(difficulty.botId).strength.estimated_elo }})
              </h6>
              <img
                v-if="difficulty.userWon"
                alt="Golden star"
                style="filter: hue-rotate(180deg)"
                src="https://storage.googleapis.com/chessiverse1/images/elements/star.webp"
              />
              <img
                v-else
                alt="Golden star outline"
                style="filter: hue-rotate(180deg)"
                src="https://storage.googleapis.com/chessiverse1/images/elements/star_grey.webp"
              />
            </div>
          </div>
        </div>
        <div class="chessboard" v-if="isDesktop()">
          <TheChessboard
            :board-config="{
              fen: activeChallenge?.start_position,
              orientation: activeChallenge?.user_side as 'white' | 'black',
              blockTouchScroll: false,
              coordinates: false,
              viewOnly: true,
            }"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { type PropType, type Ref, ref, watch } from 'vue';
  import { BoardApi, TheChessboard } from 'vue3-chessboard';
  import { useRouter } from 'vue-router';

  import { useBotsStore } from '@/stores/botStore';
  import { usePageStore } from '@/stores/pageStore';
  import type { ChallengeFromPosition } from '@/types/apitypes';
  import { ImageType } from '@/types/internaltypes';
  import { track } from '@/util/tracking';
  import { timeLeftUntilString } from '@/util/util';

  const props = defineProps({
    activeChallenge: {
      type: Object as PropType<ChallengeFromPosition>,
    },
  });

  const router = useRouter();

  const ps = usePageStore();
  const bs = useBotsStore();

  const loadingBots = ref(true);
  const difficulties: Ref<{ botId: string; name: string; color: string; userWon: boolean }[]> = ref(
    []
  );
  const timeLeft: Ref<string> = ref('');

  watch(
    () => props.activeChallenge,
    () => {
      initWeekly();
    }
  );

  initWeekly();

  function getDifficultyDisplayName(difficultyName: string) {
    return difficultyName;
  }
  async function initWeekly() {
    if (props.activeChallenge === undefined) {
      return;
    }

    const results = await Promise.all([
      bs.getUserBotProfile(props.activeChallenge.difficulties!.beginner.botId),
      bs.getUserBotProfile(props.activeChallenge.difficulties!.novice.botId),
      bs.getUserBotProfile(props.activeChallenge.difficulties!.intermediate.botId),
      bs.getUserBotProfile(props.activeChallenge.difficulties!.skilled.botId),
      bs.getUserBotProfile(props.activeChallenge.difficulties!.advanced.botId),
    ]);

    difficulties.value = [
      {
        botId: props.activeChallenge.difficulties!.beginner.botId,
        name: 'Beginner',
        color: results[0].data.bot.config.boardbg,
        userWon: props.activeChallenge.difficulties!.beginner.userWon,
      },
      {
        botId: props.activeChallenge.difficulties!.novice.botId,
        name: 'Novice',
        color: results[1].data.bot.config.boardbg,
        userWon: props.activeChallenge.difficulties!.novice.userWon,
      },
      {
        botId: props.activeChallenge.difficulties!.intermediate.botId,
        name: 'Intermediate',
        color: results[2].data.bot.config.boardbg,
        userWon: props.activeChallenge.difficulties!.intermediate.userWon,
      },
      {
        botId: props.activeChallenge.difficulties!.skilled.botId,
        name: 'Skilled',
        color: results[3].data.bot.config.boardbg,
        userWon: props.activeChallenge.difficulties!.skilled.userWon,
      },
      {
        botId: props.activeChallenge.difficulties!.advanced.botId,
        name: 'Advanced',
        color: results[4].data.bot.config.boardbg,
        userWon: props.activeChallenge.difficulties!.advanced.userWon,
      },
    ];

    timeLeft.value = timeLeftUntilString(props.activeChallenge.end!.toString());

    loadingBots.value = false;
  }

  const gotoGame = (difficulty: string) => {
    router.push({
      name: 'game',
      query: {
        challengeId: props.activeChallenge?.id,
        difficulty: difficulty,
        type: 'challenge',
      },
    });
  };

  function isDesktop() {
    return window.innerWidth >= 1200;
  }
</script>

<style scoped>
  .tooltip-icon {
    height: 0.8rem;
    margin-left: 0.2rem;
    margin-top: -3rem;
    filter: invert(1);
  }

  .zoom-hover {
    transition: all 0.2s ease;
    cursor: pointer;
  }

  .zoom-hover:hover {
    color: var(--clr-accent);
  }

  .zoom-hover:hover .bot-profile-image {
    transform: scale(1.1) !important;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.8);
  }

  .choose-difficulty {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 1rem;
  }
  .difficulties {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;
  }
  .difficulty {
    font-size: 1.5em;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .difficulty img {
    --size: 2rem;
    height: var(--size);
    width: var(--size);
  }

  .difficulty .bot-profile-image {
    --size: 5rem;
    height: var(--size);
    width: var(--size);
    border-radius: 50%;
    border: 2px solid #fff;
  }

  :deep(cg-board) {
    background-color: var(--clr-accent); /* Override the board background color */
  }
  .container {
    display: flex;
    flex-direction: column;
    padding-inline: 4rem;
    padding-block: 2rem;
    gap: 2rem;
    font-size: 1rem;
    color: white;
  }
  .top-area {
    display: flex;
  }

  .top-right {
    display: flex;
    flex-direction: column;
    align-items: end;
  }

  .choose-difficulty-text {
    font-size: 1.5em;
  }

  .beat-them-text {
    font-size: 1.5rem;
    text-wrap: balance;
    color: var(--clr-main-light);
  }

  .opening-text {
    font-size: 3rem;
    color: white;
    margin-top: -1rem;
  }

  .chessboard {
    min-width: 15rem;
    aspect-ratio: 1;
  }

  .weekly-box {
    border: 1px solid var(--clr-main);
    padding: 1rem;
    width: 25rem;
    position: absolute;
    top: 0rem;
    left: -1rem;
    transform: rotate(-5deg);
    text-align: center;
  }

  .bottom-area {
    text-align: right;
    display: flex;
  }
  .choose-difficulty {
    flex: 1 1 auto;
  }

  .top-left {
    flex: 1 1 auto;
  }

  @media (max-width: 1399px) {
    .top-right {
      margin-top: 3rem;
    }
  }

  @media (max-width: 1199px) {
    .chessboard {
      min-width: 10rem;
      aspect-ratio: 1;
    }
    .top-area,
    .bottom-area {
      flex-direction: column;
      align-items: center;
      gap: 1rem;
    }
    .container {
      padding-inline: 0;
    }
    .top-right {
      align-items: center;
    }

    .difficulty {
      font-size: 1.2em;
      min-width: 5rem;
    }
    .difficulty .bot-profile-image {
      --size: 3.5rem;
    }

    .difficulties {
      justify-content: center;
    }

    .choose-difficulty-text {
      margin: auto;
      font-size: 1.2em;
    }

    .beat-them-text {
      font-size: 1.5em;
    }

    .opening-text {
      font-size: 2.3em;
    }
    .weekly-box {
      text-align: center;
    }

    .tooltip-icon {
      margin-left: 0.2rem;
      margin-top: -0.9rem;
    }

    .opening-text {
      margin-top: -0.9rem;
    }
  }

  @media (max-width: 767px) {
    .difficulties {
      gap: 0.5rem;
    }

    .choose-difficulty {
      margin: 0rem auto;
    }
  }

  @media (max-width: 500px) {
    .beat-them-text {
      font-size: 1rem;
    }
    .opening-text {
      font-size: 1.8rem;
    }
    .weekly-box {
      width: 20rem;
      padding: 0.5rem;
    }

    .top-right {
      margin-top: 1.5rem;
    }

    .difficulty {
      min-width: 0rem;
    }

    .difficulty h5 {
      font-size: 0.8rem;
    }

    .difficulty h6 {
      font-size: 0.8rem;
    }
  }

  @media (max-width: 360px) {
    .difficulties {
      gap: 0rem;
    }
  }
</style>
