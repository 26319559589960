<template>
  <div class="move" :class="{ muted: muted }">
    <span v-if="ply != null && formattedMoveNumber != null" class="move-number">{{
      formattedMoveNumber
    }}</span>
    <div
      class="move-san"
      :class="{
        selected: useCourseStore().selectedNode?.uid === uid,
        chosenAlternative: chosenAlternative,
      }"
      @click="useCourseStore().selectMove(uid)"
    >
      <span :id="navigableName + '-' + uid">{{ san }} </span>
      <component :is="moveNag" />

      <i
        v-if="hasArrow"
        class="fa-solid fa-circle-arrow-up-right"
        style="
          margin-left: 0.1rem;
          vertical-align: super;
          font-size: 0.5rem;
          color: var(--clr-accent2);
        "
      />
      <i
        v-if="hasTranspositions"
        class="fa-solid fa-arrows-cross"
        style="
          margin-left: 0.1rem;
          vertical-align: super;
          font-size: 0.5rem;
          color: var(--clr-accent2);
        "
      />
    </div>
    <component :is="positionNag" />
  </div>
</template>

<script setup lang="ts">
  import { type PropType, type VNode, computed, defineProps, h, ref, watch } from 'vue';

  import { useCourseStore } from '@/stores/courseStore';
  import { shortFen } from '@/util/util';

  const props = defineProps({
    uid: {
      type: Number,
      required: true,
    },
    fen: {
      type: String,
      required: true,
    },
    ply: {
      type: Number,
    },
    san: {
      type: String,
      required: true,
    },
    nags: {
      type: Array as PropType<number[]>,
      required: false,
    },
    hasArrow: {
      type: Boolean,
      default: false,
    },
    partOfChain: {
      type: String as PropType<'start' | 'middle' | 'end' | null>,
      required: false,
    },
    muted: {
      type: Boolean,
      default: false,
    },
    navigableName: {
      // This is use to scroll in the tree, should override this if used in other places or it will get the same id in the dom and break scrolling
      type: String,
      default: 'move-tree-move',
    },
  });

  const cs = useCourseStore();
  const chosenAlternative = ref(false);
  const positionNag = computed(() => getPositionNag(props.nags));
  const moveNag = computed(() => getMoveNag(props.nags));
  const formattedMoveNumber = ref<string | null>(formatMoveNumber(props.ply));

  const hasTranspositions = ref<boolean>(checkHasTranspositions(props.fen));

  // Targetted reactivity suggested by Deepseek, instead of watching cs.chooseAlternative, we do this and it should only trigger the watch if the uid matches, avoiding to do the if-check for all other nodes
  const isChosenAlternative = computed(() => {
    return cs.choseAlternative?.uid === props.uid;
  });

  watch(isChosenAlternative, (newValue) => {
    chosenAlternative.value = newValue;
  });

  function checkHasTranspositions(fen: string): boolean {
    fen = shortFen(fen);

    const position = cs.explorer[fen]?.filter((game) => game.id == cs.activeCourseGame.id);

    return position != null && position.length > 0 && position[0].uids.length > 1;
  }

  function formatMoveNumber(input: number | undefined): string | null {
    if (input == null || input <= 0) {
      return null;
    }

    const moveNumber = Math.ceil(input / 2);

    if (input % 2 === 1) {
      return `${moveNumber}.`;
    } else if (props.partOfChain != null && props.partOfChain != 'start') {
      // If this move is part of chain, don't show black numbers (unless it's the first move of the chain)
      return null;
    } else {
      return `${moveNumber}..`;
    }
  }

  function getMoveNag(nags: number[] | undefined): VNode | null {
    const nodes = [];

    if (nags == null) {
      return null;
    }

    for (const nag of nags) {
      if (nag == 0) {
        continue;
      }

      if (nag > 8) {
        // Only tags 1-8 are for moves
        continue;
      }

      switch (nag) {
        case 1:
          nodes.push(h('span', { style: { color: 'var(--clr-nag-1)' } }, '!'));
          break;
        case 2:
          nodes.push(h('span', { style: { color: 'var(--clr-nag-2)' } }, '?'));
          break;
        case 3:
          nodes.push(h('span', { style: { color: 'var(--clr-nag-3)' } }, '!!'));
          break;
        case 4:
          nodes.push(h('span', { style: { color: 'var(--clr-nag-4)' } }, '??'));
          break;
        case 5:
          nodes.push(h('span', { style: { color: 'var(--clr-nag-5)' } }, '!?'));
          break;
        case 6:
          nodes.push(h('span', { style: { color: 'var(--clr-nag-6)' } }, '?!'));
          break;
        case 7:
          nodes.push(h('span', { style: {} }, '□'));
          break;
        default:
          console.error('Unknown move NAG: ' + nag);
      }
    }

    if (nodes.length > 1) {
      console.error('Multiple move NAGs found: ' + nags);
    }

    return nodes.length > 0 ? nodes[0] : null;
  }

  function getPositionNag(nags: number[] | undefined): VNode | null {
    const nodes = [];

    if (nags == null) {
      return null;
    }

    for (const nag of nags) {
      if (nag <= 8) {
        // Tags 1-8 are for moves
        continue;
      }

      switch (nag) {
        case 10:
        case 11:
        case 12:
          nodes.push(h('span', { style: { color: 'var(--clr-nag-common)' } }, '='));
          break;
        case 13:
          nodes.push(h('span', { style: { color: 'var(--clr-nag-common)' } }, '∞'));
          break;
        case 14:
          nodes.push(h('span', { style: { color: 'var(--clr-nag-common)' } }, '⩲'));
          break;
        case 15:
          nodes.push(h('span', { style: { color: 'var(--clr-nag-common)' } }, '⩱'));
          break;
        case 16:
          nodes.push(h('span', { style: { color: 'var(--clr-nag-common)' } }, '±'));
          break;
        case 17:
          nodes.push(h('span', { style: { color: 'var(--clr-nag-common)' } }, '∓'));
          break;
        case 18:
          nodes.push(h('span', { style: { color: 'var(--clr-nag-common)' } }, '+−'));
          break;
        case 19:
          nodes.push(h('span', { style: { color: 'var(--clr-nag-common)' } }, '−+'));
          break;
        case 22:
        case 23:
          nodes.push(h('span', { style: { color: 'var(--clr-nag-common)' } }, '⨀'));
          break;
        case 26:
        case 27:
          nodes.push(h('span', { style: { color: 'var(--clr-nag-common)' } }, '○'));
          break;
        case 32:
        case 33:
          nodes.push(h('span', { style: { color: 'var(--clr-nag-common)' } }, '⟳'));
          break;
        case 36:
        case 37:
          nodes.push(h('span', { style: { color: 'var(--clr-nag-common)' } }, '↑'));
          break;
        case 40:
        case 41:
          nodes.push(h('span', { style: { color: 'var(--clr-nag-common)' } }, '→'));
          break;
        case 44:
        case 45:
          nodes.push(h('span', { style: { color: 'var(--clr-nag-common)' } }, '⯹'));
          break;
        case 132:
        case 133:
          nodes.push(h('span', { style: { color: 'var(--clr-nag-common)' } }, '⇆'));
          break;
        case 138:
        case 139:
          nodes.push(h('span', { style: { color: 'var(--clr-nag-common)' } }, '⨁'));
          break;
        case 146:
          nodes.push(h('span', { style: { color: 'var(--clr-nag-common)' } }, 'N'));
          break;
        default:
          console.error('Unknown position NAG: ' + nag);
      }
    }

    if (nodes.length > 1) {
      console.error('Multiple move NAGs found: ' + nags);
    }

    return nodes.length > 0 ? nodes[0] : null;
  }
</script>

<style scoped>
  .move {
    display: flex;
    align-items: center;
    gap: 0.25rem;
  }

  .move-number {
    color: var(--clr-nag-common);
  }

  .move-san {
    display: flex;
    gap: 0.1rem;
    flex-wrap: nowrap;
    color: #000;
    border-radius: 4px;
    border: 2px solid rgba(255, 255, 255, 0); /* Highlight the selected move */
  }

  .move-san:hover {
    cursor: pointer;
    text-decoration: underline;
    border: 2px solid var(--clr-main-lighter); /* Highlight the selected move */
  }

  .move-san.selected {
    border: 2px solid #007bff; /* Highlight the selected move */
  }

  .move-san.chosenAlternative {
    border: 2px solid var(--clr-main-lighter); /* Highlight the chosen alternative */
  }

  .muted {
    opacity: 0.4;
  }
</style>
