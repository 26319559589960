import type { Ref } from 'vue';

export function useGamesActivity(timeframeRef: Ref<string>) {
  const getGamesInARow = (
    ratingHistory: any
  ): {
    consecutiveDaysWithEntries: number;
    consecutiveMissingDays: number;
  } => {
    if (ratingHistory == null) {
      return {
        consecutiveDaysWithEntries: 0,
        consecutiveMissingDays: 0,
      };
    }

    let filterMillis = 1;

    if (timeframeRef.value == 'week') {
      filterMillis = new Date().getTime() - 1000 * 60 * 60 * 24 * 7;
    } else if (timeframeRef.value == 'month') {
      filterMillis = new Date().getTime() - 1000 * 60 * 60 * 24 * 30;
    } else if (timeframeRef.value == 'year') {
      filterMillis = new Date().getTime() - 1000 * 60 * 60 * 24 * 365;
    }

    const orderedDaysWithRating = JSON.parse(JSON.stringify(ratingHistory.daily))
      .filter((r: any) => r.started_at._seconds * 1000 > filterMillis)
      .map((r: any) => {
        return new Date(r.started_at._seconds * 1000);
      })
      .sort((a: any, b: any) => a.getTime() - b.getTime());

    // Calculate the starting date based on daysAgo
    const today = new Date();
    const startDate = new Date();
    startDate.setDate(today.getDate() - 365);

    let currentStreak = 0;
    let currentMissingStreak = 0;

    let maxConsecutiveDaysWithEntries = 0;
    let maxConsecutiveMissingDays = 0;

    // Iterate from startDate to today, checking for gaps and streaks
    let lastEntryDate: Date | null = null;
    let foundFirstEntry = false;

    for (let d = new Date(startDate); d <= today; d.setDate(d.getDate() + 1)) {
      const isEntryFound = orderedDaysWithRating.some(
        (entryDate: Date) => entryDate.toDateString() === d.toDateString()
      );

      if (!foundFirstEntry && !isEntryFound) {
        continue;
      }

      if (isEntryFound) {
        foundFirstEntry = true;
        // Consecutive days with entries found
        currentStreak += 1;
        maxConsecutiveDaysWithEntries = Math.max(maxConsecutiveDaysWithEntries, currentStreak);
        currentMissingStreak = 0; // Reset missing streak
      } else {
        // Missing day found
        currentMissingStreak += 1;
        maxConsecutiveMissingDays = Math.max(maxConsecutiveMissingDays, currentMissingStreak);

        if (lastEntryDate) {
          currentStreak = 0; // Reset the streak when a missing day is found
        }
      }

      lastEntryDate = d; // Update the last checked day
    }

    return {
      consecutiveDaysWithEntries: maxConsecutiveDaysWithEntries,
      consecutiveMissingDays: maxConsecutiveMissingDays,
    };
  };

  const getGamesPerDay = (
    games: number,
    timeframe: 'week' | 'month' | 'year' | 'all',
    ratingHistory: any
  ) => {
    if (ratingHistory == null) {
      return 0;
    }

    function daysFromNow(date: Date) {
      const timeDifference = date.getTime() - new Date().getTime();
      const daysDifference = Math.abs(Math.ceil(timeDifference / (1000 * 3600 * 24)));
      return daysDifference;
    }

    let daysInPeriod;
    switch (timeframe) {
      case 'week':
        daysInPeriod = 7;
        break;
      case 'month':
        daysInPeriod = 30;
        break;
      case 'year':
        daysInPeriod = 365;
        break;
      case 'all':
      default:
        daysInPeriod = daysFromNow(new Date(ratingHistory.daily[0].started_at._seconds * 1000)) + 1;
    }

    return (games / daysInPeriod).toFixed(1);
  };

  const getLongestWinStreak = (gameResults: any[]): number => {
    if (!gameResults || gameResults.length === 0) {
      return 0;
    }

    // Sort games by end time to ensure chronological order
    const sortedGames = [...gameResults].sort((a, b) => {
      const aTime = a.ended_at ? new Date(a.ended_at.value || a.ended_at).getTime() : 0;
      const bTime = b.ended_at ? new Date(b.ended_at.value || b.ended_at).getTime() : 0;
      return aTime - bTime;
    });

    let currentStreak = 0;
    let longestStreak = 0;

    // Count consecutive wins
    for (const game of sortedGames) {
      if (game.result_user === 'win') {
        currentStreak++;
        longestStreak = Math.max(longestStreak, currentStreak);
      } else {
        currentStreak = 0;
      }
    }

    return longestStreak;
  };

  return {
    getGamesInARow,
    getGamesPerDay,
    getLongestWinStreak,
  };
}
