<template>
  <div
    v-if="botExists"
    class="card"
    style="
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 11.5rem;
      padding: 1rem;
      text-align: center;
      filter: drop-shadow(2px 2px 2px var(--clr-main-lighter));
      height: 19rem;
    "
  >
    <h3 style="height: 3.5rem; display: flex; justify-content: center; align-items: center">
      {{ heading }}
    </h3>
    <span style="font-size: 1.2rem">{{ loading ? '-' : subHeading }}</span>
    <ProfileCircleWithPlaque
      :bot="bot"
      :imageSize="5"
      style="margin-bottom: 1.5rem"
      role="button"
      @click="
        () => {
          useModalsStore().showBotProfile(botId);
        }
      "
    />
    <div style="white-space: pre-line">{{ loading ? '-' : footer }}</div>
  </div>
</template>
<script setup lang="ts">
  import { computed } from 'vue';

  import ProfileCircleWithPlaque from '@/components/common/ProfileCircleWithPlaque.vue';
  import { useBotsStore } from '@/stores/botStore';
  import { useModalsStore } from '@/stores/modalsStore';

  const props = defineProps({
    heading: {
      type: String,
      required: true,
    },
    subHeading: {
      type: String,
      required: true,
    },
    botId: {
      type: String,
      required: true,
    },
    footer: {
      type: String,
    },
    loading: {
      type: Boolean,
      default: true,
    },
  });

  const botsStore = useBotsStore();
  const bot = computed(() => botsStore.getBot(props.botId));
  const botExists = computed(() => bot.value !== undefined && bot.value !== null);
</script>
<style scoped></style>
