<template>
  <div
    class="card mainpractice rect-gradient-2"
    :style="{ minHeight: isMobileWidth() ? '15rem' : '25rem' }"
  >
    <div>
      <div class="header-icon-container">
        <i class="header-icon fa-solid fa-chess" />
      </div>
      <div style="max-width: 200px">
        <h1>Explore</h1>
        <span style="text-align: center">
          Explore all the bots, find your favorite or see how many you can beat!
        </span>
      </div>
    </div>
    <div class="categoryheader-points">
      <div
        v-tippy="{
          content: 'Number of beaten bots',
        }"
        class="categoryheader-points-item"
      >
        <div>
          <img src="https://storage.googleapis.com/chessiverse1/images/elements/star.webp" />
          <span>Beaten</span>
        </div>
        <span v-if="bots == null" style="width: 7rem; height: 2rem; text-align: center"
          ><LoaderNew size="2rem"
        /></span>
        <span
          v-else
          class="fade-in"
          style="font-size: 1.8rem; width: 7rem; height: 2rem; text-align: center"
          >{{ beaten }} / {{ bots.length }}</span
        >
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
  import { type PropType, watch } from 'vue';
  import { ref } from 'vue';

  import LoaderNew from '@/components/util/LoaderNew.vue';
  import type { Bot } from '@/types/apitypes';
  import { isMobileWidth } from '@/util/util';

  const props = defineProps({
    bots: {
      type: Object as PropType<Bot[]>,
      required: true,
    },
  });

  const beaten = ref(0);

  watch(
    () => props.bots,
    () => {
      updateStats();
    },
    { immediate: true }
  );

  function updateStats() {
    beaten.value = 0;
    if (props.bots == null) {
      return;
    }
    for (const bot of props.bots) {
      if (bot.user?.hasWon) {
        beaten.value++;
      }
    }
  }
</script>

<style scoped>
  .mainpractice {
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
    text-align: center;
  }

  .mainpractice > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }

  .header-icon {
    color: var(--clr-rect-2);
    font-size: 2rem;
  }
  .header-icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: #fff;
    min-width: 5rem;
    height: 5rem;
  }

  .categoryheader-points {
    display: flex;
    flex-direction: row;
    gap: 1.5rem;
    align-items: center;
    padding: 0.5rem;
  }

  .categoryheader-points-item img {
    width: 2rem;
    aspect-ratio: 1;
  }

  .categoryheader-points-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
  }

  .categoryheader-points-item > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 3rem;
  }

  @media (max-width: 992px) {
    .mainpractice > div {
      flex-direction: row;
      text-align: left;
      justify-content: center;
      align-items: start;
    }

    .categoryheader-points {
      flex-direction: column;
      gap: 0.5rem;
      align-items: start;
    }

    .categoryheader-points-item {
      display: flex;
      flex-direction: row;
      gap: 1.5rem;
    }
  }
</style>
