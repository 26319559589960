<template>
  <div class="main">
    <div class="left">
      <div
        style="display: flex; flex-direction: column; gap: 0rem; align-items: center; width: 100%"
      >
        <AskForRatingBanner v-if="quickStats != null && quickStats.gamesAll > 10" />
        <GoingPremiumBanner />
        <GotFreePremiumbanner />

        <InformationBanner />
      </div>
      <PlayBox class="accent-gradient card-drop-shadow" style="margin-bottom: 2rem" />
      <WeeklyChallengeFromPosition
        class="main-gradient-half"
        :activeChallenge="activeWeeklyChallenge"
      />
      <div>
        <h5 style="var(--clr-main)">
          Today's challenges
          <span style="color: var(--clr-main-lighter)"
            >(resets in {{ getHoursToUtcMidnightText() }})</span
          >
        </h5>
        <div style="width: 100%; display: flex; gap: 1rem; flex-wrap: wrap">
          <div style="flex-grow: 1">
            <TodaysMatchup class="main-gradient-half" />
          </div>
          <DailyPosition
            positionType="dailyendgame"
            style="flex-grow: 1"
            class="main-gradient-half"
          />
          <DailyPosition
            positionType="dailymaster"
            style="flex-grow: 1"
            class="main-gradient-half"
          />
        </div>
      </div>
      <UserStats
        v-if="isMobileWidth()"
        class="main-gradient"
        @quick-stats="
          (q) => {
            quickStats = q;
          }
        "
      />
      <div class="new-bots">
        <NewBots
          :titleText="'New bots showcase'"
          :botList="['TmeLa', 'KeWur', 'XovaR', 'bUcLi']"
          class="main-gradient"
        />
        <NewBots
          :titleText="'VIP spotlight'"
          :botList="['Crois', 'WillT', 'HpyHa', 'matJi']"
          class="main-gradient"
        />
      </div>
      <WhatsNew />
    </div>
    <div class="right">
      <UserStats
        v-if="!isMobileWidth()"
        class="main-gradient"
        @quick-stats="
          (q) => {
            quickStats = q;
          }
        "
      />
      <LatestGames class="main-gradient" />
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ref } from 'vue';

  import AskForRatingBanner from '@/components/dashboard/AskForRatingBanner.vue';
  import DailyPosition from '@/components/dashboard/DailyPosition.vue';
  import GoingPremiumBanner from '@/components/dashboard/GoingPremiumBanner.vue';
  import GotFreePremiumbanner from '@/components/dashboard/GotFreePremiumbanner.vue';
  import InformationBanner from '@/components/dashboard/InformationBanner.vue';
  import LatestGames from '@/components/dashboard/LatestGames.vue';
  import NewBots from '@/components/dashboard/NewBots.vue';
  import TodaysMatchup from '@/components/dashboard/TodaysMatchup.vue';
  import UserStats from '@/components/dashboard/UserStats.vue';
  import WeeklyChallengeFromPosition from '@/components/dashboard/WeeklyChallengeFromPosition.vue';
  import WhatsNew from '@/components/dashboard/WhatsNew.vue';
  import PlayBox from '@/components/dashboard/playbox/PlayBox.vue';
  import { useGeneralStore } from '@/stores/generalStore';
  import { useUserStore } from '@/stores/userStore';
  import type { ChallengeFromPosition } from '@/types/apitypes';
  import { isMobileWidth } from '@/util/util';

  const gs = useGeneralStore();
  const us = useUserStore();

  const quickStats = ref();

  const activeWeeklyChallenge = ref<ChallengeFromPosition>();

  initialize();

  async function initialize() {
    const activeChallenges = await gs.refreshActiveChallenges();

    if (activeChallenges.length > 0) {
      activeWeeklyChallenge.value = activeChallenges[0];
    }
  }

  function getMonthDateString() {
    return new Date().toLocaleString('en-US', { month: 'long', day: 'numeric' });
  }

  function getDateString(offsetDays = 0) {
    const date = new Date();
    date.setDate(date.getDate() + offsetDays);
    return date.toISOString().split('T')[0];
  }

  function getHoursToUtcMidnightText() {
    const date = new Date();
    const hours = 23 - date.getUTCHours();
    const minutes = 59 - date.getUTCMinutes();
    return `${hours}h ${minutes}m`;
  }
</script>

<style scoped>
  :deep(div.card .title) {
    color: var(--clr-background-detail);
    font-size: 1.5rem;
    text-align: center;
    margin-top: 1rem;
    text-decoration: none;
  }

  .card-drop-shadow {
    filter: drop-shadow(0.3rem 0.3rem 0.4rem var(--clr-main));
  }

  .main {
    display: grid;
    grid-template-columns: 3fr 1fr;
    grid-gap: 5rem;
  }

  @media (max-width: 992px) {
    .main {
      grid-template-columns: 1fr;
    }
  }

  .left {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
  }

  .right {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }

  .new-bots {
    display: flex;
    gap: 1rem;
  }

  @media (max-width: 1200px) {
    .new-bots {
      flex-direction: column;
    }
  }
</style>
