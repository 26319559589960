<template>
  <div :key="viewKey">
    <div v-if="loadingGame" class="text-center">
      <LoaderNew />
    </div>
    <div v-else class="courseContainer">
      <div class="left">
        <div style="display: flex; flex-direction: column; width: 100%">
          <div
            style="display: flex; flex-direction: column; align-items: center; gap: 0.5rem; flex: 1"
          >
            <div style="width: 100%; flex-grow: 1">
              <div style="display: flex; flex-direction: column; gap: 0.5rem">
                <div class="boardcontainer expose-item-left" style="margin: 0">
                  <TheChessboard
                    :board-config="boardConfig"
                    @board-created="boardCreated"
                    @move="moveMade"
                  />
                </div>
                <div
                  :class="
                    ps.courseSettings.boardWidth != null && ps.courseSettings.boardWidth < 35
                      ? 'navigate-col'
                      : 'navigate-row'
                  "
                >
                  <MoveNavigationCourse
                    :has-prev="hasPrev"
                    :next-moves="nextMoves"
                    :has-alternatives="cs.alternatives.length > 1"
                  />
                  <CurrentCourseLine
                    @next-partial="(uid: any) => (preferredAlternativeUid = uid.uid)"
                  />
                </div>
                <EngineBox :fen="currentBoardFen" />
              </div>
            </div>
          </div>
          <div class="nav-button">
            <div>
              <a
                role="button"
                style="color: var(--clr-main-lighter)"
                @click="
                  router.push({
                    name: 'course',
                    params: { courseid: 'scandi' },
                  })
                "
                ><i class="fa-solid fa-arrow-left" /> Course overview</a
              >
            </div>
          </div>
        </div>
      </div>
      <div
        class="right expose-item-right"
        style="display: flex; flex-direction: column; height: 80vh"
      >
        <ModelGameHeader v-if="preselectedGame != null" />

        <TreeMainLine style="flex: 1; min-height: 0" />

        <div>
          <div v-if="cs.selectedCourseSection == 'keypositions'" class="card" style="padding: 1rem">
            <KeyPositions />
          </div>
          <div v-if="cs.selectedCourseSection == 'share'" class="card" style="padding: 1rem">
            <ShareCoursePosition />
          </div>
          <div v-if="cs.selectedCourseSection == 'settings'" class="card" style="padding: 1rem">
            <CourseSettings />
          </div>
          <div
            v-if="
              cs.selectedCourseSection == 'gamesinternal' ||
              cs.selectedCourseSection == 'gameslichess'
            "
            class="card"
            style="padding: 1rem"
          >
            <CourseGameExplorer />
          </div>
          <div v-if="cs.selectedCourseSection == 'videos'" class="card" style="padding: 1rem">
            <CourseVideoExplorer />
          </div>
        </div>

        <div style="display: flex; gap: 0.5rem; justify-content: space-between">
          <div style="display: flex; gap: 0.5rem; white-space: nowrap">
            <button
              class="btn btn-info"
              type="button"
              @click="cs.setSelectedCourseSection('keypositions')"
            >
              <i class="fa-solid fa-chess" /> Key Positions
              <i v-if="cs.selectedCourseSection == 'keypositions'" class="fa-solid fa-caret-up" />
              <i v-else class="fa-solid fa-caret-down" />
            </button>
            <button
              class="btn btn-info"
              type="button"
              @click="cs.setSelectedCourseSection('gamesinternal')"
            >
              <i class="fa-solid fa-chess-board" /> Games
              <i
                v-if="
                  cs.selectedCourseSection == 'gamesinternal' ||
                  cs.selectedCourseSection == 'gameslichess'
                "
                class="fa-solid fa-caret-up"
              />
              <i v-else class="fa-solid fa-caret-down" />
            </button>
            <button
              class="btn btn-info"
              type="button"
              @click="cs.setSelectedCourseSection('videos')"
            >
              <i class="fa-solid fa-video" /> Videos
              <i v-if="cs.selectedCourseSection == 'videos'" class="fa-solid fa-caret-up" />
              <i v-else class="fa-solid fa-caret-down" />
            </button>
          </div>

          <!-- Right group -->
          <div style="display: flex; gap: 0.5rem">
            <button
              class="btn btn-info"
              type="button"
              @click="cs.setSelectedCourseSection('share')"
            >
              <i class="fa-solid fa-share-from-square" />
              <i v-if="cs.selectedCourseSection == 'share'" class="fa-solid fa-caret-up" />
              <i v-else class="fa-solid fa-caret-down" />
            </button>
            <button
              class="btn btn-info"
              type="button"
              @click="cs.setSelectedCourseSection('settings')"
            >
              <i class="fa-solid fa-gear" />

              <i v-if="cs.selectedCourseSection == 'settings'" class="fa-solid fa-caret-up" />
              <i v-else class="fa-solid fa-caret-down" />
            </button>
          </div>
        </div>
      </div>
    </div>
    <SoundsStorage />
  </div>
</template>

<script setup lang="ts">
  import { computed, nextTick, reactive, ref, shallowRef, watch } from 'vue';
  import { BoardApi, type DrawShape, TheChessboard } from 'vue3-chessboard';
  import { useRoute } from 'vue-router';

  import type { Key } from 'chessground/types';

  import CourseGameExplorer from '@/components/common/tree/CourseGameExplorer.vue';
  import CourseSettings from '@/components/common/tree/CourseSettings.vue';
  import CourseVideoExplorer from '@/components/common/tree/CourseVideoExplorer.vue';
  import CurrentCourseLine from '@/components/common/tree/CurrentCourseLine.vue';
  import KeyPositions from '@/components/common/tree/KeyPositions.vue';
  import ModelGameHeader from '@/components/common/tree/ModelGameHeader.vue';
  import MoveNavigationCourse from '@/components/common/tree/MoveNavigationCourse.vue';
  import ShareCoursePosition from '@/components/common/tree/ShareCoursePosition.vue';
  import TreeMainLine from '@/components/common/tree/TreeMainLine.vue';
  import EngineBox from '@/components/engine/EngineBox.vue';
  import SoundsStorage from '@/components/sounds/SoundsStorage.vue';
  import LoaderNew from '@/components/util/LoaderNew.vue';
  import router from '@/router';
  import { useCourseStore } from '@/stores/courseStore';
  import { usePageStore } from '@/stores/pageStore';
  import { UserInput } from '@/types/internaltypes';
  import { extractCalTags, extractCslTags, getNagSvg } from '@/util/course';
  import { STARTING_FEN } from '@/util/util';

  const cs = useCourseStore();
  const ps = usePageStore();
  const route = useRoute();

  const chessBoardBg = ref('#3cbfe0');
  setBoardBg();
  const boardWidth = computed(() => {
    if (ps.courseSettings.boardWidth == null) {
      // Default width
      return '35rem';
    }

    return ps.courseSettings.boardWidth + 'rem';
  });

  const chessboardOpacity = ref(1);

  const loadingGame = ref(true);

  const viewKey = ref(''); // Used to make sure the component is re-rendered when query changes

  const boardApi = shallowRef<BoardApi | null>(null);
  const nextMoves = ref<string[] | null>(null);
  const hasPrev = ref(true);
  let currentShapes: { type: string; shape: DrawShape }[] = [];
  const currentBoardFen = ref<string>();
  const preferredAlternativeUid = ref<number | null>(null);
  const preselectedNode = ref<number | null>(null);
  const preselectedFen = ref<string | null>(null);
  const preselectedGame = ref<string | null>(null);
  const preselectedGameType = ref<string | null>(null);

  const courseId = computed(() => route.params.courseid as string);

  const boardConfig = reactive({
    blockTouchScroll: true,
    coordinates: usePageStore().showBoardCoordinates,
    movable: { showDests: usePageStore().showPossibleMoves },
    highlight: {
      lastMove: usePageStore().showLastMove,
    },
    orientation: 'black' as 'white' | 'black' | undefined,
    premovable: { enabled: false },
    drawable: { enabled: true, visible: true },
  });

  watch(
    () => cs.selectedNode,
    () => {
      nodeChanged();
    },
    { immediate: true }
  );

  watch(
    () => cs.selectedCourseSection,
    () => {
      if (cs.selectedCourseSection === 'board') {
        // If the board tab was openened, make sure the current position is put on it (since it doesn' update while closed)
        nextTick(() => {
          refreshBoardPosition();
        });
      }
    }
  );

  watch(
    () => cs.manualViewing,
    () => {
      if (cs.manualViewing) {
        setManualViewing();
      } else {
        stopManualViewing();
      }
    },
    { immediate: true }
  );

  watch(
    () => cs.alternatives,
    () => {
      if (cs.alternatives.length > 0) {
        let selectedFrom = '';
        let selectedTo = '';
        nextMoves.value = cs.alternatives.map((a) => {
          if (a.selected) {
            selectedFrom = a.uci.substring(0, 2);
            selectedTo = a.uci.substring(2, 4);
          }
          return a.san;
        });
        if (
          boardApi.value != null &&
          cs.choseAlternative != null &&
          selectedFrom != '' &&
          selectedTo != ''
        ) {
          // Clean previous alternatives
          currentShapes = currentShapes.filter((s) => s.type != 'alternative');
          // Add this alternative arrow
          currentShapes.push({
            type: 'alternative',
            shape: {
              orig: selectedFrom as Key,
              dest: selectedTo as Key,
              brush: 'paleGrey',
            },
          });
          drawShapes();
        }
      } else {
        nextMoves.value = null;
      }
    },
    { deep: true, immediate: true }
  );

  watch(
    () => route.fullPath,
    () => {
      handleQueryChange();
    },
    { immediate: true }
  );

  async function handleQueryChange() {
    preselectedNode.value = route.query.uid != null ? Number(route.query.uid as string) : null;
    preselectedFen.value = route.query.fen != null ? (route.query.fen as string) : null;
    preselectedGame.value = route.query.gameid != null ? (route.query.gameid as string) : null;
    preselectedGameType.value =
      route.query.gametype != null ? (route.query.gametype as string) : null;

    await init();

    viewKey.value = `courseviewkeygame${preselectedGame.value}node${preselectedNode.value}fen${preselectedFen.value}type${preselectedGameType.value}`;
  }

  function drawShapes() {
    if (boardApi.value == null) {
      return;
    }
    nextTick().then(() => {
      boardApi.value!.setShapes(currentShapes.map((s) => s.shape));
    });
  }

  async function init() {
    await useCourseStore().loadCourseData(courseId.value);

    if (preselectedGameType.value == 'main' || preselectedGame.value == null) {
      await useCourseStore().setActiveCourseGame('main', '0');
    } else if (preselectedGameType.value == 'model') {
      await useCourseStore().setActiveCourseGame('model', preselectedGame.value);
    } else if (preselectedGameType.value == 'reference') {
      await useCourseStore().setActiveCourseGame('reference', preselectedGame.value);
    } else if (preselectedGameType.value == 'lichess') {
      await useCourseStore().setActiveCourseGame('lichess', preselectedGame.value);
    } else if (preselectedGameType.value == 'video') {
      await useCourseStore().setActiveCourseGame('video', preselectedGame.value);
    }

    loadingGame.value = false;
    if (preselectedNode.value != null) {
      cs.selectMove(preselectedNode.value);
    } else if (preselectedFen.value != null) {
      cs.selectMoveByFen(preselectedFen.value);
    }
  }

  function boardCreated(api: BoardApi) {
    boardApi.value = api;
  }

  function setBoardBg(lineType = '') {
    let color;
    if (lineType === 'alternative') {
      color = 'var(--clr-linebg-alternative)';
    } else if (lineType === 'caution') {
      color = 'var(--clr-linebg-caution)';
    } else {
      color = usePageStore().boardColorOverride.active
        ? usePageStore().boardColorOverride.color
        : '#3cbfe0';
    }

    chessBoardBg.value = color;
    return color;
  }

  function scrollTo(uid: number) {
    try {
      const container = document.getElementById('move-tree');
      const anchor = document.getElementById('move-tree-move-' + uid.toString()); // Adjust the selector to target the anchor you want.
      // @ts-ignore
      const anchorPosition = anchor.offsetTop;
      // @ts-ignore
      const containerCenter = container.offsetHeight / 4;
      // @ts-ignore
      const newScrollTop =
        // @ts-ignore
        anchorPosition - containerCenter + anchor.offsetHeight / 4;
      // @ts-ignore
      container.scrollTop = newScrollTop;
    } catch (e) {
      // Ignore, it seems that sometimes there's a race condition here where the anchor element isn't yet rendered or so, just don't scroll then it's not the end of the world
    }
  }

  function moveMade(move: any) {
    const matchingAlternatives: any = cs.alternatives.filter((a) => a.san == move.san);

    if (matchingAlternatives.length > 0) {
      // There was a matching, so just navigate to that, this will be equivalent to hitting the right arrow key for that move
      useCourseStore().navigate(UserInput.HistoryNext, matchingAlternatives[0].uid);
    } else {
      // There was no matching alternative, so we're leaving the course material and browsing on our own
      cs.manualViewing = true;
      refreshNags(null); // Clear existing NAGs since we're not following the course
      let startMoveNumber;
      try {
        startMoveNumber = cs.selectedNode?.fen.split(' ')[5];
      } catch (e) {
        // Something went wrong getting the currently selected fen (probably no current node) so just default to 1
        startMoveNumber = 1;
      }

      if (startMoveNumber == null) {
        startMoveNumber = 1;
      }

      const boardFen = boardApi.value!.getFen().split(' ');
      boardFen[5] = startMoveNumber;
      currentBoardFen.value = boardFen.join(' ');
    }
  }

  function setManualViewing() {
    chessboardOpacity.value = 0.8;
  }

  function stopManualViewing() {
    chessboardOpacity.value = 1;
    nodeChanged();
  }

  function nodeChanged() {
    currentShapes = [];
    if (cs.selectedNode != null) {
      nextTick().then(() => {
        scrollTo(cs.selectedNode!.uid);
      });

      if (cs.selectedNode.type.includes('alternative')) {
        setBoardBg('alternative');
      } else if (cs.selectedNode.type.includes('caution')) {
        setBoardBg('caution');
      } else {
        // Set the board to the default color
        setBoardBg();
      }

      if (cs.selectedNode?.comments != null && cs.selectedNode.comments.length > 0) {
        currentShapes.push(
          ...extractCslTags(cs.selectedNode.comments.join(' ')).map((c) => ({
            type: 'csl',
            shape: c,
          })),
          ...extractCalTags(cs.selectedNode.comments.join(' ')).map((c) => ({
            type: 'cal',
            shape: c,
          }))
        );
        if (currentShapes.length > 0) {
          drawShapes();
        }
      }

      refreshNags(cs.selectedNode?.nags?.find((num: number) => num >= 1 && num <= 6) ?? null);
    }

    hasPrev.value = cs.selectedNode != null;

    refreshBoardPosition();
  }

  function refreshNags(moveNag: any | null) {
    const toSquare = getAdjustedToSquare(cs.selectedNode);

    const customSvgContainer = document.querySelector('.cg-custom-svgs');

    if (customSvgContainer) {
      // We can't set this to completely every or the code doesn't work for some strange reason. The <g></g> is there from the start, and if it's not there at some point it will break and not add correctly I guess chessground is somehow relying on it or so
      // And also always clear before adding, since we always only want one NAG on the board, so any previous NAG should be cleared before adding a new one
      customSvgContainer.innerHTML = '<g></g>';
      if (moveNag != null && toSquare != null) {
        // TODO get the orientation from the course settings instead
        customSvgContainer.innerHTML += getNagSvg(toSquare, 'b', moveNag);
      }
    }
  }

  function getAdjustedToSquare(node: any): string {
    const toSquare = node?.uci?.substring(2, 4);

    // If the move is castling, make sure the toSquare is e.g. g1 and not h1
    if (node?.san === 'O-O') {
      return toSquare.includes('1') ? 'g1' : 'g8';
    } else if (node?.san === 'O-O-O') {
      return toSquare.includes('1') ? 'c1' : 'c8';
    }

    return toSquare;
  }

  function refreshBoardPosition() {
    if (boardApi.value != null) {
      let newFen = cs.selectedNode?.fen;
      if (newFen == null) {
        newFen = STARTING_FEN;
      }
      boardApi.value!.setPosition(newFen);

      if (cs.selectedNode?.uci != null) {
        // @ts-ignore setConfig doesn't work since it resets the shapes for some reason, so have to bypass and call board directly
        boardApi.value!.board.set({
          lastMove: [cs.selectedNode?.uci.substring(0, 2), cs.selectedNode?.uci.substring(2, 4)],
        });
      }

      currentBoardFen.value = newFen;
    }
  }
</script>

<style scoped>
  .navigate-col {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .navigate-row {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
  }

  .active {
    background: linear-gradient(315deg, var(--clr-background-detail) 0%, var(--clr-accent) 100%);
  }

  .courseContainer {
    display: grid;
    grid-template-columns: 1fr v-bind('boardWidth') auto 1fr;
    gap: 1rem;
  }

  .left {
    grid-column: 2;
    flex: 1 0;
    display: flex;
    justify-content: end;
  }

  .right {
    grid-column: 3;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    min-width: 0;
  }

  .boardcontainer {
    width: 100%;
    position: relative;
    display: flex;
    margin-right: 1rem;
  }

  @media (max-width: 1200px) {
    .courseContainer {
      display: flex; /* Use flexbox for a single-column layout */
      flex-direction: column; /* Stack elements vertically */
      gap: 1rem; /* Maintain spacing between items */
    }

    .left {
      grid-column: unset; /* Reset any grid placement */
      width: 100%; /* Full width */
      max-width: 100%; /* Remove the width limitation */
      justify-content: center; /* Center the content */
      align-items: center; /* Optional: center-align items */
      display: flex; /* Ensure flex styles apply */
      flex-direction: column; /* Align items in a column */
    }

    .right {
      grid-column: unset; /* Reset any grid placement */
      width: 100%; /* Full width */
      flex: 0 0 auto; /* Allow it to shrink dynamically */
      max-width: 100%; /* Remove the width limitation */
      margin-top: 0; /* Adjust spacing if needed */
      display: flex; /* Ensure flex styles apply */
      flex-direction: column; /* Align items in a column */
      align-items: center; /* Optional: center-align items */
    }

    .move-tree {
      width: 100%; /* Ensure it spans the full width */
      max-width: none; /* Remove width constraint */
      height: 100vh;
      overflow-y: auto; /* Keep scrollable if content overflows */
    }

    .boardcontainer {
      margin: 0 auto; /* Center the chessboard horizontally */
      width: 100%; /* Optional: Adjust for mobile layout */
      height: auto; /* Maintain responsiveness */
    }
  }

  :deep(cg-board) {
    opacity: v-bind('chessboardOpacity');
    background-color: v-bind('chessBoardBg'); /* Override the board background color */
  }
</style>
