<template>
  <div>
    <p
      :class="[...(props.pClasses ?? []), 'overflow', 'ph-no-capture']"
      :style="pStyleWithHeight"
      @click="
        () => {
          stopTyping();
          track('type_writer', 'text', 'click');
        }
      "
      id="typewriterBox"
      v-html="currentText"
    />
    <!-- Hidden element to calculate height -->
    <p
      ref="hiddenTextElement"
      :class="['overflow', 'hidden-element']"
      :style="{
        position: 'absolute',
        top: '-9999px',
      }"
      v-html="targetText"
    />
  </div>
</template>

<script setup lang="ts">
  import { type PropType, type Ref, nextTick, onMounted, ref, watch } from 'vue';

  import { track } from '@/util/tracking';
  import { getMarkupText } from '@/util/util';

  const props = defineProps({
    pClasses: { type: Array as PropType<string[]> },
    pStyle: { type: String },
    targetText: { type: String, required: true },
    newTextDelay: { type: Number, required: true },
    fadeawayMillis: { type: Number, default: -1 },
  });

  const currentText = ref('');
  const typingSpeed = 15;
  const finishTextNow = ref(false);
  const pStyleWithHeight = ref(props.pStyle || '');

  const hiddenTextElement = ref<HTMLElement | null>(null);

  watch(finishTextNow, (newValue) => {
    if (!newValue) {
      return;
    }
    currentText.value = getMarkupText(props.targetText) as string;
    if (newValue && props.fadeawayMillis > 0) {
      fadeaway(props.fadeawayMillis, props.targetText);
    }
  });

  watch(
    () => props.targetText,
    () => {
      finishTextNow.value = false;
      currentText.value = '';
      startTyping();
    }
  );

  onMounted(() => {
    // Ensure the height is set after mounting
    nextTick(() => {
      setFullHeight();
    });
  });

  watch(
    () => props.targetText,
    () => {
      nextTick(() => {
        setFullHeight();
      });
    }
  );

  const setFullHeight = () => {
    if (hiddenTextElement.value) {
      const height = hiddenTextElement.value.clientHeight;
      pStyleWithHeight.value = `${props.pStyle || ''}; height: ${height}px;`;
    }
  };

  const fadeaway = (delay: number, textToFadeAway: string) => {
    setTimeout(function () {
      if (props.targetText != textToFadeAway) {
        // While this timeout was going, the text changed, so we shouldn't fade away
        return;
      }
      // start a delay
      const fade = document.getElementById('typewriterBox'); // get required element
      if (fade == null) {
        return;
      }

      fade.style.opacity = '1'; // set opacity for the element to 1
      const timerId = setInterval(function () {
        if (fade == null) {
          return;
        }
        // start interval loop
        const opacity = parseFloat(fade.style.opacity); // get current opacity
        if (opacity == 0.0) {
          // check if its 0 yet
          clearInterval(timerId); // if so, exit from interval loop
        } else {
          fade.style.opacity = (opacity - 0.05).toString(); // else remove 0.05 from opacity
        }
      }, 100); // run every 0.1 second
    }, delay); // wait to run after 5 seconds
  };
  const stopTyping = () => {
    finishTextNow.value = true;
    currentText.value = getMarkupText(props.targetText) as string;
  };

  const typeTextInit = (text: string, typeValue: Ref<string>) => {
    let charIndex = 0;
    const typeText = () => {
      if (props.targetText != text) {
        // Target text is something different so stop typing this old text
        return;
      }
      if (finishTextNow.value || text == null) {
        return;
      }
      if (charIndex < text.length) {
        if (text.charAt(charIndex) != '*' && text.charAt(charIndex) != '_') {
          typeValue.value += text.charAt(charIndex);
        }
        charIndex += 1;
        setTimeout(typeText, typingSpeed);
      } else {
        finishTextNow.value = true;
        return;
      }
    };
    setTimeout(typeText, typingSpeed);
  };

  const startTyping = () => {
    setTimeout(typeTextInit.bind(null, props.targetText, currentText), props.newTextDelay + 200);
  };
  startTyping();
</script>

<style lang="scss" scoped>
  .overflow {
    overflow-wrap: anywhere;
  }

  .scaled-image {
    width: 20rem;
    height: 20rem;
  }
  // Cursor blinking CSS Starts...
  .blinking-cursor {
    color: #2c3e50;
    -webkit-animation: 1s blink step-end infinite;
    -moz-animation: 1s blink step-end infinite;
    -ms-animation: 1s blink step-end infinite;
    -o-animation: 1s blink step-end infinite;
    animation: 1s blink step-end infinite;
  }
  @keyframes blink {
    from,
    to {
      color: transparent;
    }
    50% {
      color: #2c3e50;
    }
  }
  // Cursor blinking CSS Ends...
</style>
