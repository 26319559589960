import { ref } from 'vue';
import type { Ref } from 'vue';

// Import as a type

export function useRatingChange(ratingHistory: Ref<any>) {
  const ratingChanges = ref({
    week: 0,
    month: 0,
    year: 0,
    all: 0,
  });

  const calculateRatingChange = (currentRating: number, timeframe: string, historyChange?: any) => {
    if (ratingHistory.value == null || ratingHistory.value.length == 0 || !historyChange) {
      return 0;
    }

    if (timeframe === 'week') {
      if (historyChange.lastWeek == null) return '-';
      return Math.round(currentRating - historyChange.lastWeek);
    } else if (timeframe === 'month') {
      if (historyChange.lastMonth == null) return '-';
      return Math.round(currentRating - historyChange.lastMonth);
    }

    const sortedRatings = ratingHistory.value.daily.sort((a: any, b: any) => {
      return a.started_at._seconds - b.started_at._seconds;
    });

    const firstDay = new Date(sortedRatings[0].started_at._seconds * 1000);
    const aYearAgoMillis = new Date().getTime() - 1000 * 60 * 60 * 24 * 365;

    if (timeframe === 'all' || firstDay.getTime() > aYearAgoMillis) {
      return Math.round(currentRating - ratingHistory.value.daily[0].rating_new);
    }

    // Find the first rating within the last year
    for (let i = 0; i < sortedRatings.length; i++) {
      const date = new Date(sortedRatings[i].started_at._seconds * 1000);
      if (date.getTime() > aYearAgoMillis) {
        return Math.round(currentRating - sortedRatings[i].rating_new);
      }
    }

    // Should really not get here
    return 0;
  };

  const updateRatingChanges = (currentRating: number, historyChange?: any) => {
    ratingChanges.value = {
      week: calculateRatingChange(currentRating, 'week', historyChange) as number,
      month: calculateRatingChange(currentRating, 'month', historyChange) as number,
      year: calculateRatingChange(currentRating, 'year', historyChange) as number,
      all: calculateRatingChange(currentRating, 'all', historyChange) as number,
    };
  };

  return {
    ratingChanges,
    calculateRatingChange,
    updateRatingChanges,
  };
}
