import { useBotsStore } from '@/stores/botStore';
import type { HigherRatedBot } from '@/types/statstypes';

/**
 * Composable for calculating statistics related to higher-rated and lower-rated bots
 */
export function useRatedBotsStats() {
  /**
   * Calculate statistics for higher-rated bots
   * @param validBots Array of bots with game statistics
   * @param userRating The user's current rating
   * @returns Object containing higher-rated bots statistics
   */
  const calculateHigherRatedStats = (validBots: any[], userRating: number) => {
    // Filter bots with rating higher than the user's rating
    const higherRatedBots = validBots.filter((bot: any) => {
      const botRating = useBotsStore().getBot(bot.bot_id)?.strength?.estimated_elo || 0;
      return botRating > userRating && bot.games > 0;
    });

    // Calculate wins and total games against higher rated bots
    const higherRatedWins = higherRatedBots.reduce(
      (total: number, bot: any) => total + bot.wins,
      0
    );
    const higherRatedGames = higherRatedBots.reduce(
      (total: number, bot: any) => total + bot.games,
      0
    );

    // Calculate win rate against higher rated bots
    const winRateAgainstHigherRated = higherRatedGames > 0 ? higherRatedWins / higherRatedGames : 0;

    // Find the best higher-rated bot (best win-loss record)
    let bestHigherRatedBot = null;
    if (higherRatedBots.length > 0) {
      // Sort by win rate (descending)
      const sortedHigherRatedBots = higherRatedBots
        .filter((bot: any) => bot.wins > 0) // Only include bots with at least one win
        .sort((a: any, b: any) => {
          // First prioritize bots with more wins
          if (a.wins !== b.wins) {
            return b.wins - a.wins;
          }
          // Then prioritize bots with fewer losses
          return a.losses - b.losses;
        });

      if (sortedHigherRatedBots.length > 0) {
        const bestBot = sortedHigherRatedBots[0];
        bestHigherRatedBot = {
          botId: bestBot.bot_id,
          wins: bestBot.wins,
          losses: bestBot.losses,
          draws: bestBot.draws,
          total: bestBot.games,
        } as HigherRatedBot;
      }
    }

    return {
      higherRatedBots,
      higherRatedWins,
      higherRatedGames,
      winRateAgainstHigherRated,
      bestHigherRatedBot,
    };
  };

  /**
   * Calculate statistics for lower-rated bots
   * @param validBots Array of bots with game statistics
   * @param userRating The user's current rating
   * @returns Object containing lower-rated bots statistics
   */
  const calculateLowerRatedStats = (validBots: any[], userRating: number) => {
    // Filter bots with rating lower than the user's rating
    const lowerRatedBots = validBots.filter((bot: any) => {
      const botRating = useBotsStore().getBot(bot.bot_id)?.strength?.estimated_elo || 0;
      return botRating < userRating && bot.games > 0;
    });

    // Calculate wins and total games against lower rated bots
    const lowerRatedWins = lowerRatedBots.reduce((total: number, bot: any) => total + bot.wins, 0);
    const lowerRatedGames = lowerRatedBots.reduce(
      (total: number, bot: any) => total + bot.games,
      0
    );

    // Calculate win rate against lower rated bots
    const winRateAgainstLowerRated = lowerRatedGames > 0 ? lowerRatedWins / lowerRatedGames : 0;

    return {
      lowerRatedBots,
      lowerRatedWins,
      lowerRatedGames,
      winRateAgainstLowerRated,
    };
  };

  return {
    calculateHigherRatedStats,
    calculateLowerRatedStats,
  };
}
