<template>
  <div class="win-rate-weekday-container">
    <h3 class="weekday-title">
      <i class="fa-solid fa-trophy" style="color: var(--clr-accent)" />
      Win Rate Per Weekday
    </h3>
    <div v-if="!loading && weekdayData.length > 0" class="weekday-chart">
      <div class="weekday-bars">
        <div
          v-for="day in weekdayData"
          :key="day.name"
          class="weekday-bar-container"
          v-tippy="{
            content: `${day.name}: ${formatPercentage(day.winRate)} win rate (${
              day.wins
            }/${day.total} ${
              day.total === 1 ? TEXT_FORMATTING.SINGULAR_GAME : TEXT_FORMATTING.PLURAL_GAMES
            })`,
          }"
        >
          <div class="weekday-label">{{ day.name.substring(0, 3) }}</div>
          <div class="weekday-bar-wrapper">
            <div
              class="weekday-bar"
              :style="{
                height: `${day.winRate * 100}%`,
                backgroundColor: getBarColor(day.winRate),
              }"
            />
          </div>
          <div class="weekday-count">{{ formatPercentage(day.winRate) }}</div>
        </div>
      </div>
    </div>
    <div v-else-if="loading" class="weekday-placeholder pulsate-load" />
    <div v-else class="weekday-placeholder">
      <p>No data available</p>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ref, watch } from 'vue';

  import { TEXT_FORMATTING, WEEKDAY_NAMES } from '@/constants';

  interface WeekdayData {
    name: string;
    dayIndex: number;
    wins: number;
    losses: number;
    draws: number;
    total: number;
    winRate: number;
  }

  interface GameResult {
    timestamp: string;
    result: string;
    moves_count?: number;
    [key: string]: any;
  }

  const props = defineProps({
    loading: {
      type: Boolean,
      default: false,
    },
    rawStats: {
      type: Array,
      default: () => [],
    },
    ratingHistory: {
      type: Object,
      default: () => ({}),
    },
    userStatsResponse: {
      type: Object,
      default: () => ({}),
    },
  });

  const weekdayData = ref<WeekdayData[]>([]);

  // Process data when props change
  watch(
    () => [props.rawStats, props.ratingHistory, props.userStatsResponse, props.loading],
    () => {
      if (!props.loading) {
        processWeekdayData();
      }
    },
    { immediate: true }
  );

  // Format percentage for display
  function formatPercentage(value: number): string {
    return `${Math.round(value * 100)}%`;
  }

  // Get color based on win rate
  function getBarColor(winRate: number): string {
    if (winRate >= 0.6) return '#76b7b2';
    if (winRate >= 0.5) return '#f28e2c';
    if (winRate >= 0.4) return '#e15759';
    return '#e15759';
  }

  // Process data to get win rates by weekday
  function processWeekdayData(): void {
    // Initialize data structure for each weekday
    const weekdayStats: WeekdayData[] = WEEKDAY_NAMES.map((name, index) => ({
      name,
      dayIndex: index,
      wins: 0,
      losses: 0,
      draws: 0,
      total: 0,
      winRate: 0,
    }));

    // Process data from userStatsResponse if available
    if (props.userStatsResponse && props.userStatsResponse.gameResults) {
      try {
        const results = JSON.parse(props.userStatsResponse.gameResults) as GameResult[];

        if (Array.isArray(results)) {
          results.forEach((game: GameResult) => {
            // Process game results in format {"started_at":{"value":"2025-02-17T09:52:21.575Z"},"ended_at":{"value":"2025-02-17T09:54:57.064Z"},"result_user":"loss"}
            const timeField = game.ended_at || game.started_at;
            if (timeField && typeof timeField === 'object' && timeField.value) {
              const date = new Date(timeField.value);

              if (date && date instanceof Date && !isNaN(date.getTime())) {
                const dayIndex = date.getDay(); // 0 = Sunday, 1 = Monday, etc.
                // Ensure dayIndex is valid (0-6)
                if (dayIndex >= 0 && dayIndex < 7) {
                  // Process result_user field
                  if (game.result_user === 'win') {
                    weekdayStats[dayIndex].wins++;
                  } else if (game.result_user === 'loss') {
                    weekdayStats[dayIndex].losses++;
                  } else if (game.result_user === 'draw') {
                    weekdayStats[dayIndex].draws++;
                  }

                  weekdayStats[dayIndex].total++;
                } else {
                  console.warn(`Invalid day index: ${dayIndex} for date: ${date}`);
                }
              }
            }
          });
        } else {
          console.error('Results is not an array:', results);
        }
      } catch (error) {
        console.error('Error processing weekday data:', error);
      }
    }

    // Calculate win rates
    weekdayStats.forEach((day) => {
      if (day.total > 0) {
        day.winRate = day.wins / day.total;
      }
    });

    // Use the natural order of days (starting with Sunday)
    // Always show all days, even if they have no games
    // This ensures all weekdays are always displayed
    weekdayData.value = weekdayStats;
  }
</script>

<style scoped>
  .win-rate-weekday-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 1rem;
  }

  .weekday-title {
    margin-bottom: 1.5rem;
    text-align: center;
    color: var(--clr-main);
  }

  .weekday-chart {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .weekday-bars {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    height: 250px;
    margin-top: 1rem;
  }

  .weekday-bar-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 13%;
  }

  .weekday-label {
    margin-bottom: 0.5rem;
    font-weight: bold;
    color: var(--clr-main);
  }

  .weekday-bar-wrapper {
    width: 100%;
    height: 200px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }

  .weekday-bar {
    width: 100%;
    border-radius: 4px 4px 0 0;
    transition: height 0.5s ease;
  }

  .weekday-count {
    margin-top: 0.5rem;
    font-weight: bold;
    color: var(--clr-accent);
  }

  .weekday-placeholder {
    width: 100%;
    height: 250px;
    background-color: var(--clr-main-lightest);
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--clr-main);
  }

  .pulsate-load {
    animation: pulsate 1.5s ease-in-out infinite;
  }

  @keyframes pulsate {
    0% {
      opacity: 0.6;
    }
    50% {
      opacity: 0.8;
    }
    100% {
      opacity: 0.6;
    }
  }

  @media (max-width: 768px) {
    .weekday-bars {
      height: 200px;
    }

    .weekday-bar-wrapper {
      height: 150px;
    }

    .weekday-label {
      font-size: 0.8rem;
    }

    .weekday-count {
      font-size: 0.8rem;
    }
  }
</style>
