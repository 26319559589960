<template>
  <div class="practicegrid">
    <div class="left">
      <MainPracticeBox style="width: 100%" :practices="practicesData" />
      <CustomChallenge style="width: 300px" />
    </div>
    <div class="right">
      <ul class="nav nav-tabs justify-content-center" role="tablist">
        <li class="nav-item" role="presentation">
          <a
            class="nav-link active ph-no-capture"
            data-bs-toggle="tab"
            href="#"
            aria-selected="false"
            tabindex="-1"
            role="tab"
            @click="
              () => {
                setSelectedSection('openings');
                track('practice_view', 'set_selected_section', 'click', {
                  section: 'openings',
                });
              }
            "
            >Openings</a
          >
        </li>
        <li class="nav-item" role="presentation">
          <a
            class="nav-link ph-no-capture"
            data-bs-toggle="tab"
            href="#"
            aria-selected="true"
            role="tab"
            @click="
              () => {
                setSelectedSection('middlegames');
                track('practice_view', 'set_selected_section', 'click', {
                  section: 'middlegames',
                });
              }
            "
            >Middlegames</a
          >
        </li>
        <li class="nav-item" role="presentation">
          <a
            class="nav-link ph-no-capture"
            data-bs-toggle="tab"
            href="#"
            aria-selected="false"
            role="tab"
            tabindex="-1"
            @click="
              () => {
                setSelectedSection('endgames');
                track('practice_view', 'set_selected_section', 'click', {
                  section: 'endgames',
                });
              }
            "
            >Endgames</a
          >
        </li>
        <li class="nav-item" role="presentation">
          <a
            class="nav-link ph-no-capture"
            data-bs-toggle="tab"
            href="#"
            aria-selected="false"
            tabindex="-1"
            role="tab"
            @click="
              () => {
                setSelectedSection('masters');
                track('practice_view', 'set_selected_section', 'click', {
                  section: 'masters',
                });
              }
            "
            >Masters</a
          >
        </li>
      </ul>
      <div v-if="selectedSection === 'masters'" style="margin: 0 auto">
        Take over a master game and lead it to victory
      </div>
      <div v-if="loading" class="text-center">
        <Loader />
      </div>
      <div
        v-else
        class="practicesection"
        v-for="(s, si) in structuredPractices"
        :key="'section' + si"
      >
        <div class="practicecategory cardEntrance">
          <h2>{{ s.name }}</h2>
        </div>
        <div class="practicecategories">
          <div
            v-for="(c, ci) in s.categories"
            :class="
              'card practicecategory ph-no-capture ' +
              getCategoryBackgroundGradient(c.difficulty) +
              (c.name === selectedCategory ? ' selected' : '')
            "
            :key="'category' + ci"
            role="button"
            @click="
              () => {
                setSelectedCategory(c.name);
                track('practice_view', 'set_selected_category', 'click', {
                  category: c.name,
                });
              }
            "
            :style="{ animationDelay: si * 0.2 + ci * 0.05 + 's' }"
          >
            <div class="categoryheader">
              <div class="categoryheader-names">
                <div class="d-flex gap-2">
                  <div class="category-icon-container">
                    <img
                      v-if="c.icon.startsWith('http')"
                      :src="c.icon"
                      style="border-radius: 50%; width: 2rem"
                    />
                    <i
                      v-else
                      :class="'category-icon ' + c.icon"
                      :style="{ color: getCategoryIconColor(c.difficulty) }"
                    />
                  </div>
                  <h3>{{ c.name }}</h3>
                </div>
                <p>{{ c.description }}</p>
              </div>
              <div class="categoryheader-points">
                <div>
                  <img
                    v-if="getCategoryResult(s.name, c.name).points > 0"
                    style="margin-top: -0.4rem; filter: hue-rotate(45deg)"
                    src="https://storage.googleapis.com/chessiverse1/images/elements/star.webp"
                  />
                  <img
                    v-else
                    style="filter: hue-rotate(45deg)"
                    src="https://storage.googleapis.com/chessiverse1/images/elements/star_grey.webp"
                  />
                  {{ getCategoryResult(s.name, c.name).points }}
                </div>
                <div>
                  <i
                    class="header-icon fa-solid fa-square-check"
                    :style="{
                      color:
                        getCategoryResult(s.name, c.name).finished ===
                        getCategoryResult(s.name, c.name).total
                          ? 'var(--clr-accent4)'
                          : 'var(--clr-rect-2)',
                      display: 'inline-block',
                      marginTop: '-0.4rem',
                    }"
                  />
                  {{ getCategoryResult(s.name, c.name).finished }}/{{
                    getCategoryResult(s.name, c.name).total
                  }}
                </div>
              </div>
            </div>

            <div class="positions" v-if="c.name === selectedCategory">
              <PracticeCard v-for="(p, pi) in c.positions" :position="p" :key="'position' + pi" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { nextTick, ref } from 'vue';

  import CustomChallenge from '@/components/practice/CustomChallenge.vue';
  import MainPracticeBox from '@/components/practice/MainPracticeBox.vue';
  import PracticeCard from '@/components/practice/PracticeCard.vue';
  // import { useRouter } from "vue-router";
  import Loader from '@/components/util/Loader.vue';
  import { useGeneralStore } from '@/stores/generalStore';
  import type { PracticePosition } from '@/types/apitypes';
  import type { PracticeCategory, PracticeSection } from '@/types/internaltypes';
  import { track } from '@/util/tracking';

  const gs = useGeneralStore();
  // const router = useRouter();

  const loading = ref(true);
  const selectedSection = ref('openings');
  const selectedCategory = ref('');
  const practicesData = ref<PracticePosition[]>([]);
  const structuredPractices = ref<PracticeSection[]>([]);

  gs.getPractices().then((p) => {
    practicesData.value = p;
    structuredPractices.value = structurePractices(p);
    loading.value = false;
  });

  /// Take the flat list of practice positions and structure it into
  // PracticeSelection (which has section > category > position structure)
  function structurePractices(practicesArray: PracticePosition[]) {
    const practiceSections: PracticeSection[] = [];

    for (const practice of practicesArray) {
      if (practice.section_type !== selectedSection.value) {
        continue;
      }

      let section: PracticeSection | undefined = practiceSections.find(
        (s) => s.name === practice.section_title
      );
      if (section === undefined) {
        section = {
          name: practice.section_title,
          categories: [],
        };
        practiceSections.push(section);
      }

      let category: PracticeCategory | undefined = section.categories.find(
        (c) => c.name === practice.category_title
      );
      if (category === undefined) {
        category = {
          name: practice.category_title,
          description: practice.category_description,
          icon: practice.category_icon,
          difficulty: practice.difficulty, // This will take the difficulty of the first encountered position, if the positions have different difficulty (they shouldn't), only the first one will apply
          positions: [],
        };
        section.categories.push(category);
      }

      category.positions.push(practice);
    }

    return practiceSections;
  }

  function setSelectedSection(sectionType: 'openings' | 'middlegames' | 'endgames' | 'masters') {
    switch (sectionType) {
      case 'openings':
        selectedSection.value = 'openings';
        break;
      case 'middlegames':
        selectedSection.value = 'middlegames';
        break;
      case 'endgames':
        selectedSection.value = 'endgames';
        break;
      case 'masters':
        selectedSection.value = 'masters';
        break;
    }

    structuredPractices.value = [];
    nextTick().then(() => {
      structuredPractices.value = structurePractices(practicesData.value);
      document.querySelectorAll('.practicecategory').forEach((el) => {
        el.classList.remove('cardEntrance');
        el.classList.add('cardEntrance');
      });

      document.querySelectorAll('.practicesection > h2').forEach((el) => {
        el.classList.remove('cardEntrance');
        el.classList.add('cardEntrance');
      });
    });
  }

  function setSelectedCategory(category: string) {
    if (selectedCategory.value === category) {
      // If setting to same, it means we want to deselect
      selectedCategory.value = '';
      return;
    }
    selectedCategory.value = category;
  }

  function getCategoryIconColor(difficulty: string) {
    // TODO Perhaps we don't want different colors, but then we should probably have a difficulty icon instead

    switch (difficulty) {
      case 'beginner':
      case 'novice':
      case 'intermediate':
      case 'skilled':
      case 'advanced':
      default:
        return 'var(--clr-accent)';
    }
  }

  function getCategoryBackgroundGradient(difficulty: string) {
    // TODO Perhaps we don't want different colors, but then we should probably have a difficulty icon instead

    switch (difficulty) {
      case 'beginner':
      case 'novice':
      case 'intermediate':
      case 'skilled':
      case 'advanced':
      default:
        return 'main-gradient';
    }
  }

  function getCategoryResult(
    sectionId: string,
    categoryId: string
  ): { finished: number; total: number; points: number } {
    const section: PracticeSection | undefined = structuredPractices.value.find(
      (s) => s.name === sectionId
    );
    if (section === undefined) {
      return { finished: 0, total: 0, points: 0 };
    }
    const category: PracticeCategory | undefined = section.categories.find(
      (c) => c.name === categoryId
    );
    if (category === undefined) {
      return { finished: 0, total: 0, points: 0 };
    }
    let finished = 0;
    let total = 0;
    let points = 0;
    for (const position of category.positions) {
      if (position.user_achieved) {
        finished++;
        points += position.user_points!;
      }
      total++;
    }
    return { finished, total, points };
  }
</script>

<style scoped>
  .active {
    background: linear-gradient(315deg, var(--clr-background-detail) 0%, var(--clr-rect-2) 100%);
  }

  .practicegrid {
    display: grid;
    grid-template-columns: 1fr 4fr;
    gap: 5rem;
  }

  .practicesection {
    display: flex;
    flex-direction: column;
  }

  .practicecategories {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(24rem, 1fr));
    gap: 1rem;
  }

  .practicecategory {
    padding: 1rem;
    max-height: 9rem;
    animation: cardEntrance 250ms ease-in-out;
    animation-fill-mode: backwards;
    width: 100%;
  }

  .practicecategory.selected {
    max-height: none;
  }

  .positions {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .position {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
  }

  .positionmiddle {
    flex-grow: 1;
    padding: 0.2rem;
  }

  .positionright {
    padding: 0.2rem 0.4rem 0.2rem 0.2rem;
    font-size: 1.5rem;
  }

  .positionright img {
    width: 1.5rem;
    aspect-ratio: 1;
    display: inline-block;
  }

  .positionboard {
    width: 6rem;
    height: 6rem;
  }

  @media (max-width: 992px) {
    .practicegrid {
      grid-template-columns: 1fr;
      gap: 1rem;
    }
    .practicecategories {
      grid-template-columns: 1fr;
    }
  }

  :deep(cg-board) {
    background-color: var(--clr-accent); /* Override the board background color */
  }

  @keyframes cardEntrance {
    from {
      opacity: 0;
      transform: scale(0.3);
      filter: hue-rotate(180deg);
    }

    to {
      opacity: 1;
      transform: scale(1);
      filter: hue-rotate(0deg);
    }
  }

  .bot-profile-image {
    border-radius: 50%;
  }

  .points > * {
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
    align-items: center;
  }

  .points img {
    width: 2rem;
    aspect-ratio: 1;
  }

  .positionmiddle {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .position {
    cursor: auto;
  }

  .positionname {
    flex-grow: 1;
  }

  .best-win {
    display: flex;
    flex-direction: row;
    gap: 0.1rem;
    align-items: center;
    justify-content: start;
  }

  .best-win img {
    width: 1.5rem;
    aspect-ratio: 1;
    margin: -0.3rem 0 0 0.2rem;
  }

  .play-icon {
    position: absolute;
    top: 2rem;
    left: 1.5rem;
    z-index: 10;
    color: white;
    --bs-btn-bg: var(--clr-rect-2);
    --bs-btn-border-color: var(--clr-rect-2);
    --bs-btn-hover-bg: var(--clr-rect-1);
    --bs-btn-hover-border-color: var(--clr-rect-1);
    --bs-btn-text: var(--clr-rect-1);
  }
  .categoryheader {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
  }

  .categoryheader-points {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: end;
    padding: 0.5rem;
    margin-top: -1.4rem;
    margin-right: -0.8rem;
    white-space: nowrap;
  }

  .categoryheader-points img {
    width: 2.3rem;
    aspect-ratio: 1;
  }

  .categoryheader-points div:first-child {
    font-size: 1.8rem;
  }

  .category-icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: #fff;
    width: 2.5rem;
    height: 2.5rem;
    margin: -0.5rem 0 0 -0.5rem;
  }

  .category-icon {
    font-size: 1.5rem;
  }

  .right {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .right a {
    font-family: 'Cabin Sketch', 'Neucha', sans-serif;
  }

  .left {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    min-width: 300px;
  }
</style>
