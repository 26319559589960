<template>
  <div :style="background" />
  <header>
    <HeaderMenu />
  </header>
  <main class="container top-bottom-padding">
    <RouterView />
  </main>
  <footer>
    <FooterMenu />
  </footer>
  <BotProfileModal v-if="showing == 'botProfile'" />
  <GameFeedbackModal v-if="showing == 'gameFeedback'" />
  <FeedbackModal v-if="showing == 'feedback'" />
  <PuzzleInfoModal v-if="showing == 'puzzleInfo'" />
  <PracticePreviewModal v-if="showing == 'practicePreview'" />
</template>

<script setup lang="ts">
  import { ref } from 'vue';
  import { RouterView } from 'vue-router';

  import { storeToRefs } from 'pinia';

  import FooterMenu from '@/components/FooterMenu.vue';
  import HeaderMenu from '@/components/HeaderMenu.vue';
  import BotProfileModal from '@/components/modals/BotProfileModal.vue';
  import FeedbackModal from '@/components/modals/FeedbackModal.vue';
  import GameFeedbackModal from '@/components/modals/GameFeedbackModal.vue';
  import PracticePreviewModal from '@/components/modals/PracticePreviewModal.vue';
  import PuzzleInfoModal from '@/components/modals/PuzzleInfoModal.vue';
  import { useBackgroundStore } from '@/stores/backgroundStore.js';
  import { useModalsStore } from '@/stores/modalsStore';

  const ms = useModalsStore();
  const { showing } = storeToRefs(ms);
  const backgroundDefault =
    'linear-gradient(180deg, hsl(200deg 100% 94.17%) 50%, hsla(0, 0%, 100%, 1) 100%)';

  const background = ref<any>({
    background: backgroundDefault,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    minHeight: '100vh',
    minWidth: '100vw',
    position: 'fixed',
    zIndex: '-1',
  });

  useBackgroundStore().$subscribe((mutation, state) => {
    if (!state.background) {
      document.body.style.background = backgroundDefault;
    } else {
      background.value.background = 'url(' + state.background + ')';
    }
  });
</script>

<style scoped>
  .top-bottom-padding {
    padding-top: 5rem;
    padding-bottom: 2rem;
  }
</style>
