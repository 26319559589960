import { createRouter, createWebHistory } from 'vue-router';

import PostHog from 'posthog-js';

import { useBackgroundStore } from '@/stores/backgroundStore';
import { useUserStore } from '@/stores/userStore';
import AccountView from '@/views/AccountView.vue';
import BotProfileView from '@/views/BotProfileView.vue';
import CheckoutView from '@/views/CheckoutView.vue';
import ContactView from '@/views/ContactView.vue';
import CourseView from '@/views/CourseView.vue';
import CourseViewView from '@/views/CourseViewView.vue';
import DashboardView from '@/views/DashboardView.vue';
import GameAnalysisView from '@/views/GameAnalysisView.vue';
import GameView from '@/views/GameView.vue';
import HistoryView from '@/views/HistoryView.vue';
import LandingView from '@/views/LandingView.vue';
import OpponentListView from '@/views/OpponentListView.vue';
import PageNotFoundView from '@/views/PageNotFoundView.vue';
import PersonaPlayView from '@/views/PersonaPlayView.vue';
import PracticeView from '@/views/PracticeView.vue';
import PremiumView from '@/views/PremiumView.vue';
import PrivacyPolicyView from '@/views/PrivacyPolicyView.vue';
import PuzzleView from '@/views/PuzzleView.vue';
import SignedUpView from '@/views/SignedUpView.vue';
import SigninView from '@/views/SigninView.vue';
import StatsView from '@/views/StatsView.vue';
import SurveyView from '@/views/SurveyView.vue';
import ToSView from '@/views/ToSView.vue';

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
      };
    } else {
      return { top: 0 };
    }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: LandingView,
    },
    {
      path: '/signin',
      name: 'signin',
      component: SigninView,
      meta: { type: 'signin' },
    },
    {
      path: '/signup',
      name: 'signup',
      component: SigninView,
      meta: { type: 'signup' },
    },
    {
      path: '/new',
      name: 'newhome',
      component: LandingView,
    },
    {
      path: '/games',
      name: 'games',
      component: HistoryView,
    },
    {
      path: '/premium',
      name: 'premium',
      component: PremiumView,
    },
    {
      path: '/game',
      name: 'game',
      component: GameView,
    },
    {
      path: '/game/signedup',
      name: 'gamesignedup',
      component: GameView,
    },
    {
      path: '/analysis',
      name: 'analysis',
      component: GameAnalysisView,
    },
    {
      path: '/explore',
      name: 'explore',
      component: OpponentListView,
    },
    {
      path: '/play/signedup',
      name: 'playsignedup',
      component: OpponentListView,
    },
    {
      path: '/practice',
      name: 'practice',
      component: PracticeView,
    },
    {
      path: '/puzzles',
      name: 'puzzles',
      component: PuzzleView,
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: DashboardView,
    },
    {
      path: '/dashboard/signedup',
      name: 'dashboardsignedup',
      component: DashboardView,
    },
    {
      path: '/signedup',
      name: 'signedup',
      component: SignedUpView,
    },
    {
      path: '/checkout',
      name: 'checkout',
      component: CheckoutView,
      children: [
        {
          path: '/checkout/:planid/success',
          name: 'checkout-success',
          component: CheckoutView,
        },
        {
          path: '/checkout/missing',
          name: 'checkout-missing',
          component: CheckoutView,
        },
      ],
    },
    {
      path: '/personaplay',
      name: 'personaplay',
      component: PersonaPlayView,
    },
    {
      path: '/survey',
      name: 'survey',
      component: SurveyView,
    },
    {
      path: '/bot/:botid',
      name: 'botprofile',
      component: BotProfileView,
    },
    {
      path: '/contact',
      name: 'contact',
      component: ContactView,
    },
    {
      path: '/settings',
      name: 'settings',
      component: AccountView,
      meta: { type: 'settings' },
    },
    {
      path: '/account',
      name: 'account',
      component: AccountView,
      meta: { type: 'account_info' },
    },
    {
      path: '/privacypolicy',
      name: 'privacypolicy',
      component: PrivacyPolicyView,
    },
    {
      path: '/cookiepolicy',
      name: 'cookiepolicy',
      component: PrivacyPolicyView,
    },
    {
      path: '/tos',
      name: 'tos',
      component: ToSView,
    },
    {
      path: '/courses/:courseid',
      name: 'course',
      component: CourseView,
    },
    {
      path: '/courses/:courseid/view',
      name: 'courseview',
      component: CourseViewView,
    },
    {
      path: '/stats',
      name: 'stats',
      component: StatsView,
    },
    { path: '/:pathMatch(.*)*', component: PageNotFoundView },
  ],
});

router.beforeEach((to, from, next) => {
  useBackgroundStore().setBackground(null);

  const userStore = useUserStore();

  if (to.name === 'home' && userStore.user.loggedIn) {
    next({ name: 'dashboard' });
  } else {
    next();
  }
});

PostHog.init('phc_gUy45B61enl4KIQtn4zzTcsa44uJUC55x0RsicMsh3E', {
  api_host: 'https://eu.posthog.com',
});

export async function setupPostHog() {}

// Global afterEach guard
router.afterEach((to, from) => {
  // @ts-ignore href doesn't seem to be in the type definition
  if (from.href != null) {
    // If href is defined, it should mean that we used the router programatically to navigate, and if so we need
    // to send the pageview to posthog since it won't be captured automatically
    PostHog.capture('$pageview', {
      path: to.path,
      title: document.title,
    });
  }
});

export default router;
