<template>
  <div style="display: flex; flex-direction: column; align-items: center">
    <div style="display: flex; flex-direction: column; gap: 1rem">
      <div
        :style="{
          display: 'flex',
          flexDirection: 'column',
          alignItems: isMobileWidth(1400) ? 'end' : 'center',
          marginTop: isMobileWidth(1400) ? '-2rem' : '0',
        }"
      >
        <span
          v-if="!loading"
          v-tippy="{
            content: 'Selected bots based on your rating and playstyle',
          }"
          :style="{
            fontSize: isMobileWidth(1400) ? '1.1rem' : '1.3rem',
            color: 'white',
          }"
          >Suggested bots
          <i
            class="fa-solid fa-comment-exclamation"
            style="vertical-align: super; font-size: 0.8rem"
        /></span>
        <span
          v-else
          :style="{
            fontSize: isMobileWidth(1400) ? '1.1rem' : '1.3rem',
            color: 'white',
          }"
          >{{ '\u00A0' }}
        </span>
        <div
          :style="{
            display: 'flex',
            gap: isMobileWidth(1400) ? '0.5rem' : '3rem',
          }"
        >
          <div
            style="margin: 1rem 0 1rem; display: flex; flex-direction: column; align-items: center"
          >
            <SuggestedBot
              :bot="nextOpponentSuggestion?.bots.suggested2"
              trackingIdentifer="suggested2"
            />
          </div>
          <div
            style="margin: 1rem 0 1rem; display: flex; flex-direction: column; align-items: center"
          >
            <SuggestedBot
              :bot="nextOpponentSuggestion?.bots.suggested"
              trackingIdentifer="suggested1"
            />
          </div>
          <div
            style="margin: 1rem 0 1rem; display: flex; flex-direction: column; align-items: center"
            v-if="getPremiumState()"
          >
            <SuggestedBot
              :bot="nextOpponentSuggestion?.bots.suggested3"
              trackingIdentifer="suggested3"
            />
          </div>
          <div
            style="margin: 1rem 0 1rem; display: flex; flex-direction: column; align-items: center"
            v-else
          >
            <SuggestedBot
              :bot="nextOpponentSuggestion?.bots.suggestedFree"
              trackingIdentifer="suggested-free"
            />
          </div>
        </div>
      </div>
      <div
        :style="{
          position: isMobileWidth(1400) ? 'relative' : 'absolute',
          marginBottom: isMobileWidth(1400) ? '1rem' : '0',
          right: isMobileWidth(1400) ? '' : '1rem',
          bottom: isMobileWidth(1400) ? '' : '1rem',
        }"
      >
        <div
          :style="{
            display: 'flex',
            gap: '1rem',
            justifyContent: 'center',
          }"
        >
          <div
            :style="{
              display: 'flex',
              flexDirection: isMobileWidth(1400) ? 'row' : 'column',
              gap: isMobileWidth(1400) ? '0.2rem' : '0',
            }"
          >
            <div
              class="bot-and-name"
              :style="{ marginBottom: isMobileWidth(1400) ? '' : '1.5rem' }"
            >
              <ProfileCircleWithPlaque
                v-if="nextOpponentSuggestion?.bots.rematch == null"
                :bot="null"
                :image-size="isMobileWidth(1400) ? 2 : 4"
                :font-size="'0.9rem'"
                :show-plaque="!isMobileWidth(1400)"
                role="button"
              />
              <div v-else style="display: flex; flex-direction: column; align-items: center">
                <img
                  v-tippy="{
                    content: 'Random suitable bot around your rating',
                  }"
                  alt="Bot profile image"
                  class="profile-image-circle"
                  style="border: 2px solid #ddd"
                  src="https://storage.googleapis.com/chessiverse1/images/elements/rated_box_124.webp"
                />
                <div
                  v-if="!isMobileWidth(1400)"
                  class="card profile-name-text"
                  style="background-color: var(--clr-accent); z-index: 10; white-space: nowrap"
                >
                  <span style="font-size: 0.9rem"
                    ><i class="fa-solid fa-plus-minus" style="font-size: 0.8rem" /> 200</span
                  >
                </div>
              </div>
            </div>
            <div
              v-if="nextOpponentSuggestion?.bots.rematch == null"
              style="height: 2rem; width: 7rem"
            />
            <div v-else style="height: 2rem">
              <button
                class="btn btn-info bounce-top"
                style="padding: 0.2rem 0.5rem; font-size: 0.9rem; width: 7rem"
                @click="
                  () => {
                    startGame(
                      getPremiumState()
                        ? nextOpponentSuggestion!.bots.random.id
                        : nextOpponentSuggestion!.bots.randomFree.id
                    );
                    track('suggested_bots', 'start_rated', 'click');
                  }
                "
              >
                <span style="white-space: nowrap"
                  ><i class="fa-solid fa-dice" style="font-size: 0.8rem" /> Matchmaking</span
                >
              </button>
            </div>
          </div>
          <div
            :style="{
              display: 'flex',
              flexDirection: isMobileWidth(1400) ? 'row' : 'column',
              gap: isMobileWidth(1400) ? '0.2rem' : '0',
            }"
          >
            <ProfileCircleWithPlaque
              :bot="nextOpponentSuggestion?.bots.rematch"
              :image-size="isMobileWidth(1400) ? 2 : 4"
              :font-size="'0.9rem'"
              :show-plaque="!isMobileWidth(1400)"
              role="button"
              :style="{ marginBottom: isMobileWidth(1400) ? '' : '1.5rem' }"
              @click="
                () => {
                  ms.showBotProfile(nextOpponentSuggestion!.bots.rematch.id);
                  track('suggested_bots', 'show_profile_rematch', 'click');
                }
              "
            />
            <div
              v-if="nextOpponentSuggestion?.bots.rematch == null"
              style="height: 2rem; width: 6rem"
            />
            <div v-else style="height: 2rem">
              <button
                class="btn btn-info bounce-top"
                style="padding: 0.2rem 0.5rem; font-size: 0.9rem; width: 6rem"
                @click="
                  () => {
                    startGame(nextOpponentSuggestion!.bots.rematch.id);
                    track('suggested_bots', 'rematch', 'click');
                  }
                "
              >
                <span style="white-space: nowrap"
                  ><i class="fa-solid fa-arrow-rotate-right" /> Rematch</span
                >
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
  import { type PropType, nextTick, onMounted, ref } from 'vue';
  import { useRouter } from 'vue-router';

  import ProfileCircleWithPlaque from '@/components/common/ProfileCircleWithPlaque.vue';
  import SuggestedBot from '@/components/dashboard/playbox/SuggestedBot.vue';
  import { useBotsStore } from '@/stores/botStore';
  import { useModalsStore } from '@/stores/modalsStore';
  import { useUserStore } from '@/stores/userStore';
  import type { Bot, NextOpponentSuggestion } from '@/types/apitypes';
  import { getPremiumState } from '@/util/premium';
  import { track } from '@/util/tracking';
  import { isMobileWidth } from '@/util/util';

  const router = useRouter();
  const us = useUserStore();
  const bs = useBotsStore();
  const ms = useModalsStore();

  const emit = defineEmits(['userInput']);

  defineProps({
    loading: { type: Boolean, default: false },
  });

  const nextOpponentSuggestion = ref<NextOpponentSuggestion | null>();

  function startGame(botId: string) {
    router.push({
      name: 'game',
      query: {
        bid: botId,
        type: 'casual',
        auto: 'true',
      },
    });
  }

  onMounted(() => {
    nextTick(() => {
      useBotsStore()
        .getNextOpponent(null)
        .then((n) => {
          nextOpponentSuggestion.value = n;
        });
    });
  });
</script>
<style scoped>
  .profile-image-circle {
    width: 4rem;
    height: 4rem;
    z-index: 10;
    border-radius: 50%;
    border: 1.5px solid #444;
  }

  .profile-name-text {
    position: absolute;
    bottom: -1rem;
    padding: 0 0.5rem;
  }

  .bot-and-name {
    position: relative;
    display: flex;
    justify-content: center;
    margin: auto;
    z-index: 100;
  }

  @media (max-width: 768px) {
    .profile-image-circle {
      width: 2rem;
      height: 2rem;
    }
  }
</style>
