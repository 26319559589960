import type { Ref } from 'vue';

export function useRatingRecords(ratingRecords: Ref<any>) {
  const getRatingRecords = (timeframe: 'week' | 'month' | 'year' | 'all') => {
    if (ratingRecords.value == null) {
      return {
        highest: '-',
        highestDate: '',
        lowest: '-',
        lowestDate: '',
      };
    }

    let highest;
    let highestDate;
    let lowest;
    let lowestDate;

    if (timeframe == 'week') {
      highest = ratingRecords?.value?.highest_rating_7d ?? '-';
      highestDate =
        highest == '-'
          ? ''
          : (ratingRecords?.value?.highest_rating_7d_ended_at?.value?.split('T')[0] ?? '');
      lowest = ratingRecords?.value?.lowest_rating_7d ?? '-';
      lowestDate =
        lowest == '-'
          ? ''
          : (ratingRecords?.value?.lowest_rating_7d_ended_at?.value?.split('T')[0] ?? '');
    } else if (timeframe == 'month') {
      highest = ratingRecords?.value?.highest_rating_30d ?? '-';
      highestDate =
        highest == '-'
          ? ''
          : (ratingRecords?.value?.highest_rating_30d_ended_at?.value?.split('T')[0] ?? '');
      lowest = ratingRecords?.value?.lowest_rating_30d ?? '-';
      lowestDate =
        lowest == '-'
          ? ''
          : (ratingRecords?.value?.lowest_rating_30d_ended_at?.value?.split('T')[0] ?? '');
    } else if (timeframe == 'year') {
      highest = ratingRecords?.value?.highest_rating_365d ?? '-';
      highestDate =
        highest == '-'
          ? ''
          : (ratingRecords?.value?.highest_rating_365d_ended_at?.value?.split('T')[0] ?? '');
      lowest = ratingRecords?.value?.lowest_rating_365d ?? '-';
      lowestDate =
        lowest == '-'
          ? ''
          : (ratingRecords?.value?.lowest_rating_365d_ended_at?.value?.split('T')[0] ?? '');
    } else {
      highest = ratingRecords?.value?.highest_rating_all ?? '-';
      highestDate =
        highest == '-'
          ? ''
          : (ratingRecords?.value?.highest_rating_all_ended_at?.value?.split('T')[0] ?? '');
      lowest = ratingRecords?.value?.lowest_rating_all ?? '-';
      lowestDate =
        lowest == '-'
          ? ''
          : (ratingRecords?.value?.lowest_rating_all_ended_at?.value?.split('T')[0] ?? '');
    }

    return {
      highest,
      highestDate,
      lowest,
      lowestDate,
    };
  };

  return {
    getRatingRecords,
  };
}
