<template>
  <div class="games-per-weekday-container">
    <h3 class="weekday-title">
      <i class="fa-solid fa-calendar-week" style="color: var(--clr-accent)" />
      Games Played Per Weekday
    </h3>
    <div v-if="!loading" class="weekday-chart">
      <div class="weekday-bars">
        <div
          v-for="(day, index) in weekdayData"
          :key="day.name"
          class="weekday-bar-container"
          v-tippy="{
            content: `${day.name}: ${day.count} ${
              day.count === 1 ? TEXT_FORMATTING.SINGULAR_GAME : TEXT_FORMATTING.PLURAL_GAMES
            }`,
          }"
        >
          <div class="weekday-label">{{ day.name.substring(0, 3) }}</div>
          <div class="weekday-bar-wrapper">
            <div
              class="weekday-bar"
              :style="{
                height: `${getBarHeight(day.count)}%`,
                backgroundColor: getBarColor(index),
              }"
            />
          </div>
          <div class="weekday-count">{{ day.count }}</div>
        </div>
      </div>
    </div>
    <div v-else class="weekday-placeholder pulsate-load" />
  </div>
</template>

<script setup lang="ts">
  import { computed } from 'vue';

  import { TEXT_FORMATTING, WEEKDAY_NAMES } from '@/constants';
  import type { FirestoreTimestamp } from '@/types/internaltypes';

  interface GameDayData {
    game_date:
      | { value: string }
      | Date
      | number
      | FirestoreTimestamp
      | { _seconds: number; _nanoseconds: number };
    games_played: number;
  }

  const props = defineProps({
    loading: {
      type: Boolean,
      default: true,
    },
    gamesPerDay: {
      type: Array as () => Array<GameDayData>,
      default: () => [],
    },
  });

  // Process the games per day data to get games per weekday
  const weekdayData = computed(() => {
    // Initialize counts for each day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
    const dayCounts = [0, 0, 0, 0, 0, 0, 0];

    // Process each game day
    props.gamesPerDay.forEach((dayData) => {
      if (dayData.game_date) {
        let date;
        if (typeof dayData.game_date === 'object' && '_seconds' in dayData.game_date) {
          // Handle Firestore timestamp format with _seconds property
          date = new Date(dayData.game_date._seconds * 1000);
        } else if (typeof dayData.game_date === 'object' && 'value' in dayData.game_date) {
          // Handle object with value property
          date = new Date((dayData.game_date as { value: string }).value);
        } else if (dayData.game_date instanceof Date) {
          // Handle Date object directly
          date = dayData.game_date;
        } else if (typeof dayData.game_date === 'number') {
          // Handle Unix timestamp (seconds) by converting to milliseconds
          date = new Date(dayData.game_date * 1000);
        } else if (typeof dayData.game_date === 'string') {
          // Handle string date
          date = new Date(dayData.game_date);
        }

        if (date && date instanceof Date && !isNaN(date.getTime())) {
          // Get day of week (0 = Sunday, 1 = Monday, ..., 6 = Saturday in JS)
          // Use the natural day order from JavaScript Date
          const dayOfWeek = date.getDay();

          // Add the games played to the appropriate day
          dayCounts[dayOfWeek] += dayData.games_played;
        }
      }
    });

    // Map the counts to day names
    return WEEKDAY_NAMES.map((name, index) => ({
      name,
      count: dayCounts[index],
    }));
  });

  // Calculate the maximum number of games for scaling
  const maxGames = computed(() => {
    const counts = weekdayData.value.map((day) => day.count);
    return Math.max(...counts, 1); // Ensure at least 1 to avoid division by zero
  });

  // Calculate the height percentage for each bar
  function getBarHeight(count: number): number {
    return (count / maxGames.value) * 100;
  }

  // Get a color for the bar based on the index
  function getBarColor(index: number): string {
    const colors = [
      '#4e79a7', // Sunday
      '#f28e2c', // Monday
      '#e15759', // Tuesday
      '#76b7b2', // Wednesday
      '#59a14f', // Thursday
      '#edc949', // Friday
      '#af7aa1', // Saturday
    ];
    return colors[index % colors.length];
  }
</script>

<style scoped>
  .games-per-weekday-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 1rem;
  }

  .weekday-title {
    margin-bottom: 1.5rem;
    text-align: center;
    color: var(--clr-main);
  }

  .weekday-chart {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .weekday-bars {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    height: 250px;
    margin-top: 1rem;
  }

  .weekday-bar-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 13%;
  }

  .weekday-label {
    margin-bottom: 0.5rem;
    font-weight: bold;
    color: var(--clr-main);
  }

  .weekday-bar-wrapper {
    width: 100%;
    height: 200px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }

  .weekday-bar {
    width: 100%;
    border-radius: 4px 4px 0 0;
    transition: height 0.5s ease;
  }

  .weekday-count {
    margin-top: 0.5rem;
    font-weight: bold;
    color: var(--clr-accent);
  }

  .weekday-placeholder {
    width: 100%;
    height: 250px;
    background-color: var(--clr-main-lightest);
    border-radius: 8px;
  }

  @media (max-width: 768px) {
    .weekday-bars {
      height: 200px;
    }

    .weekday-bar-wrapper {
      height: 150px;
    }

    .weekday-label {
      font-size: 0.8rem;
    }

    .weekday-count {
      font-size: 0.8rem;
    }
  }
</style>
