<template>
  <div
    class="modal fade"
    id="practicePreviewModal"
    tabindex="-1"
    aria-labelledby="practicePreviewModalLabel"
    aria-hidden="true"
    ref="modalElement"
  >
    <div class="modal-dialog modal-md">
      <div class="modal-content">
        <div class="modal-header" style="border-bottom: 0px; justify-content: right; z-index: 10">
          <a
            type="button"
            id="practicePreviewModalClose"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <img
              alt="Cross icon"
              src="https://storage.googleapis.com/chessiverse1/images/elements/cross.webp"
              style="width: 1rem; height: 1rem"
            />
          </a>
        </div>
        <div class="modal-body" style="margin-top: -2rem">
          <div class="modal-body-wrapper">
            <div style="width: 100%" v-if="practicePreviewModalPracticePosition != null">
              <span style="font-size: 1.5rem">{{
                practicePreviewModalPracticePosition!.category_title +
                ': ' +
                practicePreviewModalPracticePosition!.name
              }}</span>
              <TheChessboard
                :board-config="
                  getBoardConfig(
                    practicePreviewModalPracticePosition!.start_position,
                    practicePreviewModalPracticePosition!.side
                  )
                "
                class="positionboard"
              />
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-info ph-no-capture"
            style="height: 2.5rem; width: 5rem"
            @click="
              () => {
                doneWithModal();
                track('practice_preview_modal', 'dismiss', 'click');
              }
            "
          >
            Cancel
          </button>
          <button
            type="button"
            class="btn btn-info play-icon ph-no-capture"
            style="height: 2.5rem; width: 5rem"
            @click="
              () => {
                playPosition(practicePreviewModalPracticePosition!.id);
                track('practice__modalview', 'play_position', 'click', {
                  position_id: practicePreviewModalPracticePosition!.id,
                });
              }
            "
          >
            Play
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { type BoardConfig, TheChessboard } from 'vue3-chessboard';

  import { storeToRefs } from 'pinia';

  import router from '@/router';
  import { useModalsStore } from '@/stores/modalsStore';
  import { track } from '@/util/tracking';

  const ms = useModalsStore();

  const { practicePreviewModalPracticePosition } = storeToRefs(ms);

  const doneWithModal = () => {
    ms.closeCurrentModal();
  };

  function getBoardConfig(fen: string, side: 'white' | 'black'): BoardConfig {
    return {
      fen: fen,
      orientation: side,
      viewOnly: true,
    };
  }
  function playPosition(positionId: string) {
    doneWithModal();
    router.push({
      name: 'game',
      query: {
        type: 'practice',
        practiceId: positionId,
      },
    });
  }
</script>

<style scoped>
  :deep(cg-board) {
    background-color: var(--clr-accent); /* Override the board background color */
  }
  .header-icon {
    color: white;
    font-size: 2rem;
  }
  .header-icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: var(--clr-rect-2);
    width: 5rem;
    height: 5rem;
    margin-top: -2.5rem;
  }

  .modal-body-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    text-align: center;
  }

  .modal-footer {
    border-top: 0px;
  }

  .lighted {
    color: yellow;
    filter: drop-shadow(0 0 1px black);
  }

  .play-icon {
    color: white;
    --bs-btn-bg: var(--clr-rect-2);
    --bs-btn-border-color: var(--clr-rect-2);
    --bs-btn-hover-bg: var(--clr-rect-1);
    --bs-btn-hover-border-color: var(--clr-rect-1);
    --bs-btn-text: var(--clr-rect-1);
  }
</style>
