<template>
  <GameAnalysis :initialGameId="gameId" />
</template>

<script setup lang="ts">
  import { ref, watchEffect } from 'vue';
  import { useRoute } from 'vue-router';

  import GameAnalysis from '@/components/games/GameAnalysis.vue';

  const route = useRoute();
  const gameId = ref(route.query.id as string);

  watchEffect(() => {
    gameId.value = route.query.id as string;
  });
</script>
