<template>
  <div class="practicecategories">
    <div
      v-for="(puzzleSet, ci) in puzzleSets"
      :class="
        'card practicecategory ph-no-capture main-gradient' +
        (puzzleSet.bot.id === selectedCategory ? ' selected' : '')
      "
      :key="'set' + ci"
      role="button"
      @click="
        () => {
          setSelectedCategory(puzzleSet.bot.id);
          track('puzzle_list', 'set_selected_puzzle_set', 'click', {
            puzzle_set_id: puzzleSet.bot.id,
          });
        }
      "
    >
      <div class="categoryheader">
        <div class="categoryheader-names">
          <div class="d-flex gap-2">
            <div class="category-icon-container">
              <img
                :src="ps.img(puzzleSet.bot.id, ImageType.BotProfile, '80')"
                style="border-radius: 50%; width: 2rem"
                alt="Bot profile image"
              />
            </div>
          </div>
        </div>
        <div class="categoryheader-points">
          <div class="finished-box" v-if="isFinished(puzzleSet)">
            <img
              alt="Star"
              v-if="isPerfect(puzzleSet)"
              :style="{
                height: '2.5rem',
                width: '2.5rem',
                marginTop: '-0.5rem',
              }"
              src="https://storage.googleapis.com/chessiverse1/images/elements/star.webp"
            />
            <i
              v-else
              class="fa-solid fa-circle-check"
              :style="{
                fontSize: '2.5rem',
                color: successCount(puzzleSet) == 10 ? 'var(--clr-accent4)' : 'var(--clr-rect-2)',
                background: 'white',
                borderRadius: '50%',
              }"
            />
            <span>{{ successCount(puzzleSet) }}/10</span>
          </div>

          <button
            v-else-if="getPremiumState() || puzzleSet.bot.premium == 'open'"
            class="btn btn-info ph-no-capture"
            @click="
              () => {
                playPuzzleSet(puzzleSet);
                track('puzzle_list', 'play_puzzle_set', 'click', {
                  puzzle_set_id: puzzleSet.bot.id,
                });
              }
            "
          >
            {{ isStarted(puzzleSet) ? 'Continue' : 'Start' }}
          </button>
          <button
            v-else
            class="btn btn-outline-info ph-no-capture"
            @click="
              () => {
                router.push({ name: 'premium' });
                track('puzzle_list', 'play_puzzle_set_locked', 'click', {
                  puzzle_set_id: puzzleSet.bot.id,
                });
              }
            "
          >
            <i class="fa-solid fa-crown" style="color: var(--clr-premium-yellow)" />
            Unlock
          </button>
        </div>
      </div>

      <div class="positions" v-if="puzzleSet.bot.id === selectedCategory">
        <span>{{ puzzleSet.bot.name }} ({{ puzzleSet.bot.strength.estimated_elo }})</span>
        <PuzzleBox :puzzles="puzzleSet.puzzles" size="small" />
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
  import { type PropType, ref, watch } from 'vue';
  import { useRouter } from 'vue-router';

  import PuzzleBox from '@/components/puzzles/PuzzleBox.vue';
  import { useBotsStore } from '@/stores/botStore';
  import { usePageStore } from '@/stores/pageStore';
  import type { Bot, Puzzle } from '@/types/apitypes';
  import { ImageType } from '@/types/internaltypes';
  import { getPremiumState } from '@/util/premium';
  import { track } from '@/util/tracking';

  const router = useRouter();

  const bs = useBotsStore();
  const ps = usePageStore();

  const props = defineProps({
    puzzles: {
      type: Object as PropType<Puzzle[]>,
      required: true,
    },
  });

  const puzzleSets = ref(getPuzzleSets(props.puzzles));
  const selectedCategory = ref('');

  watch(
    () => props.puzzles,
    (puzzles) => {
      puzzleSets.value = getPuzzleSets(puzzles);
    },
    { deep: true }
  );

  function isPerfect(puzzleSet: { bot: Bot; puzzles: Puzzle[] }) {
    function isNotPerfect(puzzle: Puzzle) {
      return (
        puzzle.user_result != null &&
        !puzzle.user_result.every((r) => r.every((rr) => rr === 'correct_move'))
      );
    }

    const successes = successCount(puzzleSet);

    if (successes >= 10) {
      return (
        puzzleSet.puzzles.reduce((acc, puzzle) => {
          return acc + (isNotPerfect(puzzle) ? 1 : 0);
        }, 0) === 0
      );
    }
    return false;
  }

  function successCount(puzzleSet: { bot: Bot; puzzles: Puzzle[] }) {
    function isFailed(puzzle: Puzzle) {
      return puzzle.user_result != null && puzzle.user_result.some((r) => r.includes('failed'));
    }

    return (
      puzzleSet.puzzles.length -
      puzzleSet.puzzles.reduce((acc, puzzle) => {
        return acc + (isFailed(puzzle) ? 1 : 0);
      }, 0)
    );
  }

  function isFinished(puzzleSet: { bot: Bot; puzzles: Puzzle[] }) {
    return puzzleSet.puzzles.every((p) => p.user_result != null);
  }

  function isStarted(puzzleSet: { bot: Bot; puzzles: Puzzle[] }) {
    return puzzleSet.puzzles.some((p) => p.user_result != null);
  }

  function playPuzzleSet(puzzleSet: { bot: Bot; puzzles: Puzzle[] }) {
    router.push({
      name: 'game',
      query: {
        type: 'puzzle',
        puzzleId: puzzleSet.bot.id,
      },
    });
  }

  function setSelectedCategory(category: string) {
    if (selectedCategory.value === category) {
      // If setting to same, it means we want to deselect
      selectedCategory.value = '';
      return;
    }
    selectedCategory.value = category;
  }

  function getPuzzleSets(puzzles: Puzzle[]): { bot: Bot; puzzles: Puzzle[] }[] {
    const botidToPuzzles: Map<string, Puzzle[]> = new Map();
    for (const puzzle of puzzles) {
      if (!botidToPuzzles.has(puzzle.bot_id)) {
        botidToPuzzles.set(puzzle.bot_id, []);
      }
      botidToPuzzles.get(puzzle.bot_id)!.push(puzzle);
    }

    const result = [];

    for (const [botid, puzzles] of botidToPuzzles) {
      const bot = bs.getBot(botid);

      if (bot.name == 'Unknown') {
        // Just ignoring unknown bots, they'll pop up eventually once they're published
        continue;
      }

      result.push({
        bot: bot,
        puzzles: puzzles,
      });
    }
    return result;
  }
</script>

<style scoped>
  .practicecategories {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
  }

  .practicecategory {
    width: 10rem;
    padding: 1rem;
    justify-content: center;
    height: 5rem;
    animation: cardEntrance 250ms ease-in-out;
    animation-fill-mode: backwards;
  }

  .practicecategory.selected {
    height: auto;
  }

  .finished-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.1rem;
  }

  .finished-box span {
    font-size: 1rem;
  }

  .finished {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10%;
    width: 2.5rem;
    height: 2.5rem;
    margin: -0.5rem 0 0 -0.5rem;
  }

  .perfect_gold span {
    filter: drop-shadow(0 0 1px orange) drop-shadow(0 0 1px orange);
  }

  .perfect_gold {
    color: yellow;

    background:
      radial-gradient(
        ellipse farthest-corner at right bottom,
        #fedb37 0%,
        #fdb931 8%,
        #9f7928 30%,
        #8a6e2f 40%,
        transparent 80%
      ),
      radial-gradient(
        ellipse farthest-corner at left top,
        #ffffff 0%,
        #ffffac 8%,
        #d1b464 80%,
        #5d4a1f 90%,
        #5d4a1f 100%
      );
  }

  .gold {
    background:
      radial-gradient(
        ellipse farthest-corner at right bottom,
        #fedb37 0%,
        #fdb931 8%,
        #9f7928 30%,
        #8a6e2f 40%,
        transparent 80%
      ),
      radial-gradient(
        ellipse farthest-corner at left top,
        #ffffff 0%,
        #ffffac 8%,
        #d1b464 80%,
        #5d4a1f 90%,
        #5d4a1f 100%
      );
  }

  .silver {
    background:
      radial-gradient(
        ellipse farthest-corner at right bottom,
        #e6e8fa 0%,
        #d4d8f0 8%,
        #b0b8d1 30%,
        #989fb6 40%,
        transparent 80%
      ),
      radial-gradient(
        ellipse farthest-corner at left top,
        #ffffff 0%,
        #f0f0f0 8%,
        #b4b8c5 80%,
        #7a7f8e 90%,
        #7a7f8e 100%
      );
  }

  .bronze {
    background:
      radial-gradient(
        ellipse farthest-corner at right bottom,
        #cd7f32 0%,
        #a97148 8%,
        #804a2f 30%,
        #703825 40%,
        transparent 80%
      ),
      radial-gradient(
        ellipse farthest-corner at left top,
        #ffffff 0%,
        #ffe6cc 8%,
        #ad6f33 80%,
        #5c341f 90%,
        #5c341f 100%
      );
  }

  .white {
    background: white;
  }
  .positions {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    flex-wrap: wrap;
    justify-content: center;
  }

  @media (max-width: 992px) {
    .practicecategories {
      grid-template-columns: 1fr;
    }
  }

  :deep(cg-board) {
    background-color: var(--clr-accent); /* Override the board background color */
  }

  @keyframes cardEntrance {
    from {
      opacity: 0;
      transform: scale(0.3);
      filter: hue-rotate(180deg);
    }

    to {
      opacity: 1;
      transform: scale(1);
      filter: hue-rotate(0deg);
    }
  }

  .bot-profile-image {
    border-radius: 50%;
  }

  .points > * {
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
    align-items: center;
  }

  .points img {
    width: 2rem;
    aspect-ratio: 1;
  }

  .positionmiddle {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .position {
    cursor: auto;
  }

  .positionname {
    flex-grow: 1;
  }

  .best-win {
    display: flex;
    flex-direction: row;
    gap: 0.1rem;
    align-items: center;
    justify-content: start;
  }

  .best-win img {
    width: 1.5rem;
    aspect-ratio: 1;
    margin: -0.3rem 0 0 0.2rem;
  }

  .play-icon {
    position: absolute;
    top: 2rem;
    left: 1.5rem;
    z-index: 10;
    color: white;
    --bs-btn-bg: var(--clr-rect-2);
    --bs-btn-border-color: var(--clr-rect-2);
    --bs-btn-hover-bg: var(--clr-rect-1);
    --bs-btn-hover-border-color: var(--clr-rect-1);
    --bs-btn-text: var(--clr-rect-1);
  }
  .categoryheader {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: center;
    justify-content: space-between;
  }

  .categoryheader-points {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: end;
    padding: 0.5rem;
    white-space: nowrap;
  }

  .categoryheader-points img {
    width: 2.3rem;
    aspect-ratio: 1;
  }

  .categoryheader-points div:first-child {
    font-size: 1.8rem;
  }

  .category-icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: #fff;
    width: 2.5rem;
    height: 2.5rem;
    margin: -0.5rem 0 0 -0.5rem;
  }

  .category-icon {
    font-size: 1.5rem;
  }

  .right {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .right a {
    font-family: 'Cabin Sketch', 'Neucha', sans-serif;
  }

  .left {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    min-width: 300px;
  }
</style>
