import { useBotsStore } from '@/stores/botStore';

/**
 * Composable for calculating Challenging Wins Rate statistics
 * Challenging Wins Rate refers to games won after being at a disadvantage
 */
export function useChallengingWinsRate() {
  /**
   * Calculate Challenging Wins Rate (percentage of games won after being at a disadvantage)
   * Since we don't have detailed game data with position evaluations, we use an estimate based on win patterns
   * @param validBots Array of bots with game statistics
   * @param userRating The current rating of the user
   * @param totalWins Total number of wins by the user
   * @returns The comeback win rate as a decimal
   */
  const calculateChallengingWinsRate = (
    validBots: any[],
    userRating: number,
    totalWins: number
  ) => {
    // We can look at the pattern of wins across different bots to make a more educated guess
    // For example, wins against higher-rated bots are more likely to have been comebacks
    const likelyComeback = validBots
      .filter((bot: any) => {
        const botRating = useBotsStore().getBot(bot.bot_id)?.strength?.estimated_elo || 0;
        // Consider wins against bots rated higher than the player as potential comebacks
        return botRating > userRating && bot.wins > 0;
      })
      .reduce((total: number, bot: any) => total + bot.wins, 0);

    let comebackWinRate = 0;
    // If we have likely comebacks, use that data, otherwise fall back to the 40% estimate
    if (likelyComeback > 0 && totalWins > 0) {
      // Add a small factor for comebacks against similarly rated bots
      const estimatedComeback = likelyComeback + Math.round(totalWins * 0.2);
      // Cap at total wins
      const cappedEstimate = Math.min(estimatedComeback, totalWins);
      comebackWinRate = totalWins > 0 ? cappedEstimate / totalWins : 0;
    } else {
      // Fallback to standard estimate if we can't make a better guess
      const estimatedComebackWins = Math.round(totalWins * 0.4);
      comebackWinRate = totalWins > 0 ? estimatedComebackWins / totalWins : 0;
    }

    return {
      comebackWinRate,
      likelyComeback,
    };
  };

  return {
    calculateChallengingWinsRate,
  };
}
