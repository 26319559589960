<template>
  <div class="settings-grid">
    <!-- Font size section -->
    <div class="setting-section">
      <span>Font size:</span>
      <div style="display: flex; gap: 0.2rem">
        <span style="font-size: 1.5rem; min-width: 4rem"> {{ (fontSize * 100).toFixed(0) }}% </span>
        <button class="btn btn-info small-button" @click="decreaseFontSize">-</button>
        <button class="btn btn-info small-button" @click="increaseFontSize">+</button>
      </div>
    </div>

    <div class="setting-section">
      <span>Font:</span>
      <div class="btn-group" role="group" aria-label="Button group with nested dropdown">
        <div class="btn-group" role="group" style="width: 11rem">
          <a
            id="btnGroupDrop3"
            type="button"
            class="btn btn-info dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            :style="{ fontFamily: ps.courseSettings.fontFamily ?? 'inherit' }"
          >
            {{ ps.courseSettings.fontFamily ?? 'Default' }}
          </a>
          <div class="dropdown-menu" aria-labelledby="btnGroupDrop3">
            <a
              v-for="font in fonts"
              :key="font"
              :value="font"
              class="dropdown-item"
              href="#"
              :style="{ fontFamily: font ?? 'inherit' }"
              @click="ps.courseSettings.fontFamily = font === 'Default' ? null : font"
            >
              {{ font }}
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="setting-section">
      <span>Board size:</span>
      <div style="display: flex; gap: 0.5rem">
        {{ boardWidthPercent.toFixed(0) + '%' }}
        <Slider
          :min="0"
          :max="100"
          :lazy="true"
          :step="5"
          showTooltip="drag"
          class="board-size-slider"
          v-model="boardWidthPercent"
        />
      </div>
    </div>

    <div class="setting-section">
      <span>Colors: </span>
      <div style="display: flex; gap: 0.5rem">
        <div style="display: flex; flex-direction: column; gap: 0.5rem; margin-right: 1.5rem">
          <div style="display: flex; flex-direction: column; gap: 0.2rem">
            <span style="font-size: 0.8rem; color: var(--clr-accent)">Highlight mode:</span>
            <Toggle
              class="wider-toggle"
              v-model="ps.courseSettings.colors.mode"
              :true-value="'bg'"
              :false-value="'fg'"
              onLabel="Background"
              offLabel="Foreground"
            />
          </div>
          <div>
            <button class="btn btn-info small-button" @click="resetAllColors()" style="width: 5rem">
              {{ resetColorsClickedOnce ? 'Confirm' : 'Reset colors' }}
            </button>
          </div>
        </div>
        <div class="color-group">
          <span>Main</span>
          <div class="color-picker-row">
            <span
              :style="{
                color: bgTextColor,
              }"
              >Background:</span
            >
            <pick-colors
              role="button"
              class="bounce-top"
              v-model:value="ps.courseSettings.colors.main.bg.override"
              :width="colorPickerSize"
              :height="colorPickerSize"
            />
          </div>
          <div class="color-picker-row">
            <span
              :style="{
                color: fgTextColor,
              }"
              >Foreground:</span
            >
            <pick-colors
              role="button"
              class="bounce-top"
              v-model:value="ps.courseSettings.colors.main.fg.override"
              :width="colorPickerSize"
              :height="colorPickerSize"
            />
          </div>
        </div>

        <!-- Alternative Colors -->
        <div class="color-group">
          <span>Alternative</span>
          <div class="color-picker-row">
            <span
              :style="{
                color: bgTextColor,
              }"
              >Background:</span
            >
            <pick-colors
              role="button"
              class="bounce-top"
              v-model:value="ps.courseSettings.colors.alternative.bg.override"
              :width="colorPickerSize"
              :height="colorPickerSize"
            />
          </div>
          <div>
            <span
              :style="{
                color: fgTextColor,
              }"
              >Foreground:</span
            >
            <pick-colors
              role="button"
              class="bounce-top"
              v-model:value="ps.courseSettings.colors.alternative.fg.override"
              :width="colorPickerSize"
              :height="colorPickerSize"
            />
          </div>
        </div>

        <div class="color-group">
          <span>Caution</span>
          <div class="color-picker-row">
            <span
              :style="{
                color: bgTextColor,
              }"
              >Background:</span
            >
            <pick-colors
              role="button"
              class="bounce-top"
              v-model:value="ps.courseSettings.colors.caution.bg.override"
              :width="colorPickerSize"
              :height="colorPickerSize"
            />
          </div>
          <div class="color-picker-row">
            <span
              :style="{
                color: fgTextColor,
              }"
              >Foreground:</span
            >
            <pick-colors
              role="button"
              class="bounce-top"
              v-model:value="ps.courseSettings.colors.caution.fg.override"
              :width="colorPickerSize"
              :height="colorPickerSize"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { computed, ref } from 'vue';
  import PickColors from 'vue-pick-colors';

  import Slider from '@vueform/slider';
  import Toggle from '@vueform/toggle';

  import { usePageStore } from '@/stores/pageStore';

  // Access the Pinia store
  const ps = usePageStore();

  const colorPickerSize = 24;

  const resetColorsClickedOnce = ref(false);

  const bgTextColor = computed(() =>
    ps.courseSettings.colors.mode == 'bg' ? 'var(--clr-main)' : 'var(--clr-main-lighter)'
  );
  const fgTextColor = computed(() =>
    ps.courseSettings.colors.mode == 'fg' ? 'var(--clr-main)' : 'var(--clr-main-lighter)'
  );

  // Board size: convert boardWidth (20–50) to a percentage (0–100)
  const boardWidthPercent = computed({
    get() {
      return ps.courseSettings.boardWidth == null
        ? 50
        : ((ps.courseSettings.boardWidth - 20) / 30) * 100;
    },
    set(value) {
      ps.courseSettings.boardWidth = (value / 100) * 30 + 20;
    },
  });

  // Font size computed value with a default of 1 if not set.
  const fontSize = computed({
    get: () => ps.courseSettings.fontSize ?? 1,
    set: (value: number) => {
      ps.courseSettings.fontSize = value;
    },
  });

  // Increase font size by 0.1 (10%)
  const increaseFontSize = () => {
    fontSize.value = Number((fontSize.value + 0.1).toFixed(1));
  };

  // Decrease font size by 0.1 (10%), enforcing a minimum of 0.1.
  const decreaseFontSize = () => {
    if (fontSize.value > 0.1) {
      fontSize.value = Number((fontSize.value - 0.1).toFixed(1));
    }
  };

  // Predefined list of fonts.
  const fonts = [
    'Default',
    'Arial',
    'Helvetica',
    'Times New Roman',
    'Courier New',
    'Verdana',
    'cursive',
    'fantasy',
    'monospace',
    'serif',
    'sans-serif',
    'system-ui',
  ];

  function resetAllColors() {
    if (resetColorsClickedOnce.value) {
      const colors = ps.courseSettings.colors;
      colors.main.bg.override = colors.main.bg.default;
      colors.main.fg.override = colors.main.fg.default;
      colors.alternative.bg.override = colors.alternative.bg.default;
      colors.alternative.fg.override = colors.alternative.fg.default;
      colors.caution.bg.override = colors.caution.bg.default;
      colors.caution.fg.override = colors.caution.fg.default;
      resetColorsClickedOnce.value = false;
    } else {
      resetColorsClickedOnce.value = true;
      setTimeout(() => {
        resetColorsClickedOnce.value = false;
      }, 1000);
    }
  }
</script>

<style scoped>
  .setting-section > span:first-of-type {
    font-size: 1.1rem;
    color: var(--clr-accent);
  }

  .color-group {
    display: flex;
    flex-direction: column;
    gap: 0rem;
  }

  .color-group div {
    display: flex;
    align-items: center;
  }

  .color-group > span:first-of-type {
    font-size: 0.8rem;
    color: var(--clr-accent);
  }

  .board-size-slider {
    --slider-bg: var(--clr-main-lightest);
    --slider-connect-bg: var(--clr-accent);
    --slider-tooltip-bg: var(--clr-accent);
    --slider-handle-ring-color: var(--clr-accent);
    width: 12rem;
    margin: 0.5rem 0 0 0.5rem;
  }

  .settings-grid {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
  }

  .settings-grid > div:not(:last-child) {
    border-right: 1px solid var(--clr-main-lightest);
    padding-right: 1rem;
  }

  .settings-grid > div {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .wider-toggle {
    --toggle-width: 5rem;
    --toggle-bg-on: var(--clr-accent);
    --toggle-bg-off: var(--clr-accent2);
    --toggle-border-on: var(--clr-accent);
    --toggle-border-off: var(--clr-accent2);
    --toggle-text-on: #ffffff;
    --toggle-text-off: #ffffff;
  }
</style>
