<template>
  <div>
    <nav class="navbar navbar-expand-lg navbar-dark fixed-top bg-primary">
      <div v-if="isChristmas()" style="z-index: -1">
        <div
          class="snowflake"
          style="--rand: 0.55; --rand-x: 0.02; --rand-y: -0.09"
          v-if="!isMobileWidth()"
        />
        <div
          class="snowflake"
          style="--rand: 1.03; --rand-x: 0.09; --rand-y: -0.02"
          v-if="!isMobileWidth()"
        />
        <div class="snowflake" style="--rand: 0.89; --rand-x: 0.17; --rand-y: -0.08" />
        <div class="snowflake" style="--rand: 0.61; --rand-x: 0.27; --rand-y: -0.05" />
        <div class="snowflake" style="--rand: 0.34; --rand-x: 0.31; --rand-y: 0.21" />
        <div
          class="snowflake"
          style="--rand: 0.87; --rand-x: 0.35; --rand-y: -0.12"
          v-if="!isMobileWidth()"
        />
        <div class="snowflake" style="--rand: 0.71; --rand-x: 0.32; --rand-y: -0.18" />
        <div class="snowflake" style="--rand: 0.67; --rand-x: 0.42; --rand-y: -0.15" />
        <div class="snowflake" style="--rand: 0.12; --rand-x: 0.41; --rand-y: 0.36" />
        <div class="snowflake" style="--rand: 1.11; --rand-x: 0.48; --rand-y: 0.07" />
        <div class="snowflake" style="--rand: 0.41; --rand-x: 0.58; --rand-y: 0.27" />
        <div
          class="snowflake"
          style="--rand: 0.19; --rand-x: 0.59; --rand-y: 0.45"
          v-if="!isMobileWidth()"
        />
        <div class="snowflake" style="--rand: 0.56; --rand-x: 0.51; --rand-y: 0.32" />
        <div
          class="snowflake"
          style="--rand: 1.18; --rand-x: 0.6; --rand-y: 0.17"
          v-if="!isMobileWidth()"
        />
        <div class="snowflake" style="--rand: 0.79; --rand-x: 0.66; --rand-y: 0.29" />
        <div
          class="snowflake"
          style="--rand: 0.62; --rand-x: 0.71; --rand-y: -0.11"
          v-if="!isMobileWidth()"
        />
        <div class="snowflake" style="--rand: 1.12; --rand-x: 0.75; --rand-y: 0.41" />
        <div
          class="snowflake"
          style="--rand: 1.2; --rand-x: 0.77; --rand-y: 0.1"
          v-if="!isMobileWidth()"
        />
        <div class="snowflake" style="--rand: 0.95; --rand-x: 0.69; --rand-y: -0.19" />
        <div
          class="snowflake"
          style="--rand: 0.49; --rand-x: 0.64; --rand-y: 0.13"
          v-if="!isMobileWidth()"
        />
        <div
          class="snowflake"
          style="--rand: 1.05; --rand-x: 0.81; --rand-y: 0.23"
          v-if="!isMobileWidth()"
        />
        <div class="snowflake" style="--rand: 0.26; --rand-x: 0.72; --rand-y: 0.31" />
        <div class="snowflake" style="--rand: 0.3; --rand-x: 0.83; --rand-y: 0.42" />
        <div
          class="snowflake"
          style="--rand: 0.23; --rand-x: 0.98; --rand-y: 0.05"
          v-if="!isMobileWidth()"
        />
        <div class="snowflake" style="--rand: 0.43; --rand-x: 0.94; --rand-y: 0.18" />
        <div class="snowflake" style="--rand: 0.5; --rand-x: 0.92; --rand-y: -0.2" />
        <div
          class="snowflake"
          style="--rand: 0.8; --rand-x: 0.9; --rand-y: 0.25"
          v-if="!isMobileWidth()"
        />
      </div>

      <div class="container">
        <a
          class="navbar-brand ph-no-capture"
          href="/"
          style="position: relative"
          @click="track('header', 'chessiverse_logo', 'click')"
        >
          <img
            v-if="isChristmas()"
            src="/img/santa_hat.webp"
            class="d-inline-block align-text-top header-size"
            alt="Chessiverse logo"
            style="position: absolute; top: -0.4rem; left: -0.5rem; width: 2.4rem"
          />
          <img
            v-if="isNewYear()"
            src="/img/party_popper.webp"
            class="d-inline-block align-text-top header-size"
            alt="Chessiverse logo"
            style="
              transform: scaleX(-1);
              position: absolute;
              top: -0.4rem;
              left: -1.5rem;
              width: 2rem;
              height: 2rem;
            "
          />
          <img
            v-if="isHalloween()"
            src="/img/halloween_logo.webp"
            class="d-inline-block align-text-top header-size"
            alt="Chessiverse logo"
            style="
              border: 2px solid white;
              border-radius: 50%;
              margin-right: 0.5rem;
              margin-top: -0.4rem;
            "
          /><img
            v-else
            src="@/assets/images/logo.svg"
            class="d-inline-block align-text-top header-size"
            alt="Chessiverse logo"
            style="
              border: 2px solid white;
              border-radius: 50%;
              margin-right: 0.5rem;
              margin-top: -0.4rem;
            "
          />Chessiverse
        </a>

        <div style="margin-right: 1rem; margin-left: auto" v-if="activeUser() && isMobileWidth()">
          <a
            v-if="!getPremiumState() || getPremiumType() == 'trial'"
            class="btn btn-warning"
            href="/premium"
            :style="{
              fontSize: getPremiumType() == 'trial' ? '0.8rem' : '1rem',
            }"
            @click="
              () => {
                track('header', 'get_premium', 'click');
              }
            "
          >
            <span
              v-if="getPremiumType() == 'trial' && useUserStore().user.data?.trial != null"
              style="font-size: 0.8rem"
              ><i class="fa-solid fa-crown" />
              {{ getTrialDaysLeftStringMobile(useUserStore().user.data?.trial as number) }}
            </span>
            <span v-else><i class="fa-solid fa-crown" /> Premium</span>
          </a>
        </div>
        <button
          v-if="activeUser()"
          class="navbar-toggler ph-no-capture"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
          @click="track('header', 'account_menu', 'click')"
        >
          <span class="navbar-toggler-icon" />
        </button>
        <div
          v-else-if="
            playNowButtonText != null &&
            router.currentRoute.value.name !== 'signin' &&
            router.currentRoute.value.name !== 'signup'
          "
          style="display: flex; align-items: center; gap: 0.5rem"
        >
          <a
            type="button"
            class="btn btn-warning me-2 ph-no-capture"
            href="/signup"
            @click="
              () => {
                track('header_landing', 'play_now_button', 'click');
              }
            "
          >
            {{ playNowButtonText }}
          </a>
          <a
            class="nav-link ph-no-capture"
            href="/signin"
            @click="
              () => {
                track('header_landing', 'signup_button', 'click');
              }
            "
            style="cursor: pointer; color: white"
          >
            Sign In
          </a>
        </div>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <div class="navbar-nav">
            <a
              v-if="isMobileWidth() && active != null && currentRoute.name !== 'game'"
              type="button"
              class="btn btn-info position-relative me-3 ph-no-capture"
              style="width: 8rem; margin-bottom: 1rem"
              @click="onOngoingGameClick(active.id)"
              >Ongoing game <i class="fa-solid fa-arrow-right"
            /></a>
            <a
              v-if="activeUser()"
              :class="getNavLinkClass('dashboard')"
              href="/dashboard"
              @click="track('header', 'dashboard_link', 'click')"
            >
              Play
            </a>
            <a
              v-if="activeUser()"
              :class="getNavLinkClass('play')"
              href="/explore"
              @click="track('header', 'play_link', 'click')"
            >
              Explore bots
            </a>
            <a
              v-if="activeUser()"
              :class="getNavLinkClass('practice')"
              href="/practice"
              @click="track('header', 'practice_link', 'click')"
            >
              <span>Practice</span>
            </a>
            <a
              v-if="activeUser()"
              :class="getNavLinkClass('puzzles')"
              href="/puzzles"
              @click="track('header', 'puzzles_link', 'click')"
            >
              <span>Puzzles</span>
            </a>
            <div class="nav-item dropdown" v-if="activeUser()">
              <a
                class="nav-link dropdown-toggle ph-no-capture"
                href="#"
                id="navbarDropdownMenuLink"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                @click="track('header', 'account_menu', 'click')"
              >
                <span class="newtag">Insights</span>
              </a>
              <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink" style="width: 50%">
                <li>
                  <a
                    class="dropdown-item ph-no-capture"
                    v-if="activeUser()"
                    href="/stats"
                    @click="track('header', 'statistics', 'click')"
                  >
                    <i
                      class="fa-duotone fa-solid fa-chart-simple"
                      style="color: var(--clr-accent)"
                    />
                    Statistics
                    <span style="color: red; font-size: 0.8rem">New!</span>
                  </a>
                </li>
                <li>
                  <a
                    class="dropdown-item ph-no-capture"
                    v-if="activeUser()"
                    href="/games"
                    @click="track('header', 'game_history', 'click')"
                  >
                    <i class="fa-solid fa-clock-rotate-left" style="color: var(--clr-accent)" />
                    Game history
                  </a>
                </li>
              </ul>
            </div>
            <a
              v-if="!isMobileWidth() && active != null && currentRoute.name !== 'game'"
              type="button"
              class="btn btn-info position-relative me-3 ph-no-capture"
              style="width: 8rem; margin-left: 1rem"
              @click="onOngoingGameClick(active.id)"
              >Ongoing game <i class="fa-solid fa-arrow-right" />
            </a>
          </div>
          <div
            class="d-flex align-items-center justify-content-end flex-grow-1"
            style="gap: 1rem"
            v-if="activeUser() && !isMobileWidth()"
          >
            <a
              v-if="!getPremiumState() || getPremiumType() == 'trial'"
              class="btn btn-warning"
              href="/premium"
              @click="
                () => {
                  track('header', 'get_premium', 'click');
                }
              "
            >
              <i class="fa-solid fa-crown" />
              {{
                getPremiumType() == 'trial' && useUserStore().user.data?.trial != null
                  ? getTrialDaysLeftString(useUserStore().user.data?.trial as number)
                  : 'Get premium'
              }}
            </a>
          </div>
          <div class="navbar-nav ms-md-auto">
            <a
              v-if="
                !userStore.user.loggedIn &&
                router.currentRoute.value.name !== 'signin' &&
                router.currentRoute.value.name !== 'signup'
              "
              href="/signup"
              type="button"
              class="btn btn-warning me-2 ph-no-capture"
              @click="
                () => {
                  track('header_landing', 'play_now_button', 'click');
                }
              "
            >
              Play now for Free!
            </a>
            <a
              v-if="
                !userStore.user.loggedIn &&
                router.currentRoute.value.name !== 'signin' &&
                router.currentRoute.value.name !== 'signup'
              "
              href="/signin"
              class="nav-link ph-no-capture"
              @click="
                () => {
                  track('header_landing', 'signup_button', 'click');
                }
              "
              style="cursor: pointer"
            >
              Sign In
            </a>
            <div
              v-else-if="
                router.currentRoute.value.name !== 'signin' &&
                router.currentRoute.value.name !== 'signup'
              "
            >
              <div class="nav-item dropdown">
                <a
                  class="nav-link dropdown-toggle ph-no-capture"
                  href="#"
                  id="navbarDropdownMenuLink"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  @click="track('header', 'account_menu', 'click')"
                >
                  <span>Account</span>
                </a>
                <ul
                  class="dropdown-menu"
                  aria-labelledby="navbarDropdownMenuLink"
                  style="width: 50%"
                >
                  <li>
                    <a
                      class="dropdown-item ph-no-capture"
                      v-if="activeUser()"
                      href="/settings"
                      @click="track('header', 'settings', 'click')"
                      ><i class="fa-solid fa-gear" style="color: var(--clr-accent)" /> Settings</a
                    >
                  </li>
                  <li>
                    <a
                      class="dropdown-item ph-no-capture"
                      v-if="activeUser()"
                      href="/account"
                      @click="track('header', 'account_info', 'click')"
                      ><i
                        class="fa-duotone fa-solid fa-list-check"
                        style="color: var(--clr-accent)"
                      />
                      Manage account</a
                    >
                  </li>
                  <li><hr class="dropdown-divider" v-if="activeUser()" /></li>
                  <li>
                    <a
                      class="dropdown-item ph-no-capture"
                      role="button"
                      @click="
                        () => {
                          userStore.logout();
                          track('header', 'logout_button', 'click');
                        }
                      "
                      ><i class="fa-solid fa-right-from-bracket" style="color: var(--clr-accent)" />
                      Log Out
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script setup lang="ts">
  import { onMounted, ref } from 'vue';
  import { useRouter } from 'vue-router';
  import { useToast } from 'vue-toast-notification';

  import { useGameStore } from '@/stores/gameStore';
  import { useModalsStore } from '@/stores/modalsStore';
  import { useUserStore } from '@/stores/userStore';
  import { getPremiumState, getPremiumType } from '@/util/premium';
  import { track } from '@/util/tracking';
  import { isMobileWidth } from '@/util/util';

  const router = useRouter();
  const currentRoute = ref(router.currentRoute);
  const userStore = useUserStore();
  const gameStore = useGameStore();
  const ms = useModalsStore();
  const active = ref();

  const playNowButtonText = ref(getMobileHeaderButtons());

  function isHalloween() {
    const today = new Date();
    const start = new Date(today.getFullYear(), 9, 25); // October 25th
    const end = new Date(today.getFullYear(), 10, 4); // November 4th
    return today >= start && today <= end;
  }

  function isChristmas() {
    const today = new Date();
    const start = new Date(today.getFullYear(), 11, 10); // December 10th
    const end = new Date(today.getFullYear(), 11, 28); // December 28th
    return today >= start && today <= end;
  }

  function isNewYear() {
    const today = new Date();
    const start = new Date(today.getFullYear(), 11, 28); // December 28th
    const end = new Date(today.getFullYear() + 1, 0, 3); // January 3rd of the next year
    return today >= start && today <= end;
  }

  function getTrialDaysLeftString(trialEndMillis: number): string {
    const fullDaysLeft = Math.floor((trialEndMillis - Date.now()) / 1000 / 60 / 60 / 24);

    if (fullDaysLeft <= 0) {
      return 'Premium (ends today)';
    } else if (fullDaysLeft == 1) {
      return 'Premium (ends tomorrow)';
    } else {
      return `Premium (${fullDaysLeft} days left)`;
    }
  }

  function getTrialDaysLeftStringMobile(trialEndMillis: number): string {
    const fullDaysLeft = Math.floor((trialEndMillis - Date.now()) / 1000 / 60 / 60 / 24);

    if (fullDaysLeft <= 0) {
      return 'End today';
    } else if (fullDaysLeft == 1) {
      return '1 day left';
    } else {
      return `${fullDaysLeft} days left`;
    }
  }

  function getMobileHeaderButtons() {
    if (window.innerWidth >= 992) return null;
    if (window.innerWidth < 375) return 'Play now!';

    // Window is between 375 and 991
    return 'Play now for Free!';
  }

  // Bit hacky, but this closes the navbar on click
  document.addEventListener('click', function (event) {
    const isClickInside = document!
      .getElementById('navbarSupportedContent')!
      .contains(event.target as Node);
    const navbarToggler = document.querySelector('.navbar-toggler') as HTMLElement;

    if (!isClickInside && navbarToggler!.getAttribute('aria-expanded') == 'true') {
      navbarToggler.click();
    }
  });

  const activeUser = (): boolean => {
    return userStore.user.loggedIn;
  };

  const getNavLinkClass = (activePath: string) => {
    let classString = 'nav-link ph-no-capture';
    classString += activePath == router.currentRoute.value.name ? ' active' : '';

    return classString;
  };

  onMounted(() => {
    if (useUserStore().user.loggedIn) {
      gameStore.refreshActiveGame().then((game) => {
        active.value = game;
      });
    }

    window.addEventListener('resize', () => {
      playNowButtonText.value = getMobileHeaderButtons();
    });
  });

  const onOngoingGameClick = async (gameId: string) => {
    track('header', 'goto_active_game_button', 'click');

    const activeGame = await gameStore.refreshActiveGame();
    if (!activeGame) {
      active.value = null;
      useToast().success('The game was finished already, start a new game instead');

      return;
    }

    router.push({
      name: 'game',
      query: { id: gameId, type: 'continue' },
    });
  };
</script>

<style scoped>
  .newtag {
    position: relative;
  }

  .newtag::before {
    content: 'New';
    position: absolute;
    top: -0.8rem;
    right: -0.2rem;
    color: var(--clr-rect-5);
    font-size: 0.7rem;
  }

  .dropdown-divider {
    --bs-dropdown-divider-bg: #ddd;
  }
  .header-size {
    height: 2rem;
  }

  .snowflake {
    position: absolute;
    background: url('/img/snow_flake.webp') no-repeat center center;
    background-size: contain;
    opacity: calc(0.3 * var(--rand));
    filter: brightness(1.5);
    width: calc(20px * var(--rand)); /* Adjust the size */
    height: calc(20px * var(--rand));
    left: calc(100% * var(--rand-x));
    top: calc(100% * var(--rand-y));
  }
</style>
