<template>
  <div style="display: flex; justify-content: center">
    <div class="card feature-list" style="max-width: 50rem">
      <h2 style="margin: 2rem auto 0; font-size: 2.7rem; text-align: center">
        Chessiverse <span style="color: var(--clr-accent)">Features</span>
      </h2>
      <h2
        style="margin: 2rem auto 0; font-size: 1.5rem; text-align: center; color: var(--clr-accent)"
      >
        Play chess against computer!
      </h2>
      <ul>
        <li>
          <i class="fa-duotone fa-robot" />
          <h3><span style="color: var(--clr-accent)">500 chess Bots</span> and counting</h3>
          <p>Each one with their own unique style and personality</p>
        </li>
        <li>
          <i class="fa-duotone fa-chart-simple" />
          <h3>
            From Beginner to Grandmaster, with
            <span style="color: var(--clr-accent)">real Elo</span>
            Ratings
          </h3>
          <p>
            Bot strength ranges from 100 to master level Elo, and are regularly checked to match
            real FIDE ratings
          </p>
        </li>
        <li>
          <i class="fa-duotone fa-arrow-trend-up" />
          <h3>Play <span style="color: var(--clr-accent)">rated or casual</span></h3>
          <p>Compete and find your real level, or play just for fun</p>
        </li>
        <li>
          <i class="fa-sharp fa-solid fa-chess-clock-flip" />
          <h3>
            <span style="color: var(--clr-accent)">Bullet, Blitz, Rapid</span>, or take your time
          </h3>
          <p>
            Select any time control, or play without the clock. The bots will adjust and use time as
            you expect!
          </p>
        </li>
        <li>
          <i class="fa-regular fa-face-smile" />
          <h3>
            Genuine
            <span style="color: var(--clr-accent)">Human-like</span> play
          </h3>
          <p>Building on Leela and Maia Chess, our bots make the same mistakes a human would</p>
        </li>
        <li>
          <i class="fa-regular fa-book-open" />
          <h3>
            Custom
            <span style="color: var(--clr-accent)">Opening Repertoires</span>
            for Every Bot
          </h3>
          <p>All openings are based on human play, and popular moves at every level.</p>
        </li>
        <li>
          <i class="fa-sharp fa-solid fa-feather-pointed" />
          <h3>
            Bots with Truly Engaging Backstories and
            <span style="color: var(--clr-accent)">Personalities</span>
          </h3>
          <p>
            With a bit of help from AI, our bots tell genuine stories and react in convincing ways
          </p>
        </li>
        <li>
          <i class="fa-duotone fa-dumbbell" />
          <h3>
            Play and Practice from
            <span style="color: var(--clr-accent)">Any Position</span> on the Board
          </h3>
          <p>
            Play your favorite openings, or practice endgames. Our online chess bots are always
            happy to spar with you!
          </p>
        </li>
        <li>
          <i class="fa-duotone fa-trophy" />
          <h3>
            Daily Opening and Endgame
            <span style="color: var(--clr-accent)">Challenges</span>
          </h3>
          <p>You'll always have something to do when you login</p>
        </li>
        <li>
          <i class="fa-sharp fa-solid fa-puzzle-piece" />
          <h3>Engaging <span style="color: var(--clr-accent)">puzzles</span></h3>
          <p>Solve puzzles presented by the bots, and use their hints if you need</p>
        </li>
        <li>
          <i class="fa-sharp fa-solid fa-arrow-right" />
          <h4>And much much more to come!</h4>
          <p>We have a long list of things in the works. Stay tuned!</p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped>
  .feature-list {
    display: flex;
    justify-content: center;
    position: relative;
  }
  .feature-list path {
    fill: var(--clr-accent);
  }

  .feature-list ul {
    max-width: 40rem;
    list-style-type: none;
    padding: 2rem 1rem 1rem 4rem;
  }

  .feature-list i {
    font-size: 1.8rem;
    color: var(--clr-accent);
    margin-right: 1rem;
    min-width: 2.5rem;
  }

  @media (max-width: 768px) {
    .feature-list ul {
      padding: 2rem 1rem 0rem 1rem;
    }
  }

  .feature-list li {
    display: grid;
    grid-template-columns: auto 1fr; /* Two columns of equal width */
    grid-template-rows: auto;
    margin-block: 1rem;
  }

  .feature-list li h2,
  p {
    grid-column: 2;
  }

  .feature-list li h2 {
    font-size: 1.5rem;
    margin-bottom: 0rem;
  }
  .feature-list li p {
    font-size: 1rem;
    color: var(--clr-main-light);
  }

  @media (min-width: 768px) {
    .feature-list:before {
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 0 5rem 5rem;
      border-color: transparent transparent var(--clr-accent) transparent;
      left: 0;
      top: 0;
      transform: rotate(180deg);
      position: absolute;
    }

    .feature-list:after {
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 0 5rem 5rem;
      border-color: transparent transparent var(--clr-accent) transparent;
      right: 0;
      bottom: 0;
      position: absolute;
    }
  }
</style>
