<template>
  <div id="text-spot" style="overflow-x: hidden; overflow-y: hidden">
    <WinAnimation v-if="showWin" :termination="termination" />
    <StarAnimation :blue="blue" v-if="showStar" @isRunning="boardAnimationState" />
  </div>
</template>

<script setup lang="ts">
  import { type PropType, onMounted, ref } from 'vue';

  import type { GameTermination } from '@/types/apitypes';

  import StarAnimation from './StarAnimation.vue';
  import WinAnimation from './WinAnimation.vue';

  defineProps({
    blue: {
      type: Boolean,
      default: false,
    },
    termination: {
      type: Object as PropType<GameTermination>,
    },
  });

  const emit = defineEmits(['isRunning']);

  const showStar = ref(false);
  const showWin = ref(false);

  onMounted(() => {
    emit('isRunning', { isRunning: true });
    showWin.value = true;
    setTimeout(() => {
      showWin.value = false;
      showStar.value = true;
    }, 4000);
  });

  function boardAnimationState(data: any) {
    if (!data.isRunning) {
      emit('isRunning', { isRunning: false });
    }
  }
</script>

<style scoped>
  #text-spot {
    height: 100%;
    width: 100%;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
</style>
