<template>
  <div class="modal-body">
    <div v-if="botProfile == null">
      <Loader />
    </div>
    <div v-else class="bot-profile-wrapper">
      <div class="bot-image-box" style="min-height: 20rem">
        <img
          v-if="!loadingProfile"
          alt="Bot profile image"
          :src="ps.img(botProfile.bot.id, ImageType.BotProfile, '320')"
        />
      </div>
      <div class="card presentation-box">
        <div class="card-body" style="height: 100%" v-if="!loadingProfile">
          <div class="top-section">
            <div @click="onToggleFavoriteBot" class="favorite-box">
              <i :class="['fa-heart', isFavorite ? 'fa-solid' : 'fa-light']" />
              <div class="label">Favorite</div>
            </div>
            <div style="display: flex; justify-content: center; align-items: center; flex-grow: 1">
              <div
                v-if="active != null"
                style="display: flex; flex-direction: column; align-items: center; gap: 0.5rem"
              >
                <span>You're already playing a game</span>
                <button
                  v-if="active != null"
                  type="button"
                  class="btn btn-info bounce-top ph-no-capture"
                  @click="
                    () => {
                      gotoGame();
                      track('bot_profile_modal', 'continue_game', 'click', {
                        isActive: active != null,
                      });
                    }
                  "
                  v-tippy="{
                    content: 'You already have an active game, go finish that!',
                  }"
                >
                  Go to game
                </button>
              </div>
              <div
                v-else-if="!noUser && useBotsStore().isLocked(botProfile.bot)"
                style="display: flex; flex-direction: column; align-items: center"
              >
                <span>Unlock with premium</span>
                <div style="display: flex; gap: 1rem">
                  <button
                    type="button"
                    class="btn btn-warning bounce-top ph-no-capture"
                    @click="
                      () => {
                        gotoPremium();
                        track('bot_profile_modal', 'go_premium', 'click', {
                          isActive: active != null,
                        });
                      }
                    "
                  >
                    <i class="fa-solid fa-crown" /> Get premium
                  </button>
                </div>
              </div>
              <div v-else>
                <button
                  type="button"
                  class="btn btn-info btn-lg position-relative me-3 bounce-top ph-no-capture"
                  @click="
                    () => {
                      gotoGame();
                      track('bot_profile_modal', 'play_game', 'click', {
                        isActive: active != null,
                      });
                    }
                  "
                  v-tippy="{
                    content: 'Play against ' + botProfile.bot.name,
                    placement: 'bottom',
                  }"
                >
                  <i class="fa-duotone fa-swords" /> Play
                </button>
              </div>
            </div>
          </div>
          <hr class="hr" style="margin: 1.5rem" />
          <h1 class="card-title text-center" style="font-size: 1.5rem">
            <strong>{{ botProfile.bot.name }}</strong>
          </h1>
          <div>
            <img
              alt="Country flag"
              :src="getFlagUrl(botProfile.bot.country.code)"
              v-tippy="{
                content: botProfile.bot.country.name,
              }"
              style="width: 2rem"
            />
            <span class="mx-2">|</span>
            <span>Age {{ botProfile.bot.age }}</span>
            <span class="mx-2">|</span>
            <span> {{ botProfile.bot.occupation }}</span>
          </div>
          <div style="margin-top: 1rem">
            <span
              >"<em>{{ botProfile.bot.favorite_quote }}</em
              >"</span
            >
          </div>
          <hr class="hr" />
          <BlinkingCursor v-if="loadingPresentationText" />
          <TypeWriter
            v-else
            :targetText="presentationText"
            :pClasses="['card-text', 'text-center']"
            :newTextDelay="100"
          />
        </div>
      </div>
      <div class="card persona-box">
        <div
          v-if="!loadingProfile"
          class="card-body"
          style="display: flex; flex-direction: column; align-items: start; gap: 1rem"
        >
          <span
            style="
              position: absolute;
              top: 0.6rem;
              right: 0.6rem;
              font-size: 0.8rem;
              color: var(--clr-main-lighter);
            "
            role="button"
            @click="
              () => {
                gotoPersonaPlay();
                track('bot_profile', 'personaplay_button', 'click');
              }
            "
            >Read more
            <i class="fa-solid fa-arrow-up-right-from-square" />
          </span>
          <div style="display: flex; gap: 0.5rem">
            <h2 class="card-title" style="font-size: 1.7rem; color: var(--clr-accent)">
              PersonaPlay™
            </h2>
          </div>
          <div style="display: flex; gap: 1rem; width: 100%">
            <div style="display: flex; flex-grow: 1; justify-content: center">
              <HalfDonut
                label="Rating"
                tooltip="Estimated FIDE Elo rating based on performance against all other bots and users"
                :color="personaCategories()[botProfile.bot.persona.category - 1].bg"
                :valueMain="botProfile.bot.strength.estimated_elo"
                :valueOff="2300 - botProfile.bot.strength.estimated_elo"
                :labelCenter="false"
              />
            </div>
            <div style="display: flex; flex-grow: 1; justify-content: center">
              <PlaystyleViewer :selected="botProfile.bot.persona.category" />
            </div>
          </div>
          <div style="display: flex; gap: 0.5rem">
            <h3 class="card-title" style="font-size: 1.3rem; color: var(--clr-accent)">Overview</h3>
          </div>
          <div style="width: 100%; margin-top: -1.5rem">
            <Radar :data="personaRadaraData" :options="personaRadarOptions" />
          </div>
          <div>
            <i>{{ botProfile.bot.persona.summary }}</i>
          </div>
          <div
            style="display: flex; gap: 0.5rem"
            role="button"
            @click="
              () => {
                showDetails = !showDetails;
                track('bot_profile', 'show_details', 'click');
              }
            "
          >
            <h3 class="card-title" style="font-size: 1.3rem; color: var(--clr-accent)">
              Details
              <i
                :class="'fa-solid ' + (showDetails ? 'fa-caret-up' : 'fa-caret-down')"
                style="margin: -0.1rem 0 0 0.2rem; font-size: 1.5rem; color: #ddd"
              />
            </h3>
          </div>
          <div v-if="showDetails" style="display: flex; gap: 1rem; flex-direction: column">
            <div
              v-for="(value, key) in personaValues"
              :key="key"
              style="display: flex; flex-direction: column; align-items: start; text-align: start"
              v-tippy="{
                content: value.desc,
              }"
            >
              <h4 style="font-size: 1.1rem; color: var(--clr-accent)">
                {{ key }}
                <i
                  class="fa-solid fa-comment-exclamation"
                  style="vertical-align: super; font-size: 0.5rem; opacity: 0.5"
                />:
                <span style="color: var(--clr-main-lighter)">
                  {{ value.range[Math.ceil(getPersonaFieldNumber(key) / 2) - 1].name }}
                  ({{ getPersonaField(key) }}/10)
                </span>
              </h4>
              <span>
                {{ getPersonaField(key.toLowerCase() + '_desc') }}
              </span>
            </div>
            <div>
              <span style="font-size: 0.8rem; color: var(--clr-main-lighter)"
                >Note: All descriptions are relative to other bots around the rating. "Great at
                endgames" means different things at 300 and 2300 rating</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="card history-box">
        <div class="card-body">
          <h2
            v-if="botProfile.gameStats.latest.length > 0"
            class="card-title"
            style="font-size: 1.2rem"
          >
            Recent games
          </h2>
          <div
            v-if="botProfile.gameStats.latest.length > 0"
            style="
              display: grid;
              grid-template-columns: 1fr;
              gap: 1rem;
              align-items: center;
              justify-content: center;
            "
          >
            <div v-for="(game, i) in botProfile.gameStats.latest" :key="i">
              <div
                style="
                  display: flex;
                  gap: 0.5rem;
                  align-items: center;
                  justify-content: space-between;
                  width: 10rem;
                  margin: auto;
                "
                class="game-result-row"
                role="button"
                @click="
                  () => {
                    if (game.termination == GameTermination.Aborted) {
                      return;
                    } else if (game.result == null) {
                      gotoHistoryGame(game.id);
                      track('bot_profile', 'view_button', 'click');
                    } else {
                      gotoAnalysis(game.id);
                      track('bot_profile', 'view_button', 'click');
                    }
                  }
                "
              >
                <span style="font-size: 0.9rem">{{
                  dateToDateMonthWithOptionalYear(game.startedAt)
                }}</span>
                <div v-if="game.result != null">
                  <a
                    class="btn btn-info"
                    :style="{
                      background: resultData(game.result, game.userSide).color,
                      color: 'black',
                      height: '1.5rem',
                      width: '4rem',
                      padding: '0',
                    }"
                    >{{ resultData(game.result, game.userSide).message }}
                    <img
                      alt="Golden star"
                      v-if="resultData(game.result, game.userSide).userWon"
                      :style="{
                        height: '1rem',
                        marginTop: '-0.25rem',
                      }"
                      src="https://storage.googleapis.com/chessiverse1/images/elements/star.webp"
                    />
                  </a>
                </div>
                <div class="text-center" v-else>Ongoing</div>
                <span v-if="game.termination == GameTermination.Aborted">Aborted</span>
                <span v-else-if="game.result == null">
                  <i class="fa-solid fa-arrow-up-right-from-square" />
                </span>
                <span v-else>
                  <i class="fa-solid fa-arrow-up-right-from-square" />
                </span>
              </div>
            </div>
          </div>
          <h2 class="card-title" style="font-size: 1.2rem; margin: 2rem 0 -1rem 0">Total score</h2>
          <Pie :data="winLossData" :options="options" style="width: 15rem; margin: auto" />
          <p class="card-text" style="text-align: center; margin-top: -1rem">
            {{ againstScoreString(botProfile.gameStats) }}
          </p>
        </div>
      </div>
      <div class="card openings-box">
        <div
          class="card-body"
          style="display: flex; flex-direction: column; align-items: center; gap: 1rem"
          v-if="!loadingProfile"
          role="button"
          @click="
            () => {
              showOpenings = !showOpenings;
              track('bot_profile', 'show_openings', 'click');
            }
          "
        >
          <h2 class="card-title" style="font-size: 1.5rem">
            <i
              class="fa-solid fa-book-open-cover"
              style="font-size: 1.5rem; color: var(--clr-accent); vertical-align: -0.1rem"
            />
            Opening repertoire
            <i
              :class="'fa-solid ' + (showOpenings ? 'fa-caret-up' : 'fa-caret-down')"
              style="margin: -0.1rem 0 0 0.2rem; font-size: 1.5rem; color: #ddd"
            />
          </h2>
          <div
            v-if="showOpenings"
            style="display: flex; flex-direction: column; gap: 1rem; align-items: center"
          >
            <div style="display: flex; flex-direction: column">
              <a
                type="button"
                class="btn btn-info"
                style="
                  background: white;
                  margin: 0 auto 0.5rem auto;
                  color: black;
                  height: 1.5rem;
                  width: 4rem;
                  padding: 0;
                "
                >As white
              </a>
              <span v-if="botProfile.bot.openings.white.length == 0">A bit of everything</span>
              <span
                v-else
                v-for="(o, i) in botProfile.bot.openings.white"
                :key="'whiteopening' + i"
                >{{ o }}</span
              >
            </div>
            <div style="display: flex; flex-direction: column">
              <a
                type="button"
                class="btn btn-info"
                style="
                  background: black;
                  margin: 0 auto 0.5rem auto;
                  height: 1.5rem;
                  width: 4rem;
                  padding: 0;
                "
                >As black
              </a>
              <span v-if="botProfile.bot.openings.black.length == 0">A bit of everything</span>
              <span
                v-else
                v-for="(o, i) in botProfile.bot.openings.black"
                :key="'blackopening' + i"
                >{{ o }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
  import { computed, onMounted, ref, watch } from 'vue';
  import { Pie, Radar } from 'vue-chartjs';
  import { useRouter } from 'vue-router';

  import {
    ArcElement,
    Chart as ChartJS,
    Filler,
    Legend,
    LineElement,
    PointElement,
    RadialLinearScale,
    Tooltip,
  } from 'chart.js';
  import { storeToRefs } from 'pinia';

  import HalfDonut from '@/components/common/HalfDonut.vue';
  import PlaystyleViewer from '@/components/common/PlaystyleViewer.vue';
  import BlinkingCursor from '@/components/util/BlinkingCursor.vue';
  import Loader from '@/components/util/Loader.vue';
  import TypeWriter from '@/components/util/TypeWriter.vue';
  import { useBotsStore } from '@/stores/botStore';
  import { useGameStore } from '@/stores/gameStore';
  import { useModalsStore } from '@/stores/modalsStore';
  import { usePageStore } from '@/stores/pageStore';
  import { useUserStore } from '@/stores/userStore';
  import { type Bot, GameTermination, Result, Side } from '@/types/apitypes';
  import type { Game } from '@/types/apitypes';
  import { ImageType } from '@/types/internaltypes';
  import { personaCategories, personaValues } from '@/util/personplaycategory';
  import { track } from '@/util/tracking';
  import { dateToDateMonthWithOptionalYear, getFlagUrl } from '@/util/util';

  const props = defineProps({
    botId: {
      type: String,
      required: true,
    },
  });

  const router = useRouter();
  const ps = usePageStore();

  const gameStore = useGameStore();
  const bs = useBotsStore();
  const ms = useModalsStore();
  const { active } = storeToRefs(gameStore);
  const personaRadaraData = ref({} as any);
  const personaRadarOptions = ref({} as any);

  const showOpenings = ref(false);
  const showDetails = ref(false);

  const presentationText = ref('');
  const loadingProfile = ref(true);
  const loadingPresentationText = ref(true);
  const screenWidth = ref(window.innerWidth);
  const winLossData = ref(getWinLossData({ wins: 0, draws: 0, losses: 0 }));
  const noUser = ref(useUserStore().user == null || !useUserStore().user.loggedIn);

  const emit = defineEmits(['challengeBot']);

  const botProfile = ref<{
    bot: Bot;
    games?: Game[];
    gameStats: {
      wins: number;
      losses: number;
      draws: number;
      latest: Game[];
    };
  }>({
    bot: bs.getBot('unknown'),
    gameStats: { wins: 0, losses: 0, draws: 0, latest: [] },
  });

  ChartJS.register(
    ArcElement,
    Tooltip,
    Legend,
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler
  );

  const options = ref({
    responsive: false,
    maintainAspectRatio: false,
    layout: {
      padding: 30,
    },
    plugins: {
      tooltip: {
        displayColors: false,
      },
      legend: {
        display: false,
      },
    },
  });

  watch(
    () => props.botId,
    (newValue) => {
      init(props.botId);
    }
  );

  onMounted(() => {
    init(props.botId);
  });

  window.addEventListener('resize', () => (screenWidth.value = window.innerWidth));

  async function init(botId: string) {
    loadingProfile.value = true;
    loadingPresentationText.value = true;

    presentationText.value = '';
    bs.getChat(botId, 'introduction', {}, noUser.value)
      .then((data) => {
        presentationText.value = data;
        loadingPresentationText.value = false;
      })
      .catch(() => {
        // If no presentation text is found, just leave it empty for now
        loadingPresentationText.value = false;
      });

    const r = await bs.getUserBotProfile(botId, noUser.value);
    botProfile.value = r.data;

    // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
    winLossData.value = getWinLossData(botProfile.value?.gameStats!);

    const personaBg = personaCategories()[botProfile.value.bot.persona.category - 1].bg;

    personaRadaraData.value = {
      labels: [
        'Openings',
        'Repertoire',
        'Blunders',
        'Endgames',
        'Temperament',
        'Playstyle',
        'Tenacity',
      ],
      datasets: [
        {
          label: '',
          backgroundColor: personaBg,
          borderColor: '#aaa',
          pointBackgroundColor: personaBg,
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: '#fff',
          data: [
            botProfile.value.bot.persona.openings,
            botProfile.value.bot.persona.repertoire,
            botProfile.value.bot.persona.blunders,
            botProfile.value.bot.persona.endgames,
            botProfile.value.bot.persona.temperament,
            botProfile.value.bot.persona.playstyle,
            botProfile.value.bot.persona.tenacity,
          ],
        },
      ],
    };

    personaRadarOptions.value = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false, // Hides the dataset names
        },
      },
      scales: {
        r: {
          grid: {
            color: (context: any) => {
              return context.index === context.chart.scales.r.ticks.length - 1
                ? personaBg // Only the outermost line, slightly visible
                : '#f5f5f5'; // Hide inner lines
            },
            lineWidth: (context: any) => {
              // Different line width for outer and inner lines
              return context.index === context.chart.scales.r.ticks.length - 1
                ? 5 // Outermost line is thicker
                : 1; // Inner lines are thinner
            },
          },
          ticks: {
            display: false, // Hide value labels on radial axes
          },
          pointLabels: {
            font: {
              family: 'Neucha', // Customize the font family here
              size: 16, // Customize the font size here
            },
            color: '#333', // You can also customize the color here
          },
        },
      },
    };

    loadingProfile.value = false;
  }

  const getProgress = (strength: number) => {
    return 'height: 1.3rem; width: ' + strength + '%';
  };

  function gotoPremium() {
    emit('challengeBot', null); // This just triggers the close modal if the modal was used, should probably rename it
    router.push({
      name: 'premium',
    });
  }

  function gotoGame() {
    if (noUser.value) {
      router
        .push({
          name: 'signin',
        })
        .then(() => {
          router.go(0);
        });
    } else {
      const query =
        active.value != null
          ? { id: active.value.id, type: 'continue' }
          : { bid: props.botId, type: 'casual' };

      emit('challengeBot', query);
      router
        .push({
          name: 'game',
          query: query,
        })
        .then(() => {
          router.go(0);
        });
    }
  }

  function gotoPersonaPlay() {
    router
      .push({
        name: 'personaplay',
      })
      .then(() => {
        router.go(0);
      });
  }
  function gotoAnalysis(gameId: string) {
    router
      .push({
        name: 'analysis',
        query: { id: gameId },
      })
      .then(() => {
        router.go(0);
      });
  }
  function gotoHistoryGame(gameId: string) {
    router
      .push({
        name: 'game',
        query: { id: gameId, type: 'continue' },
      })
      .then(() => {
        router.go(0);
      });
  }

  function resultData(
    result: Result | null,
    userSide: Side
  ): { message: string; color: string; userWon: boolean } {
    if (result == null) {
      return { message: '', color: '#f00', userWon: false };
    } else if (result === Result.Draw) {
      return { message: 'Draw', color: '#ccc', userWon: false };
    } else if (
      (result === Result.White && userSide === Side.White) ||
      (result === Result.Black && userSide === Side.Black)
    ) {
      return { message: 'Win', color: '#469C14', userWon: true };
    } else {
      return { message: 'Loss', color: '#9C2614', userWon: false };
    }
  }

  function getWinLossData(score: { wins: number; draws: number; losses: number }): any {
    if (score.wins + score.draws + score.losses == 0) {
      return {
        labels: ['No games yet'],
        datasets: [
          {
            backgroundColor: ['#CCCCCC'],
            data: [1],
            hoverOffset: 30,
            borderWidth: 0,
          },
        ],
      };
    }

    const numberOfZeros =
      (score.wins > 0 ? 0 : 1) + (score.losses > 0 ? 0 : 1) + (score.draws > 0 ? 0 : 1);

    return {
      labels: ['Wins', 'Losses', 'Draws'],
      datasets: [
        {
          backgroundColor: ['#52B817', '#B82D17', '#CCCCCC'],
          data: [score.wins, score.losses, score.draws],
          hoverOffset: numberOfZeros == 2 ? 30 : 30,
          borderWidth: numberOfZeros == 2 ? 0 : 3,
        },
      ],
    };
  }

  const againstScoreString = (score: { wins: number; draws: number; losses: number }): string => {
    if (score.wins + score.draws + score.losses == 0) {
      return 'No games played';
    }

    return ' Win/draw/loss: ' + score.wins + '/' + score.draws + '/' + score.losses;
  };

  function getOpeningStrength(bot: Bot) {
    let openingStrength: number = bot.strength.openings;

    if (openingStrength < 0) {
      openingStrength = Math.floor((bot.strength.estimated_elo - 800) / 100);

      if (openingStrength <= 0) {
        openingStrength = 1;
      } else if (openingStrength > 14) {
        openingStrength = 14;
      }
    }

    const main = openingStrength - 1;
    const off = 14 - openingStrength;

    return { main, off };
  }

  function getPersonaField(key: string): number | string {
    // Hacky shit to get around typescript check. We could do a whole list of possible fields and so on to make typescript
    // happy, but let's not... Use with caution.
    // @ts-ignore
    return botProfile.value.bot.persona[key.toLowerCase()];
  }
  function getPersonaFieldNumber(key: string): number {
    // Hacky shit to get around typescript check. We could do a whole list of possible fields and so on to make typescript
    // happy, but let's not... Use with caution.
    // @ts-ignore
    return botProfile.value.bot.persona[key.toLowerCase()];
  }

  function onToggleFavoriteBot() {
    if (isFavorite.value) {
      bs.removeBotFromFavorites(botProfile.value.bot.id);
      return;
    }

    bs.addBotToFavorites(botProfile.value.bot.id);
  }

  const isFavorite = computed<boolean>(() => {
    return bs.favoriteBotIds.includes(botProfile.value.bot.id);
  });
</script>
<style lang="scss" scoped>
  .bot-profile-wrapper {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto 1fr;
    margin-top: -4rem;
  }

  .bot-profile-wrapper > div {
    text-align: center;
  }

  .bot-image-box {
    display: flex;
    justify-content: center;
    grid-area: 1 / 1 / 1 / 4;
    margin-bottom: -1rem;
  }

  .bot-image-box img {
    width: min(100%, 20rem);
  }

  .persona-box {
    grid-area: 2 / 1 / 4 / 1;
  }

  .openings-box {
    grid-area: 3 / 2 / 3 / 2;
  }

  .presentation-box {
    grid-area: 2 / 2 / 2 / 2;
    max-height: fit-content;
    overflow: hidden;

    .top-section {
      display: flex;

      .favorite-box {
        display: flex;
        flex-direction: column;
        align-self: stretch;
        margin-top: 9px;
        cursor: pointer;

        i {
          font-size: 1.6rem;
          color: var(--clr-rect-4);
        }

        .label {
          color: var(--clr-main-lighter);
          font-weight: 500;
        }
      }
    }
  }

  .history-box {
    grid-area: 2 / 3 / 4 / 3;
  }

  @media (max-width: 992px) {
    .bot-profile-wrapper {
      display: flex;
      flex-direction: column;
    }
  }

  // Cursor blinking CSS Starts...
  @keyframes blink {
    from,
    to {
      color: transparent;
    }
    50% {
      color: #2c3e50;
    }
  }

  @-moz-keyframes blink {
    from,
    to {
      color: transparent;
    }
    50% {
      color: #2c3e50;
    }
  }

  @-webkit-keyframes blink {
    from,
    to {
      color: transparent;
    }
    50% {
      color: #2c3e50;
    }
  }

  @-ms-keyframes blink {
    from,
    to {
      color: transparent;
    }
    50% {
      color: #2c3e50;
    }
  }

  @-o-keyframes blink {
    from,
    to {
      color: transparent;
    }
    50% {
      color: #2c3e50;
    }
  }

  .table th,
  .table td {
    text-align: left;
    border: 1px solid #dee2e6;
  }

  .table th.col-auto,
  .table td.col-auto {
    width: 1%;
    white-space: nowrap;
  }

  .table th.col,
  .table td.col {
    width: 100%;
  }
</style>
