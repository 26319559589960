<template>
  <div class="card todays-matchup-container">
    <div v-if="loading || !bs.fetched">
      <h2
        style="font-size: 1.5rem; color: var(--clr-background-detail)"
        v-tippy="{
          content: 'Every day a new challenge, can you beat today\'s bot?',
        }"
      >
        Daily Matchup<i
          class="fa-solid fa-comment-exclamation"
          style="vertical-align: super; font-size: 0.8rem"
        />
      </h2>
    </div>
    <div v-else>
      <h2
        style="font-size: 1.5rem; color: var(--clr-background-detail)"
        v-tippy="{
          content: 'Every day a new challenge, can you beat today\'s bot?',
        }"
      >
        Daily Matchup<i
          class="fa-solid fa-comment-exclamation"
          style="vertical-align: super; font-size: 0.8rem"
        />
      </h2>
      <div
        style="
          position: relative;
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
          justify-content: center;
          margin-bottom: 1rem;
        "
      >
        <div
          class="bot-and-name"
          role="button"
          @click="
            () => {
              ms.showBotProfile(bot!.id);
              track('daily_challenge_box', 'bot_profile', 'click', {
                botId: bot!.id,
              });
            }
          "
        >
          <img
            alt="Bot profile image"
            class="profile-image-circle"
            :style="{ backgroundColor: bot!.config.boardbg }"
            :src="ps.img(bot!.id, ImageType.BotProfile, '175')"
          />
          <div class="card profile-name-text" :style="{ backgroundColor: bot!.config.boardbg }">
            <div class="card-body" style="margin-top: -0.75rem">
              {{ bot!.name }}
              <img
                :src="getFlagUrl(bot!.country.code)"
                :alt="bot!.country.name"
                style="height: 0.7rem; margin: -0.2rem 0.2rem 0 0"
              />
              ({{ bot!.strength.estimated_elo }})
            </div>
          </div>
        </div>
        <div>
          <div class="card quote-box">
            <BlinkingCursor v-if="loadingChat" style="text-align: center" />
            <div v-else>
              <TypeWriter
                :targetText="botText"
                :style="isMobileWidth() ? '' : 'padding-top: 1rem; ' + 'cursor: pointer'"
                :pClasses="['card-text', 'text-center']"
                :newTextDelay="10"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        style="display: flex; gap: 2rem; justify-content: center; color: white; margin-bottom: 2rem"
      >
        <div
          style="display: flex; flex-direction: column; align-items: center; max-width: 6rem"
          v-tippy="{
            content: 'Today\'s result',
          }"
        >
          <h5>Today</h5>
          <div>
            <a
              v-if="challenge!.result === null"
              class="btn btn-info"
              @click="
                () => {
                  startDailyMatchup();
                  track('daily_matchup_box', 'start_challenge', 'click');
                }
              "
              ><i class="fa-duotone fa-swords" /> Play</a
            >
            <i
              v-else-if="challenge!.result == 'success'"
              style="font-size: 2.5rem"
              class="check-icon fa-solid fa-circle-check"
            />
            <i
              v-else-if="challenge!.result == 'fail'"
              style="font-size: 2.5rem"
              class="xmark-icon fa-solid fa-circle-xmark"
            />
          </div>
        </div>
        <div
          style="display: flex; flex-direction: column; align-items: center; max-width: 6rem"
          v-tippy="{
            content:
              'How many days in a row you have won the matchup. Best streak ever: ' +
              (us.user.data?.dailyMatchupStreaks == null
                ? 0
                : us.user.data?.dailyMatchupStreaks?.best),
          }"
        >
          <h5>Win streak</h5>
          <div
            v-if="
              us.user.data == null ||
              us.user.data.dailyMatchupStreaks == null ||
              us.user.data.dailyMatchupStreaks.current <= 0
            "
            style="display: flex; align-items: center; flex-wrap: wrap"
          >
            <span>-</span>
          </div>
          <div
            v-if="
              us.user.data != null &&
              us.user.data.dailyMatchupStreaks != null &&
              us.user.data.dailyMatchupStreaks.current > 0
            "
            style="display: flex; align-items: center; flex-wrap: wrap"
          >
            <i
              v-for="n in us.user.data.dailyMatchupStreaks.current"
              :key="'checkbox' + n"
              class="check-icon fa-solid fa-circle-check"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ref } from 'vue';
  import { useRouter } from 'vue-router';

  import BlinkingCursor from '@/components/util/BlinkingCursor.vue';
  import TypeWriter from '@/components/util/TypeWriter.vue';
  import { useBotsStore } from '@/stores/botStore';
  import { useGeneralStore } from '@/stores/generalStore';
  import { useModalsStore } from '@/stores/modalsStore';
  import { usePageStore } from '@/stores/pageStore';
  import { useUserStore } from '@/stores/userStore';
  import type { Bot, ChatTriggerId, DailyMatchup } from '@/types/apitypes';
  import { ImageType } from '@/types/internaltypes';
  import { track } from '@/util/tracking';
  import { getFlagUrl, isMobileWidth } from '@/util/util';

  const us = useUserStore();
  const bs = useBotsStore();
  const ms = useModalsStore();
  const gs = useGeneralStore();
  const ps = usePageStore();
  const router = useRouter();

  const loading = ref(true);
  const loadingChat = ref(true);
  const botText = ref('');
  const challenge = ref<DailyMatchup>();
  const bot = ref<Bot>();

  initMatchup();

  function startDailyMatchup() {
    router.push({
      name: 'game',
      query: { type: 'dailymatchup' },
    });
  }

  async function initMatchup(): Promise<void> {
    // Grabbing updated user so we always have the latest streak
    await us.refreshUserProfile();

    const d = await gs.getDailyMatchup();
    challenge.value = d;

    bot.value = (await bs.getUserBotProfile(d.botId)).data.bot;

    let triggerId: ChatTriggerId = 'daily_matchup_ongoing';
    if (d.result !== null) {
      triggerId =
        d.result == 'success' ? 'end_of_daily_matchup_succeeded' : 'end_of_daily_matchup_failed';
    }

    bs.getChat(bot.value.id, triggerId)
      .then((r) => {
        botText.value = r;
        loadingChat.value = false;
      })
      .catch(() => {
        switch (triggerId) {
          case 'daily_matchup_ongoing':
            botText.value = "Up for a match? Let's go!";
            break;
          case 'end_of_daily_matchup_succeeded':
          case 'end_of_daily_matchup_failed':
            botText.value = 'Another round tomorrow?';
            break;
        }
        loadingChat.value = false;
      });

    loading.value = false;
  }
</script>

<style scoped>
  h2 {
    text-align: center;
    margin-block: 1rem;
  }

  .check-icon {
    background: white;
    color: var(--clr-accent4);
    border-radius: 50%;
    --bs-btn-bg: var(--clr-rect-2);
    --bs-btn-border-color: var(--clr-rect-2);
    --bs-btn-hover-bg: var(--clr-rect-1);
    --bs-btn-hover-border-color: var(--clr-rect-1);
    --bs-btn-text: var(--clr-rect-1);
  }

  .xmark-icon {
    background: white;
    color: var(--clr-rect-5);
    border-radius: 50%;
    --bs-btn-bg: var(--clr-rect-2);
    --bs-btn-border-color: var(--clr-rect-2);
    --bs-btn-hover-bg: var(--clr-rect-1);
    --bs-btn-hover-border-color: var(--clr-rect-1);
    --bs-btn-text: var(--clr-rect-1);
  }

  .todays-matchup-container {
    min-width: 18rem;
    min-height: 27rem;
  }

  .profile-name-text {
    height: 2rem;
    position: absolute;
    top: 1rem;
    left: 2rem;
    width: 14rem;
    text-align: center;
  }

  .profile-image-circle {
    position: absolute;
    width: 4rem;
    height: 4rem;
    z-index: 10;
    border-radius: 50%;
    border: 1.5px solid #444;
  }

  .bot-and-name {
    position: relative;
    width: 16rem;
    height: 5rem;
    margin: auto;
    z-index: 100;
  }

  .card-text p {
    color: green;
  }

  .quote-box {
    padding: 1rem;
    width: 16rem;
    min-height: 9rem;
    margin: -3rem auto 0 auto;
  }

  .quote-box:before {
    content: '';
    position: absolute;
    top: -0.6rem; /* Adjust based on your needs, should be slightly above the smaller triangle */
    left: 1.25rem; /* Position where the triangle should appear, slightly left to the smaller triangle */
    border-width: 0 0.6rem 0.6rem 0.6rem; /* Make this triangle slightly larger */
    border-style: solid;
    border-color: transparent transparent #000 transparent; /* Match your bubble's border color */
  }

  /* Add the smaller triangle */
  .quote-box:after {
    content: '';
    position: absolute;
    top: -0.5rem; /* Position directly above the larger triangle, but slightly lower */
    left: 1.25rem; /* Position directly over the larger triangle, but slightly right */
    border-width: 0 0.6rem 0.6rem 0.6rem; /* This is your smaller triangle */
    border-style: solid;
    border-color: transparent transparent #fff transparent; /* Match your bubble's background color */
  }

  @media (max-width: 1199px) {
    .profile-image-circle {
      width: 3.5rem;
      height: 3.5rem;
    }
    .quote-box {
      min-height: 8rem;
    }
    .todays-matchup-container {
      min-height: 22rem;
    }
  }
</style>
