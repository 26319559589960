<template>
  <div
    class="card container"
    :style="{
      zIndex: '1000',
      padding: '1rem',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '21rem',
    }"
  >
    <div
      class="card main-gradient"
      style="
        width: 10rem;
        padding: 0.5rem;
        text-align: center;
        position: absolute;
        top: -2rem;
        left: 2rem;
        filter: drop-shadow(0.3rem 0.3rem 0.4rem var(--clr-main));
      "
    >
      <span style="color: white; font-size: 2rem"><i class="fa-solid fa-chess" /> Play</span>
    </div>

    <div class="inner-container" style="min-height: 16rem">
      <div
        :style="{
          flexBasis: !isMobileWidth(1400) && (suggestedBots != null || loading) ? '0' : '10rem',
          flexGrow: '0',
        }"
      />
      <div
        :style="{
          width: '100%',
          flexGrow: '1',
          marginRight: !isMobileWidth(1400) && showTime ? '1rem' : '',
        }"
      >
        <SuggestedBots v-if="loading || suggestedBots != null" :loading="loading" />
        <IntroGame v-else-if="suggestedBots == null" />
      </div>
      <div
        :style="{
          display: 'flex',
          flexDirection: 'column',
          alignItems: isMobileWidth(1400) ? 'center' : 'end',
          flexBasis: '10rem',
          flexGrow: '0',
        }"
      >
        <div
          :style="{
            display: 'flex',
            gap: '0.5rem',
          }"
          role="button"
          @click="showTime = !showTime"
        >
          <div
            class="card"
            :style="{
              justifyContent: 'center',
              alignItems: 'center',
              width: isMobileWidth(1400) ? '2.3rem' : '4rem',
              height: isMobileWidth(1400) ? '2.3rem' : '4rem',
            }"
          >
            <i
              class="fa-solid fa-chess-clock"
              :style="{
                color: 'var(--clr-main)',
                fontSize: isMobileWidth(1400) ? '1.2rem' : '1.5rem',
              }"
            />
            <i
              :class="'fa-solid ' + (showTime ? 'fa-caret-up' : 'fa-caret-down')"
              :style="{ fontSize: isMobileWidth(1400) ? '0.7rem' : '1rem' }"
            />
          </div>
          <div
            :style="{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }"
          >
            <span
              :style="{
                color: 'white',
                fontSize: isMobileWidth(1400) ? '0.7rem' : '1.2rem',
                whiteSpace: 'nowrap',
              }"
              >Time control</span
            >
            <span
              style="color: var(--clr-accent); font-size: 1rem; white-space: nowrap"
              v-if="currentTimeControl"
              >{{ timeControlCategory }}
              <span style="color: white; font-size: 0.8rem">({{ timeControlText }})</span></span
            >
            <span v-else style="color: var(--clr-accent); font-size: 1rem; white-space: nowrap">
              Unlimited
            </span>
          </div>
        </div>
        <TimeSelection v-if="showTime" @update="updateTimecontrol" />
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
  import { onMounted, ref, watch } from 'vue';

  import IntroGame from '@/components/dashboard/playbox/IntroGame.vue';
  import SuggestedBots from '@/components/dashboard/playbox/SuggestedBots.vue';
  import TimeSelection from '@/components/dashboard/playbox/TimeSelection.vue';
  import { useBackgroundStore } from '@/stores/backgroundStore';
  import { useBotsStore } from '@/stores/botStore';
  import { useModalsStore } from '@/stores/modalsStore';
  import { usePageStore } from '@/stores/pageStore';
  import type { NextOpponentSuggestion, TimeControl } from '@/types/apitypes';
  import { getTimeCategory } from '@/util/time';
  import { isMobileWidth } from '@/util/util';

  const ms = useModalsStore();
  const ps = usePageStore();
  const bs = useBackgroundStore();
  const emit = defineEmits(['userInput', 'setBoardBg']);

  const currentTimeControl = ref(
    usePageStore().gameSettings?.timeControl ? usePageStore().gameSettings?.timeControl : null
  );
  const timeControlText = ref('');
  const timeControlCategory = ref('');

  const showTime = ref(false);
  const suggestedBots = ref<NextOpponentSuggestion | null>(null);
  const loading = ref(true);

  onMounted(() => {
    loading.value = true;
    useBotsStore()
      .getNextOpponent(null)
      .then((n) => {
        suggestedBots.value = n;
        loading.value = false;
      });
  });

  watch(
    () => currentTimeControl.value,
    (newTimeControl) => {
      updateTimeControlTexts(newTimeControl as TimeControl | null | undefined);
    },
    { immediate: true }
  );

  function updateTimeControlTexts(timeControl: TimeControl | null | undefined) {
    if (timeControl == null) {
      timeControlText.value = '';
      timeControlCategory.value = 'Unlimited';
    } else {
      timeControlText.value =
        timeControl.initial / 60 / 1000 + ' + ' + timeControl.increment / 1000;
      timeControlCategory.value = getTimeCategory(timeControl);
    }
  }

  function updateTimecontrol(timeControl: TimeControl | null) {
    showTime.value = false;
    currentTimeControl.value = timeControl;
  }
</script>

<style scoped>
  .inner-container {
    width: 100%;
    display: flex;
    align-items: start;
  }

  h2 {
    text-align: center;
    margin-block: 1rem;
  }

  .card-text p {
    color: green;
  }

  @media (max-width: 1400px) {
    .inner-container {
      flex-direction: column;
      align-items: center;
    }

    .inner-container > :nth-child(1),
    .inner-container > :nth-child(3) {
      flex-basis: 2rem !important;
    }
  }
</style>
