<template>
  <div v-if="!loading && challenge == null">
    <!-- No daily position available -->
  </div>
  <div class="card daily-position-container" v-else>
    <div v-if="loading || !bs.fetched || challenge == null">
      <h2
        style="font-size: 1.5rem; color: var(--clr-background-detail)"
        v-tippy="{
          content: getDisplay().tooltip,
        }"
      >
        {{ getDisplay().title
        }}<i
          class="fa-solid fa-comment-exclamation"
          style="vertical-align: super; font-size: 0.8rem"
        />
      </h2>
    </div>
    <div v-else>
      <h2
        style="font-size: 1.5rem; color: var(--clr-background-detail)"
        v-tippy="{
          content: getDisplay().tooltip,
        }"
      >
        {{ getDisplay().title
        }}<i
          class="fa-solid fa-comment-exclamation"
          style="vertical-align: super; font-size: 0.8rem"
        />
      </h2>

      <div
        style="
          position: relative;
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
          justify-content: center;
          align-items: center;
          margin-bottom: 1rem;
        "
      >
        <div class="chessboard card">
          <TheChessboard
            :board-config="{
              fen: challenge!.position,
              orientation: challenge!.userSide == Side.White ? 'white' : 'black',
              blockTouchScroll: false,
              coordinates: false,
              viewOnly: true,
            }"
          />
        </div>
        <div
          style="
            text-align: center;
            font-size: 0.9rem;
            color: white;
            display: flex;
            flex-direction: column;
            align-items: center;
          "
          v-tippy="{
            content: challenge!.extra.title + '<br/>' + challenge!.extra.subtitle,
          }"
        >
          <span
            style="
              color: var(--clr-main);
              white-space: nowrap;
              max-width: 15rem;
              overflow: hidden;
              text-overflow: ellipsis;
            "
            >{{ challenge!.extra.title }}
            <i
              class="fa-solid fa-comment-exclamation"
              style="vertical-align: super; font-size: 0.5rem; color: white"
          /></span>
        </div>
        <div class="difficulties" style="position: relative" v-if="challenge != null">
          <div
            v-tippy="{
              content:
                'Play the challenge against ' +
                bots[b].name +
                ' (' +
                bots[b].strength.estimated_elo +
                ' rating)',
            }"
            v-for="(b, i) in defaultDifficultiesOrder"
            :key="'botdiffiuclty' + i"
            class="difficulty zoom-hover"
            @click="
              () => {
                if (getPremiumState()) {
                  startDailyPosition(b);
                } else {
                  router.push({ name: 'premium' });
                }
                track(
                  'daily_position_box',
                  'start_challenge' + (getPremiumState() ? '' : 'locked'),
                  'click',
                  {
                    // @ts-ignore
                    botId: challenge!.difficulties[b].botId,
                    difficulty: b,
                    type: props.positionType,
                  }
                );
              }
            "
          >
            <div style="position: relative">
              <img
                alt="Bot profile image"
                class="profile-image-circle"
                :style="{
                  backgroundColor: bots[b].config.boardbg,
                  filter: getPremiumState() ? '' : 'saturate(30%) opacity(50%)',
                }"
                :src="ps.img(bots[b].id, ImageType.BotProfile, '175')"
              />
              <span
                v-if="!getPremiumState()"
                style="
                  font-size: 1.2rem;
                  position: absolute;
                  opacity: 1;
                  left: 0.75rem;
                  bottom: 0.1rem;
                "
                ><i class="fa-solid fa-lock"
              /></span>
              <div v-else>
                <img
                  v-if="
                    challenge!.difficulties[
                      b as 'simple' | 'easy' | 'balanced' | 'hard' | 'intense'
                    ].userWon
                  "
                  alt="Golden star outline"
                  style="
                    filter: hue-rotate(180deg);
                    height: 1rem;
                    position: absolute;
                    right: -0.25rem;
                    top: -0.25rem;
                  "
                  src="https://storage.googleapis.com/chessiverse1/images/elements/star.webp"
                />
                <img
                  v-else
                  alt="Golden star outline"
                  style="
                    filter: hue-rotate(180deg);
                    height: 1rem;
                    position: absolute;
                    right: -0.25rem;
                    top: -0.25rem;
                  "
                  src="https://storage.googleapis.com/chessiverse1/images/elements/star_grey.webp"
                />
              </div>
            </div>
            <span style="text-transform: capitalize; font-weight: 100; color: var(--clr-main)">{{
              b
            }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { type PropType, ref } from 'vue';
  import { TheChessboard } from 'vue3-chessboard';
  import { useRouter } from 'vue-router';

  import { useBotsStore } from '@/stores/botStore';
  import { useGeneralStore } from '@/stores/generalStore';
  import { useModalsStore } from '@/stores/modalsStore';
  import { usePageStore } from '@/stores/pageStore';
  import { type DailyPosition, Side } from '@/types/apitypes';
  import { ImageType } from '@/types/internaltypes';
  import { getPremiumState } from '@/util/premium';
  import { track } from '@/util/tracking';

  const bs = useBotsStore();
  const ms = useModalsStore();
  const gs = useGeneralStore();
  const ps = usePageStore();
  const router = useRouter();

  // We know that this will be the keys of difficulties, but need them in this order so instead of doing Object.keys, take them from here
  const defaultDifficultiesOrder = ['simple', 'easy', 'balanced', 'hard', 'intense'];

  const props = defineProps({
    positionType: {
      type: String as PropType<'dailyendgame' | 'dailymaster'>,
      required: true,
    },
  });

  const loading = ref(true);
  const challenge = ref<DailyPosition | null>();
  const bots = ref();

  initPosition();

  function getDisplay() {
    if (props.positionType === 'dailyendgame') {
      return {
        title: 'Classic Endgame',
        tooltip: 'Win this classic endgame position',
      };
    } else if (props.positionType === 'dailymaster') {
      return {
        title: 'New Master Position',
        tooltip: 'Can you win this position from a recently played top game',
      };
    } else {
      return {
        title: 'Unknown',
        tooltip: 'Unknown position type, this is a bug',
      };
    }
  }

  function startDailyPosition(difficulty: string) {
    router.push({
      name: 'game',
      query: { type: props.positionType, difficulty: difficulty },
    });
  }

  async function initPosition(): Promise<void> {
    const d = await gs.getDailyPosition(props.positionType);
    challenge.value = d;

    if (d == null) {
      loading.value = false;
      return;
    }

    if (!challenge.value!.extra) {
      challenge.value!.extra = { title: '', subtitle: '' };
    } else {
      challenge.value!.extra = JSON.parse(challenge.value!.extra);
    }

    const retrievedBots = await Promise.all([
      bs.getUserBotProfile(d.difficulties.simple.botId),
      bs.getUserBotProfile(d.difficulties.easy.botId),
      bs.getUserBotProfile(d.difficulties.balanced.botId),
      bs.getUserBotProfile(d.difficulties.hard.botId),
      bs.getUserBotProfile(d.difficulties.intense.botId),
    ]);

    bots.value = {};
    bots.value['simple'] = retrievedBots[0].data.bot;
    bots.value['easy'] = retrievedBots[1].data.bot;
    bots.value['balanced'] = retrievedBots[2].data.bot;
    bots.value['hard'] = retrievedBots[3].data.bot;
    bots.value['intense'] = retrievedBots[4].data.bot;

    loading.value = false;
  }
</script>

<style scoped>
  :deep(cg-board) {
    background-color: var(--clr-accent); /* Override the board background color */
  }

  .chessboard {
    box-sizing: content-box;
    width: 15rem;
    aspect-ratio: 1;
    margin: auto;
  }

  h2 {
    text-align: center;
    margin-block: 1rem;
  }

  .daily-position-container {
    min-width: 18rem;
    min-height: 27rem;
  }

  .profile-image-circle {
    width: 2.5rem;
    height: 2.5rem;
    z-index: 10;
    border-radius: 50%;
    border: 1.5px solid #444;
  }

  .difficulties {
    display: flex;
    justify-content: center;
    gap: 0.5rem;
    font-size: 0.9rem;
    color: var(--clr-background-detail);
  }

  .difficulty {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    color: var(--clr-background-detail);
  }

  .zoom-hover {
    transition: all 0.2s ease;
    cursor: pointer;
  }

  .zoom-hover:hover {
    color: var(--clr-accent);
  }

  .zoom-hover:hover .profile-image-circle {
    transform: scale(1.1) !important;
    box-shadow: 0 2px 7px rgba(0, 0, 0, 0.8);
  }
</style>
