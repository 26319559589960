// Use for controlling modals and their internal state
import { nextTick } from 'vue';

import { Modal } from 'bootstrap';
import { defineStore } from 'pinia';

import { FeedbackType, type PracticePosition } from '@/types/apitypes';

export const useModalsStore = defineStore('modals', {
  state: () => ({
    showing: null as
      | 'botProfile'
      | 'gameFeedback'
      | 'feedback'
      | 'puzzleInfo'
      | 'initialRating'
      | 'practicePreview'
      | null,
    // @ts-ignore - Complaining about not being able to use Modal as type since it's a namespace, not sure how to fix but working for now
    currentModal: null as Modal | null,
    closeModalCallback: null as ((data: any) => void) | null,
    botProfileModalBotId: null as string | null,
    practicePreviewModalPracticePosition: null as PracticePosition | null,
    gameFeedbackInitialFeedbackRating: FeedbackType.Neutral as FeedbackType,
    gameFeedbackGameId: null as string | null,
    currentModalEventListener: null as ((event: any) => void) | null,
  }),
  actions: {
    async showBotProfile(botId: string) {
      this.showing = 'botProfile';
      this.botProfileModalBotId = botId;

      await nextTick(); // Needed to make sure the modal has time to load after this.showing is changed

      this.showModal('botProfileModal');
    },
    async showPracticePreview(practicePosition: PracticePosition) {
      this.showing = 'practicePreview';
      this.practicePreviewModalPracticePosition = null;

      await nextTick(); // Needed to make sure the modal has time to load after this.showing is changed

      this.practicePreviewModalPracticePosition = practicePosition;

      this.showModal('practicePreviewModal');
    },
    async showPuzzleInfo(callback: (data: any) => void) {
      this.showing = 'puzzleInfo';

      await nextTick(); // Needed to make sure the modal has time to load after this.showing is changed

      this.showModal('puzzleInfoModal', callback);
    },
    async showFeedback() {
      this.showing = 'feedback';

      await nextTick(); // Needed to make sure the modal has time to load after this.showing is changed

      this.showModal('feedbackModal');
    },
    async showGameFeedback(
      initialRating: FeedbackType,
      gameId: string,
      callback: (data: any) => void
    ) {
      this.showing = 'gameFeedback';
      this.gameFeedbackInitialFeedbackRating = initialRating;
      this.gameFeedbackGameId = gameId;

      await nextTick(); // Needed to make sure the modal has time to load after this.showing is changed

      this.showModal('gameFeedbackModal', callback);
    },
    showModal(modalId: string, callback: ((data: any) => void) | null = null) {
      this.closeModalCallback = callback;
      const modalElement = document.getElementById(modalId);
      if (modalElement) {
        this.currentModal = new Modal(modalElement);
        this.currentModal.show();
      } else {
        console.error('Modal element not found, looking for ', modalId);
      }
    },
    closeCurrentModal(closeData: any = null) {
      if (this.currentModal != null) {
        if (this.closeModalCallback != null) {
          this.closeModalCallback(closeData);
          this.closeModalCallback = null;
        }
        this.currentModal.hide();
      }
    },
  },
});
