<template>
  <div>
    <div style="display: flex; flex-direction: column; gap: 1rem">
      <div style="display: flex; justify-content: space-between; align-items: end">
        <div class="btn-group" role="group" aria-label="Basic radio toggle button group">
          <input
            type="radio"
            class="btn-check"
            name="timeframe"
            id="timeframe-week"
            autocomplete="off"
            :checked="chosenTimeframe === 'week'"
            @click="chosenTimeframe = 'week'"
            :disabled="state != 'done'"
          />
          <label
            class="btn btn-outline-info"
            for="timeframe-week"
            :style="{
              backgroundColor: chosenTimeframe === 'week' ? '' : 'white',
            }"
            >Week</label
          >
          <input
            type="radio"
            class="btn-check"
            name="timeframe"
            id="timeframe-month"
            autocomplete="off"
            :checked="chosenTimeframe === 'month'"
            @click="chosenTimeframe = 'month'"
            :disabled="state != 'done'"
          />
          <label
            class="btn btn-outline-info"
            for="timeframe-month"
            :style="{
              backgroundColor: chosenTimeframe === 'month' ? '' : 'white',
            }"
            >Month</label
          >
          <input
            type="radio"
            class="btn-check"
            name="timeframe"
            id="timeframe-year"
            autocomplete="off"
            :checked="chosenTimeframe === 'year'"
            @click="chosenTimeframe = 'year'"
            :disabled="state != 'done'"
          />
          <label
            class="btn btn-outline-info"
            for="timeframe-year"
            :style="{
              backgroundColor: chosenTimeframe === 'year' ? '' : 'white',
            }"
            >Year</label
          >
          <input
            type="radio"
            class="btn-check"
            name="timeframe"
            id="timeframe-all"
            autocomplete="off"
            :checked="chosenTimeframe === 'all'"
            @click="chosenTimeframe = 'all'"
            :disabled="state != 'done'"
          />
          <label
            class="btn btn-outline-info"
            for="timeframe-all"
            :style="{
              backgroundColor: chosenTimeframe === 'all' ? '' : 'white',
            }"
            >All</label
          >
        </div>
        <div>
          <div
            v-if="
              state == 'initialload' ||
              state == 'nocache' ||
              state == 'refreshingcache' ||
              state == 'refreshingcachenogames'
            "
          >
            <span
              class="loading-ellipsis"
              v-if="state == 'initialload' || state == 'nocache'"
              style="color: var(--clr-accent)"
              >Loading your stats</span
            >
            <span
              class="loading-ellipsis"
              style="color: var(--clr-accent)"
              v-if="state == 'refreshingcache' || state == 'refreshingcachenogames'"
            >
              Found new games, refreshing</span
            >
          </div>

          <div v-if="state == 'doneerror'">
            Something went wrong retrieving stats, try again in a while!
          </div>
          <div v-if="state == 'donenogames'" />
          <div
            v-if="state == 'done'"
            v-tippy="{
              content:
                'Stats are updated automatically if there are new rated games, but at most once an hour',
            }"
          >
            <span style="color: var(--clr-main-lighter)">{{
              getLastUpdateText((userStatsResponse as UserStatsResponseData).updated)
            }}</span
            ><i
              class="fa-solid fa-comment-exclamation"
              style="
                vertical-align: super;
                font-size: 0.8rem;
                margin-left: 0.2rem;
                color: var(--clr-main-lightest);
              "
            />
          </div>
        </div>
      </div>
      <div v-if="state == 'donenogames'" style="width: 100%; text-align: center">
        <h3>You haven't played any games yet</h3>
        <a href="/" class="btn btn-info" role="button">Play some games</a>
      </div>
      <div
        v-if="state != 'donenogames'"
        style="
          display: flex;
          gap: 1rem;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        "
      >
        <div
          :class="
            'top-two' + (state == 'initialload' || state == 'nocache' ? ' pulsate-load-strong' : '')
          "
        >
          <div
            class="card"
            :style="{
              padding: '1rem',
              width: '100%',
              textAlign: 'center',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '1rem',
              flexGrow: '1',
              height: isMobileWidth() ? '50rem' : '43rem',
            }"
          >
            <h3>
              <i class="fa-duotone fa-arrow-trend-up" style="color: var(--clr-accent)" />
              Rating
            </h3>
            <div class="rating-box">
              <div class="rating-box-boxes">
                <RatingsBox
                  :loading="state != 'done' && state != 'refreshingcache'"
                  :lowest-rating-date="getRatingRecords(chosenTimeframe).lowestDate"
                  :lowest-rating="getRatingRecords(chosenTimeframe).lowest"
                  :highest-rating-date="getRatingRecords(chosenTimeframe).highestDate"
                  :highest-rating="getRatingRecords(chosenTimeframe).highest"
                  :current-rating="quickStats?.ratingInfo?.currentRating?.rating?.toFixed(0) ?? 0"
                />
                <div class="rating-box-box-boxes">
                  <InfoBox
                    :loading="state != 'done' && state != 'refreshingcache'"
                    :heading="'Change ' + chosenTimeframe"
                    :sub-heading="
                      getRatingChangeElement(
                        getRatingChange(
                          quickStats?.ratingInfo?.currentRating?.rating ?? 0,
                          ratingHistory,
                          chosenTimeframe,
                          quickStats?.ratingInfo?.historyChange
                        )
                      )
                    "
                    v-tippy="{
                      content: 'How much your rating has changed in the selected timeframe',
                    }"
                  />
                  <InfoBox
                    :loading="state != 'done' && state != 'refreshingcache'"
                    heading="'Change 24 hours'"
                    :sub-heading="
                      getRatingChangeElement(
                        getRatingChangeDiff(
                          quickStats?.ratingInfo?.currentRating?.rating,
                          quickStats?.ratingInfo?.historyChange?.lastDay
                        )
                      )
                    "
                    v-tippy="{
                      content: 'How much your rating has changed in the last 24 hours',
                    }"
                  />
                </div>
              </div>
              <div style="width: 100%; height: 100%">
                <RatingChart
                  :loading="state != 'done' && state != 'refreshingcache'"
                  :initial-zoom="30"
                  :ratingHistory="ratingHistory"
                  :timeframe="chosenTimeframe"
                />
              </div>
            </div>
          </div>
          <div style="width: 100%">
            <div
              class="card"
              :style="{
                padding: '1rem',
                width: '100%',
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '1rem',
                height: isMobileWidth() ? '38rem' : '43rem',
              }"
            >
              <div style="display: flex; flex-direction: column; align-items: center">
                <h3>
                  <i class="fa-solid fa-chess" style="color: var(--clr-accent)" />
                  Games
                </h3>
                <span>(only rated games)</span>
              </div>
              <HorizontalBar
                :loading="state != 'done' && state != 'refreshingcache'"
                :wins="stats.allGames.wins"
                :draws="stats.allGames.draws"
                :losses="stats.allGames.losses"
              />
              <div style="display: flex; gap: 0.5rem; flex-direction: column">
                <div class="game-box-boxes">
                  <InfoBox
                    :loading="state != 'done' && state != 'refreshingcache'"
                    heading="Games played"
                    :sub-heading="stats.allGames.total + ''"
                    v-tippy="{
                      content: 'Total games played',
                    }"
                  />

                  <InfoBox
                    :loading="state != 'done' && state != 'refreshingcache'"
                    heading="Games/day"
                    :sub-heading="
                      getGamesPerDay(stats.allGames.total, chosenTimeframe, ratingHistory)
                    "
                    v-tippy="{
                      content: 'Games played per day on average',
                    }"
                  />

                  <InfoBox
                    :loading="state != 'done' && state != 'refreshingcache'"
                    heading="Days in a row"
                    :sub-heading="getGamesInARow(ratingHistory).consecutiveDaysWithEntries"
                    v-tippy="{
                      content: 'Rated games days in a row',
                    }"
                  />

                  <InfoBox
                    :loading="state != 'done' && state != 'refreshingcache'"
                    heading="Longest win streak"
                    :sub-heading="
                      userStatsResponse?.gameResults
                        ? getLongestWinStreak(JSON.parse(userStatsResponse.gameResults))
                        : (quickStats?.longestWinStreak ?? 0)
                    "
                    v-tippy="{
                      content: 'Longest consecutive wins',
                    }"
                  />
                </div>
                <div class="game-box-boxes">
                  <InfoBox
                    :loading="state != 'done' && state != 'refreshingcache'"
                    heading="Win/loss"
                    :sub-heading="getWinLossText(stats.allGames.wins, stats.allGames.losses)"
                    v-tippy="{
                      content:
                        'Win loss ratio, how many wins compared to losses (draws not counted)',
                    }"
                  />

                  <InfoBox
                    :loading="state != 'done' && state != 'refreshingcache'"
                    heading="Average Game Length"
                    :sub-heading="
                      userStatsResponse?.averageMoves !== undefined
                        ? Number(userStatsResponse.averageMoves).toFixed(0) + ' moves'
                        : '-'
                    "
                    v-tippy="{
                      content: 'Average moves per game.',
                    }"
                  />
                  <InfoBox
                    :loading="state != 'done' && state != 'refreshingcache'"
                    heading="Longest break"
                    :sub-heading="getGamesInARow(ratingHistory).consecutiveMissingDays"
                    v-tippy="{
                      content: 'Most days without rated games in a row',
                    }"
                  />
                </div>
                <div class="game-box-boxes">
                  <InfoBox
                    :loading="state != 'done' && state != 'refreshingcache'"
                    heading="Short Games Win Rate"
                    :sub-heading="getShortGamesWinRate()"
                    v-tippy="{
                      content: 'Win rate in games under 20 moves',
                    }"
                  />
                  <InfoBox
                    :loading="state != 'done' && state != 'refreshingcache'"
                    heading="Long Games Win Rate"
                    :sub-heading="getLongGamesWinRate()"
                    v-tippy="{
                      content: 'Win rate in games over 50 moves',
                    }"
                  />
                  <InfoBox
                    :loading="state != 'done' && state != 'refreshingcache'"
                    heading="White/Black Win Rate"
                    :sub-heading="getWhiteBlackWinRate()"
                    :black-white-rating="true"
                    v-tippy="{
                      content:
                        'Win percentages split by playing white vs. black. Shows your performance as W (white) and B (black).',
                    }"
                  />
                </div>
                <div class="game-box-boxes">
                  <InfoBox
                    v-if="stats.comebackWinRate !== undefined && stats.bestWin?.botId"
                    :loading="state != 'done' && state != 'refreshingcache'"
                    heading="Challenging Wins Rate"
                    :sub-heading="(stats.comebackWinRate * 100).toFixed(1) + ' %'"
                    footer="Wins from tough games show growth."
                    v-tippy="{
                      content:
                        'How often your wins come from challenging games where you beat stronger opponents, suggesting you overcame tougher odds',
                    }"
                  />

                  <InfoBox
                    v-if="stats.quickVictoryAvgMoves > 0"
                    :loading="state != 'done' && state != 'refreshingcache'"
                    heading="Quick Victory"
                    :sub-heading="stats.quickVictoryAvgMoves.toFixed(0) + ' moves'"
                    footer="Average moves in winning games"
                    v-tippy="{
                      content: 'Average number of moves in games that you won',
                    }"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          :class="state == 'initialload' || state == 'nocache' ? ' pulsate-load-strong' : ''"
          style="display: flex; flex-direction: column; width: 100%"
        >
          <div
            class="card"
            style="
              padding: 1rem;
              text-align: center;
              display: flex;
              flex-direction: column;
              align-items: center;
              gap: 1rem;
              width: 100%;
            "
          >
            <h3>
              <i class="fa-solid fa-web-awesome" style="color: var(--clr-accent)" />
              Bot facts
            </h3>
            <span v-if="stats.allGames.total == 0"
              >Play some more games to see more stats here</span
            >
            <div class="bots-container">
              <InfoStyleBox
                :loading="state != 'done' && state != 'refreshingcache'"
                heading="Beaten bots"
                :sub-heading="useUserStore().user.data?.beatenBots?.length ?? 0"
                styleName="star"
                :footer="'Different bots you\'ve defeated'"
                v-tippy="{
                  content: 'Number of different beaten bots',
                }"
              />
              <InfoStyleBox
                :loading="state != 'done' && state != 'refreshingcache'"
                heading="Daily matchup"
                :sub-heading="useUserStore().user.data?.dailyMatchupStreaks?.best ?? 0"
                styleName="check"
                :footer="'Longest streak in daily matchups'"
                v-tippy="{
                  content: 'Highest daily matchup win streak',
                }"
              />
              <InfoStyleBox
                v-if="stats.bestPlaystyle != null"
                :loading="state != 'done' && state != 'refreshingcache'"
                heading="Best style"
                :sub-heading="(stats.bestPlaystyle.winRate * 100).toFixed(1) + ' %'"
                :styleName="stats.bestPlaystyle.playstyle.name"
                :footer="'Style you excel against'"
                v-tippy="{
                  content: 'The type of bot you have the highest win rate against',
                }"
              />
              <InfoStyleBox
                v-if="stats.worstPlaystyle != null"
                :loading="state != 'done' && state != 'refreshingcache'"
                heading="Worst style"
                :sub-heading="(stats.worstPlaystyle.winRate * 100).toFixed(1) + ' %'"
                :styleName="stats.worstPlaystyle.playstyle.name"
                :footer="'Style you struggle with'"
                v-tippy="{
                  content: 'The type of bot you have the lowest win rate against',
                }"
              />
              <TopBotsTop
                v-if="stats.favoriteBots.length > 0"
                :loading="state != 'done' && state != 'refreshingcache'"
                :bots="stats.favoriteBots"
                v-tippy="{
                  content: 'Top 5 bots played against',
                }"
              />
              <InfoBotBox
                v-if="stats.equalBots.length > 0"
                :loading="state != 'done' && state != 'refreshingcache'"
                heading="Equal match"
                :sub-heading="equalBotText(stats.equalBots[0])"
                :botId="stats.equalBots[0].botId"
                :footer="`(${formatCount(stats.equalBots[0].wins, 'win')}, ${formatCount(
                  stats.equalBots[0].losses,
                  'loss',
                  'losses'
                )})  \n  Your most balanced matchup`"
                v-tippy="{
                  content: 'The bot you have the most equal score against, score is wins - losses',
                }"
              />
              <InfoBotBox
                v-if="stats.nemesisBots.length > 0"
                :loading="state != 'done' && state != 'refreshingcache'"
                heading="Nemesis"
                :sub-heading="(stats.nemesisBots[0].winRate * 100).toFixed(1) + ' %'"
                :botId="stats.nemesisBots[0].botId"
                :footer="
                  ' (' +
                  formatCount(stats.nemesisBots[0].wins, 'win') +
                  ', ' +
                  formatCount(stats.nemesisBots[0].losses, 'loss', 'losses') +
                  ') \n Your toughest opponent '
                "
                v-tippy="{
                  content:
                    'A bot you just can\'t seem to get the upper hand against, the worst win rate',
                }"
              />

              <InfoBotBox
                v-if="stats.easyBots.length > 0"
                :loading="state != 'done' && state != 'refreshingcache'"
                heading="Pushover"
                :sub-heading="stats.easyBots[0].wins + '-' + stats.easyBots[0].losses"
                :botId="stats.easyBots[0].botId"
                :footer="
                  ' (' +
                  formatCount(stats.easyBots[0].wins, 'win') +
                  ', ' +
                  formatCount(stats.easyBots[0].losses, 'loss', 'losses') +
                  ') \n Your easiest matchup'
                "
                v-tippy="{
                  content:
                    'The bot you have the most success against, showing the difference between wins and losses',
                }"
              />

              <InfoBotBox
                v-if="stats.bestWin?.rating != null"
                :loading="state != 'done' && state != 'refreshingcache'"
                heading="Best win"
                :sub-heading="stats.bestWin.rating + ' rating'"
                :botId="stats.bestWin.botId"
                :footer="'Highest rated opponent you\'ve defeated'"
                v-tippy="{
                  content: 'The highest rated bot you\'ve beaten',
                }"
              />

              <InfoBotBox
                v-if="stats.worstLoss?.rating != null"
                :loading="state != 'done' && state != 'refreshingcache'"
                heading="Worst loss"
                :sub-heading="stats.worstLoss.rating + ' rating'"
                :botId="stats.worstLoss.botId"
                :footer="'Lowest rated opponent that defeated you'"
                v-tippy="{
                  content: 'The lowest rated bot you\'ve lost against',
                }"
              />
              <TopPlaystyles
                v-if="stats.favoritePlaystyles.length > 0"
                :loading="state != 'done' && state != 'refreshingcache'"
                :games="stats.favoritePlaystyles"
                v-tippy="{
                  content: 'Games against the different bot playstyles',
                }"
              />

              <InfoBotBox
                v-if="stats.bestHigherRatedBot !== undefined && stats.bestHigherRatedBot.wins > 0"
                :loading="state != 'done' && state != 'refreshingcache'"
                heading="Being the underdog"
                :sub-heading="stats.bestHigherRatedBot.wins + '-' + stats.bestHigherRatedBot.losses"
                :botId="stats.bestHigherRatedBot.botId"
                :footer="
                  '(' +
                  formatCount(stats.bestHigherRatedBot.wins, 'win') +
                  ', ' +
                  formatCount(stats.bestHigherRatedBot.losses, 'loss', 'losses') +
                  ') \n Your best higher-rated opponent'
                "
                v-tippy="{
                  content: 'Shows your best record against a bot rated higher than you',
                }"
              />

              <InfoBotBox
                v-if="stats.bestGiantSlayerBot && stats.giantSlayerWins > 0"
                :loading="state != 'done' && state != 'refreshingcache'"
                heading="Giant Slayer"
                :sub-heading="formatCount(stats.bestGiantSlayerBot.wins, 'win')"
                :botId="stats.bestGiantSlayerBot.botId"
                :footer="'Wins vs. bots 200+ above your rating.'"
                v-tippy="{
                  content:
                    'Counts wins achieved against bots whose ratings are at least 200 points higher than the player’s rating at the time of the game.',
                }"
              />

              <InfoBotBox
                v-if="
                  stats.slipUpLossRate !== undefined &&
                  stats.slipUpLossRate > 0 &&
                  stats.nemesisBots.length > 0
                "
                :loading="state != 'done' && state != 'refreshingcache'"
                heading="Slip-Up"
                :sub-heading="(stats.slipUpLossRate * 100).toFixed(1) + ' %'"
                :botId="stats.worstLoss?.botId || stats.nemesisBots[0]?.botId"
                :footer="'Loss rate vs. bots –200 below your rating'"
                v-tippy="{
                  content:
                    'Calculates losses when the opponent\'s rating is at least 200 points lower than your rating, highlighting unexpected defeats',
                }"
              />

              <InfoBotBox
                v-if="
                  stats.slipUpLossRate !== undefined &&
                  stats.winRateAgainstLowerRated > 0 &&
                  stats.easyBots.length > 0
                "
                :loading="state != 'done' && state != 'refreshingcache'"
                heading="Home Turf"
                :sub-heading="(stats.winRateAgainstLowerRated * 100).toFixed(1) + ' %'"
                :botId="stats.easyBots[0]?.botId"
                :footer="'Win rate vs. lower rated bots'"
                v-tippy="{
                  content:
                    'Determines win percentage against bots with lower ratings than your rating at game time',
                }"
              />

              <InfoBotBox
                v-if="stats.perfectRecordBots && stats.perfectRecordBots.length > 0"
                :loading="state != 'done' && state != 'refreshingcache'"
                heading="Perfect Record"
                :sub-heading="formatCount(stats.perfectRecordBots.length, 'bot')"
                :botId="stats.perfectRecordBots[0].botId"
                :footer="'Bots you\'ve never lost against (min. ' + formatCount(3, 'game') + ')'"
                v-tippy="{
                  content: 'Shows bots you have played at least 3 games against and never lost to',
                }"
              />
            </div>
          </div>
        </div>

        <div class="stats-section" v-if="state === 'done' || state === 'refreshingcache'">
          <h3 class="section-heading">
            <i class="fa-solid fa-chess-board" style="color: var(--clr-accent)" />
            Chess Activity & Analysis
          </h3>
          <div class="stats-dashboard-grid">
            <div class="card stats-card">
              <ActivityCalendar
                :loading="state !== 'done' && state !== 'refreshingcache'"
                :gamesPerDay="parsedGamesPerDay"
                v-tippy="{
                  content: 'Your chess activity calendar showing games played per day.',
                }"
              />
            </div>

            <div class="card stats-card">
              <WinRateByTimeOfDay
                :loading="state !== 'done' && state !== 'refreshingcache'"
                :rawStats="rawStats"
                :ratingHistory="ratingHistory"
                :userStatsResponse="userStatsResponse"
                v-tippy="{
                  content: 'Win rate distribution over 24 hours.',
                }"
              />
            </div>
          </div>

          <div class="stats-dashboard-grid" style="margin-top: 1rem">
            <div class="card stats-card">
              <GamesPerWeekday
                :loading="state !== 'done' && state !== 'refreshingcache'"
                :gamesPerDay="parsedGamesPerDay"
                v-tippy="{
                  content: 'Number of games played each weekday.',
                }"
              />
            </div>

            <div class="card stats-card">
              <WinRateByWeekday
                :loading="state != 'done' && state != 'refreshingcache'"
                :rawStats="rawStats"
                :ratingHistory="ratingHistory"
                :userStatsResponse="userStatsResponse"
                v-tippy="{
                  content: 'Win rate breakdown by day of the week.',
                }"
              />
            </div>
          </div>

          <div class="stats-dashboard-centered" style="margin-top: 1.5rem">
            <div class="card stats-card" style="max-width: 500px; width: 100%">
              <GameTerminationBreakdown
                :loading="state != 'done' && state != 'refreshingcache'"
                heading="Game Termination Breakdown"
                :game-results="
                  userStatsResponse?.gameResults ? JSON.parse(userStatsResponse.gameResults) : []
                "
                v-tippy="{
                  content: 'How games ended: resignations, timeouts, mate, etc.',
                }"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ref, watch } from 'vue';

  import ActivityCalendar from '@/components/stats/ActivityCalendar.vue';
  import GameTerminationBreakdown from '@/components/stats/GameTerminationBreakdown.vue';
  import GamesPerWeekday from '@/components/stats/GamesPerWeekday.vue';
  import HorizontalBar from '@/components/stats/HorizontalBar.vue';
  import InfoBotBox from '@/components/stats/InfoBotBox.vue';
  import InfoBox from '@/components/stats/InfoBox.vue';
  import InfoStyleBox from '@/components/stats/InfoStyleBox.vue';
  import RatingChart from '@/components/stats/RatingChart.vue';
  import RatingsBox from '@/components/stats/RatingsBox.vue';
  import TopBotsTop from '@/components/stats/TopBotsTop.vue';
  import TopPlaystyles from '@/components/stats/TopPlaystyles.vue';
  import WinRateByTimeOfDay from '@/components/stats/WinRateByTimeOfDay.vue';
  import WinRateByWeekday from '@/components/stats/WinRateByWeekday.vue';
  import { useChallengingWinsRate } from '@/composables/stats/useChallengingWinsRate';
  import { useGameStats } from '@/composables/stats/useGameStats';
  import { useGamesActivity } from '@/composables/stats/useGamesActivity';
  import { useGiantSlayerStats } from '@/composables/stats/useGiantSlayerStats';
  import { usePerfectRecordStats } from '@/composables/stats/usePerfectRecordStats';
  import { useQuickVictoryStats } from '@/composables/stats/useQuickVictoryStats';
  import { useRatedBotsStats } from '@/composables/stats/useRatedBotsStats';
  import { useRatingChange } from '@/composables/stats/useRatingChange';
  import { useRatingChangeFormatting } from '@/composables/stats/useRatingChangeFormatting';
  import { useRatingRecords } from '@/composables/stats/useRatingRecords';
  import { useSlipUpStats } from '@/composables/stats/useSlipUpStats';
  import { useStreakInfo } from '@/composables/stats/useStreakInfo';
  import { useWinLossStats } from '@/composables/stats/useWinLossStats';
  import { useBotsStore } from '@/stores/botStore';
  import { useUserStore } from '@/stores/userStore';
  import type { UserStatsResponseData } from '@/types/apitypes';
  import type { GiantSlayerBot, HigherRatedBot, PerfectRecordBot } from '@/types/statstypes';
  import { personaCategories } from '@/util/personplaycategory';
  import { formatCount, getLastUpdateText, isMobileWidth } from '@/util/util';

  const state = ref<
    | 'initialload'
    | 'nocache'
    | 'refreshingcache'
    | 'refreshingcachenogames'
    | 'done'
    | 'doneerror'
    | 'donenogames'
  >('initialload');

  const ratingRecords = ref();
  const ratingHistory = ref();
  const chosenTimeframe = ref<'week' | 'month' | 'year' | 'all'>('all');

  const rawStats = ref();

  const quickStats = ref();

  // Store the full stats response from the API
  const userStatsResponse = ref<UserStatsResponseData>();

  // Store the parsed gamesPerDay data
  const parsedGamesPerDay = ref<any[]>([]);

  // Define stats before using it
  const stats = ref({
    allGames: {
      total: 0,
      wins: 0,
      draws: 0,
      losses: 0,
    },
    winLossRatio: 0.0,
    drawRate: 0.0,
    userRating: 0,
    favoriteBots: [
      {
        botId: '',
        total: 0,
        wins: 0,
        draws: 0,
        losses: 0,
      },
    ],
    easyBots: [
      {
        botId: '',
        winRate: 0.0,
        winLossDiff: 0,
        total: 0,
        wins: 0,
        draws: 0,
        losses: 0,
      },
    ],
    nemesisBots: [
      {
        botId: '',
        winRate: 0.0,
        total: 0,
        wins: 0,
        draws: 0,
        losses: 0,
      },
    ],
    equalBots: [
      {
        botId: '',
        winRate: 0.0,
        score: 0,
        total: 0,
        wins: 0,
        draws: 0,
        losses: 0,
      },
    ],
    bestWin: {
      botId: '',
      rating: 0,
    },
    worstLoss: {
      botId: '',
      rating: 0,
    },
    favoritePlaystyles: [
      {
        playstyle: {
          id: 0,
          name: '',
          tooltip: '',
          bg: '',
          img: '',
        },
        winRate: 0,
        total: 0,
        wins: 0,
        draws: 0,
        losses: 0,
      },
    ],
    bestPlaystyle: {
      playstyle: {
        id: 0,
        name: '',
        tooltip: '',
        bg: '',
        img: '',
      },
      total: 0,
      winRate: 0.0,
      wins: 0,
      draws: 0,
      losses: 0,
    },
    worstPlaystyle: {
      playstyle: {
        id: 0,
        name: '',
        tooltip: '',
        bg: '',
        img: '',
      },
      total: 0,
      winRate: 0.0,
      wins: 0,
      draws: 0,
      losses: 0,
    },
    winRateAgainstLowerRated: 0.0,
    winRateAgainstHigherRated: 0.0,
    giantSlayerWins: 0,
    bestGiantSlayerBot: undefined as GiantSlayerBot | undefined,
    slipUpLossRate: 0.0,
    bestHigherRatedBot: undefined as HigherRatedBot | undefined,
    perfectRecordBots: [] as PerfectRecordBot[],
    comebackWinRate: 0.0,
    quickVictoryAvgMoves: 0,
  });

  // Use composables
  const { updateRatingChanges } = useRatingChange(ratingHistory);
  const { calculateStreakInfo } = useStreakInfo(ratingHistory, chosenTimeframe);
  const { getRatingRecords } = useRatingRecords(ratingRecords);
  const { getWinLossText, equalBotText, ratioPercent } = useWinLossStats();
  const { getGamesInARow, getGamesPerDay, getLongestWinStreak } = useGamesActivity(chosenTimeframe);
  const { getRatingChangeDiff, getRatingChangeElement, getRatingChange } =
    useRatingChangeFormatting();

  // Initialize data when stats are loaded
  watch(
    [rawStats, ratingHistory, quickStats],
    () => {
      if (rawStats.value && ratingHistory.value && quickStats.value) {
        // First parse the stats to calculate bestPlaystyle, worstPlaystyle, etc.
        processStats();

        if (quickStats.value?.ratingInfo?.currentRating) {
          updateRatingChanges(
            quickStats.value.ratingInfo.currentRating.rating,
            quickStats.value.ratingInfo.historyChange
          );
        }
        calculateStreakInfo();
      } else {
        console.log('Missing required data for stats generation');
      }
    },
    { immediate: true }
  );

  watch(chosenTimeframe, () => {
    processStats();
  });

  getStats();

  function getStats() {
    Promise.all([useUserStore().getStats(), useUserStore().getQuickStats()]).then(
      ([statsResponse, quickStatsResponse]) => {
        userStatsResponse.value = statsResponse;
        quickStats.value = quickStatsResponse;

        // Process gamesPerDay data from the backend
        if (userStatsResponse.value && userStatsResponse.value.gamesPerDay) {
          // Process the gamesPerDay data to ensure proper date format
          const processedData = userStatsResponse.value.gamesPerDay.map((entry: any) => {
            // Handle Firestore timestamp format with _seconds and _nanoseconds
            if (entry.game_date) {
              // Create a proper FirestoreTimestamp object that ActivityCalendar expects
              return {
                game_date: {
                  _seconds:
                    typeof entry.game_date === 'object' && entry.game_date._seconds
                      ? entry.game_date._seconds
                      : typeof entry.game_date === 'object' && entry.game_date.value
                        ? Math.floor(new Date(entry.game_date.value).getTime() / 1000)
                        : Math.floor(new Date().getTime() / 1000),
                  _nanoseconds: 0,
                },
                games_played: entry.games_played,
              };
            }
            return entry;
          });
          parsedGamesPerDay.value = processedData;
        }

        if (userStatsResponse.value.failure) {
          state.value = 'doneerror';
        } else if (userStatsResponse.value.poll) {
          if (userStatsResponse.value?.botStats == null) {
            state.value = 'nocache';
          } else {
            rawStats.value = JSON.parse(userStatsResponse.value.botStats);
            // Process raw stats data
            ratingRecords.value = JSON.parse(userStatsResponse.value.ratingRecords)[0];
            if (rawStats.value.length == 0) {
              state.value = 'refreshingcachenogames';
            } else {
              processStats();
              ratingHistory.value = userStatsResponse.value.ratingHistory;
              state.value = 'refreshingcache';
            }
          }
          setTimeout(getStats, 1000);
        } else {
          rawStats.value = JSON.parse(userStatsResponse.value.botStats);
          ratingRecords.value = JSON.parse(userStatsResponse.value.ratingRecords)[0];
          if (rawStats.value.length == 0) {
            state.value = 'donenogames';
          } else {
            processStats();
            ratingHistory.value = userStatsResponse.value.ratingHistory;

            // Generate activity data when rating history is loaded
            if (ratingHistory.value && rawStats.value && Array.isArray(rawStats.value)) {
              // Create a deep copy of rawStats to ensure it's properly assigned
              ratingHistory.value.rawStats = JSON.parse(JSON.stringify(rawStats.value));
            }

            // Set today's games count in the rating history if available
            if (ratingHistory.value) {
              const todayGames = quickStats.value?.gamesToday || 0;
              ratingHistory.value.todayGames = todayGames;
            }

            state.value = 'done';
          }
        }
      }
    );
  }

  // Game stats functions are now imported from useGameStats composable
  const { getShortGamesWinRate, getLongGamesWinRate, getWhiteBlackWinRate } = useGameStats(
    userStatsResponse,
    ratingHistory
  );

  function processStats() {
    if (rawStats.value == null) {
      console.error('No stats to parse');
      return;
    }

    // Prepare the data for the composable
    const parsedRawStats = rawStats.value.map((item: any) => {
      const result: any = { bot_id: item.bot_id }; // Start with the bot_id
      const fields = ['games', 'wins', 'losses', 'draws'];

      // Loop through the fields and assign the appropriate timeframe value
      fields.forEach((field) => {
        result[field] = item[`${field}_${chosenTimeframe.value}`]; // Access the field dynamically
      });

      return result;
    });

    // Find the overall stats entry
    const all = parsedRawStats.find((s: any) => s.bot_id == null);

    // Set overall stats
    stats.value.allGames = {
      total: all.games,
      wins: all.wins,
      draws: all.draws,
      losses: all.losses,
    };

    // Process bot-specific statistics
    parseStats(parsedRawStats);
  }

  // Process bot statistics
  function parseStats(parsedRawStats: any) {
    if (!parsedRawStats || !stats.value) {
      return;
    }

    // Process bot-specific stats
    const userRating = useUserStore().user?.data?.givenRating?.rating || 0;

    // Filter out bots with no games played
    const validBots = parsedRawStats.filter((bot: any) => bot.bot_id !== null && bot.games > 0);

    // Favorite bots
    stats.value.favoriteBots = validBots
      .filter((bot: any) => bot.games > 0) // Keep only bots with games played
      .sort((a: any, b: any) => b.games - a.games) // Sort in descending order by games
      .map((bot: any) => {
        return {
          botId: bot.bot_id,
          total: bot.games,
          wins: bot.wins,
          draws: bot.draws,
          losses: bot.losses,
        };
      })
      .slice(0, 5); // Get the first 5

    // Easy bots
    stats.value.easyBots = validBots
      .filter((bot: any) => bot.games > 0 && bot.wins > 0) // Keep only bots with games played and at least one win
      .sort((a: any, b: any) => {
        // First prioritize bots with no losses
        if (a.losses === 0 && b.losses > 0) return -1;
        if (a.losses > 0 && b.losses === 0) return 1;
        // If both have no losses, prioritize more wins
        if (a.losses === 0 && b.losses === 0) {
          return b.wins - a.wins;
        }
        // For bots with losses, prioritize those with better win-loss ratio
        const ratioA = a.wins / (a.losses || 1); // Avoid division by zero
        const ratioB = b.wins / (b.losses || 1);
        if (Math.abs(ratioA - ratioB) > 0.1) {
          // If ratio difference is significant
          return ratioB - ratioA;
        }

        // If ratios are similar, prioritize bots with more games played
        return b.wins - a.wins;
      })
      .map((bot: any) => {
        return {
          botId: bot.bot_id,
          winRate: ratioPercent(bot.wins, bot.losses),
          winLossDiff: bot.wins - bot.losses,
          total: bot.games,
          wins: bot.wins,
          draws: bot.draws,
          losses: bot.losses,
        };
      })
      .slice(0, 5);

    // Nemesis bots
    stats.value.nemesisBots = validBots
      .filter((bot: any) => bot.losses > 0) // Keep only bots that have beaten you
      .sort((a: any, b: any) => {
        // Sort by number of losses (descending) to prioritize bots that have beaten you more times
        return b.losses - a.losses;
      })
      .map((bot: any) => {
        return {
          botId: bot.bot_id,
          winRate: ratioPercent(bot.wins, bot.losses),
          total: bot.games,
          wins: bot.wins,
          draws: bot.draws,
          losses: bot.losses,
        };
      })
      .slice(0, 5);

    stats.value.equalBots = validBots
      .filter((bot: any) => bot.games > 0 && (bot.wins > 0 || bot.losses > 0)) // Keep only bots with games played and at least one win or loss
      .sort((a: any, b: any) => {
        // This is the plus minus score, but also takes the draws into account, so many draws puts the bot higher in equal list
        const scoreA = Math.abs(a.wins - a.losses) - a.draws;
        const scoreB = Math.abs(b.wins - b.losses) - b.draws;
        return scoreA - scoreB; // Sort by score, ascending
      })
      .map((bot: any) => {
        return {
          botId: bot.bot_id,
          winRate: ratioPercent(bot.wins, bot.losses),
          score: bot.wins - bot.losses,
          total: bot.games,
          wins: bot.wins,
          draws: bot.draws,
          losses: bot.losses,
        };
      })
      .slice(0, 5);

    const playStylesData: any = {};
    const personaCategoires = personaCategories();
    validBots
      .filter((bot: any) => bot.games > 0)
      .forEach((bot: any) => {
        const playstyle = useBotsStore().getBot(bot.bot_id)?.strength.playstyle_category;

        if (playstyle != null) {
          if (playStylesData[playstyle] == null) {
            playStylesData[playstyle] = {
              playstyle: playstyle,
              total: 0,
              wins: 0,
              draws: 0,
              losses: 0,
            };
          }
          playStylesData[playstyle].total += bot.games;
          playStylesData[playstyle].wins += bot.wins;
          playStylesData[playstyle].draws += bot.draws;
          playStylesData[playstyle].losses += bot.losses;
        }
      });

    function getObjectValues(obj: Record<string, any>): any[] {
      const valuesArray: any[] = [];
      for (const key in obj) {
        // eslint-disable-next-line no-prototype-builtins
        if (obj.hasOwnProperty(key)) {
          valuesArray.push(obj[key]);
        }
      }
      return valuesArray;
    }
    stats.value.favoritePlaystyles = getObjectValues(playStylesData)
      .map((playstyle: any) => {
        return {
          playstyle: personaCategoires.find((p: any) => p.id == playstyle.playstyle)!,
          winRate: ratioPercent(playstyle.wins, playstyle.losses),
          total: playstyle.total,
          wins: playstyle.wins,
          draws: playstyle.draws,
          losses: playstyle.losses,
        };
      })
      .sort((a: any, b: any) => a.playstyle.id - b.playstyle.id);

    stats.value.bestPlaystyle = JSON.parse(JSON.stringify(stats.value.favoritePlaystyles)).sort(
      (a: any, b: any) => b.winRate - a.winRate
    )[0];

    stats.value.worstPlaystyle = JSON.parse(JSON.stringify(stats.value.favoritePlaystyles)).sort(
      (a: any, b: any) => a.winRate - b.winRate
    )[0];

    stats.value.bestWin = validBots
      .filter((bot: any) => bot.wins > 0 && useBotsStore().getBot(bot.bot_id) != null)
      .map((bot: any) => {
        return {
          botId: bot.bot_id,
          rating: useBotsStore().getBot(bot.bot_id).strength.estimated_elo,
        };
      })
      .sort((a: any, b: any) => {
        return b.rating - a.rating;
      })[0];

    stats.value.worstLoss = validBots
      .filter((bot: any) => bot.losses > 0 && useBotsStore().getBot(bot.bot_id) != null)
      .map((bot: any) => {
        return {
          botId: bot.bot_id,
          rating: useBotsStore().getBot(bot.bot_id).strength.estimated_elo,
        };
      })
      .sort((a: any, b: any) => {
        return a.rating - b.rating;
      })[0];

    // Calculate win rates against higher and lower rated bots
    const { calculateHigherRatedStats, calculateLowerRatedStats } = useRatedBotsStats();

    // Calculate higher-rated bots statistics
    const { winRateAgainstHigherRated, bestHigherRatedBot } = calculateHigherRatedStats(
      validBots,
      userRating
    );

    // Set the values in the stats object
    stats.value.winRateAgainstHigherRated = winRateAgainstHigherRated;
    if (bestHigherRatedBot) {
      stats.value.bestHigherRatedBot = bestHigherRatedBot;
    }

    // Calculate lower-rated bots statistics (Home Turf)
    const { winRateAgainstLowerRated } = calculateLowerRatedStats(validBots, userRating);

    // Set the values in the stats object
    stats.value.winRateAgainstLowerRated = winRateAgainstLowerRated;

    // Calculate Giant Slayer wins (wins against bots +200 above player's rating)
    // Make sure we have a valid user rating
    const currentUserRating = quickStats.value?.ratingInfo?.currentRating?.rating || 0;

    stats.value.userRating = currentUserRating;

    // Use the Giant Slayer composable to calculate stats
    const { calculateGiantSlayerStats } = useGiantSlayerStats();
    const { giantSlayerWins, bestGiantSlayerBot } = calculateGiantSlayerStats(
      validBots,
      currentUserRating
    );

    // Set the values in the stats object
    stats.value.giantSlayerWins = giantSlayerWins;
    if (bestGiantSlayerBot) {
      stats.value.bestGiantSlayerBot = bestGiantSlayerBot;
    }

    // Calculate Slip-Up loss rate (losses against bots -200 below player's rating)
    const { calculateSlipUpStats } = useSlipUpStats();
    const { slipUpLossRate } = calculateSlipUpStats(validBots, userRating);

    // Set the values in the stats object
    stats.value.slipUpLossRate = slipUpLossRate;

    // Calculate Comeback King percentage (percentage of games won after being at a disadvantage)
    const { calculateChallengingWinsRate } = useChallengingWinsRate();
    const totalWins = stats.value.allGames.wins;
    const { comebackWinRate } = calculateChallengingWinsRate(validBots, userRating, totalWins);

    // Set the values in the stats object
    stats.value.comebackWinRate = comebackWinRate;

    // Calculate Quick Victory average moves (average number of moves in winning games)
    const { calculateQuickVictoryStats } = useQuickVictoryStats();
    const { quickVictoryAvgMoves } = calculateQuickVictoryStats(validBots, userRating, totalWins);

    // Set the values in the stats object
    stats.value.quickVictoryAvgMoves = quickVictoryAvgMoves;

    // Find bots with perfect record (never lost against, minimum 3 games)
    const perfectRecordStatsCalculator = usePerfectRecordStats();
    const { perfectRecordBots } = perfectRecordStatsCalculator(validBots, 3);

    // Set the values in the stats object
    stats.value.perfectRecordBots = perfectRecordBots;
  }
</script>

<style scoped>
  .weekday-win-rate-container {
    margin-top: 1.5rem;
  }

  .top-two {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 1rem;
  }

  .bots-container {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    justify-content: center;
  }

  .rating-box {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    width: 100%;
    height: 100%;
  }

  .rating-box-boxes {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 13rem;
    flex-shrink: 0;
    /* Prevent shrinking */
    flex-grow: 0;
    /* Prevent growing */
  }

  .rating-box-box-boxes {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .rating-box-box-boxes > div {
    max-width: none !important;
    width: 100%;
  }

  .game-box-boxes {
    display: flex;
    gap: 0.5rem;
  }

  .game-box-boxes > div {
    max-width: none !important;
    width: 100%;
  }

  /* New Grid Layout Styles */
  .stats-dashboard-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1.5rem;
    width: 100%;
  }

  .stats-dashboard-centered {
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 1rem auto;
    max-width: 800px;
  }

  .stats-card {
    padding: 1.25rem;
    height: 100%;
    transition:
      transform 0.2s ease-in-out,
      box-shadow 0.2s ease-in-out;
    border-radius: 12px;
  }

  .stats-card:hover {
    transform: translateY(-3px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  }

  .component-heading {
    margin-bottom: 1rem;
    font-size: 1.2rem;
    color: var(--clr-main);
    text-align: center;
    font-weight: 600;
  }

  .activity-calendar-card {
    max-width: 650px;
    width: 100%;
    border-radius: 16px;
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
  }

  .activity-calendar-card:hover {
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.15);
    transform: translateY(-2px);
  }

  @media (max-width: 1200px) {
    .top-two {
      flex-direction: column;
      align-items: center;
    }

    .rating-box {
      flex-direction: column;
    }

    .rating-box-boxes {
      width: 100%;
    }

    .rating-box-box-boxes {
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 1rem;
    }

    .rating-box-box-boxes > div {
      max-width: none !important;
      width: 100%;
    }

    /* Responsive Grid Layout */
    .stats-dashboard-grid {
      grid-template-columns: 1fr;
    }

    /* Make the GameTerminationBreakdown responsive on mobile */
    .stats-dashboard-centered {
      padding: 0 1rem;
    }

    .activity-calendar-card {
      max-width: 100%;
      margin: 0 auto;
    }
  }
</style>
